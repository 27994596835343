// @flow strict
import * as React from 'react';
import type { SectionConfig } from '../../records/SectionConfig';
import type { Poi } from '../../records/Poi.js';
import type { Destination } from '../../records/Destination';
import type { DestinationCategoryContent } from '../../records/DestinationCategoryContent';
import type { DestinationContent } from '../../records/DestinationContent';
import type { Language } from '../intl/context';
import type { GroupToursBySection } from '../../records/Tour';

export type DestinationContextType = {
  configs: { [Language]: SectionConfig },
  destinations: { [Language]: Destination[] },
  tours: GroupToursBySection,
  attractions: Poi[],
  content: ?DestinationContent,
  categoryContent: ?DestinationCategoryContent,
  countryDestinations: Destination[],
  categoryTours: ?GroupToursBySection,
};

const DestinationContext = React.createContext<DestinationContextType>({
  configs: {
    en: {
      Categories: [],
      Tags: [],
    },
    de: {
      Categories: [],
      Tags: [],
    },
    it: {
      Categories: [],
      Tags: [],
    }
  },
  destinations: { en: [], de: [], it: [] },
  tours: {},
  attractions: [],
  countryDestinations: [],
  content: null,
  categoryContent: null,
  categoryTours: {},
});

export default DestinationContext;
