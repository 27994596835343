// @flow strict
import React from 'react';
import { Box } from '../../../../../../primitives/Essentials/index.js';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import mq from '../../../../../../services/mediaQuery/index.js';
import Image from '../../../../../Image/index.js';

type Props = {
  tourImageUrl: ?string,
  alt: string,
};

const StyledTourCoverImage = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;

  ${mq.TABLET`
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  `};
`;

const StyledImageBox = styled(Box)`
  min-width: 90px;
  max-width: 131px;
  height: 100%;
`;

const TourCoverImage = ({ tourImageUrl, alt }: Props) => {
  if (!tourImageUrl) {
    return <StyledImageBox width={1 / 4} />;
  }
  const coverImage = `${tourImageUrl}131x175?returnCrop=yes`;
  const coverImage2x = `${tourImageUrl}262x350?returnCrop=yes`;
  const coverImage3x = `${tourImageUrl}393x525?returnCrop=yes`;
  return (
    <StyledImageBox width={1 / 4}>
      <StyledTourCoverImage
        src={coverImage}
        srcSet={`${coverImage2x} 2x, ${coverImage3x} 3x`}
        alt={alt}
        lazy
      />
    </StyledImageBox>
  );
};

export default TourCoverImage;
