// @flow strict
import { algoliasearch } from 'algoliasearch';
import { APP_ID, API_KEY, INDICES } from '../../../../../etc/appConfig.js';
import type { Currency } from '../../currency/context.js';
import type { Tour, GroupToursBySection, GroupTour } from '../../../records/Tour.js';
import type { Language } from '../../intl/context.js';
import mapperTours from '../../mapper/tours/index.js';
import { omitFn } from '../../utils/index.js';
import lowestPrices from './services/lowestPrices/index.js';
import type { LowestPricePerDate } from './services/lowestPrices/index.js';
import TOUR_ATTRIBUTES from '../../../../../scripts/tourAttributes.js';
import type { Section } from '../../../records/PoiContent.js';
import type { Promocodes } from '../../../records/Promocodes.js';
import type { Currencies } from '../../../records/Currencies.js';
import type { Providers } from '../../../records/Providers.js';
import { filterTourGroup } from '../../tours/filterTours.js';
import mapperGroupTours from '../../mapper/groupTours/index.js';

const client = algoliasearch(APP_ID, API_KEY);

const getTourAttributes = (currencies: Currencies) =>
  TOUR_ATTRIBUTES.concat(
    Object.keys(currencies).reduce((acc, cur) => acc.concat([`price_${cur}`, `fee_${cur}`]), []),
  );

const buildFiltersString = (filters: string, tags: string[]): string => {
  const tagsFilter = tags.map(tag => `_tags:"${tag}"`).join(' AND ');
  if (filters && tagsFilter) {
    return `(${filters}) AND (${tagsFilter})`;
  } else if (filters) {
    return filters;
  } else if (tagsFilter) {
    return tagsFilter;
  } else {
    return '';
  }
};

export const getTours = (
  currency: Currency,
  language: Language,
  tags: string[],
  filters: string,
  hitsPerPage: number,
  promocodes: Promocodes,
  currencies: Currencies,
  providers: Providers,
  page?: number = 0,
  queryText?: string = '',
): Promise<{ groupTours: GroupTour[], page: number, nbPages: number, nbHits: number }> => {
  const query = queryText.trim();
  const combinedFilters = buildFiltersString(filters, tags);

  return client
    .searchSingleIndex({
      indexName: INDICES.TOURS,
      searchParams: {
        query,
        filters: combinedFilters,
        page,
        hitsPerPage,
        attributesToRetrieve: getTourAttributes(currencies),
        distinct: 10,
      },
    })
    .then(content => {
      const groupTours = mapperGroupTours(
        omitFn(mapperTours(content.hits, language, promocodes, currencies)),
        language,
        promocodes,
        currencies,
        providers,
      );
      return {
        groupTours,
        page: content.page,
        nbPages: content.nbPages,
        nbHits: content.nbHits,
      };
    });
};

export const getGroupTours = (
  language: Language,
  id: string,
  promocodes: Promocodes,
  currencies: Currencies,
  date?: ?string,
): Promise<Tour[]> => {
  const tags = [`lang:${language}`, `a:${date || 'default'}`];
  const filters = buildFiltersString(`groupId=${id}`, tags);

  return client
    .searchSingleIndex({
      indexName: INDICES.TOURS,
      searchParams: {
        filters,
        attributesToRetrieve: getTourAttributes(currencies),
        distinct: 10,
      },
    })
    .then(content => {
      const tours = mapperTours(omitFn(content.hits), language, promocodes, currencies);
      return tours;
    });
};

export const getPoiCategoryTours = (
  language: Language,
  destinationId: string | number,
  sections: Section[],
  promocodes: Promocodes,
  currencies: Currencies,
  providers: Providers,
  date?: string,
): Promise<GroupToursBySection> => {
  const output = {};
  const promises = sections.map(section => {
    const tags = [`d:${destinationId}`, `lang:${language}`, `a:${date || 'default'}`];
    const filters = buildFiltersString(section.filters, tags);

    return client
      .searchSingleIndex({
        indexName: INDICES.TOURS,
        searchParams: {
          filters,
          attributesToRetrieve: TOUR_ATTRIBUTES.concat(
            Object.keys(currencies).reduce(
              (acc, cur) => acc.concat([`price_${cur}`, `fee_${cur}`]),
              [],
            ),
          ),
          hitsPerPage: 5,
          distinct: 10,
        },
      })
      .then(content => {
        const tours = mapperGroupTours(
          omitFn(mapperTours(content.hits, language, promocodes, currencies)),
          language,
          promocodes,
          currencies,
          providers,
        );
        output[section.id] = tours;
      })
      .catch(err => {
        console.error(err);
      });
  });
  return Promise.all(promises).then(() => output);
};

export const getPoiTours = (
  language: Language,
  poiId: string,
  sections: Section[],
  promocodes: Promocodes,
  currencies: Currencies,
  providers: Providers,
  date?: ?string,
): Promise<GroupToursBySection> => {
  const output = {};
  const promises = sections.map(section => {
    const tags = [`lang:${language}`, `p:${poiId}`, `a:${date || 'default'}`];
    const filters = buildFiltersString(section.filters, tags);

    return client
      .searchSingleIndex({
        indexName: INDICES.TOURS,
        searchParams: {
          filters,
          attributesToRetrieve: getTourAttributes(currencies),
          hitsPerPage: 5,
          distinct: 10,
        },
      })
      .then(content => {
        const tours = mapperGroupTours(
          omitFn(mapperTours(content.hits, language, promocodes, currencies)),
          language,
          promocodes,
          currencies,
          providers,
        );
        output[section.id] = tours;
      })
      .catch(err => {
        console.error(err);
      });
  });
  return Promise.all(promises).then(() => output);
};

export const getLowestPricesPerDay = (
  currency: Currency,
  language: Language,
  id: string,
  promocodes: Promocodes,
  currencies: Currencies,
  providers: Providers,
): Promise<LowestPricePerDate> => {
  const tags = [`lang:${language}`];
  const filters = buildFiltersString(`groupId=${id}`, tags);

  return client
    .searchSingleIndex({
      indexName: INDICES.TOURS,
      searchParams: {
        filters,
        attributesToRetrieve: getTourAttributes(currencies),
        distinct: 10,
      },
    })
    .then(content => {
      const tours = mapperTours(content.hits, language, promocodes, currencies);
      const filteredTours = filterTourGroup(tours, providers, currency);
      const tourTags = filteredTours.reduce((acc, tour) => {
        if (!acc[tour.id]) {
          acc[tour.id] = {};
        }
        const tourWithTags = content.hits.find(
          x => x.id === tour.id && tour[`price_${currency}`] === x[`price_${currency}`],
        );
        acc[tour.id][tour[`price_${currency}`]] = tourWithTags ? tourWithTags._tags : [];
        return acc;
      }, {});
      const something = filteredTours.map(tour => {
        return {
          [`price_${currency}`]: tour[`price_${currency}`],
          _tags: tourTags[tour.id][tour[`price_${currency}`]].filter(
            tag => tag.startsWith('a:') && tag !== 'a:default',
          ),
        };
      });
      return lowestPrices(something, currency);
    });
};

export const getTour = (
  language: Language,
  id: string,
  promocodes: Promocodes,
  currencies: Currencies,
): Promise<?Tour> => {
  return client
    .searchSingleIndex({
      indexName: INDICES.TOURS,
      searchParams: {
        query: '',
        filters: `objectID:"${id}"`,
        attributesToRetrieve: getTourAttributes(currencies),
        hitsPerPage: 1,
      },
    })
    .then(content => {
      if (content.hits.length === 0) {
        return null;
      }
      const tour = mapperTours(omitFn(content.hits), language, promocodes, currencies)[0];
      return tour;
    })
    .catch(err => {
      if (err.statusCode === 404) {
        return null;
      } else {
        throw err;
      }
    });
};