// @flow strict
import React from 'react';
import { Box, Flex } from '../../../../../../primitives/Essentials/index.js';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import type { Tag } from '../../../../../../records/SectionConfig.js';
import ToursContainer from '../../../../../../components/ToursContainer/index.js';
import Helmet from './components/Helmet/index.js';
import IntlContext from '../../../../../../services/intl/context.js';
import CurrencyContext from '../../../../../../services/currency/context.js';
import ContentContext from '../../../../../../services/content/context.js';
import mq from '../../../../../../services/mediaQuery/index.js';
import PoiAvailability from '../../../../../../components/PoiAvailability/index.js';
import {
  container,
  HEADER,
  FOOTER,
  FOOTER_MOBILE,
  FOOTER_BUTTON,
} from '../../../../../../services/styleUtils/index.js';

type Props = {
  categoryId: number,
  destinationId: number,
  resultsForString: string,
  sectionConfigTags: Tag[],
  destinationName: string,
  date: ?Date,
  onChangeDate: (date: Date) => void,
};

const StyledContainerBox = styled(Box)`
  ${container()};
  min-height: calc(100vh - ${HEADER} - ${FOOTER_MOBILE} - ${FOOTER_BUTTON} - 2px);
  ${mq.TABLET`
    min-height: calc(100vh - ${HEADER} - ${FOOTER} - ${FOOTER_BUTTON} - 2px);
  `} ${mq.DESKTOP`
    min-height: calc(100vh - ${HEADER} - ${FOOTER} - ${FOOTER_BUTTON} - 2px);
  `};
`;

const StyledBox = styled(Box)`
  margin-top: -2px;
  ${mq.TABLET`
    margin-top: 0px;
  `};
  ${mq.DESKTOP`
    max-width: 435px;
  `};
`;

const ClientSideContent = ({
  categoryId,
  destinationId,
  resultsForString,
  sectionConfigTags,
  destinationName,
  date,
  onChangeDate,
}: Props) => {
  const { language, translate } = React.useContext(IntlContext);
  const { currency } = React.useContext(CurrencyContext);
  const { promocodes, providers, currencies } = React.useContext(ContentContext);

  return (
    <StyledContainerBox>
      <Flex $flexDirection={['column', null, null, null, null, 'row']}>
        <Helmet
          translate={translate}
          resultsForString={resultsForString}
          destinationName={destinationName}
        />
        <StyledBox
          $px={[0, null, null, null, 30]}
          $pt={[0, null, null, null, 14, 49]}
          width={[1, null, null, null, null, 5 / 12]}
        >
          <PoiAvailability date={date} onChangeDate={onChangeDate} />
        </StyledBox>
        <Box width={1}>
          <ToursContainer
            language={language}
            filters={`_tags:"t:${categoryId}"`}
            currency={currency}
            id={destinationId}
            type="destination"
            resultsForString={resultsForString}
            breadcrumbs={[]}
            sectionConfigTags={sectionConfigTags}
            destinationId={destinationId}
            promocodes={promocodes}
            providers={providers}
            currencies={currencies}
            date={date}
          />
        </Box>
      </Flex>
    </StyledContainerBox>
  );
};

export default ClientSideContent;
