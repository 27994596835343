// @flow strict
import type { Language } from '../../intl/context.js';
import type { SectionInput, Section } from '../../../records/PoiContent.js';

export default function mapperSections(input: SectionInput[], locale: Language): ?(Section[]) {
  const missingTranslations = input.some(
    section => !section[`title_${locale}`] || !section[`abstract_${locale}`],
  );
  if (missingTranslations) {
    // Determine nextLocale considering all three languages
    let nextLocale;
    if (locale === 'en') {
      nextLocale = 'de';
    } else if (locale === 'de') {
      nextLocale = 'it';
    } else {
      nextLocale = 'en';
    }

    const nextLocaleMissingTranslations = input.some(
      section => !section[`title_${nextLocale}`] || !section[`abstract_${nextLocale}`],
    );

    if (nextLocaleMissingTranslations) {
      const finalLocale = ['en', 'de', 'it'].find(lang => lang !== locale && lang !== nextLocale);
      const finalLocaleMissingTranslations = input.some(
        section => !section[`title_${finalLocale}`] || !section[`abstract_${finalLocale}`],
      );
      if (finalLocaleMissingTranslations) {
        return null;
      }
      return input.map(section => ({
        id: String(section.id),
        title: section[`title_${finalLocale}`],
        abstract: section[`abstract_${finalLocale}`],
        filters: section.filters,
        disabled: true,
      }));
    }
    return input.map(section => ({
      id: String(section.id),
      title: section[`title_${nextLocale}`],
      abstract: section[`abstract_${nextLocale}`],
      filters: section.filters,
      disabled: true,
    }));
  }
  return input.map(section => ({
    id: String(section.id),
    title: section[`title_${locale}`],
    abstract: section[`abstract_${locale}`],
    filters: section.filters,
  }));
}
