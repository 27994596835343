// @flow strict
import * as React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Flex, Box } from '../../../../../../primitives/Essentials/index.js';
import type { Theme } from '../../../../../../records/Theme.js';
import Line from '../../../../../../primitives/Line/index.js';
import StarRating from '../../../../../../components/StarRating/index.js';

type Props = {
  rating: number,
  ratingsCount: number,
  theme: Theme,
  inHeader: boolean,
  onRatingsClick?: ?() => void,
};

const StyledStarRating = styled(StarRating)`
  margin-right: 6px;
  margin-left: 6px;
  color: ${({ theme }) => theme.trip.rating};
  font-size: 20px;
`;

const StyledBox = styled(Box)`
  cursor: ${({ hasMouseOver }) => (hasMouseOver ? 'pointer' : 'auto')};
`;

const StyledLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  padding-right: 5px;
`;

const GroupTourRating = ({ theme, rating, ratingsCount, inHeader, onRatingsClick }: Props) => (
  <StyledBox $mt={9} onClick={onRatingsClick} $hasMouseOver={onRatingsClick !== null}>
    <Flex $alignItems="center">
      <Line fontSize={inHeader ? 19 : 16} fontWeight={900} color={theme.trip.rating}>
        {rating.toFixed(1)}
      </Line>
      <StyledStarRating rating={rating} stars={5} starSize={inHeader ? 17.5 : 15} />
      {!inHeader && (
        <>
          <StyledLine fontSize={16} color={theme.trip.offers}>
            ({ratingsCount})
          </StyledLine>
          {onRatingsClick && <img src="/icons/rating-arrow.svg" alt="rating arrow" />}
        </>
      )}
    </Flex>
  </StyledBox>
);

GroupTourRating.defaultProps = {
  onRatingsClick: null,
};

export default GroupTourRating;
