// @flow strict
import type { Tour } from '../../records/Tour.js';

export type Output = {
  ratingsCount: number,
  rating: number, // weighted average
};

type AdjustRating = Output => Output;

export const adjustRating: AdjustRating = output => ({
  ...output,
  rating:
    output.ratingsCount > 0
      ? Number((output.rating / output.ratingsCount).toFixed(2))
      : output.rating,
});

const getTourGroupRating = (tourGroup: Tour[]): Output =>
  adjustRating(
    tourGroup.reduce(
      (acc, tour) => {
        if (tour.ratingsCount === 0 || tour.rating === 0) {
          return acc;
        }
        acc.ratingsCount += tour.ratingsCount;
        acc.rating += tour.rating * tour.ratingsCount;
        return acc;
      },
      { ratingsCount: 0, rating: 0 },
    ),
  );

export default getTourGroupRating;
