// @flow strict
import React from 'react';
import { withTheme } from 'styled-components';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Flex } from '../../primitives/Essentials/index.js';
import Text from '../Text/index.js';
import Line from '../../primitives/Line/index.js';
import type { Theme } from '../../records/Theme.js';

type Props = {
  title: string,
  theme: Theme,
  subtitle?: string,
  center?: boolean,
};

const StyledH2 = styled.h2`
  margin: 0;
  color: ${({ theme }) => theme.section.title};
  font-weight: 900;
  font-size: 24px;
`;

const Subtitle = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  max-width: 720px;
  a {
    color: inherit;
  }
`;

const SectionHeader = ({ theme, title, subtitle, center }: Props) => (
  <Flex
    $flexDirection="column"
    $alignItems={center ? 'center' : 'flex-start'}
    $pb={[12.5, null, null, null, 18.5]}
    $px={[15.5, null, null, null, 0]}
  >
    <StyledH2>{title}</StyledH2>
    {subtitle && (
      <Subtitle fontSize={16} lineHeight={1.38} color={theme.trip.abstract}>
        <Text html t={subtitle} />
      </Subtitle>
    )}
  </Flex>
);

SectionHeader.defaultProps = {
  subtitle: '',
  center: false,
};

export default withTheme(SectionHeader);
