// @flow strict
import * as React from 'react';
import { Box } from '../../../../primitives/Essentials/index.js';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import Section from './components/Section/index.js';
import Attractions from './components/Attractions/index.js';
import Categories from './components/Categories/index.js';
import Breadcrumbs from '../../../../components/Breadcrumbs/index.js';
import { container } from '../../../../services/styleUtils/index.js';
import { SECTION_LAYOUT } from '../../../../consts/layout.js';
import Destinations from './components/Destinations/index.js';
import AuthorBio from '../../../../components/AuthorBio/index.js';
import Abstract from '../../../../components/Abstract/index.js';
import TopList from '../../../../components/TopList/index.js';
import SubLists from '../../../../components/SubLists/index.js';
import Navigation from '../../../../components/Navigation/index.js';
import type { Currency } from '../../../../services/currency/context.js';
import type { Language } from '../../../../services/intl/context.js';
import type { Destination } from '../../../../records/Destination.js';
import type { DestinationContent as DestinationContentType } from '../../../../records/DestinationContent.js';
import type { SectionConfig } from '../../../../records/SectionConfig.js';
import type { GroupToursBySection } from '../../../../records/Tour.js';
import type { ImageAttributions } from '../../../../records/Photo.js';
import type { Poi } from '../../../../records/Poi.js';
import mapperDestinationContent from '../../../../services/mapper/destinationContent/index.js';
import AttractionsContent from '../AttractionsContent/index.js';

const StyledContainerBox = styled(Box)`
  ${container()};
`;

type Props = {
  currency: Currency,
  language: Language,
  configs: { [Language]: SectionConfig },
  tours: GroupToursBySection,
  attractions: Poi[],
  countryDestinations: Destination[],
  content: ?DestinationContentType,
  toursCount: { [destinationId: number]: number, total: number },
  buildDestinationPageUrl: (?number) => string,
  selectedDestination: ?Destination,
  destinationId: number,
  imageAttributions: ImageAttributions,
};

type State = {
  updatedContent: ?DestinationContentType,
};

class DestinationContent extends React.Component<Props, State> {
  constructor() {
    super();
    this.state = {
      updatedContent: null, // set from json editor to alter page for preview
    };
    // this.contentWrapperNode = React.createRef();
  }

  componentDidMount = () => {
    window.addEventListener('message', this.handleMessage);
  };

  componentWillUnmount() {
    window.removeEventListener('message', this.handleMessage);
  }

  // for tweb admin live preview
  // $FlowFixMe
  handleMessage = event => {
    const { language, imageAttributions } = this.props;
    // event.origin will most likely be https://admin.ulmon.com
    // window.location.origin will most likely be https://www.ticketlens.com
    if (
      event.data.destinationId &&
      ((event.origin.includes('localhost') && window.location.origin.includes('localhost')) ||
        (event.origin.includes('admin') && window.location.origin.includes('ticketlens')))
    ) {
      this.setState({
        updatedContent: mapperDestinationContent(event.data, language, imageAttributions),
      });
    }
  };

  render() {
    const {
      language,
      currency,
      configs,
      tours,
      attractions,
      countryDestinations,
      content,
      toursCount,
      buildDestinationPageUrl,
      selectedDestination,
      destinationId,
      ImageAttributions,
    } = this.props;

    const { updatedContent } = this.state;

    const topAttractions =
        attractions.filter(poi => poi.searchRankingScore >= 15)
        .slice(0, 4);

    // eslint-disable-next-line no-underscore-dangle
    const _content = updatedContent || content;

    return (
      <>
        <StyledContainerBox>
          <Box {...SECTION_LAYOUT} $py={0} $mt={0}>
            <Breadcrumbs
              items={[
                {
                  name: selectedDestination ? selectedDestination.name : String(destinationId),
                  url: buildDestinationPageUrl(destinationId),
                },
              ]}
              activeIndex={0}
            />
          </Box>
          {_content && _content.abstract && (
            <Box width={[1, null, null, null, null, 1 / 2]}>
              <Abstract author={_content.author}>
                <span dangerouslySetInnerHTML={{ __html: _content.abstract }} />
              </Abstract>
            </Box>
          )}
          {!_content && (
            <>
              {topAttractions.length > 0 && (
                <Attractions attractions={topAttractions} language={language} />
              )}
              {configs[language].Tags.filter(tag => tours[tag.Tag] && tours[tag.Tag].length).map(
                tag => (
                  <Section key={tag.Tag} tag={tag} currency={currency} tours={tours[tag.Tag]} />
                ),
              )}
            </>
          )}
          {_content && _content.topList && <TopList topList={_content.topList} />}
        </StyledContainerBox>
        {_content && (
          <Navigation
            subLists={_content.subLists}
            destinationName={selectedDestination ? selectedDestination.name : String(destinationId)}
            destinationId={destinationId}
            topListTitle={_content && _content.topListTitle ? _content.topListTitle : null}
            type="destination"
          />
        )}
        <StyledContainerBox>
          {_content && _content.subLists && (
            <SubLists
              subLists={_content.subLists}
              destinationName={
                selectedDestination ? selectedDestination.name : String(destinationId)
              }
              language={language}
              attractions={topAttractions}
            />
          )}
          <Categories />
        </StyledContainerBox>
        <AttractionsContent
          language={language}
          attractions={attractions}
          // $FlowFixMe
          content={content}
          selectedDestination={selectedDestination}
          destinationId={destinationId}
          imageAttributions={ImageAttributions}
        />
        {_content && _content.author && (
          <AuthorBio author={_content.author} translator={_content.translator} />
        )}
        <StyledContainerBox>
          {selectedDestination && Boolean(countryDestinations.length) && (
            <Destinations
              selectedDestination={selectedDestination}
              destinations={countryDestinations}
              toursCount={toursCount}
            />
          )}
        </StyledContainerBox>
      </>
    );
  }
}

export default DestinationContent;
