// @flow strict
import React from 'react';
import { Box, Flex } from '../../../../../../primitives/Essentials/index.js';
import { withTheme } from 'styled-components';
import { RES_URL } from '../../../../../../../../etc/appConfig.js';
import Line from '../../../../../../primitives/Line/index.js';
import type { Theme } from '../../../../../../records/Theme.js';
import Category from './components/Category/index.js';
import Text from '../../../../../../components/Text/index.js';
import IntlContext from '../../../../../../services/intl/context.js';
import DestinationContext from '../../../../../../services/destination/context.js';
import { SECTION_LAYOUT } from '../../../../../../consts/layout.js';

type Props = {
  theme: Theme,
};

const Categories = ({ theme }: Props) => {
  const { language } = React.useContext(IntlContext);
  const { configs } = React.useContext(DestinationContext);

  if (!configs[language].Categories.length) {
    return null;
  }

  return (
    Boolean(configs[language].Categories.length) && (
      <Box {...SECTION_LAYOUT}>
        <Flex $flexDirection="column">
          <Line fontSize={24} fontWeight={900} color={theme.section.title}>
            <Text t="categories_title" />
          </Line>
          <Box $mt={15.5}>
            <Flex $flexDirection="row" $flexWrap="wrap">
              {configs[language].Categories.map(category => (
                <Category
                  key={category.Tag}
                  topic={category}
                  theme={theme}
                  imageUrl={`${RES_URL}/tag-images/${category.Tag}.jpg`}
                />
              ))}
            </Flex>
          </Box>
        </Flex>
      </Box>
    )
  );
};

export default withTheme(Categories);
