// @flow strict
import * as React from 'react';
import { withTheme } from 'styled-components';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Box, Flex } from '../../../../primitives/Essentials/index.js';
import queryString from 'query-string';
import Cookies from 'js-cookie';
// $FlowFixMe
import addDays from 'date-fns/addDays/index.js';
// $FlowFixMe
import isSameDay from 'date-fns/isSameDay/index.js';
import type { Language } from '../../../../services/intl/context.js';
import type { Currency } from '../../../../services/currency/context.js';
import { getGroupTours, getLowestPricesPerDay } from '../../../../services/api/tours/index.js';
import { getGroupReviews } from '../../../../services/api/reviews/index.js';
import type { Review } from '../../../../records/Review.js';
import type { Promocodes } from '../../../../records/Promocodes.js';
import type { Currencies } from '../../../../records/Currencies.js';
import type { Providers } from '../../../../records/Providers.js';
import type { Tour } from '../../../../records/Tour.js';
import GroupTourHeader from './components/GroupTourHeader/index.js';
import GroupTourAvailability from './components/GroupTourAvailability/index.js';
import GroupTourOffers from './components/GroupTourOffers/index.js';
import UrlContext from '../../../../services/url/context.js';
import DestinationContext from '../../../../services/destination/context.js';
import type { Theme } from '../../../../records/Theme.js';
import type { Poi } from '../../../../records/Poi.js';
import {
  container,
  HEADER,
  FOOTER_MOBILE,
  FOOTER,
  FOOTER_BUTTON,
  COVER_MOBILE,
  COVER_TABLET,
  COVER,
} from '../../../../services/styleUtils/index.js';
import mq from '../../../../services/mediaQuery/index.js';
import Footer from '../../../../components/Footer/index.js';
import Text from '../../../../components/Text/index.js';
import Button from '../../../../components/Button/index.js';
import { getFooterText, getFooterValues } from './services/footerUtils.js';
import { getPoi } from '../../../../services/api/poi/index.js';
import Navbar from '../../../../components/Navbar/index.js';
import { formatDateYMD, parseDate } from '../../../../services/dateTimeUtils/index.js';
import type { LowestPricePerDate } from '../../../../services/api/tours/services/lowestPrices/index.js';
import { COOKIES } from '../../../../records/Cookies.js';
import Breadcrumbs from '../../../../components/Breadcrumbs/index.js';
import { destinationSelector } from '../../../../services/destination/selectors.js';
import GroupTourReviews from './components/GroupTourReviews/index.js';
import Helmet from './components/Helmet/index.js';
import sortTours from '../../../../services/tours/sortTours.js';
import { filterTourGroup } from '../../../../services/tours/filterTours.js';
import PromoCodesInfo from './components/PromoCodesInfo/index.js';
import { getTourGroupPromocodes } from '../../../../services/tours/getTourPromocode.js';
import DiscountBanner from './components/DiscountBanner/index.js';
import ClientOnly from '../../../../components/ClientOnly/index.js';
import History from '../../../../components/History/index.js';
import { LAYOUT_COMMON } from '../../../../consts/layout.js';
import { BITMAP_URL } from '../../../../../../etc/appConfig.js';

type Props = {
  theme: Theme,
  language: Language,
  currency: Currency,
  groupId: string,
  reviewsTestGroup: boolean, // reviews AB test
  promocodes: Promocodes,
  currencies: Currencies,
  providers: Providers,
  location: Location,
  navigation: Navigation,
};

type State = {
  defaultTours: Tour[],
  tours: Tour[],
  lowestPrices: LowestPricePerDate,
  poi: ?Poi,
  error: boolean,
  date: ?Date, // YYYY-MM-DD
  reviews: Review[],
  seeAllReviews: boolean,
};

const StyledContainerBox = styled(Box)`
  ${container()};
  max-width: 1024px;
  min-height: calc(100vh - ${HEADER} - ${FOOTER_MOBILE} - ${COVER_MOBILE} - ${FOOTER_BUTTON} - 2px);
  ${mq.TABLET`
    min-height: calc(100vh - ${HEADER} - ${FOOTER} - ${COVER_TABLET} - ${FOOTER_BUTTON} - 2px);
  `};
  ${mq.DESKTOP`
    min-height: calc(100vh - ${HEADER} - ${FOOTER} - ${COVER} - ${FOOTER_BUTTON} - 2px);
  `};
`;

const StyledButton = styled(Button)`
  margin: 0 15px;

  ${mq.TABLET`
      margin: 0;
  `};
`;

const getDiscount = (tours, currency) =>
  !tours || !tours.length
    ? 0
    : 100 - tours[0][`price_${currency}`] / (tours[tours.length - 1][`price_${currency}`] / 100);

class GroupTourContent extends React.PureComponent<Props, State> {
  reviewsHeaderRef: React.Ref<*>;

  constructor(props: Props) {
    super(props);
    this.state = {
      defaultTours: [], // used only to get rating
      tours: [],
      lowestPrices: {},
      poi: null,
      error: false,
      date: null,
      reviews: [],
      seeAllReviews: false,
    };
    this.reviewsHeaderRef = React.createRef();
  }

  componentDidMount = () => {
    const { groupId, language, currency, location, promocodes, currencies, providers } = this.props;

    let parsedDate;

    const queryParams = queryString.parse(location.search);
    if (queryParams.date) {
      parsedDate = parseDate(queryParams.date);
    } else if (Cookies.get(COOKIES.DATE)) {
      parsedDate = parseDate(Cookies.get(COOKIES.DATE));
    }
    // eslint-disable-next-line no-restricted-globals
    if (parsedDate && !isNaN(parsedDate.getTime())) {
      this.setState({
        date: parsedDate,
      });
    }

    // get model tour for cover image and breadcrumbs
    getGroupTours(language, groupId, promocodes, currencies).then(tours => {
      this.setState(
        {
          defaultTours: filterTourGroup(sortTours(tours, currency), providers, currency),
          error: tours.length === 0,
        },
        () => {
          this.updateTours();
        },
      );
      if (tours.length && tours[0].breadcrumbPoi) {
        getPoi(String(tours[0].breadcrumbPoi)).then(poi => {
          this.setState({
            poi: poi[language],
          });
        });
      }
      if (tours.length) {
        // only get prices if got tours for cover image etc.
        this.updateCalendar();
        /*
        we are currently not using this, so we can deactivate it
        getGroupReviews(currency, language, tours).then(result => {
          const { reviews } = result;
          this.setState({
            reviews,
          });
        });*/
      }
    });
  };

  componentDidUpdate = (prevProps: Props) => {
    const { currency } = this.props;
    const { date, tours, defaultTours } = this.state;

    if (prevProps.currency !== currency) {
      // currency changed
      this.updateCalendar();
      if (!date) {
        // or date in past
        // TODO: fix issue
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          tours: sortTours(defaultTours, currency),
        });
      } else {
        // TODO: fix issue
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          tours: sortTours(tours, currency),
        });
      }
    }
  };

  updateCalendar = () => {
    const { currency, language, groupId, promocodes, currencies, providers } = this.props;
    getLowestPricesPerDay(currency, language, groupId, promocodes, currencies, providers).then(
      (lowestPrices) => {
        this.setState((prevState) => ({
          ...prevState,
          lowestPrices,
        }));
      },
    );
  };

  updateTours = () => {
    const { language, groupId, promocodes, currencies, providers, currency } = this.props;
    const { date, defaultTours } = this.state;
    if (!date) {
      this.setState((prevState) => ({
        ...prevState,
        tours: sortTours(defaultTours, currency),
      }));
    } else {
      getGroupTours(language, groupId, promocodes, currencies, formatDateYMD(date)).then(
        (tours) => {
          if (tours.length === 0) {
            this.setState((prevState) => ({
              ...prevState,
              tours: filterTourGroup(sortTours(defaultTours, currency), providers, currency),
              date: null,
            }));
          } else {
            this.setState((prevState) => ({
              ...prevState,
              tours: filterTourGroup(sortTours(tours, currency), providers, currency),
            }));
          }
        },
      );
    }
  };

  handleChangeDate = (newDate: Date) => {
    const { navigate, location } = this.props;
    const queryParams = queryString.parse(location.search);
    if (this.state.date && isSameDay(newDate, this.state.date)) {
      delete queryParams.date;
      navigate({ search: '' });
      Cookies.remove(COOKIES.DATE);
    } else {
      queryParams.date = formatDateYMD(newDate);
      navigate({ search: queryString.stringify(queryParams) });
      Cookies.set(COOKIES.DATE, queryParams.date, {
        expires: addDays(newDate, 1),
      });
    }

    this.setState((prevState) => ({
      ...prevState,
      date:
        (prevState.date && !isSameDay(newDate, prevState.date)) || !prevState.date
          ? newDate
          : null,
      tours: [],
      error: false,
    }),
      () => {
        // After state is updated, fetch tours for the new date.
        this.updateTours();
      }
    );
  };

  handleSeeMoreReview = () => {
    this.setState((prevState) => ({
      ...prevState,
      seeAllReviews: true,
    }));
  };

  render() {
    const {
      groupId,
      theme,
      language,
      currency,
      location,
      reviewsTestGroup,
      promocodes,
      providers,
      currencies,
    } = this.props;

    const {
      tours,
      poi,
      error,
      date,
      defaultTours,
      lowestPrices,
      reviews,
      seeAllReviews,
    } = this.state;

    // eslint-disable-next-line no-underscore-dangle
    const _tours = filterTourGroup(sortTours(tours, currency), providers, currency);
    const activePromocodes = getTourGroupPromocodes(_tours, promocodes);

    const hasContent = Boolean(defaultTours.length); // default tours

    const isLoading = !_tours.length && !error;
    const hasNoContent = !hasContent && error;
    const showReviews = reviewsTestGroup && reviews.length > 0;

    const onRatingsClick =
      this.reviewsHeaderRef && showReviews
        ? () => {
          window.scrollTo({
            // $FlowFixMe
            top: this.reviewsHeaderRef.current ? this.reviewsHeaderRef.current.offsetTop : null,
            behavior: 'smooth',
          });
        }
        : undefined;

    const tour = defaultTours
      ? defaultTours.find(t => t.providerId === 1) || defaultTours[0]
      : defaultTours[0]; // default to GyG tour for displayed data
    const imageUrl = (tour && tour.imageId) ? `${BITMAP_URL}/image/${tour.imageId}/` : null;
    const navbarImage =
      !hasContent || !imageUrl ? '/images/placeholder-large.png' : imageUrl;

    const discount = getDiscount(_tours, currency);

    return (
      <UrlContext.Consumer>
      {({ buildPoiPageUrl, buildDestinationPageUrl }) => (
        <DestinationContext.Consumer>
        {({ destinations }) => {
          // build breadcrumbs
          const breadcrumbs = [];
          let destinationId;

          if (tour) {
            const destination = destinationSelector(
              destinations[language],
              Number(tour.breadcrumbDestination),
            );
            if (destination) {
              breadcrumbs.push({
                name: destination.name,
                url: buildDestinationPageUrl(destination.destination_id),
              });
              destinationId = destination.destination_id;
            }
            if (poi) {
              breadcrumbs.push({
                name: poi.name,
                url: buildPoiPageUrl(poi),
              });
              if (!destinationId) {
                destinationId = poi.breadcrumbDestination;
              }
            }
          }

          // Get id of first tour w/ booking fee in order to dispay abstract once
          const toursWithFee = _tours.filter(t => t[`fee_${currency}`]);
          const tourIdWithFee = toursWithFee.length === 0 ? null : toursWithFee[0].id;

          return (
          <>
            { tour && (
              <Helmet title= { tour.title } description = { tour.abstract } location = { location } /> 
            )}
            <Navbar 
              imageUrl={
                    navbarImage && navbarImage.endsWith('/')
                    ? navbarImage.slice(0, -1)
                  : navbarImage
              }
              responsive = { Boolean(hasContent && imageUrl)}
              imageService = { Boolean(hasContent && imageUrl)}
              title = { tour? tour.title : 'tour'}
              showTitle = { false}
              destinationId = { destinationId }
            />
            <StyledContainerBox $px={ [0, null, null, null, 30] } $pb={ [30, null, null, null, 54.5, 76]}>
              <Box $pt={ 8 } $px={ [15.5, null, null, null, 0]} >
                <Breadcrumbs endingSlash items = { breadcrumbs } />
              </Box>
              { hasContent && (
                <>
                  <GroupTourHeader tour={tour}
                    tourGroup={defaultTours}
                    theme={theme}
                    shoudlDisplayVariant={showReviews}
                    onRatingsClick={onRatingsClick}
                  />
                  <GroupTourAvailability
                    date={date}
                    onChangeDate = {this.handleChangeDate}
                    theme={theme}
                    lowestPrices={lowestPrices}
                  />
                  { Boolean(_tours.length) && discount >= 5 && (
                    <DiscountBanner
                      discount={discount}
                      lowestPrice={ _tours[0][`price_${currency}`] }
                      highestPrice={ _tours[_tours.length - 1][`price_${currency}`] }
                    /> )
                  }
                  <GroupTourOffers
                    theme={theme}
                    tours={_tours}
                    date={ date? formatDateYMD(date): null }
                    isLoading={isLoading}
                    tourIdWithBookingFee={tourIdWithFee}
                    promocodes={promocodes} />
                      { Boolean(activePromocodes.length) && (
                        <PromoCodesInfo
                          activePromocodes={ activePromocodes }
                          tours={ _tours }
                          providers={ providers }
                          language={ language }
                        />
                    )}
                  { showReviews && (
                    <Flex flexDirection="column" >
                      <GroupTourReviews
                        ref={ this.reviewsHeaderRef }
                        tourGroup = { defaultTours }
                        language = { language }
                        theme = { theme }
                        reviews = { seeAllReviews? reviews: reviews.slice(0, 5) }
                      />
                    { !seeAllReviews && reviews.length > 5 && (
                      <StyledButton rounded onClick = { this.handleSeeMoreReview } >
                        <Text t="see_more_reviews" />
                      </StyledButton>
                    )}
                    </Flex>
                  )}
                </>
              )}
              { hasNoContent && (
                <Box pt={ 80 }>
                  <Flex justifyContent="center" >
                    <Text t="page_not_found" html />
                  </Flex>
                </Box> 
              )}
              <ClientOnly>
                { typeof window !== 'undefined' && window.localStorage.getItem('clickHistory') && (
                <Box $mt={LAYOUT_COMMON.$mt} $py={LAYOUT_COMMON.$py} >
                  <History
                    language={language}
                    promocodes={promocodes}
                    currencies={currencies}
                    providers={providers}
                    history={window.localStorage.getItem('clickHistory')}
                    groupId={ groupId }
                    maxTourCount={ 2}
                  />
                </Box>
                )}
              </ClientOnly>
            </StyledContainerBox>
            <Footer
              goBackHref={ breadcrumbs.length ? breadcrumbs[breadcrumbs.length - 1].url : '' }
              goBackText={
                <Text 
                  t={ getFooterText(defaultTours? defaultTours[0] : null) }
                  values={ getFooterValues(
                    defaultTours? defaultTours[0] : null,
                    destinations[language],
                    poi, )
                  }
                />
              }
            />
          </>
          );
        }}
        </DestinationContext.Consumer>
      )}
      </UrlContext.Consumer>
    );
  }
}

export default withTheme(GroupTourContent);