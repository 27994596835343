/* eslint-disable no-underscore-dangle */
// @flow strict
import { algoliasearch } from 'algoliasearch';
import { APP_ID, API_KEY, INDICES } from '../../../../../etc/appConfig.js';
import type { Language } from '../../intl/context.js';
import type { SearchSuggestion } from '../../../records/SearchSuggestion.js';
import {
  mapperSuggestions,
  mapperPoiSuggestions,
  mapperDestinationSuggestions,
} from '../../mapper/searchSuggestions/index.js';

const client = algoliasearch(APP_ID, API_KEY);

const POI_ATTRIBUTES = [
  'name_en',
  'name_de',
  'name_it',
  'urlName_en',
  'urlName_de',
  'urlName_it',
  'destinationName_en',
  'destinationName_de',
  'destinationName_it',
  '_tags',
];

const DESTINATION_ATTRIBUTES = [
  'name_EN',
  'name_DE',
  'name_IT',
  'destination_id',
  'urlName_en',
  'urlName_de',
  'urlName_it',
  'country_EN',
  'country_DE',
  'country_IT',
];

const buildFiltersString = (tags: string[]): string => {
  if (!tags || tags.length === 0) return '';
  return tags.map(tag => `_tags:"${tag}"`).join(' AND ');
};

export const getSuggestions = async (
  language: Language,
  tags: string[],
  searchText: string,
): Promise<{ suggestions: SearchSuggestion[] }> => {
  // Build filters
  const poiFilters = buildFiltersString(tags);

  // POIs
  const poiQuery = {
    indexName: INDICES.POIS_SCORE,
    query: searchText,
    params: {
      filters: poiFilters,
      restrictSearchableAttributes: [`name_${language.toLowerCase()}`],
      attributesToRetrieve: POI_ATTRIBUTES,
      hitsPerPage: 3,
    },
  };

  // POIs worldwide
  const destinationTags = tags
    .filter(tag => tag.startsWith('d:'))
    .map(tag => `NOT _tags:"${tag}"`)
    .join(' AND ');
  const worldwidePoiFilters = destinationTags;

  const worldwidePoiQuery = {
    indexName: INDICES.POIS_SCORE,
    query: searchText,
    params: {
      filters: worldwidePoiFilters,
      restrictSearchableAttributes: [`name_${language.toLowerCase()}`],
      attributesToRetrieve: POI_ATTRIBUTES,
      hitsPerPage: 3,
    },
  };

  // Destinations
  const destIdxName = (() => {
    switch (language) {
      case 'en':
        return INDICES.DESTINATIONS_EN;
      case 'de':
        return INDICES.DESTINATIONS_DE;
      case 'it':
        return INDICES.DESTINATIONS_IT;
      default:
        return INDICES.DESTINATIONS_EN;
    }
  })();

  const destinationQuery = {
    indexName: destIdxName,
    query: searchText,
    params: {
      restrictSearchableAttributes: [
        `name_${language.toUpperCase()}`,
        `country_${language.toUpperCase()}`,
      ],
      attributesToRetrieve: DESTINATION_ATTRIBUTES,
      hitsPerPage: 3,
    },
  };

  const queries = [poiQuery, destinationQuery, worldwidePoiQuery];

  try {
    const { results } = await client.search(queries);
    const suggestions = mapperSuggestions(results, language);
    return { suggestions };
  } catch (err) {
    throw err;
  }
};

export const getHomePageSuggestions = async (
  language: Language,
  searchText: string,
): Promise<{ suggestions: SearchSuggestion[] }> => {
  // POIs
  const poiQuery = {
    indexName: INDICES.POIS_SCORE,
    query: searchText,
    params: {
      restrictSearchableAttributes: [
        `name_${language.toLowerCase()}`,
        `destinationName_${language.toLowerCase()}`,
      ],
      attributesToRetrieve: POI_ATTRIBUTES,
      hitsPerPage: 6,
    },
  };

  // Destinations
  const destIdxName = (() => {
    switch (language) {
      case 'en':
        return INDICES.DESTINATIONS_EN;
      case 'de':
        return INDICES.DESTINATIONS_DE;
      case 'it':
        return INDICES.DESTINATIONS_IT;
      default:
        return INDICES.DESTINATIONS_EN;
    }
  })();

  const destinationQuery = {
    indexName: destIdxName,
    query: searchText,
    params: {
      restrictSearchableAttributes: [
        `name_${language.toUpperCase()}`,
        `country_${language.toUpperCase()}`,
      ],
      attributesToRetrieve: DESTINATION_ATTRIBUTES,
      hitsPerPage: 6,
    },
  };

  const queries = [destinationQuery, poiQuery];

  try {
    const { results } = await client.search(queries);
    const suggestions = mapperSuggestions(results, language);
    return { suggestions };
  } catch (err) {
    throw err;
  }
};

export const getPoiSuggestions = async (
  language: Language,
  tags: string[],
  searchText: string,
): Promise<{ poiSuggestions: SearchSuggestion[] }> => {
  const filters = buildFiltersString(tags);

  try {
    const { results } = await client.search([
      {
        indexName: INDICES.POIS_SCORE,
        query: searchText,
        params: {
          filters,
          restrictSearchableAttributes: [`name_${language.toLowerCase()}`, 'name'],
          attributesToRetrieve: POI_ATTRIBUTES,
          hitsPerPage: 3,
        },
      },
    ]);

    const hits = results[0].hits;
    const poiSuggestions = mapperPoiSuggestions(hits, language);
    return { poiSuggestions };
  } catch (err) {
    throw err;
  }
};

export const getDestinationSuggestions = async (
  language: Language,
  searchText: string,
): Promise<{ destinationSuggestions: SearchSuggestion[] }> => {
  const destIdxName = (() => {
    switch (language) {
      case 'en':
        return INDICES.DESTINATIONS_EN;
      case 'de':
        return INDICES.DESTINATIONS_DE;
      case 'it':
        return INDICES.DESTINATIONS_IT;
      default:
        return INDICES.DESTINATIONS_EN;
    }
  })();

  try {
    const { results } = await client.search([
      {
        indexName: destIdxName,
        query: searchText,
        params: {
          restrictSearchableAttributes: [
            `name_${language.toUpperCase()}`,
            `country_${language.toUpperCase()}`,
          ],
          attributesToRetrieve: DESTINATION_ATTRIBUTES,
          hitsPerPage: 3,
        },
      },
    ]);

    const hits = results[0].hits;
    const destinationSuggestions = mapperDestinationSuggestions(hits, language);
    return { destinationSuggestions };
  } catch (err) {
    throw err;
  }
};