// @flow strict
import type { ParsedUrl } from './parseUrl.js';
import type { Destination } from '../../records/Destination.js';
import type { Tag } from '../../records/SectionConfig.js';
import { destinationSelector, tagSelector } from '../destination/selectors.js';

export const ALL_POIS = {
  en: 'top-attractions',
  de: 'top-sehenswuerdigkeiten',
  it: 'top-attrazioni',
};

export default function buildUrl(
  destinations: Destination[], // needed for human-readable URL part in destination links
  destinationTags: Tag[], // needed for human-readable URL part in destination category links
  parsedUrl: ParsedUrl,
  tagsOverride?: Tag[],
  destinationId?: number,
): string {
  let query = '';
  let url = parsedUrl.page;
  if (parsedUrl.originalUrl.includes('?')) {
    query = parsedUrl.originalUrl.substring(parsedUrl.originalUrl.indexOf('?') + 1);
  }
  const destId = parsedUrl.destination || destinationId;
  switch (parsedUrl.pageType) {
    case 'destination':
      if (parsedUrl.destination) {
        url = `${parsedUrl.page}`;
        const dest = destinationSelector(destinations, parsedUrl.destination);
        const seoDestination: ?string = dest ? dest.urlName : null;
        if (seoDestination) {
          url = `${url}/${seoDestination}`;
        }
      }
      break;
    // 'poi' and 'poiCategory' page URL needs additional data which is not available at page load time,
    // so re-building is disabled for now
    case 'category':
    case 'pois':
      if (destId && parsedUrl.category) {
        const parsedUrlCategory = parsedUrl.category === 'p' ? 'p' : `c${parsedUrl.category}`;
        const dest = destinationSelector(destinations, destId);
        const seoDestination: ?string = dest ? dest.urlName : null;
        url = `/${parsedUrl.language}/d${destId}/${parsedUrlCategory}`;
        if (seoDestination) {
          url = `${url}/${seoDestination}`; // add seo destination
        }
        if (parsedUrl.category === 'p') {
          url = `${url}-${ALL_POIS[parsedUrl.language]}`; // add seo all pois
        } else {
          // $FlowFixMe
          const tag: ?Tag = tagSelector(tagsOverride || destinationTags, parsedUrl.category);
          if (tag && tag.urlName) {
            url = `${url}-${tag.urlName}`; // add seo category name from algolia config
          }
        }
      }
      break;
    case 'groupTour':
      if (parsedUrl.group) {
        url = `/${parsedUrl.language}/g${parsedUrl.group}`;
      }
      break;
    case 'forwardtopartner':
      if (parsedUrl.tour) {
        url = `/${parsedUrl.language}/t${parsedUrl.tour}/forwardtopartner`;
      }
      break;
    case 'notfound':
      url = `${parsedUrl.originalUrl}`;
      break;
    case 'landing':
    default:
      break;
  }
  return `${url}${query ? `?${query}` : ''}`;
}
