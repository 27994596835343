// @flow strict
import * as React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Box, Flex } from '../../primitives/Essentials/index.js';
import Line from '../../primitives/Line/index.js';
import Line1 from '../../primitives/Line/index_debug.js';
import Text from '../Text/index.js';
import { SECTION_LAYOUT_NO_PADDING_MOBILE } from '../../consts/layout.js';
import ContentContext from '../../services/content/context.js';

const StyledAbstractLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  max-width: 720px;
  color: ${({ theme }) => theme.section.title};
  a {
    color: inherit;
  }
`;

const AuthorLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  margin-left: 6px;
  color: ${({ theme }) => theme.trip.abstract};
`;

const AuthorImage = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 15px;
`;

type Props = {
  children: React.Node,
  author: ?string,
};

const Abstract = ({ children, author }: Props) => {
  const { authors } = React.useContext(ContentContext);

  return (
    <Box {...SECTION_LAYOUT_NO_PADDING_MOBILE} $py={0} $mt={0}>
      <Flex $px={[15, null, null, null, 0]} $pb={15} $flexDirection="column">
        <StyledAbstractLine fontSize={16} lineHeight={1.38}>
          {children}
        </StyledAbstractLine>
        {author && authors[author] && (
          <Flex $alignItems="center" $mt={15}>
            <AuthorImage
              src={authors[author].photoUrlSmall[0]}
              srcSet={`${authors[author].photoUrlSmall[1]} 2x`}
              alt={authors[author].name}
            />
            <AuthorLine fontSize={16}>
              <Text t="written_by" values={{ name: authors[author].name }} />
            </AuthorLine>
          </Flex>
        )}
      </Flex>
    </Box>
  );
};

export default Abstract;
