// @flow strict
import React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Box, Flex } from '../../../../../../primitives/Essentials/index.js';
import { SECTION_LAYOUT } from '../../../../../../consts/layout.js';
import Text from '../../../../../../components/Text/index.js';
import type { Destination as DestinationType } from '../../../../../../records/Destination.js';
import Line from '../../../../../../primitives/Line/index.js';
import Destination from './components/Destination/index.js';

const StyledLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  color: ${({ theme }) => theme.section.title};
`;

type Props = {
  destinations: DestinationType[],
  selectedDestination: DestinationType,
  toursCount: {
    [destinationId: number]: number,
    total: number, // total tour count
  },
};

const Destinations = ({ destinations, selectedDestination, toursCount }: Props) => (
  <Box {...SECTION_LAYOUT}>
    <Flex $flexDirection="column">
      <StyledLine fontSize={22} fontWeight={900}>
        <Text
          t="more_destinations_in"
          values={{
            country: selectedDestination.country,
          }}
        />
      </StyledLine>
      <Flex $mt={5} $flexWrap="wrap">
        {destinations.map((dest, i) => (
          <Destination
            key={dest.destination_id}
            destination={dest}
            order={i}
            toursCount={toursCount[dest.destination_id]}
          />
        ))}
      </Flex>
    </Flex>
  </Box>
);

export default Destinations;
