// @flow strict
import React, { useEffect, useState } from 'react';
import type { Match } from 'react-router-dom';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import Navbar from '../../components/Navbar/index.js';
import Footer from '../../components/Footer/index.js';
import MoreLinks from '../../components/MoreLinks/index.js';
import ChatUserInterface from '../../components/ChatUserInterface/index.js';
import UrlContext from '../../services/url/context.js';
import IntlContext from '../../services/intl/context.js';
import DestinationContext from '../../services/destination/context.js';
import WindowContext from '../../services/window/context.js';
import CurrencyContext from '../../services/currency/context.js';
import ContentContext from '../../services/content/context.js';
import type { PoiContextType } from '../../services/poi/context.js';
import Text from '../../components/Text/index.js';
import withPoi from '../../decorators/withPoi/index.js';
import { BITMAP_URL } from '../../../../etc/appConfig.js';
import PoiContent from './components/PoiContent/index.js';
import { destinationSelector } from '../../services/destination/selectors.js';
import TaglineCarousel from '../../components/TaglineCarousel/index.js';

type Props = {
  poiId: number,
  match: Match,
} & PoiContextType;

const PoiPage = ({ poiId, match, poi, poiContent, tours, observationDecks }: Props) => {
  const { language, translate } = React.useContext(IntlContext);
  const { buildDestinationPageUrl, buildPoiPageUrl } = React.useContext(UrlContext);
  const { promocodes, currencies, providers, imageAttributions } = React.useContext(ContentContext);
  const { destinations } = React.useContext(DestinationContext);
  const { currency } = React.useContext(CurrencyContext);
  const { isMobile } = React.useContext(WindowContext);

  const selectedDestination = destinationSelector(
    destinations[language],
    poi[language].breadcrumbDestination,
  );  

  // get the current location object to pass to ChatUserInterface component and get URL params from it
  const location = useLocation(); 

  // Determine if chat should be shown. Conditions: 
  // if German or English
  const shouldShowChat = poiContent && poiContent.abstract && (language === 'en' || language === 'de');

  return (
    <>
      <Navbar
        title={poi[language].name}
        imageUrl={`${BITMAP_URL}/poi/${poiId}`}
        imageService
        responsive
        destinationId={poi[language].breadcrumbDestination}
        imageAlt={translate('title_poi_page', {
          poi: poi[language].name,
        })}
      />
      <TaglineCarousel />
      <PoiContent
        poi={poi[language]}
        content={poiContent}
        language={language}
        tours={tours}
        goBackHref={buildDestinationPageUrl(poi[language].breadcrumbDestination)}
        goBackText={selectedDestination ? selectedDestination.name : ''}
        pageUrl={buildPoiPageUrl(poi[language])}
        promocodes={promocodes}
        providers={providers}
        currencies={currencies}
        observationDecks={observationDecks}
        currency={currency}
        isMobile={isMobile}
        imageAttributions={imageAttributions}
      />
      {/* Conditionally render ChatUserInterface */}
      {shouldShowChat && <ChatUserInterface location={location} language={language} poiId={poiId} poiName={poi[language].name}/>}
      <MoreLinks />
      <Footer
        goBackHref={buildDestinationPageUrl(poi[language].breadcrumbDestination)}
        goBackText={
          <Text
            t="more_from"
            values={{
              place: selectedDestination ? selectedDestination.name : '',
            }}
          />
        }
      />
    </>
  );
};

export default withPoi(PoiPage);
