// @flow strict
import * as React from 'react';
import * as R from 'ramda/src/index.js';
import { Flex, Box } from '../../primitives/Essentials/index.js';
// $FlowFixMe
import isSameDay from 'date-fns/isSameDay/index.js';
import type { Currency } from '../../services/currency/context.js';
import type { Language } from '../../services/intl/context.js';
import { getTours } from '../../services/api/tours/index.js';
import SvgSpinner from '../SvgSpinner/index.js';
import Text from '../Text/index.js';
import { formatDateYMD } from '../../services/dateTimeUtils/index.js';
import SearchTours from './components/SearchTours/index.js';
import type { Promocodes } from '../../records/Promocodes.js';
import type { Currencies } from '../../records/Currencies.js';
import type { Providers } from '../../records/Providers.js';
import type { GroupTour } from '../../records/Tour.js';

type Props = {
  language: Language,
  currency: Currency,
  searchText: string,
  date?: ?Date,
  promocodes: Promocodes,
  providers: Providers,
  currencies: Currencies,
};

type State = {
  page: number,
  groupTours: GroupTour[],
  isLoading: boolean,
  nbPages: number,
  nbHits: number,
};

const INFINITE_SCROLL_PAGE_SIZE = 10;
const INFINITE_SCROLL_Y_THRESHOLD = 500;

class SearchToursContainer extends React.PureComponent<Props, State> {
  static defaultProps = {
    searchText: '',
    date: null,
  };

  constructor() {
    super();
    this.state = {
      page: 0,
      nbPages: 1,
      nbHits: 0,
      groupTours: [],
      isLoading: true,
    };
  }

  componentDidMount = () => {
    this.updateData(0);
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    const { currency, date, searchText } = this.props;
    const { groupTours } = this.state;

    const newDateIsDifferent =
      (date && prevProps.date && !isSameDay(date, prevProps.date)) ||
      (!date && prevProps.date) ||
      (date && !prevProps.date);

    const newTextIsDifferent = prevProps.searchText !== searchText;

    if (
      newDateIsDifferent ||
      currency !== prevProps.currency ||
      (newTextIsDifferent && R.equals(groupTours, prevState.groupTours))
    ) {
      // TODO: fix issue
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(
        {
          page: 0,
          nbPages: 1,
          isLoading: true,
          groupTours: [],
        },
        () => this.updateData(0),
      );
    }
  };

  updateData = (page: number) => {
    const { language, searchText, currency, date, promocodes, currencies, providers } = this.props;

    this.setState({
      isLoading: true,
    });

    const tags = [`lang:${language}`, `a:${date ? formatDateYMD(date) : 'default'}`];

    getTours(
      currency,
      language,
      tags,
      '',
      INFINITE_SCROLL_PAGE_SIZE,
      promocodes,
      currencies,
      providers,
      page,
      searchText,
    ).then(result => {
      this.setState(state => ({
        groupTours: page === 0 ? result.groupTours : state.groupTours.concat(result.groupTours),
        page: result.page,
        nbPages: result.nbPages,
        nbHits: result.nbHits,
        isLoading: false,
      }));
    });
  };

  render() {
    const { groupTours, isLoading, page, nbPages, nbHits } = this.state;
    const { searchText, date } = this.props;

    return (
      <>
        <Flex $justifyContent="center">
          <SearchTours
            resultsForString={searchText}
            onPaginatedSearch={() => this.updateData(nbPages === 1 ? 0 : page + 1)}
            shouldPerformSearch={() =>
              Boolean(
                document.body &&
                  window.innerHeight + window.scrollY >=
                    document.body.offsetHeight - INFINITE_SCROLL_Y_THRESHOLD &&
                  !isLoading &&
                  page < nbPages - 1,
              )
            }
            groupTours={groupTours}
            resultsCount={nbHits}
            date={date}
          />
        </Flex>
        {!groupTours.length && !isLoading && (
          <Box $pt={80} $mx={15}>
            <Flex $justifyContent="center">
              <Text html t="no_tours_search" values={{ searchTerm: searchText }} />
            </Flex>
          </Box>
        )}
        {isLoading && (
          <Box $mt={40}>
            <Flex $justifyContent="center" $alignItems="center">
              <SvgSpinner />
            </Flex>
          </Box>
        )}
      </>
    );
  }
}

export default SearchToursContainer;
