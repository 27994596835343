import React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Box, Flex } from '../../../../../../primitives/Essentials/index.js';
import Text from '../../../../../../components/Text/index.js';
import CurrencyContext from '../../../../../../services/currency/context.js';
import type { Theme } from '../../../../../../records/Theme.js';
import { addDays, format, startOfMonth } from 'date-fns';
import IntlContext from '../../../../../../services/intl/context.js';
import { formatMonthShort, formatDay3Letter } from '../../../../../../services/dateTimeUtils/index.js';
import { formatPrice } from '../../../../../../components/Price/utils.js';

type Props = {
  theme: Theme,
};

const TableContainer = styled.div`
  overflow-x: auto;  // Enables horizontal scrolling for the container
  display: flex;     // Aligns MonthGroup components in a row
  white-space: nowrap;
  width: 100%;
  max-width: 100vw;  // Restricts the table container to the viewport width
`;

const MonthGroup = styled.div`
  // border: 1px solid #ccc;
  padding-top: 5px;
  padding-right: 10px;
  padding-left: 0px;
  padding-bottom: 10px;
  margin: 5px 0;
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  overflow-x: hidden;
`;

const DateRow = styled.div`
  display: flex;
  flex-direction: row;  
  align-items: center;
  flex-wrap: nowrap;
  overflow-x: auto;
`;

const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2px;
  padding: 3px;
  width: 33px;
  background-color: #f2f2f2;
  border-radius: 4px;
`;

const DateCell = styled.div`
  padding: 1px;
  text-align: center;
`;

const Month = styled.div`
  font-size: 16px;
  padding-bottom: 4px;
  padding-left: 3px;
  font-weight: bold;
  width: 100%; 
`;

const Day = styled.div`
  font-size: 14px;
`;

const AvailabilityCell = styled(DateCell)`
  color: ${props => props.$available ? '#6abc10' : '#c51162'};
  font-size: 14px;
  width: 100%;
  heigth: 100%;
  border-top: 1px solid white;
  padding-top: 2px;
  padding-bottom: 2px;
`;

const TableTitle = styled.h3`
  margin: 0px;
  margin-bottom: 2px;
  color: ${({ theme }) => theme.section.title};
  font-size: 16px;
`;

const TableSubtext = styled.p`
  margin: 0px;
  line-height: 1.38;
  max-width: 720px;
  color: ${({ theme }) => theme.section.description};
  font-size: 16px;
`;

const HeaderRow = styled.div`
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0; 
  font-size: 14px;
  margin-top: 34px;
  margin-right: 5px;
  border-radius: 4px;
  margin-bottom: 12px;
  padding: 2px;
`;

const HeaderDateCell = styled.div`
  padding-top: 9px;
  padding-bottom: 10px;
  width: 100%;
`;

const HeaderAvailabilityCell = styled.div`
  width: 100%;
  border-top: 1px solid white;
  padding-top: 2px;
  padding-bottom: 2px;
`;

const HeaderText = styled.p`
  margin: 0px;
  padding-right: 3px;
  text-align: right;
  padding-left: 3px;
`;

const groupToursByDate = (tour_sections, providerId, currency) => {
  if (!tour_sections || typeof tour_sections !== 'object') return {}; // Ensures tour_sections is an object

  // Flatten all tours into a single array
  const tours = Object.values(tour_sections).flatMap(section =>
    // Only filter by providerId if it's not null
    section.filter(tour => providerId === null || tour.providerId === providerId)
  );

  const filteredTours = tours.flatMap(tour =>
    tour.availabilities ? Object.entries(tour.availabilities).flatMap(([date, availabilityData]) => {
      // Ensure the data is treated as an array, even if it's not formatted as expected
      const availabilityArray = Array.isArray(availabilityData) ? availabilityData : [availabilityData];
      return availabilityArray.map(entry => ({
        date,
        price: entry[`price_${currency.toLowerCase()}`]
      }));
    }) : []
  );

  // Reduce to find the cheapest price per date
  return filteredTours.reduce((acc, { date, price }) => {
    if (!acc[date] || price < acc[date].price) {
      acc[date] = { available: true, price };
    }
    return acc;
  }, {});
};

const localFormatPrice = (price, currency) => {
  switch (currency) {
    case 'eur':
      return `${Math.round(price)}€`;
    case 'usd':
      return `$${Math.round(price)}`;
    case 'gbp':
      return `£${Math.round(price)}`;
    case 'chf':
      return `${Math.round(price)}`;
    default:
      return `${Math.round(price)}`;
  }
};

function getKeyValuePair(obj, filterKey) {
  // Check if the filterKey exists in the object
  if (obj.hasOwnProperty(filterKey)) {
    // Create a new object with only the specified key-value pair
    const result = {};
    result[filterKey] = obj[filterKey];
    return result;
  } else {
    // Return an empty object if the key is not found
    return {};
  }
}

const AvailabilitiesTable = ({ sections = {}, providerId, language }) => {
  const { translate } = React.useContext(IntlContext);
  const startDate = new Date();
  const numDays = 60;
  const dates = Array.from({ length: numDays }, (_, i) => addDays(startDate, i));
  const { currency } = React.useContext(CurrencyContext);

  const section1 = getKeyValuePair(sections,'1');
  const section2 = getKeyValuePair(sections,'2');
  const availabilitySection1Official = groupToursByDate(section1, providerId, currency);
  const availabilitySection2Official = groupToursByDate(section2, providerId, currency);

  let title;
  let sub_title;
  let section_1;
  let section_2;
  let section_3;
  if (language === 'de') 
  {
    title = "Verfügbarkeit von Eiffelturm-Tickets";
    sub_title = "Vergleichen Sie die Ticketverfügbarkeit für den Eiffelturm von der <strong>offiziellen Website</strong> und anderen Ticketverkäufern. Finden Sie alternative Optionen, wenn die offizielle Website ausverkauft ist!";
    section_1 = "Offizielle Website: Spitze";
    section_2 = "Offizielle Website: 2. Stock";
    section_3 = "Alternative Optionen";
  }
  else if (language === 'it')
  {
    title = "Disponibilità dei biglietti per la Torre Eiffel";
    sub_title = "Confronta la disponibilità dei biglietti per la Torre Eiffel dal <strong>sito ufficiale</strong> e da altri rivenditori di biglietti. Scopri opzioni alternative se il sito ufficiale è esaurito!";
    section_1 = "Sito ufficiale: Cima";
    section_2 = "Sito ufficiale: 2° piano";
    section_3 = "Opzioni alternative";
  }
  else {
    // default to English
    title = 'Eiffel Tower Availabilities';
    sub_title = "Compare ticket availability for the Eiffel Tower from the <strong>official site</strong> and other ticket reseller websites. Discover alternative options if the official site is sold out!";
    section_1 = 'Official site: Top';
    section_2 = 'Official site: 2nd';
    section_3 = 'Alternative options';
  }

  const availabilityRest = groupToursByDate(section1, null, currency);

  const months = {};
  dates.forEach(date => {
    const monthKey = format(startOfMonth(date), 'yyyy-MM');
    if (!months[monthKey]) {
      months[monthKey] = [];
    }
    months[monthKey].push(date);
  });

  return (
    <Box $px={[15.5, null, null, null, 0]}>
      <Box>
        <TableTitle>
          <Text t={title}/>
        </TableTitle>
        <TableSubtext>
          <Text t={sub_title} html/>
        </TableSubtext>
      </Box>
      <TableContainer>
        <HeaderRow>
          <HeaderDateCell><HeaderText>{translate('date')}</HeaderText></HeaderDateCell>
          <HeaderAvailabilityCell><HeaderText>{section_1}</HeaderText></HeaderAvailabilityCell>
          <HeaderAvailabilityCell><HeaderText>{section_2}</HeaderText></HeaderAvailabilityCell>
          <HeaderAvailabilityCell><HeaderText>{section_3}</HeaderText></HeaderAvailabilityCell>
        </HeaderRow>
        {Object.entries(months).map(([month, dates]) => (
          <Box key={month} $pl={[0, null, null, null, 0]}>
          <MonthGroup>
              <Month>{formatMonthShort(language, new Date(dates[0]))}</Month>
            <DateRow>
              {dates.map(date => {
                const dateKey = format(date, 'yyyy-MM-dd');
                const tourAvailable1 = availabilitySection1Official[dateKey];
                const tourAvailable2 = availabilitySection2Official[dateKey];
                const tourAvailableRest = availabilityRest[dateKey];
                return (
                  <DateContainer key={date.toISOString()}>
                    <DateCell>
                      <Day>{formatDay3Letter(language, date)}</Day>
                      <Day>{format(date, 'd')}</Day>
                    </DateCell>
                    <AvailabilityCell $available={!!tourAvailable1}>
                      {tourAvailable1 ? localFormatPrice(tourAvailable1.price, currency) : '-'}
                    </AvailabilityCell>
                    <AvailabilityCell $available={!!tourAvailable2}>
                      {tourAvailable2 ? localFormatPrice(tourAvailable2.price, currency) : '-'}
                    </AvailabilityCell>
                    <AvailabilityCell $available={!!tourAvailableRest}>
                      {tourAvailableRest ? localFormatPrice(tourAvailableRest.price, currency) : '-'}
                    </AvailabilityCell>
                  </DateContainer>
                );
              })}
            </DateRow>
          </MonthGroup>
          </Box>
        ))}
      </TableContainer>
    </Box>
  );
};

export default AvailabilitiesTable;