// Image.js
// @flow strict
import React, { useState } from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import pkg from 'react-lazy-load-image-component';
const { LazyLoadImage } = pkg;

type Props = {
  src: string,
  lowResSrc?: string,
  srcSet?: string,
  alt: string,
  className?: string,
  lazy?: boolean,
  fallbackSrc?: string,
};

const Image = ({
  src,
  lowResSrc,
  srcSet,
  alt,
  className,
  lazy,
  fallbackSrc = '', // You can define a fallback source here
}: Props) => {
  const [imgSrc, setImgSrc] = useState(src);

  const handleError = () => {
    if (fallbackSrc) {
      setImgSrc(fallbackSrc);
    } else {
      // If no fallback is provided, you can remove the image entirely or do something else.
      setImgSrc('');
    }
  };

  return imgSrc ? (
    <LazyLoadImage
      src={imgSrc}
      srcSet={srcSet}
      alt={alt}
      effect="opacity"
      wrapperClassName={className}
      className={className}
      visibleByDefault={!lazy}
      onError={handleError}
    />
   ) : null;
};

Image.defaultProps = {
  lazy: false,
};

export default Image;