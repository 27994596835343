// @flow strict
import * as React from 'react';
import { withTheme } from 'styled-components';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Flex } from '../../../../primitives/Essentials/index.js';
import Line from '../../../../primitives/Line/index.js';
import type { Language } from '../../../../services/intl/context.js';
import type { Theme } from '../../../../records/Theme.js';
import mq from '../../../../services/mediaQuery/index.js';
import Text from '../../../Text/index.js';

type Props = {
  theme: Theme,
  language: Language,
};

const StyledFlex = styled(Flex)`
  display: none;
  ${mq.DESKTOP`
    display: inherit;
  `};
`;

const TaglinePrefix = ({ language, theme }: Props) => (
  <StyledFlex $alignItems="center">
    {language !== 'de' ? (
      <>
        <Line size={17} fontWeight={900} color={theme.secondary}>
          <Text t="app_title" />
          &nbsp;
        </Line>
        <Line size={17} color={theme.navbar.searchText}>
          <Text t="lets_you" />
        </Line>
      </>
    ) : (
      <>
        <Line size={17} color={theme.navbar.searchText}>
          <Text t="lets_you" />
          &nbsp;
        </Line>
        <Line size={17} fontWeight={900} color={theme.secondary}>
          <Text t="app_title" />:
        </Line>
      </>
    )}
  </StyledFlex>
);

export default withTheme(TaglinePrefix);
