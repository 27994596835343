// @flow strict
import type { Destination } from '../../../records/Destination';

export const sortByDestinationName = (a: Destination, b: Destination): number => {
  const aName = a.name.toUpperCase();
  const bName = b.name.toUpperCase();
  if (aName < bName) {
    return -1;
  }
  if (aName > bName) {
    return 1;
  }
  return 0;
};

export const getDestinationCountries = (destinations: Destination[]): string[] =>
  destinations
    .reduce(
      (acc, dest: Destination) => (!acc.includes(dest.country) ? acc.concat(dest.country) : acc),
      [],
    )
    .sort();

export const getCountryDestinations = (
  country: string,
  destinations: Destination[],
): Destination[] =>
  destinations.filter(dest => dest.country === country).sort(sortByDestinationName);

type DestByCountry = {
  [country: string]: Destination[],
};

export const getDestinationsByCountry = (
  countries: string[],
  destinations: Destination[],
): DestByCountry =>
  countries.reduce((acc, country) => {
    acc[country] = getCountryDestinations(country, destinations);
    return acc;
  }, {});
