// @flow strict
import * as React from 'react';
import { Box, Flex } from '../../../../../../primitives/Essentials/index.js';
import Tour from '../../../../../Tours/components/Tour/index.js';
import type { GroupTour } from '../../../../../../records/Tour.js';

type Props = {
  groupTours: GroupTour[],
  showAvailabilities: boolean,
  date?: ?Date,
};

const SearchToursList = ({ groupTours, showAvailabilities, date }: Props) => (
  <Box $px={[0, null, null, null, 30]}>
    <Flex $flexWrap="wrap">
      {groupTours.map(groupTour => (
        <Tour
          key={groupTour.groupId}
          groupTour={groupTour}
          singleColumn
          showAvailabilities={showAvailabilities}
          date={date}
        />
      ))}
    </Flex>
  </Box>
);

export default SearchToursList;
