// @flow strict
import { ThemeDefault } from '../../records/Theme.js';
import SIZES from '../../consts/sizes.js';

// reusable styled component code that is not a primitve goes here

export function truncate(width?: string, height?: string) {
  return `
    ${width ? `width: ${width}` : ''};
    ${height ? `height: ${height}` : ''};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `;
}

// you need to give position: relative to parent and z-index: 1+ to above overlay elements
export function darkOverlay() {
  return `
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.22);
    border-radius: 5px;
  `;
}

export function hover(noUnderline?: boolean) {
  if (noUnderline) {
    return `
    transition: transform 0.2s ease-in;
    &:hover {
      background-color: ${ThemeDefault.trip.backgroundDark};
      transform: translate(0, -4px);
    }
  `;
  }
  return `
    transition: transform 0.2s ease-in;
    &:hover {
      .darkenOnHover {
        background-color: ${ThemeDefault.trip.backgroundDark};
      }
      transform: translate(0, -4px);
      h3,h4,.title {
        text-decoration: underline;
      }
    }
  `;
}

export function hoverMoveRight(noUnderline?: boolean) {
  if (noUnderline) {
    return `
    transition: transform 0.2s ease-in;
    &:hover {
      background-color: ${ThemeDefault.trip.backgroundDark};
      transform: translate(4px, 0);
    }
  `;
  }
  return `
    transition: transform 0.2s ease-in;
    &:hover {
      background-color: ${ThemeDefault.trip.backgroundDark};
      transform: translate(4px, 0);
      h3,h4,.title {
        text-decoration: underline;
      }
    }
  `;
}

export function container() {
  return `
    margin-right: auto;
    margin-left: auto;
    max-width: ${SIZES.DESKTOP}px;
  `;
}

// sizes for computing minHeight
// if div can break into multiline and increase height then it doesn't work
export const HEADER = '80px';
export const TAGLINE = '56px';
export const FOOTER = '232.5px'; // 157.5px + 75px margin
export const FOOTER_MOBILE = '192.5px'; // 157.5px + 35px margin
export const FOOTER_BUTTON = '51px';
export const CONTENT_MARGIN = '20px';
export const COVER = '400px';
export const COVER_TABLET = '250px';
export const COVER_MOBILE = '200px';
