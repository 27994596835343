// @flow strict
import React from 'react';
import { Flex, Box } from '../../../../../../primitives/Essentials/index.js';
import SectionHeader from '../../../../../../components/SectionHeader/index.js';
import IntlContext from '../../../../../../services/intl/context.js';
import type { Faq as FaqType } from '../../../../../../records/PoiContent.js';
import { SECTION_LAYOUT_NO_PADDING_MOBILE } from '../../../../../../consts/layout.js';
import QuestionAnswer from './QuestionAnswer.js';

type Props = {
  content: FaqType[],
  pageUrl: string,
};

const Faq = ({ content, pageUrl }: Props) => {
  const Qas = (
    <Flex $mt={40} $flexDirection="row" $flexWrap="wrap">
      {content.map(qa => (
        <QuestionAnswer
          key={qa.question}
          question={qa.question}
          answer={qa.answer}
          pageUrl={pageUrl}
        />
      ))}
    </Flex>
  );
  return (
    <IntlContext.Consumer>
      {({ translate }) => (
        <Box {...SECTION_LAYOUT_NO_PADDING_MOBILE} id="faq" itemScope itemType="https://schema.org/FAQPage">
          <SectionHeader title={translate('faq')} center />
          {Qas}
        </Box>
      )}
    </IntlContext.Consumer>
  );
};

export default Faq;
