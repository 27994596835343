// @flow strict
import * as React from 'react';
import type { Match, Location } from 'react-router-dom';
import type { PoiToursSectionContextType } from '../../services/poi/sections_context.js';
import withPoiSection from '../../decorators/withPoiSection/index.js';
import TaglineCarousel from '../../components/TaglineCarousel/index.js';
import Navbar from '../../components/Navbar/index.js';
import { BITMAP_URL } from '../../../../etc/appConfig.js';
import { destinationSelector } from '../../services/destination/selectors.js';
import UrlContext from '../../services/url/context.js';
import IntlContext from '../../services/intl/context.js';
import WindowContext from '../../services/window/context.js';
import CurrencyContext from '../../services/currency/context.js';
import DestinationContext from '../../services/destination/context.js';
import ContentContext from '../../services/content/context.js';
import Footer from '../../components/Footer/index.js';
import Text from '../../components/Text/index.js';

import PoiToursSectionContent from './components/PoiToursSectionContent/index.js';


type Props = {
  match: Match,
  poiId: number,
  sectionId: number,
} & PoiToursSectionContextType;

const PoiToursSectionPage = ({ match, poi, poiId, tours, sectionContent }: Props) => {
  const { language } = React.useContext(IntlContext);
  const { buildDestinationPageUrl, buildPoiPageUrl, buildPoiToursSectionUrl } = React.useContext(UrlContext);
  const { promocodes, currencies, providers, imageAttributions } = React.useContext(ContentContext);
  const { destinations } = React.useContext(DestinationContext);
  const { currency } = React.useContext(CurrencyContext);
  const { isMobile } = React.useContext(WindowContext);

  //console.log('debug PoiToursSectionPage match:', match, 'debug poi:', poi, 'debug sectionContent:', sectionContent);

  const selectedDestination = destinationSelector(
    destinations[language],
    poi[language].breadcrumbDestination,
  );  

  return (
    <>      
      <Navbar
        title={sectionContent.title}
        imageUrl={`${BITMAP_URL}/poi/${poiId}`}
        imageService
        responsive
        destinationId={poi[language].breadcrumbDestination}
        imageAlt={sectionContent.title}
      />
      <TaglineCarousel />
      <PoiToursSectionContent
        poi={poi[language]}
        content={sectionContent}
        language={language}
        tours={tours}
        goBackBackHref={buildDestinationPageUrl(poi[language].breadcrumbDestination)}
        goBackBackText={selectedDestination ? selectedDestination.name : ''}
        goBackHref={buildPoiPageUrl(poi[language])}
        goBackText={poi[language].name}
        pageUrl={buildPoiToursSectionUrl(poi[language], sectionContent)}
        promocodes={promocodes}
        providers={providers}
        currencies={currencies}
        currency={currency}
        isMobile={isMobile}
        imageAttributions={imageAttributions}
      />
      <Footer
        goBackHref={buildPoiPageUrl(poi[language])}
        goBackText={
          <Text
            t="more_from"
            values={{
              place: poi[language].name,
            }}
          />
        }
      />
    </>
  );

};

export default withPoiSection(PoiToursSectionPage);