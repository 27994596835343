// @flow strict
import * as React from 'react';

import { getWindowType, TYPES } from './utils.js';

type Props = {|
  children: React.Node,
|};

export type WindowContextType = {
  isMobile: boolean,
  isTablet: boolean,
  isDesktop: boolean,
};

type State = WindowContextType;

const WindowContext = React.createContext<WindowContextType>({});

export class WindowContextManager extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isMobile: false,
      isTablet: false,
      isDesktop: false,
    };
  }

  handleResize = () => {
    const type = getWindowType();
    this.setState({
      isMobile: type === TYPES.MOBILE,
      isTablet: type === TYPES.TABLET,
      isDesktop: type === TYPES.DESKTOP,
    });
  };

  componentDidMount = () => {
    window.addEventListener('resize', this.handleResize, false);
    const type = getWindowType();

    this.setState({
      isMobile: type === TYPES.MOBILE,
      isTablet: type === TYPES.TABLET,
      isDesktop: type === TYPES.DESKTOP,
    });
  };

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.handleResize);
  };

  render() {
    const { isMobile, isTablet, isDesktop } = this.state;
    const { children } = this.props;

    return (
      <WindowContext.Provider
        value={{
          isMobile,
          isTablet,
          isDesktop,
        }}
      >
        {children}
      </WindowContext.Provider>
    );
  }
}

export default WindowContext;
