// @flow strict
import * as React from 'react';
import * as R from 'ramda/src/index.js';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie/src/js.cookie.js';
import queryString from 'query-string/index.js';
// $FlowFixMe
import addDays from 'date-fns/addDays/index.js';
// $FlowFixMe
import isSameDay from 'date-fns/isSameDay/index.js';
import ClientSideContent from './components/ClientSideContent/index.js';
import PreRenderedContent from './components/PreRenderedContent/index.js';
import type { Destination } from '../../../../records/Destination.js';
import type { Tag } from '../../../../records/SectionConfig.js';
import CurrencyContext from '../../../../services/currency/context.js';
import IntlContext from '../../../../services/intl/context.js';
import ContentContext from '../../../../services/content/context.js';
import type { DestinationCategoryContent } from '../../../../records/DestinationCategoryContent.js';
import type { GroupToursBySection } from '../../../../records/Tour.js';
import { formatDateYMD, parseDate } from '../../../../services/dateTimeUtils/index.js';
import { COOKIES } from '../../../../records/Cookies.js';

type State = {
  date: ?Date,
};

type Props = {
  isPrerendered: boolean,
  categoryContent: ?DestinationCategoryContent,
  shownTours: ?GroupToursBySection,
  selectedDestination: ?Destination,
  sectionConfigTags: Tag[],
  categoryId: number,
  resultsForString: string,
  destinationId: number,
  destinationName: string,
};

const CategoryContent = ({
  isPrerendered,
  categoryContent,
  shownTours,
  selectedDestination,
  sectionConfigTags,
  categoryId,
  resultsForString,
  destinationId,
  destinationName,
}: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [date, setDate] = React.useState<?Date>(null);

  React.useEffect(() => {
    let parsedDate;
    const queryParams = queryString.parse(location.search);

    if (queryParams.date) {
      parsedDate = parseDate(queryParams.date);
    } else if (Cookies.get(COOKIES.DATE)) {
      parsedDate = parseDate(Cookies.get(COOKIES.DATE));
    }

    if (parsedDate && !isNaN(parsedDate.getTime())) {
      setDate(parsedDate);
    }
  }, [location.search]);

  const handleChangeDate = (newDate: Date) => {
    const queryParams = queryString.parse(location.search);

    if (date && isSameDay(newDate, date)) {
      delete queryParams.date;
      navigate({ search: queryString.stringify(queryParams) });
      Cookies.remove(COOKIES.DATE);
    } else {
      queryParams.date = formatDateYMD(newDate);
      navigate({ search: queryString.stringify(queryParams) });
      Cookies.set(COOKIES.DATE, queryParams.date, {
        expires: addDays(newDate, 1),
      });
    }

    setDate((prevDate) => (prevDate && !isSameDay(newDate, prevDate)) || !prevDate ? newDate : null);
  };

  return isPrerendered && selectedDestination && categoryContent && shownTours ? (
    <ContentContext.Consumer>
      {({ currencies, promocodes, providers, imageAttributions }) => (
        <CurrencyContext.Consumer>
          {({ currency }) => (
            <IntlContext.Consumer>
              {({ language }) => (
                <PreRenderedContent
                  selectedDestination={selectedDestination}
                  categoryContent={categoryContent}
                  shownTours={shownTours}
                  resultsForString={resultsForString}
                  date={date}
                  onChangeDate={handleChangeDate}
                  currency={currency}
                  currencies={currencies}
                  promocodes={promocodes}
                  providers={providers}
                  destinationId={destinationId}
                  language={language}
                  imageAttributions={imageAttributions}
                />
              )}
            </IntlContext.Consumer>
          )}
        </CurrencyContext.Consumer>
      )}
    </ContentContext.Consumer>
  ) : (
    <ClientSideContent
      categoryId={categoryId}
      resultsForString={resultsForString}
      sectionConfigTags={sectionConfigTags}
      destinationId={destinationId}
      destinationName={destinationName}
      date={date}
      onChangeDate={handleChangeDate}
    />
  );
};

export default CategoryContent;