// @flow strict
import * as R from 'ramda/src/index.js';
import type { Language } from '../../intl/context.js';
import type { Tour, TourInput } from '../../../records/Tour.js';
import type { Promocodes } from '../../../records/Promocodes.js';
import type { Currencies } from '../../../records/Currencies.js';
import stripHtml from './stripHtml.js';
import getTourPromocode from '../../tours/getTourPromocode.js';

export default function mapperTours(
  tours: TourInput[],
  locale: Language,
  promocodes: Promocodes,
  currencies: Currencies,
  returnUrl: boolean = true,
): Tour[] {
  return tours.map(tour => {
    const title = tour[`title_${locale}`];
    const abstract = stripHtml(tour[`abstract_${locale}`]);
    const url = returnUrl ? tour[`url_${locale}`] : '';
    const promocode = getTourPromocode(tour, promocodes);

    const imageId = tour.imageIds[0] ? tour.imageIds[0] : null;

    const prices = Object.keys(currencies).reduce((acc, cur) => {
      acc[`price_${cur}`] = promocode
        ? +(
            tour[`price_${cur}`] -
            (tour[`price_${cur}`] / 100) * promocode.discountPercent
          ).toFixed(2)
        : tour[`price_${cur}`];
      acc[`originalPrice_${cur}`] = tour[`price_${cur}`];
      acc[`fee_${cur}`] =
        tour[`fee_${cur}`] > 0 && promocode
          ? tour[`fee_${cur}`] - (tour[`fee_${cur}`] / 100) * promocode.discountPercent
          : tour[`fee_${cur}`];
      return acc;
    }, {});

    let availabilities = null;
    // Extract and process the date tags
    // if (tour[`providerId`] >= 100) { // 
    if (tour._tags && tour._tags.some(tag => tag === 'p:660851')) {
      availabilities = tour._tags
        .filter(tag => tag.startsWith('a:') && tag !== 'a:default')
        .reduce((acc, tag) => {
          const date = tag.substring(2);
          acc[date] = {
            price_eur: tour[`price_eur`],
            price_usd: tour[`price_usd`],
            price_gbp: tour[`price_gbp`],
            price_chf: tour[`price_chf`]
          };
          return acc;
        }, {});
    }

    return {
      ...R.omit(
        [
          'title_en',
          'title_de',
          'title_it',
          'abstract_en',
          'abstract_de',
          'abstract_it',
          'url_en',
          'url_de',
          'url_it',
          '_tags',
          '_breadcrumbs',
          'imageIds',
        ],
        tour,
      ),
      title,
      abstract,
      url,
      imageId, 
      // eslint-disable-next-line no-underscore-dangle
      breadcrumbDestination: tour._breadcrumbs.d ? Number(tour._breadcrumbs.d) : null,
      // eslint-disable-next-line no-underscore-dangle
      breadcrumbPoi: tour._breadcrumbs.p ? Number(tour._breadcrumbs.p) : null,
      bestConverter: Boolean(tour.bestConverter), // value can be 0 to 256
      ...prices,
      availabilities,
    };
  });
}
