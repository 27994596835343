// @flow strict
import * as React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Flex, Box } from '../../primitives/Essentials/index.js';
import queryString from 'query-string';

import type { DestinationContextType } from '../../services/destination/context.js';
import IntlContext from '../../services/intl/context.js';
import type { Language } from '../../services/intl/context.js';
import UrlContext from '../../services/url/context.js';
import CurrencyContext from '../../services/currency/context.js';
import ContentContext from '../../services/content/context.js';
import { container, HEADER, FOOTER, FOOTER_MOBILE, FOOTER_BUTTON } from '../../services/styleUtils/index.js';
import Navbar from '../../components/Navbar/index.js';
import Footer from '../../components/Footer/index.js';
import Text from '../../components/Text/index.js';
import Helmet from './components/Helmet/index.js';
import withDestination from '../../decorators/withDestination/index.js';
import mq from '../../services/mediaQuery/index.js';
import { destinationSelector } from '../../services/destination/selectors.js';
import { RES_URL } from '../../../../etc/appConfig.js';
import type { Section } from '../../records/PoiContent.js';
import type { ImageAttributions } from '../../records/Photo.js';
import mapperCategoryContent from '../../services/mapper/categoryContent/index.js';
import SubCategoryContent from './components/SubCategoryContent/index.js';

type Props = {
  match: Match,
} & DestinationContextType;

const StyledContainerBox = styled(Box)`
  ${container()};
  min-height: calc(100vh - ${HEADER} - ${FOOTER_MOBILE} - ${FOOTER_BUTTON} - 2px);
  ${mq.TABLET`
    min-height: calc(100vh - ${HEADER} - ${FOOTER} - ${FOOTER_BUTTON} - 2px);
  `} ${mq.DESKTOP`
    min-height: calc(100vh - ${HEADER} - ${FOOTER} - ${FOOTER_BUTTON} - 2px);
  `};
`;

const useCategoryContent = (
  destinationId: number | string,
  categoryId: number | string,
  language: Language,
  imageAttributions: ImageAttributions,
) => {
  const url = `${RES_URL}/categories${
    process.env.IS_STAGE ? '_stage' : ''
  }/d${destinationId}/c${categoryId}.json`;
  const [data, updateData] = React.useState(undefined);

  // empty array as second argument equivalent to componentDidMount
  React.useEffect(() => {
    fetch(url)
      .then(res => {
        return res.json();
      })
      .then(json => {
        updateData(mapperCategoryContent(json, language, imageAttributions));
      });
  }, []);

  return data;
};

const SubCategoryPage = ({ match, configs, destinations }: Props) => {
  const { language, translate } = React.useContext(IntlContext);
  const { buildDestinationCategoryPageUrl } = React.useContext(UrlContext);
  const { currency } = React.useContext(CurrencyContext);
  const { promocodes, currencies, providers, imageAttributions } = React.useContext(ContentContext);

  const { destinationId: rawDestinationId, categoryId: rawCategoryId } = useParams();
  const destinationId = Number(rawDestinationId.replace(/^d/, ''));
  const categoryId = Number(rawCategoryId.replace(/^c/, ''));
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const sectionId: ?string = queryParams.s ? String(queryParams.s) : null;
  const categoryContent = useCategoryContent(
    destinationId,
    categoryId,
    language,
    imageAttributions, // attributions are {} here but they are not needed
  );
  const section: ?Section =
    categoryContent && categoryContent.sections.find(x => x.id === sectionId);

  if (!categoryId || !sectionId) {
    return null;
  }

  const selectedDestination = destinationSelector(destinations[language], destinationId);
  const sectionConfigTags = configs[language].Tags.concat(configs[language].Categories);
  const tagFromConfig = sectionConfigTags.filter(tag => tag.Tag === categoryId)[0];
  const resultsForString = tagFromConfig ? tagFromConfig.Name : '';

  return (
    <>
      <Navbar destinationId={destinationId} />
      <StyledContainerBox>
        <Flex $flexDirection={['column', null, null, null, null, 'row']}>
          <Helmet
            translate={translate}
            location={location}
            resultsForString={resultsForString}
            sectionId={sectionId}
            destinationName={selectedDestination ? selectedDestination.name : ''}
          />
          <SubCategoryContent
            language={language}
            section={section}
            currency={currency}
            id={destinationId}
            destinationId={destinationId}
            breadcrumbs={[
              {
                url: buildDestinationCategoryPageUrl(categoryId, sectionConfigTags),
                name: resultsForString,
              },
            ]}
            sectionConfigTags={sectionConfigTags}
            sectionId={sectionId}
            promocodes={promocodes}
            providers={providers}
            currencies={currencies}
          />
        </Flex>
      </StyledContainerBox>
      <Footer
        goBackHref={buildDestinationCategoryPageUrl(categoryId, sectionConfigTags)}
        goBackText={
          <Text
            t="more_from"
            values={{
              place: resultsForString,
            }}
          />
        }
      />
    </>
  );
};

export default withDestination(SubCategoryPage);
