export const logEvent = (eventName, eventParams = {}) => {
  /*if (typeof window !== 'undefined' && window.gtag) {
    window.gtag('event', eventName, eventParams);
  } else {
    console.warn(`GA4 not initialized. Event "${eventName}" not sent.`);
  }*/
};


// Usage
// const chatSplitTestGroup = !excludeBotFromTest && (getTestGroupAllocation('chatb1', 2) === 0); // false for control group, true for test group

export function getTestGroupAllocation(testId, numberOfGroups, cookieLifetime = 7 * 24 * 60 * 60 * 1000) {
  // Check if running in a browser environment
  if (typeof document === "undefined") {
    return 0; // Return default group 0 if not in a browser
  }

  // Function to read a cookie by name
  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  // Function to set a cookie
  function setCookie(name, value, expiresIn) {
    const expires = new Date(Date.now() + expiresIn).toUTCString();
    document.cookie = `${name}=${value}; expires=${expires}; path=/`;
  }

  // Check if the test group is already allocated by checking the cookie
  const existingGroup = getCookie(testId);
  if (existingGroup) {
    return parseInt(existingGroup, 10);
  }

  // Generate a random test group allocation
  const assignedGroup = Math.floor(Math.random() * numberOfGroups);

  // Save the assigned test group to a cookie
  setCookie(testId, assignedGroup, cookieLifetime);

  // Return the newly assigned group
  return assignedGroup;
}

// Utility function to detect Googlebot
export const isGoogleBot = () => {
  const userAgent = typeof navigator !== 'undefined' ? navigator.userAgent : '';
  return /Googlebot/i.test(userAgent);
};

export default logEvent;