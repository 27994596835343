// @flow strict
import * as React from 'react';
import { withTheme } from 'styled-components';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Box, Flex } from '../../primitives/Essentials/index.js';
import { container } from '../../services/styleUtils/index.js';
import Line from '../../primitives/Line/index.js';
import Link from '../Link/index.js';
import Text from '../Text/index.js';
import IntlContext from '../../services/intl/context.js';
import DestinationContext from '../../services/destination/context.js';
import UrlContext from '../../services/url/context.js';
import PoiContext from '../../services/poi/context.js';
import ContentContext from '../../services/content/context.js';

const StyledContainerBox = styled(Box)`
  ${container()};
  width: 100%;
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.secondary};
  line-height: 1.79;
  &:hover {
    text-decoration: underline;
  }
  &:not(&:last-child)&:after {
    display: inline-block;
    padding: 0 4px;
    color: ${({ theme }) => theme.loader};
    content: '|';
  }
`;

type Props = {
  attractionsOnly: boolean,
  hideTopics?: boolean,
};

const MoreLinks = ({ attractionsOnly, hideTopics }: Props) => {
  const { buildDestinationPageUrl, buildPoiPageUrl } = React.useContext(UrlContext);
  const { language, translate } = React.useContext(IntlContext);
  const { popularAttractions } = React.useContext(PoiContext);
  const { destinations, configs } = React.useContext(DestinationContext);
  const { popularTopics } = React.useContext(ContentContext);

  const shownDestinations = destinations[language].slice(0, 10);
  const topicsWithContent =
    configs &&
    configs[language].Tags.concat(configs[language].Categories).filter(
      category => category.hasContent,
    );

  return (
    <StyledContainerBox $mt={[20, null, null, null, 30]}>
      <Flex
        $flexDirection="column"
        $px={!attractionsOnly ? [15.5, null, null, null, 30] : undefined}
        $py={12.5}
      >
        {!attractionsOnly ? (
          <>
            {topicsWithContent && !hideTopics && Boolean(topicsWithContent.length) && (
              <Flex $mb={24} $flexDirection="column">
                <Line lineHeight={1.79} fontSize={16} fontWeight={900}>
                  <Text t={translate('popular_topics')} />
                </Line>
                <Flex $flexWrap="wrap">
                  {topicsWithContent.map(topic => (
                    <StyledLink key={topic.Tag} href={topic.urlName}>
                      {topic.Name}
                    </StyledLink>
                  ))}
                </Flex>
              </Flex>
            )}
            <Flex $mb={24} $flexDirection="column">
              <Line lineHeight={1.79} fontSize={16} fontWeight={900}>
                <Text t={translate('popular_destinations')} />
              </Line>
              <Flex $flexWrap="wrap">
                {shownDestinations.map(destination => (
                  <StyledLink
                    key={destination.destination_id}
                    href={buildDestinationPageUrl(destination.destination_id)}
                  >
                    {destination.name}
                  </StyledLink>
                ))}
              </Flex>
            </Flex>
            {Boolean(popularAttractions.length) && (
              <Flex $mb={24} $flexDirection="column">
                <Line lineHeight={1.79} fontSize={16} fontWeight={900}>
                  <Text
                    t={translate(attractionsOnly ? 'more_attractions' : 'popular_attractions')}
                  />
                </Line>
                <Flex $flexWrap="wrap">
                  {popularAttractions.map(attraction => (
                    <StyledLink key={attraction.objectID} href={buildPoiPageUrl(attraction)}>
                      {translate('attraction_mainkeyword_item', { poi: attraction.name })}
                    </StyledLink>
                  ))}
                </Flex>
              </Flex>
            )}
          </>
        ) : (
          <>
            <Flex $mb={24} $flexDirection="column">
              <Line lineHeight={1.79} fontSize={16} fontWeight={900}>
                <Text t={translate(attractionsOnly ? 'more_attractions' : 'popular_attractions')} />
              </Line>
              <Flex $flexWrap="wrap">
                {popularAttractions.map(attraction => (
                  <StyledLink key={attraction.objectID} href={buildPoiPageUrl(attraction)}>
                    {translate('attraction_mainkeyword_item', { poi: attraction.name })}
                  </StyledLink>
                ))}
              </Flex>
            </Flex>
            <Flex $flexDirection="column">
              <Line lineHeight={1.79} fontSize={16} fontWeight={900}>
                <Text t={translate('popular_topics')} />
              </Line>
              <Flex $flexWrap="wrap">
                {popularTopics.map(topic => (
                  <StyledLink key={topic.name} href={topic.url}>
                    {topic.name}
                  </StyledLink>
                ))}
              </Flex>
            </Flex>
          </>
        )}
      </Flex>
    </StyledContainerBox>
  );
};

MoreLinks.defaultProps = {
  hideTopics: false,
};

export default withTheme(MoreLinks);
