// @flow strict
import React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Box } from '../../../../../../primitives/Essentials/index.js';
import USPSlider from './components/USPSlider/index.js';
import LogoCarousel from './components/LogoCarousel/index.js';
import type { Providers } from '../../../../../../records/Providers.js';

// To extend to full width regardless of the parent width
// from more detailed explanation https://css-tricks.com/full-width-containers-limited-width-parents/
const StyledContainerBox = styled(Box)`
  width: 100%;
`;

type Props = {
  totalToursCount: number,
  providers: Providers,
};

const USPSection = ({ totalToursCount, providers }: Props) => (
  <StyledContainerBox>
    <USPSlider totalToursCount={totalToursCount} />
    <LogoCarousel providers={providers} />
  </StyledContainerBox>
);

export default USPSection;
