// @flow strict
import * as React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Box } from '../../primitives/Essentials/index.js';
import queryString from 'query-string';

import type { PoiContextType } from '../../services/poi/context.js';
import IntlContext from '../../services/intl/context.js';
import UrlContext from '../../services/url/context.js';
import type { Section } from '../../records/PoiContent.js';
import { container, HEADER, FOOTER, FOOTER_MOBILE, FOOTER_BUTTON } from '../../services/styleUtils/index.js';
import Navbar from '../../components/Navbar/index.js';
import Footer from '../../components/Footer/index.js';
import Text from '../../components/Text/index.js';
import DEFAULT_POI_SECTIONS from '../../../../etc/sections.js';
import mq from '../../services/mediaQuery/index.js';
import withPoi from '../../decorators/withPoi/index.js';
// cross import, move to common components if it works
import PoiCategoryContent from './components/PoiCategoryContent/index.js';
import Helmet from './components/Helmet/index.js';

type Props = {
  match: Match,
  poiId: number,
} & PoiContextType;

const StyledContainerBox = styled(Box)`
  ${container()};
  min-height: calc(100vh - ${HEADER} - ${FOOTER_MOBILE} - ${FOOTER_BUTTON} - 2px);
  ${mq.TABLET`
    min-height: calc(100vh - ${HEADER} - ${FOOTER} - ${FOOTER_BUTTON} - 2px);
  `} ${mq.DESKTOP`
    min-height: calc(100vh - ${HEADER} - ${FOOTER} - ${FOOTER_BUTTON} - 2px);
  `};
`;

const PoiCategoryPage = ({ match, poiId, poi, poiContent }: Props) => {
  const { language, translate } = React.useContext(IntlContext);
  const { buildPoiPageUrl } = React.useContext(UrlContext);

  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const sectionId: ?string = queryParams.s ? String(queryParams.s) : null;

  const sections =
    poiContent && poiContent.sections
      ? poiContent.sections.concat(DEFAULT_POI_SECTIONS[language])
      : DEFAULT_POI_SECTIONS[language];
  const section: ?Section = sections.find(x => x.id === sectionId);
  if (!sectionId || !section) {
    return null;
  }
  const breadcrumbs = [];
  if (poi[language]) {
    breadcrumbs.push({
      url: buildPoiPageUrl(poi[language]),
      name: poi[language].name,
    });
  }

  return (
    <>
      {poi && Boolean(poi[language].name.length) && (
        <Helmet
          translate={translate}
          resultsForString={section.title}
          poiName={poi[language].name}
          location={location}
          sectionId={sectionId}
        />
      )}
      <Navbar destinationId={poi[language].breadcrumbDestination} />
      <StyledContainerBox>
        <PoiCategoryContent
          resultsFor={section.title}
          poiId={poiId}
          poi={poi[language]}
          section={section}
          language={language}
          breadcrumbs={breadcrumbs}
        />
      </StyledContainerBox>
      <Footer
        goBackHref={buildPoiPageUrl(poi[language])}
        goBackText={
          <Text
            t="more_from"
            values={{
              place: poi[language].name,
            }}
          />
        }
      />
    </>
  );
};

export default withPoi(PoiCategoryPage);
