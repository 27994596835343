// @flow strict
/* eslint-disable react/no-danger */
import * as React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Flex } from '../../../../primitives/Essentials/index.js';
import queryString from 'query-string';
import Cookies from 'js-cookie';
// $FlowFixMe
import addDays from 'date-fns/addDays/index.js';
// $FlowFixMe
import isSameDay from 'date-fns/isSameDay/index.js';
import {
  container,
  HEADER,
  TAGLINE,
  COVER_MOBILE,
  COVER,
  COVER_TABLET,
  FOOTER,
  FOOTER_MOBILE,
  CONTENT_MARGIN
} from '../../../../services/styleUtils/index.js';
import SIZES from '../../../../consts/sizes.js';
import mq from '../../../../services/mediaQuery/index.js';
import type { Language } from '../../../../services/intl/context.js';
import type { GroupToursBySection } from '../../../../records/Tour.js';
import { parseDate, formatDateYMD } from '../../../../services/dateTimeUtils/index.js';
import { getPoiTours } from '../../../../services/api/tours/index.js';
import PoiAvailability from '../../../../components/PoiAvailability/index.js';
import DestinationContext from '../../../../services/destination/context.js';
import { COOKIES } from '../../../../records/Cookies.js';
import type { Promocodes } from '../../../../records/Promocodes.js';
import type { Providers } from '../../../../records/Providers.js';
import type { ObservationDeck } from '../../../../records/ObservationDeck.js';
import type { Currencies } from '../../../../records/Currencies.js';
import type { ImageAttributions } from '../../../../records/Photo.js';
import type { Currency } from '../../../../services/currency/context.js';
import type { Poi } from '../../../../records/Poi.js';
import type { PoiContent as PoiContentType } from '../../../../records/PoiContent.js';
import Breadcrumbs from '../../../../components/Breadcrumbs/index.js';
import {
  BREADCRUMBS_LAYOUT,
  LAYOUT_COMMON,
  SECTION_LAYOUT_NO_PADDING_MOBILE,
} from '../../../../consts/layout.js';
import Abstract from '../../../../components/Abstract/index.js';
import PoiSections from './components/PoiSections/index.js';
import Attractions from './components/Attractions/index.js';
import TopTips from './components/TopTips/index.js';
import Faq from './components/Faq/index.js';
import ObservationDecks from './components/ObservationDecks/index.js';
import About from './components/About/index.js';
import AvailabilitiesTable from './components/AvailabilitiesTable/index.js';
import { destinationSelector } from '../../../../services/destination/selectors.js';
import DEFAULT_POI_SECTIONS from '../../../../../../etc/sections.js';
import NoToursNote from './components/NoToursNote/index.js';
import Rating from './components/Rating/index.js';
import History from '../../../../components/History/index.js';
import ClientOnly from '../../../../components/ClientOnly/index.js';
import EditorialContent from '../../../../components/EditorialContent/index.js';
import TableOfContents from '../../../../components/TableOfContents/index.js';
import AuthorBio from '../../../../components/AuthorBio/index.js';
import mapperPoiContent from '../../../../services/mapper/poiContent/index.js';
import SvgSpinner from '../../../../components/SvgSpinner/index.js';
import Exhibitions from './components/Exhibitions/index.js';

// Styled components
const StyledContainerBox = styled(Box)`
  ${container()};
  min-height: calc(
    100vh - ${HEADER} - ${TAGLINE} - ${FOOTER_MOBILE} - ${COVER_MOBILE} - ${CONTENT_MARGIN} - 57px
  );
  ${mq.TABLET`
    min-height: calc(100vh - ${HEADER} - ${TAGLINE} - ${FOOTER} - ${COVER_TABLET} - ${CONTENT_MARGIN} - 67px);
  `};
  ${mq.DESKTOP`
    min-height: calc(100vh - ${HEADER} - ${TAGLINE} - ${FOOTER} - ${COVER} - ${CONTENT_MARGIN} - 67px);
    left-margin: 0px;
  `};
`;

const POIContentContainer = styled.div``;

const TOCLayoutContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  ${mq.DESKTOP_TOC`
    display: flex;
    flex-direction: row;
    margin-left: calc((100vw - ${SIZES.DESKTOP}px)/2 - 200px);
  `};
`;

const Container = styled(Box)`
  ${container()};
`;

// Helper function to check if there are tours
const hasTours = (tours: GroupToursBySection) => Object.values(tours).some(x => x.length);

// Define sections based on content or default
const getSections = (content: ?PoiContentType, language: Language) =>
  content && content.sections && !content.sections.some(section => section.disabled)
    ? content.sections
    : DEFAULT_POI_SECTIONS[language];

type Props = {
  tours: GroupToursBySection,
  language: Language,
  poi: Poi,
  content: ?PoiContentType,
  goBackHref: string,
  goBackText: string,
  pageUrl: string,
  promocodes: Promocodes,
  providers: Providers,
  currencies: Currencies,
  observationDecks: ObservationDeck[],
  isMobile: boolean,
  currency: Currency,
  imageAttributions: ImageAttributions,
};

const PoiContent = ({
  tours,
  language,
  poi,
  content,
  goBackHref,
  goBackText,
  pageUrl,
  promocodes,
  providers,
  currencies,
  observationDecks,
  isMobile,
  currency,
  imageAttributions,
}: Props) => {
  const location = useLocation();
  const navigate = useNavigate();

  // State variables
  const [date, setDate] = React.useState<?Date>(null);
  const [dateTours, setDateTours] = React.useState<?GroupToursBySection>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [callToActionVisible, setCallToActionVisible] = React.useState<boolean>(false);
  const [updatedContent, setUpdatedContent] = React.useState<?PoiContentType>(null);

  // Refs
  const contentWrapperNode = React.useRef<?HTMLElement>(null);
  const sectionWrapperNode = React.useRef<?HTMLElement>(null);

  // Effect to handle initial data fetching and event listeners
  React.useEffect(() => {
    let parsedDate;
    if (hasTours(tours)) {
      const queryParams = queryString.parse(location.search);
      if (queryParams.date) {
        parsedDate = parseDate(queryParams.date);
      } else if (Cookies.get(COOKIES.DATE)) {
        parsedDate = parseDate(Cookies.get(COOKIES.DATE));
      }

      if (parsedDate && !isNaN(parsedDate.getTime())) {
        setDate(parsedDate);
        setIsLoading(true);
        updateTours(parsedDate);
      }
    }

    // Event listeners
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('message', handleMessage);
    handleScroll();

    // Cleanup
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('message', handleMessage);
    };
  }, [tours, location.search, language, content, updatedContent, providers, currencies]);

  // Handler for messages from other windows or iframes
  const handleMessage = (event: MessageEvent) => {
    if (
      event.data.uniqueId &&
      ((event.origin.includes('localhost') && window.location.origin.includes('localhost')) ||
        (event.origin.includes('admin') && window.location.origin.includes('ticketlens')))
    ) {
      setUpdatedContent(mapperPoiContent(event.data, language, imageAttributions));
      updateTours(date);
    }
  };

  // Handler for scroll events to toggle CTA visibility
  const handleScroll = () => {
    const currentScrollPosition = window.pageYOffset;

    if (contentWrapperNode.current) {
      const contentOutOfView =
        currentScrollPosition >=
        contentWrapperNode.current.offsetTop +
        contentWrapperNode.current.getBoundingClientRect().height;
      const contentInView = currentScrollPosition > contentWrapperNode.current.offsetTop;
      setCallToActionVisible(
        contentInView && ((isMobile && !contentOutOfView) || !isMobile)
      );
    }
  };

  // Handler to change the selected date
  const handleChangeDate = (newDate: Date) => {
    const queryParams = queryString.parse(location.search);
    if (date && isSameDay(newDate, date)) {
      delete queryParams.date;
      navigate({ search: queryString.stringify(queryParams) });
      Cookies.remove(COOKIES.DATE);
      setDate(null);
      setDateTours(null);
    } else {
      queryParams.date = formatDateYMD(newDate);
      navigate({ search: queryString.stringify(queryParams) });
      Cookies.set(COOKIES.DATE, queryParams.date, {
        expires: addDays(newDate, 1),
      });
      setDate(newDate);
      setIsLoading(true);
      updateTours(newDate);
    }
  };

  // Function to fetch tours based on the selected date
  const updateTours = (selectedDate: ?Date) => {
    if (selectedDate) {
      setIsLoading(true);
      getPoiTours(
        language,
        poi.objectID,
        getSections(updatedContent || content, language),
        promocodes,
        currencies,
        providers,
        formatDateYMD(selectedDate),
      )
        .then((fetchedDateTours: GroupToursBySection) => {
          const allToursLength = Object.values(fetchedDateTours).reduce(
            (acc, section) => acc + section.length,
            0,
          );
          if (allToursLength === 0) {
            setDateTours(null);
            setDate(null);
            Cookies.remove(COOKIES.DATE);
          } else {
            setDateTours(fetchedDateTours);
          }
          setIsLoading(false);
        })
        .catch(error => {
          console.error('Error fetching POI tours:', error);
          setIsLoading(false);
        });
    }
  };

  // Function to render the main content box
  const renderStyledContainerBox = () => {
    // Access variables directly from closure
    const _content = updatedContent || content;
    const sections = getSections(_content, language);
    const showTOC = content && content.editorialContent && content.editorialContent.length > 1;

    const shownTours = (date || updatedContent) && dateTours ? dateTours : tours;

    return (
    <POIContentContainer>
      <StyledContainerBox>
        {/* Breadcrumbs */ }
        <Box $px={BREADCRUMBS_LAYOUT.$px} >
          <Breadcrumbs
                activeIndex={1} 
                items = {[{ name: goBackText, url: goBackHref }, { name: poi.name, url: pageUrl },]}/>
        </Box>

        {/* Conditional rendering based on showTOC */ }
        { showTOC ? (
          <Flex $flexDirection= { ['column', null, null, null, 'row', 'row']} >
            <Flex $flexDirection={['column', null, null, 'column', 'column', 'column'] }>
              { _content && _content.abstract && (
                <Box width={[1, null, null, null, null, 1]}
                    $flexDirection={['column', null, null, null, 'row', 'row']} >
                  <Abstract author={_content.author}>
                    <span dangerouslySetInnerHTML={{ __html: _content.abstract } } />
                  </Abstract>
                </Box>
              )}
              <Box width={[1, null, null, null, null, 1]}
                  $pl={_content? [0, null, null, null, 30, 15]: [0, null, null, null, 30]}
                  $pr={_content? [0, null, null, null, 30]: [0, null, null, null, 30, 0]}>
                { hasTours(tours) ? (
                  <PoiAvailability date={date} onChangeDate={handleChangeDate} />
                ) : (
                  <NoToursNote />
                )}
              </Box>
            </Flex>
            <TableOfContents content={_content} poi={poi} inline={true} />
          </Flex>
        ) : (
          <Flex $flexDirection= { ['column', null, null, null, null, 'row']} >
            { _content && _content.abstract && (
              <Box width={[1, null, null, null, null, 1 / 2] }>
                <Abstract author={_content.author}>
                  <span dangerouslySetInnerHTML={{ __html: _content.abstract} } />
                    </Abstract>
                    </Box>
            )}
            <Box width={[1, null, null, null, null, 1 / 2]}
                $pl={_content? [0, null, null, null, 30, 15]: [0, null, null, null, 30]}
                $pr={_content? [0, null, null, null, 30]: [0, null, null, null, 30, 0]}>
              { hasTours(tours) ? (
                <PoiAvailability date={date} onChangeDate={handleChangeDate} />
              ) : (
                <NoToursNote />
              )}
            </Box>
          </Flex>
        )}
        {/* Specific POI Handling */ }
        { poi.objectID === "660851" && (
          <Box { ...SECTION_LAYOUT_NO_PADDING_MOBILE} $pb = "0px" >
            <AvailabilitiesTable sections={tours} providerId={102} language={language} />
          </Box>
          )
        }
        {/* Loading Spinner */ }
        { isLoading && (
          <Box $mt={40}>
            <Flex $justifyContent="center" $alignItems = "center" >
              <SvgSpinner />
            </Flex>
          </Box>
          )
        }
        {/* Tours Sections */ }
        { !isLoading && shownTours && (
          <div ref={sectionWrapperNode}>
            <PoiSections shownTours={shownTours} poi={poi} date={date} sections={sections} />
          </div>
          )
        }
        {/* Exhibitions */ }
        {
          _content?.dynamicContent?.exhibitions && (
            <Box { ...SECTION_LAYOUT_NO_PADDING_MOBILE } $pb = "0px" >
              <Exhibitions language={ language } data={ _content.dynamicContent.exhibitions } />
            </Box>
          )
        }
        {/* Additional Content */ }
        <div ref={(_content && (_content.faq || _content.topTips || _content.about || _content.editorialContent)) 
          || observationDecks.length > 0 ? contentWrapperNode : null}>
          {
            updatedContent? (
              <>
                {
                  updatedContent.topTips && (
                    <TopTips tips={updatedContent.topTips} poiName={poi.name} /> )
                }
                {
                  updatedContent.editorialContent && (
                    <EditorialContent editorialContent={updatedContent.editorialContent} smallerMarginTop />
                  )
                }
                { updatedContent.faq && <Faq content={updatedContent.faq} pageUrl={pageUrl} /> }
                {
                  observationDecks.length > 0 && (
                    <ObservationDecks content={observationDecks} poiId={Number(poi.objectID)} />
                  )
                }
                { updatedContent.about && <About content={updatedContent.about} poi={poi} /> }
              </>
            ) : (
            <>
            { content && content.topTips && (
                <TopTips tips={content.topTips} poiName={poi.name} />
            )}
            {
              content && content.editorialContent && (
                <EditorialContent editorialContent={content.editorialContent} smallerMarginTop />
              )
            }
            <div>
              { content && content.faq && <Faq content={content.faq} pageUrl={pageUrl} />}
            </div>
            {
              observationDecks.length > 0 && (
                <ObservationDecks content={observationDecks} poiId={Number(poi.objectID)} />
              )
            }
            <div>
              { content && content.about && <About content={content.about} poi={poi} />}
            </div>
            </>
            )
          }
        </div>
      </StyledContainerBox>
      {/* Author Bio */ }
      {
        _content && _content.author && (
          <AuthorBio author={content.author} translator={_content.translator} />
        )
      }
      {/* Rating */ }
      <Rating ratings={poi.ratings} poiId={poi.objectID} language={language} noMarginTop={Boolean(_content && _content.author)} />

      {/* History Section */ }
      <ClientOnly>
        { typeof window !== 'undefined' && window.localStorage.getItem('clickHistory') && (
        <Container $mt={LAYOUT_COMMON.$mt}
                   $py={LAYOUT_COMMON.$py}
                   $px={SECTION_LAYOUT_NO_PADDING_MOBILE.$px} >
          <History language={language}
                   promocodes={promocodes}
                   currencies={currencies}
                   providers={providers}
                   history={window.localStorage.getItem('clickHistory')} />
        </Container>
        )}
      </ClientOnly>
      {/* Attractions Section */ }
      <Container>
        <DestinationContext.Consumer>
          {({ attractions, destinations }) => {
            const selectedDestination = destinationSelector(
              destinations[language],
              poi.breadcrumbDestination,
            );
            return (
              <Attractions destinationName={selectedDestination? selectedDestination.name : ''}
                          attractions={attractions}
                          currentPoi={poi}
                          language={language} />
            );
          }}
        </DestinationContext.Consumer>
      </Container>
    </POIContentContainer>
    );
  };

  // Main render logic
  const showTOC = content && content.editorialContent && content.editorialContent.length > 1;

  return showTOC ? (
    <TOCLayoutContainer>
      <TableOfContents content={content} poi={poi} inline={false} />
        <POIContentContainer>{renderStyledContainerBox()} </POIContentContainer>
        </TOCLayoutContainer>
      ) : (
    <POIContentContainer>
    { renderStyledContainerBox() }
    </POIContentContainer>
  );
};

export default PoiContent;