// @flow strict
import * as R from 'ramda/src/index.js';
import type { Language } from '../../intl/context.js';
import type { TagInput, Tag } from '../../../records/SectionConfig.js';

const attributesToOmmit = [
  'Name_EN', 
  'Name_DE', 
  'Name_IT',
  'urlName_en', 
  'urlName_de',
  'urlName_it',
];

export default function mapperTags(
  tags: TagInput[],
  locale: Language,
  categoryContentFiles?: string[],
): Tag[] {
  return tags.map(tag => {
    const Name = tag[`Name_${locale.toUpperCase()}`];
    const urlName = tag[`urlName_${locale.toLowerCase()}`];
    const hasContent = Boolean(
      categoryContentFiles && categoryContentFiles.includes(`c${tag.Tag}.json`),
    );
    // TODO: should map result to lowercase and refactor no-mixed-case
    if (urlName) {
      return {
        ...R.omit(attributesToOmmit, tag),
        Name,
        urlName,
        hasContent,
      };
    }
    return {
      ...R.omit(attributesToOmmit, tag),
      Name,
      hasContent,
    };
  });
}
