// @flow strict
import * as React from 'react';
import { Flex } from '../../../../primitives/Essentials/index.js';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { IconDropdown } from '../../../Icons/index.js';
import Line from '../../../../primitives/Line/index.js';
import Text from '../../../Text/index.js';
import type { Theme } from '../../../../records/Theme.js';
import mq from '../../../../services/mediaQuery/index.js';

const Container = styled(Flex)`
  position: relative;
  height: 40px;

  ${mq.TABLET`
    height: auto;
  `};
`;

const Icon = styled.span`
  position: absolute;
  right: 15px;
  color: ${({ theme }) => theme.tertiary};
  pointer-events: none;
`;

const StyledLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  line-height: 2.86;
  ${mq.TABLET`
    line-height: 2.14;
  `}
`;

type Props = {
  title: string,
  theme: Theme,
  isMobile: boolean,
  onClick?: ?() => void,
  className?: ?string,
};

const CollapsableTitle = ({ title, theme, isMobile, onClick, className }: Props) => (
  <Container className={className} onClick={onClick} $alignItems="center">
    <StyledLine fontSize={16} fontWeight={900} letterSpacing={-0.1} color={theme.tertiary}>
      <Text t={title} />
    </StyledLine>
    {isMobile && (
      <Icon>
        <IconDropdown color={theme.tertiary} size={10} />
      </Icon>
    )}
  </Container>
);

CollapsableTitle.defaultProps = {
  className: null,
  onClick: null,
};

export default CollapsableTitle;
