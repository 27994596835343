// @flow strict
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import queryString from 'query-string';
import Cookies from 'js-cookie/src/js.cookie.js';
import addDays from 'date-fns/addDays/index.js';
import isSameDay from 'date-fns/isSameDay/index.js';
import { Box } from '../../../../primitives/Essentials/index.js';
import mq from '../../../../services/mediaQuery/index.js';
import ToursContainer from '../../../../components/ToursContainer/index.js';
import PoiAvailability from '../../../../components/PoiAvailability/index.js';
import type { Language } from '../../../../services/intl/context.js';
import type { Currency } from '../../../../services/currency/context.js';
import type { Currencies } from '../../../../records/Currencies.js';
import type { Promocodes } from '../../../../records/Promocodes.js';
import type { Providers } from '../../../../records/Providers.js';
import type { Section } from '../../../../records/PoiContent.js';
import type { Tag } from '../../../../records/SectionConfig.js';
import type { Breadcrumb } from '../../../../components/Breadcrumbs/index.js';
import { formatDateYMD, parseDate } from '../../../../services/dateTimeUtils/index.js';
import { COOKIES } from '../../../../records/Cookies.js';

const StyledBox = styled(Box)`
  margin-top: -2px;
  ${mq.TABLET`
    margin-top: 0px;
  `};
  ${mq.DESKTOP`
    max-width: 435px;
  `};
`;

type Props = {
  language: Language,
  currency: Currency,
  section: ?Section,
  sectionId: string,
  promocodes: Promocodes,
  providers: Providers,
  currencies: Currencies,
  sectionConfigTags: Tag[],
  breadcrumbs: Breadcrumb[],
  destinationId: number,
};

const SubCategoryContent = ({
  language,
  currency,
  section,
  sectionId,
  promocodes,
  providers,
  currencies,
  sectionConfigTags,
  breadcrumbs,
  destinationId,
}: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [date, setDate] = React.useState<?Date>(null);

  React.useEffect(() => {
    let parsedDate;
    const queryParams = queryString.parse(location.search);
    if (queryParams.date) {
      parsedDate = parseDate(queryParams.date);
    } else if (Cookies.get(COOKIES.DATE)) {
      parsedDate = parseDate(Cookies.get(COOKIES.DATE));
    }

    if (parsedDate && !isNaN(parsedDate.getTime())) {
      setDate(parsedDate);
    }
  }, [location]);

  const handleChangeDate = (newDate: Date) => {
    const queryParams = queryString.parse(location.search);
    if (date && isSameDay(newDate, date)) {
      queryParams.date = formatDateYMD(newDate);
      navigate({ search: queryString.stringify(R.omit(['date'], queryParams)) });
      Cookies.remove(COOKIES.DATE);
    } else {
      queryParams.date = formatDateYMD(newDate);
      navigate({ search: queryString.stringify(queryParams) });
      Cookies.set(COOKIES.DATE, queryParams.date, {
        expires: addDays(newDate, 1),
      });
    }

    setDate((prevDate) => 
      (prevDate && !isSameDay(newDate, prevDate)) || !prevDate ? newDate : null
    );
  };

  return (
    <>
      <StyledBox
        $px={[0, null, null, null, 30]}
        $pt={[0, null, null, null, 14, 49]}
        width={[1, null, null, null, null, 5 / 12]}
      >
        <PoiAvailability date={date} onChangeDate={handleChangeDate} />
      </StyledBox>
      <Box width={1}>
        {section && (
          <ToursContainer
            language={language}
            filters={section.filters}
            currency={currency}
            id={destinationId}
            type="destination"
            resultsForString={section.title}
            breadcrumbs={breadcrumbs}
            sectionConfigTags={sectionConfigTags}
            sectionId={sectionId}
            destinationId={destinationId}
            promocodes={promocodes}
            providers={providers}
            currencies={currencies}
            date={date}
          />
        )}
      </Box>
    </>
  );
};

export default SubCategoryContent;