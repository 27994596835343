// @flow strict
import * as React from 'react';
import type { Match } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import IntlContext from '../../services/intl/context.js';
import CurrencyContext from '../../services/currency/context.js';
import ContentContext from '../../services/content/context.js';
import GroupTourContent from './components/GroupTourContent/index.js';

type Props = {
  match: Match,
  groupId: number,
};

const GroupTourPage = ({ match, groupId }: Props) => {
  const { language } = React.useContext(IntlContext);
  const { currency } = React.useContext(CurrencyContext);
  const { promocodes, currencies, providers } = React.useContext(ContentContext);
  const location = useLocation();
  const navigate = useNavigate();

  if (!groupId) {
    return null;
  }

  return (
    <GroupTourContent
      language={language}
      currency={currency}
      groupId={groupId}
      reviewsTestGroup={false}
      promocodes={promocodes}
      currencies={currencies}
      providers={providers}
      location={location}
      navigate={navigate}
    />
  );
};

export default GroupTourPage;
