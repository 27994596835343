// @flow strict
import type { Language } from '../../../intl/context';
import type { SearchSuggestion } from '../../../../records/SearchSuggestion';

export function filterHomePageSuggestions(suggestion: SearchSuggestion): boolean {
  if (suggestion.tag.startsWith('d')) {
    return true;
  }

  return false;
}

export function filterHomePageExtraSuggestions(suggestion: SearchSuggestion): boolean {
  if (suggestion.tag.startsWith('p')) {
    return true;
  }

  return false;
}

export function filterInDestinationSuggestions(
  suggestion: SearchSuggestion,
  destinationTag: string,
): boolean {
  if (
    suggestion.tag.startsWith('p') &&
    suggestion.tags &&
    suggestion.tags.includes(destinationTag)
  ) {
    return true;
  }

  return false;
}

export function filterInDestinationExtraSuggestions(
  suggestion: SearchSuggestion,
  language: Language,
  destinationTag: string,
): boolean {
  // Worldwide POI
  if (
    suggestion.tag.startsWith('p') &&
    suggestion.tags &&
    !suggestion.tags.includes(destinationTag)
  ) {
    return true;
  }

  // Destination
  if (suggestion.tag.startsWith('d') && suggestion.tag !== destinationTag) {
    const { highlightResult } = suggestion;
    const nameResult = highlightResult[`name_${language.toUpperCase()}`];
    const { matchLevel, matchedWords } = nameResult;
    const matchedString = matchedWords.join(' ');

    return (
      (matchLevel === 'full' || matchLevel === 'partial') &&
      matchedString.length >= Math.floor(suggestion.name.length * 0.6)
    );
  }

  return false;
}
