import 'core-js/es';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';
import React from 'react';
import ReactDOM from 'react-dom/client';
import smoothscroll from 'smoothscroll-polyfill';
import { BrowserRouter } from 'react-router-dom';
import * as R from 'ramda/src/index.js';
import queryString from 'query-string';
import { ThemeProvider } from 'styled-components';
import Cookies from 'js-cookie';
import { ThemeDefault } from './records/Theme.js';
import getInitialCurrency from './services/currency/getInitialCurrency';
import IntlContext from './services/intl/context.js';
import { CurrencyContextManager } from './services/currency/context.js';
import type { Currency } from './services/currency/context.js';
import DestinationContext from './services/destination/context.js';
import PoiContext from './services/poi/context.js';
import { WindowContextManager } from './services/window/context.js';
import ContentContext from './services/content/context.js';
import buildUrl from './services/url/buildUrl';
import translate from './services/intl/translate.js';
import Routes from './Routes.jsx';
import { COOKIES } from './records/Cookies.js';
import PoiToursSectionContext from './services/poi/sections_context.js';

smoothscroll.polyfill();

const container = document.getElementById('root');
const translateFn = R.partial(translate, [window.__INTL__.translations]);

const queryParams = queryString.parse(window.location.search);
const lang = window.__INTL__.language;

const countryCode = Cookies.get(COOKIES.VIEWER_COUNTRY);
const { currencies } = window.__CONTENT__;
const currency: Currency =
  queryParams.cur && Object.keys(currencies).includes(queryParams.cur)
    ? queryParams.cur
    : getInitialCurrency(lang, currencies, countryCode);

if (container) {
  const root = ReactDOM.createRoot(container);

  root.render(
    <ThemeProvider theme={ThemeDefault}>
      <IntlContext.Provider
        value={{
          ...window.__INTL__,
          translate: translateFn,
        }}
      >
        <DestinationContext.Provider value={window.__DESTINATION__}>
          <PoiContext.Provider value={window.__POI__}>
            <ContentContext.Provider value={window.__CONTENT__}>
              <PoiToursSectionContext.Provider value={window.__POI_TOURS_SECTION__}>
                <CurrencyContextManager initialCurrency={currency} currencies={currencies}>
                  <WindowContextManager>
                      <BrowserRouter>
                        <Routes
                          buildUrl={R.partial(buildUrl, [
                            window.__DESTINATION__.destinations[lang],
                            window.__DESTINATION__.configs[lang].Tags.concat(
                              window.__DESTINATION__.configs[lang].Categories,
                            ),
                          ])}
                          destinations={window.__DESTINATION__.destinations[lang]}
                          isErrorPage={window.__ERROR_PAGE__}
                          language={lang}
                        />
                      </BrowserRouter>
                  </WindowContextManager>
                </CurrencyContextManager>
              </PoiToursSectionContext.Provider>
            </ContentContext.Provider>
          </PoiContext.Provider>
        </DestinationContext.Provider>
      </IntlContext.Provider>
    </ThemeProvider>
  );
}