// @flow strict
import * as React from 'react';

type Props = {
  onPaginatedSearch: () => void, // side-effects
  shouldPerformSearch: () => boolean,
  // $FlowFixMe need better way to type dynamic props
} & any;

const withInfiniteScroll = (conditionFn: (props: Props) => boolean) => (
  Component: React.ComponentType<Props>,
): React.ComponentType<Props> =>
  class WithInfiniteScroll extends React.Component<Props> {
    componentDidMount() {
      const { onPaginatedSearch } = this.props;
      onPaginatedSearch(); // get first page
      window.addEventListener('scroll', this.onScroll, false);
    }

    componentWillUnmount() {
      window.removeEventListener('scroll', this.onScroll, false);
    }

    onScroll = () => {
      const { onPaginatedSearch } = this.props;

      if (conditionFn(this.props)) {
        onPaginatedSearch();
      }
    };

    render() {
      return <Component {...this.props} />;
    }
  };

export default withInfiniteScroll;
