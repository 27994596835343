// @flow strict
import * as R from 'ramda/src/index.js';
import type { Language } from '../../intl/context.js';
import type { PoiInput, Poi } from '../../../records/Poi.js';

export default function mapperPois(pois: PoiInput[], locale: Language): Poi[] {
  return pois.map(poi => {
    const name = poi[`name_${locale}`];
    const urlName = poi[`urlName_${locale}`];

    // More robust by having a default value (empty array) for poi._tags if it's undefined
    const tags = poi._tags || [];

    // eslint-disable-next-line no-underscore-dangle
    const breadcrumbDestinationId = tags
      .filter(t => t.startsWith('d:'))
      .map(t => Number(t.slice(2, t.length)))[0];
    // .filter(id => DESTINATIONS.includes(id))

    const searchRankingScore = poi.search_ranking_score;
    const index = [];
    if (poi.allowSearchIndexing_en) {
      index.push('en');
    }
    if (poi.allowSearchIndexing_de) {
      index.push('de');
    }
    if (poi.allowSearchIndexing_it) {
      index.push('it');
    }

    return {
      ...R.omit(
        [
          'name_en',
          'name_de',
          'name_it',
          'urlName_en',
          'urlName_de',
          'urlName_it',
          '_tags',
          'search_ranking_score',
          'allowSearchIndexing_en',
          'allowSearchIndexing_de',
          'allowSearchIndexing_it',
          'poiPageRatings',
        ],
        poi,
      ),
      name,
      urlName,
      breadcrumbDestination: breadcrumbDestinationId,
      searchRankingScore,
      index,
      ratings: poi.poiPageRatings,
    };
  });
}
