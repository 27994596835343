// @flow strict
import * as React from 'react';
import type { GroupToursBySection } from '../../records/Tour.js';
import type { Poi } from '../../records/Poi.js';
import type { PoiContent } from '../../records/PoiContent.js';
import type { ObservationDeck } from '../../records/ObservationDeck.js';
import type { Language } from '../intl/context.js';
import type { DynamicPoiContent } from '../../records/DynamicPoiContent.js';

// search for "TODO add dynamic content" to find the relevant code for adding further dynamic content
export type PoiContextType = {
  poi: { [Language]: Poi },
  tours: GroupToursBySection,
  poiContent: ?PoiContent,
  observationDecks: ObservationDeck[],
  popularAttractions: Poi[],
  dynamicContent: DynamicPoiContent[],
};

const PoiContext = React.createContext<PoiContextType>({
  poi: {
    en: {
      name: '',
      urlName: '',
      objectID: '0',
      breadcrumbDestination: 0,
      toursCount: 0,
      searchRankingScore: 0,
      displayPoiGroupId: 0,
      index: [],
      ratings: [],
    },
    de: {
      name: '',
      urlName: '',
      objectID: '0',
      breadcrumbDestination: 0,
      toursCount: 0,
      searchRankingScore: 0,
      displayPoiGroupId: 0,
      index: [],
      ratings: [],
    },
    it: {
      name: '',
      urlName: '',
      objectID: '0',
      breadcrumbDestination: 0,
      toursCount: 0,
      searchRankingScore: 0,
      displayPoiGroupId: 0,
      index: [],
      ratings: [],
    },
  },
  tours: {},
  poiContent: null,
  observationDecks: [],
  popularAttractions: [],
  // search for "TODO add dynamic content" to find the relevant code for adding further dynamic content
  dynamicContent: [],
});

export default PoiContext;
