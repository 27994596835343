// @flow strict
import React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Box } from '../../../../../../../../../../primitives/Essentials/index.js';
import Line from '../../../../../../../../../../primitives/Line/index.js';
import mq from '../../../../../../../../../../services/mediaQuery/index.js';

const Discount = styled(Box)`
  position: absolute;
  right: 0;
  min-width: 47px;
  margin-top: -9px;
  padding: 9.5px 6.5px;
  text-align: center;
  background-color: ${({ theme }) => theme.secondary};
  &::after {
    position: absolute;
    bottom: -11px;
    left: 0;
    width: 0;
    height: 0;
    border-color: ${({ theme }) => theme.secondary} transparent transparent transparent;
    border-style: solid;
    border-width: 12px 25px 0 0;
    border-radius: 0 0 0 5px;
    content: '';
  }
  &::before {
    position: absolute;
    right: 0;
    bottom: -11px;
    width: 0;
    height: 0;
    border-color: transparent ${({ theme }) => theme.secondary} transparent transparent;
    border-style: solid;
    border-width: 0 25px 12px 0;
    border-radius: 0 0 5px 0;
    content: '';
  }
  ${({ $secondary }) =>
    !$secondary &&
    mq.TABLET`
    display: none;
  `}
`;

const DiscountLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  color: ${({ theme }) => theme.tertiary};
`;

type Props = {
  discount: number,
  secondary: boolean,
};

const DiscountBadgeMobile = ({ discount, secondary }: Props) => (
  <Discount $secondary={secondary}>
    <DiscountLine fontSize={14} fontWeight={900} letterSpacing={0.32}>
      -{discount.toFixed(0)}%
    </DiscountLine>
  </Discount>
);

export default DiscountBadgeMobile;
