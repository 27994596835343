// primitives/Line/index.js
// @flow strict
import * as React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;

type Props = {
  fontSize?: ?number,
  fontWeight?: ?number,
  letterSpacing?: ?number,
  lineHeight?: ?number, // Changed from $lineHeight to lineHeight
  $lineThrough?: ?boolean,
  color?: ?string,
  children: React.Node,
  className?: ?string,
};

const StyledLine = styled('span').withConfig({
  shouldForwardProp: (prop) => !['lineHeight', '$lineThrough'].includes(prop),
})`
  display: inline-block;
  color: ${({ color, theme }) => color || theme.primary};
  font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
  font-size: ${({ fontSize }) => fontSize || 14}px;
  line-height: ${({ lineHeight }) => lineHeight || 'normal'}; // Use lineHeight
  letter-spacing: ${({ letterSpacing }) =>
    letterSpacing ? `${letterSpacing}px` : 'normal'};
  text-decoration: ${({ $lineThrough }) =>
    $lineThrough ? 'line-through' : 'none'};
`;

const Line1 = (props: Props) => <StyledLine { ...props } />;

Line1.defaultProps = {
  fontSize: null,
  fontWeight: null,
  letterSpacing: null,
  lineHeight: null, // Changed from $lineHeight to lineHeight
  $lineThrough: null,
  color: null,
};

export default Line1;