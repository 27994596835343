// @flow strict
/* eslint-disable react/no-danger */
import * as React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Box, Flex } from '../../primitives/Essentials/index.js';
import { SECTION_LAYOUT } from '../../consts/layout.js';
import type { SubList as SubListType } from '../../records/DestinationContent.js';
import Line from '../../primitives/Line/index.js';
import type { Poi } from '../../records/Poi.js';
import Text from '../Text/index.js';
import Image from '../Image/index.js';
import mq from '../../services/mediaQuery/index.js';
import WindowContext from '../../services/window/context.js';
import LinkTo from '../LinkTo/index.js';
import ListElements from '../ListElements/index.js';
import Attraction from '../../scenes/PoiPage/components/PoiContent/components/Attractions/components/Attraction/index.js';
import type { Language } from '../../services/intl/context.js';

type Props = {
  subLists: SubListType[],
  destinationName: string,
  language: Language,
  attractions: Poi[],
  simpleLinkStyle: boolean,
};

const MorePois = styled(Flex)`
  text-align: left;
`;

const Title = styled.h2`
  margin-block-start: 0;
  margin-block-end: 0;
  font-weight: 900;
  font-size: 24px;
  line-height: 1.35;
`;

const List = styled(Flex)`
  text-align: center;
`;

const TabletOnlyLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  display: none;
  ${mq.TABLET`
    display: initial;
  `};
`;

const AbstractLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  max-width: 700px;
  margin: 12px 0;
  color: ${({ theme }) => theme.trip.abstract};
  a {
    color: ${({ theme }) => theme.secondary};
    font-weight: 900;
    text-decoration: none;
  }
`;

const TextLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  max-width: 700px;
  color: ${({ theme }) => theme.trip.abstract};
  text-align: left;
  a {
    color: ${({ theme }) => theme.secondary};
    font-weight: 900;
    text-decoration: none;
  }
`;

const StyledImageLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  color: ${({ theme }) => theme.trip.divider};
  text-align: center;
`;

const StyledImg = styled(Image)`
  display: block;
  width: 100%;
  height: auto;
  min-height: 194px;
  margin-bottom: 6px;
  border-radius: 5px;
  aspect-ratio: 3/2;
  ${mq.BIG_MOBILE`
    min-height: 372px;
  `};
  ${mq.TABLET`
    min-height: 227px;
  `};
  ${mq.DESKTOP`
    min-height: 384px;
  `};
`;

const SubLists = ({ language, subLists, destinationName, attractions, simpleLinkStyle }: Props) => {
  const { isMobile } = React.useContext(WindowContext);
  const subListsWithoutListElements = subLists.filter(x => !x.listElements);

  return (
    <Flex
      $px={SECTION_LAYOUT.$px}
      $mt={[105, null, null, null, 115]}
      $alignItems="center"
      $flexDirection="column"
    >
      {subLists.map((list, i) => {
        const order = subListsWithoutListElements.indexOf(list);
        const photoNodeFirst =
          isMobile ||
          Boolean(
            list.photo &&
              ((list.linkTo && list.linkTo.length === 1) || (!order || (order && order % 2 === 1))),
          );
        const photoNodeSecond =
          !isMobile &&
          !photoNodeFirst &&
          Boolean(list.photo && (!order || (order && order % 2 === 0)));
        const headerNodeSecond = Boolean(photoNodeFirst);
        const PhotoNode = list.photo && (
          <Flex
            $flexDirection="column"
            $mb={20}
            width={[
              1,
              null,
              null,
              null,
              photoNodeSecond || (list.linkTo && list.linkTo.length === 1) ? 1 : 1 / 2,
            ]}
            $pl={
              photoNodeSecond && ((list.linkTo && list.linkTo.length > 1) || !list.linkTo)
                ? [null, null, null, null, 15]
                : null
            }
            $pr={
              photoNodeFirst && ((list.linkTo && list.linkTo.length > 1) || !list.linkTo)
                ? [null, null, null, null, 15]
                : null
            }
          >
            <StyledImg
              src={list.photo.photoUrls[0]}
              srcSet={list.photo.photoUrls[1] ? `${list.photo.photoUrls[1]} 2x` : undefined}
              alt={list.photo.photoTitle}
              lazy
            />
            <StyledImageLine fontSize={14}>{list.photo.photoTitle}</StyledImageLine>
          </Flex>
        );
        const HeaderNode = (
          <Flex
            width={[
              1,
              null,
              null,
              null,
              photoNodeSecond || (list.linkTo && list.linkTo.length) === 1 ? 1 / 2 : 1,
            ]}
            $pl={
              ((list.linkTo && list.linkTo.length !== 1) || !list.linkTo) && photoNodeFirst
                ? [null, null, null, null, 15]
                : 0
            }
            $pr={
              // eslint-disable-next-line no-nested-ternary
              ((list.linkTo && list.linkTo.length !== 1) || !list.linkTo) && photoNodeSecond
                ? [null, null, null, null, 15]
                : list.linkTo && list.linkTo.length === 1
                ? [null, null, null, null, 7.5]
                : 0
            }
            $flexDirection="column"
            $alignItems="center"
          >
            {list.title && <Title>{list.title}</Title>}
            {list.abstract && (
              <AbstractLine fontSize={20} lineHeight={1.35}>
                <span dangerouslySetInnerHTML={{ __html: list.abstract }} />
              </AbstractLine>
            )}
            {list.text && (
              <TextLine fontSize={16} lineHeight={1.35}>
                <span dangerouslySetInnerHTML={{ __html: list.text }} />
              </TextLine>
            )}
          </Flex>
        );

        return (
          <List
            key={list.title || i}
            id={list.title ? list.title.replace(/\s+/g, '-').toLowerCase() : i}
            $flexDirection="column"
            $alignItems="center"
            $mb={[85, null, null, null, 96]}
            width={1}
          >
            <Flex
              $flexDirection={[
                'column',
                null,
                null,
                null,
                list.linkTo && list.linkTo.length === 1 ? 'column' : 'row',
              ]}
              $justifyContent="center"
              width={1}
            >
              {photoNodeFirst ? PhotoNode : HeaderNode}
              {(headerNodeSecond ||
                photoNodeSecond ||
                (list.linkTo && list.linkTo.length === 1)) && (
                <Flex
                  width={[1, null, null, null, list.linkTo && list.linkTo.length === 1 ? 1 : 1 / 2]}
                  $justifyContent={
                    list.linkTo && list.linkTo.length === 1 ? 'space-between' : 'center'
                  }
                  $flexDirection={['column', null, null, null, 'row']}
                >
                  {headerNodeSecond && HeaderNode}
                  {photoNodeSecond && PhotoNode}
                  {list.linkTo && list.linkTo.length === 1 && (
                    <Flex
                      $flexDirection="column"
                      $alignItems="flex-start"
                      width={[1, null, null, null, 1 / 2]}
                      $pl={[null, null, null, null, 7.5]}
                    >
                      <TabletOnlyLine lineHeight={1.35} fontSize={24} fontWeight={900}>
                        <Text t="find_offers_for" />
                      </TabletOnlyLine>
                      <Flex $mt={[25, null, null, null, 0]} $flexWrap="wrap" width={1}>
                        {list.linkTo.map((link, j) => (
                          <Box
                            key={link.id}
                            $mt={8}
                            width={1}
                            $pr={[null, null, null, null, j % 2 === 0 ? 8 : 0]}
                            $pl={[null, null, null, null, j % 2 === 1 ? 8 : 0]}
                          >
                            <LinkTo link={link} singleLink={false} />
                          </Box>
                        ))}
                      </Flex>
                    </Flex>
                  )}
                </Flex>
              )}
            </Flex>
            {list.listElements && (
              <ListElements listElements={list.listElements} simpleLinkStyle={simpleLinkStyle} />
            )}
            {list.morePOIs && list.morePOIs.ids && (
              <MorePois $flexDirection="column" width={1}>
                <Line fontSize={22} fontWeight={900}>
                  {list.morePOIs.title ? (
                    <Text
                      t="more_attractions_in_destination"
                      values={{
                        title: list.morePOIs.title,
                        destination: destinationName,
                      }}
                    />
                  ) : (
                    <Text
                      t="other_top_attractions"
                      values={{
                        destination: destinationName,
                      }}
                    />
                  )}
                </Line>
                <Flex $mt={5} $flexWrap="wrap" width={1}>
                  {list.morePOIs.ids
                    .filter(poi => attractions.find(x => x.objectID === String(poi)))
                    .map((poi, j) => (
                      <Attraction
                        key={poi}
                        // $FlowFixMe
                        attraction={attractions.find(x => x.objectID === String(poi))}
                        order={j}
                        language={language}
                      />
                    ))}
                </Flex>
              </MorePois>
            )}
            {list.linkTo && list.linkTo.length > 1 && (
              <Flex
                $flexDirection="column"
                $alignItems="flex-start"
                width={1}
                $pt={[null, null, null, null, 12]}
              >
                <TabletOnlyLine lineHeight={1.35} fontSize={24} fontWeight={900}>
                  <Text t="find_offers_for" />
                </TabletOnlyLine>
                <Flex $mt={[20, null, null, null, 0]} $flexWrap="wrap" width={1}>
                  {list.linkTo.map((link, j) => (
                    <Box
                      key={link.id}
                      $mt={8}
                      width={[1, null, null, null, 1 / 2]}
                      $pr={[null, null, null, null, j % 2 === 0 ? 8 : 0]}
                      $pl={[null, null, null, null, j % 2 === 1 ? 8 : 0]}
                    >
                      <LinkTo
                        link={link}
                        singleLink={Boolean(list.linkTo && list.linkTo.length === 1)}
                      />
                    </Box>
                  ))}
                </Flex>
              </Flex>
            )}
          </List>
        );
      })}
    </Flex>
  );
};

SubLists.defaultProps = {
  simpleLinkStyle: false,
};

export default SubLists;
