// @flow strict
/* eslint-disable react/no-danger */
import React from 'react';
import { Flex, Box } from '../../../../../../primitives/Essentials/index.js';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import Breadcrumbs from '../../../../../../components/Breadcrumbs/index.js';
import type { Destination } from '../../../../../../records/Destination.js';
import { BREADCRUMBS_LAYOUT } from '../../../../../../consts/layout.js';
import UrlContext from '../../../../../../services/url/context.js';
import type { Currency } from '../../../../../../services/currency/context.js';
import type { Language } from '../../../../../../services/intl/context.js';
import type {
  DestinationCategoryContent,
  DestinationCategoryContentInput,
} from '../../../../../../records/DestinationCategoryContent.js';
// TODO: put to shared components folder
import Abstract from '../../../../../../components/Abstract/index.js';
import CategorySections from './components/CategorySections/index.js';
import type { GroupToursBySection } from '../../../../../../records/Tour.js';
import EditorialContent from '../../../../../../components/EditorialContent/index.js';
import MoreLinks from '../../../../../../components/MoreLinks/index.js';
import PoiAvailability from '../../../../../../components/PoiAvailability/index.js';
import { getPoiCategoryTours } from '../../../../../../services/api/tours/index.js';
import type { Promocodes } from '../../../../../../records/Promocodes.js';
import type { Providers } from '../../../../../../records/Providers.js';
import type { Currencies } from '../../../../../../records/Currencies.js';
import type { ImageAttributions } from '../../../../../../records/Photo.js';
import { formatDateYMD } from '../../../../../../services/dateTimeUtils/index.js';
import SvgSpinner from '../../../../../../components/SvgSpinner/index.js';
import AuthorBio from '../../../../../../components/AuthorBio/index.js';
import {
  container,
  HEADER,
  FOOTER,
  FOOTER_MOBILE,
  FOOTER_BUTTON,
} from '../../../../../../services/styleUtils/index.js';
import mq from '../../../../../../services/mediaQuery/index.js';
import mapperCategoryContent from '../../../../../../services/mapper/categoryContent/index.js';

type Props = {
  categoryContent: DestinationCategoryContent,
  shownTours: GroupToursBySection,
  selectedDestination: Destination,
  currency: Currency,
  promocodes: Promocodes,
  providers: Providers,
  currencies: Currencies,
  resultsForString: string,
  date: ?Date,
  onChangeDate: (date: Date) => void,
  destinationId: number,
  language: Language,
  imageAttributions: ImageAttributions,
};

type State = {
  dateTours: ?GroupToursBySection,
  isLoading: boolean,
  updatedCategoryContent: ?DestinationCategoryContent,
};

const StyledContainerBox = styled(Box)`
  ${container()};
  min-height: calc(100vh - ${HEADER} - ${FOOTER_MOBILE} - ${FOOTER_BUTTON} - 2px);
  ${mq.TABLET`
    min-height: calc(100vh - ${HEADER} - ${FOOTER} - ${FOOTER_BUTTON} - 2px);
  `} ${mq.DESKTOP`
    min-height: calc(100vh - ${HEADER} - ${FOOTER} - ${FOOTER_BUTTON} - 2px);
  `};
`;

const MaxWidthContainer = styled(Box)`
  ${container()};
`;

class PreRenderedContent extends React.Component<Props, State> {
  constructor() {
    super();
    this.state = {
      dateTours: null,
      isLoading: false,
      updatedCategoryContent: null,
    };
  }

  componentDidMount = () => {
    this.updateTours();
    window.addEventListener('message', this.handleMessage);
  };

  componentDidUpdate(prevProps: Props) {
    const { date } = this.props;

    if (date !== prevProps.date) {
      this.updateTours();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleMessage);
  }

  updateTours = () => {
    const {
      categoryContent,
      language,
      promocodes,
      currencies,
      providers,
      destinationId,
      date,
    } = this.props;
    const { updatedCategoryContent } = this.state;

    // eslint-disable-next-line no-restricted-globals
    if (date && !isNaN(date.getTime())) {
      this.setState(
        {
          isLoading: true,
        },
        () => {
          getPoiCategoryTours(
            language,
            destinationId,
            categoryContent.sections,
            promocodes,
            currencies,
            providers,
            formatDateYMD(date),
          ).then(tours => {
            this.setState({
              dateTours: tours,
              isLoading: false,
            });
          });
        },
      );
    } else if (updatedCategoryContent && updatedCategoryContent.sections) {
      this.setState(
        {
          isLoading: true,
        },
        () => {
          getPoiCategoryTours(
            language,
            destinationId,
            updatedCategoryContent.sections,
            promocodes,
            currencies,
            providers,
          ).then(tours => {
            this.setState({
              dateTours: tours,
              isLoading: false,
            });
          });
        },
      );
    } else {
      this.setState({
        dateTours: null,
        isLoading: false,
      });
    }
  };

  // for tweb admin live preview
  handleMessage = (event: { origin: string, data: ?DestinationCategoryContentInput }) => {
    const { language, imageAttributions } = this.props;
    // event.origin will most likely be https://admin.ulmon.com
    // window.location.origin will most likely be https://www.ticketlens.com
    if (
      event.data &&
      event.data.destinationId &&
      event.data.categoryId &&
      ((event.origin.includes('localhost') && window.location.origin.includes('localhost')) ||
        (event.origin.includes('admin') && window.location.origin.includes('ticketlens')))
    ) {
      this.setState(
        {
          updatedCategoryContent: mapperCategoryContent(event.data, language, imageAttributions),
        },
        () => {
          this.updateTours();
        },
      );
    }
  };

  render() {
    const {
      categoryContent,
      shownTours,
      selectedDestination,
      resultsForString,
      date,
      onChangeDate,
      currency,
    } = this.props;

    const { dateTours, isLoading, updatedCategoryContent } = this.state;

    const tours = dateTours || shownTours;
    // eslint-disable-next-line no-underscore-dangle
    const _categoryContent = updatedCategoryContent || categoryContent;

    return (
      <UrlContext.Consumer>
        {({ buildDestinationPageUrl }) => {
          const breadcrumbs = [
            {
              name: selectedDestination.name,
              url: buildDestinationPageUrl(selectedDestination.destination_id),
            },
            {
              name:
                _categoryContent && _categoryContent.title
                  ? _categoryContent.title
                  : resultsForString,
              html: !_categoryContent.title,
              url: _categoryContent.urlName,
            },
          ];
          return (
            <>
              <StyledContainerBox>
                <Box {...BREADCRUMBS_LAYOUT}>
                  <Breadcrumbs activeIndex={1} items={breadcrumbs} />
                </Box>
                <Flex $flexDirection={['column', null, null, null, null, 'row']}>
                  {_categoryContent.abstract && (
                    <Box width={[1, null, null, null, null, 1 / 2]}>
                      <Abstract author={_categoryContent.author}>
                        <span dangerouslySetInnerHTML={{ __html: _categoryContent.abstract }} />
                      </Abstract>
                    </Box>
                  )}
                  <Box
                    width={[1, null, null, null, null, 1 / 2]}
                    $pl={[0, null, null, null, 30, 15]}
                    $pr={[0, null, null, null, 30]}
                  >
                    <PoiAvailability date={date} onChangeDate={onChangeDate} />
                  </Box>
                </Flex>
                {isLoading && (
                  <Box $mt={40}>
                    <Flex $justifyContent="center" $alignItems="center">
                      <SvgSpinner />
                    </Flex>
                  </Box>
                )}
                {!isLoading && tours && (
                  <CategorySections
                    sections={_categoryContent.sections}
                    shownTours={tours}
                    currency={currency}
                    date={date}
                  />
                )}
                {updatedCategoryContent ? (
                  <EditorialContent
                    editorialContent={updatedCategoryContent.editorialContent || []}
                  />
                ) : (
                  <EditorialContent editorialContent={categoryContent.editorialContent} />
                )}
              </StyledContainerBox>
              {categoryContent.author && (
                <AuthorBio
                  author={categoryContent.author}
                  translator={categoryContent.translator}
                />
              )}
              <MaxWidthContainer>
                <MoreLinks hideTopics />
              </MaxWidthContainer>
            </>
          );
        }}
      </UrlContext.Consumer>
    );
  }
}

export default PreRenderedContent;
