// @flow strict
import React from 'react';
import { withTheme } from 'styled-components';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Flex, Box } from '../../primitives/Essentials/index.js';
import type { Theme } from '../../records/Theme.js';
import UrlContext from '../../services/url/context.js';
import Navbar from '../../components/Navbar/index.js';
import Footer from '../../components/Footer/index.js';
import Text from '../../components/Text/index.js';
import { container, HEADER, FOOTER, FOOTER_MOBILE } from '../../services/styleUtils/index.js';
import mq from '../../services/mediaQuery/index.js';
import Link from '../../components/Link/index.js';
import { SECTION_LAYOUT } from '../../consts/layout.js';
import Line from '../../primitives/Line/index.js';
import type { Language } from '../../services/intl/context.js';

type Props = {
  theme: Theme,
  language: Language, 
};

const StyledContainerBox = styled(Box)`
  ${container()};
  min-height: calc(100vh - ${HEADER} - ${FOOTER_MOBILE} - 20px);
  ${mq.TABLET`
    min-height: calc(100vh - ${HEADER} - ${FOOTER} - 30px);
  `};
`;

const StyledImg = styled.img`
  width: 80%;
  max-width: 647px;
`;

const StyledBox = styled(Box)`
  width: 80%;
  max-width: 647px;
  text-align: center;
  ${mq.DESKTOP`
    text-align: inherit;
  `};
`;

const StyledLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  margin-bottom: 25px;
  font-size: 24px;
  ${mq.TABLET`
    font-size: 40px;
  `};
`;

const StyledLinkLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  display: inline;
  text-decoration: underline;
`;

const NotFoundPage = ({ theme, language }: Props) => (
  <UrlContext.Consumer>
  {({ buildDestinationPageUrl }) => {
      // Determine the URL based on whether language is defined
      const url = language ? `/${language}` : buildDestinationPageUrl();
      return (
        <>
          <Navbar search={false} />
          <StyledContainerBox {...SECTION_LAYOUT}>
            <Flex $alignItems="center" $flexDirection="column">
              <StyledImg src="/images/404-image.png" alt="page not found" />
              <StyledBox $mt={40}>
                <StyledLine color={theme.section.title} letterSpacing={0.3}>
                  <Text t="page_not_found" html />
                </StyledLine>
                <Line color={theme.section.title} fontSize={24} letterSpacing={0.2}>
                  <Text t="stay_safe" />
                  &nbsp;
                </Line>
                <StyledLinkLine
                  color={theme.section.title}
                  fontSize={24}
                  letterSpacing={0.2}
                  fontWeight={900}
                >
                  <Link href={url}>
                    <Text t="visit_home_page" />
                  </Link>
                </StyledLinkLine>
              </StyledBox>
            </Flex>
          </StyledContainerBox>
          <Footer />
        </>
      );
    }}
  </UrlContext.Consumer>
);

export default withTheme(NotFoundPage);
