// @flow strict
import React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Flex, Box } from '../../../../../../primitives/Essentials/index.js';
import type { About as AboutType } from '../../../../../../records/PoiContent.js';
import { SECTION_LAYOUT_NO_PADDING_MOBILE } from '../../../../../../consts/layout.js';
import { AboutSection, AboutSectionOfficialUrl} from './AboutSection.js';
import Text from '../../../../../../components/Text/index.js';
import mq from '../../../../../../services/mediaQuery/index.js';
import getColumnContent from './services/columns.js';
import type { Poi } from '../../../../../../records/Poi.js';

// TODO: move margin and padding to px and mx when there is time
const Container = styled(Box)`
  background-color: ${({ theme }) => theme.trip.background};

  ${mq.DESKTOP`
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 20px;
    padding-right: 20px;
 `}
`;

const TitleBox = styled(Box)`
  text-align: center;
  background-color: ${({ theme }) => theme.tertiary};
  border-radius: 5px;
`;

const TitleLine = styled.h2`
  margin: 0;
  color: ${({ theme }) => theme.primary};
  font-weight: 900;
  font-size: 24px;
`;

const Column = styled(Box)`
  width: 100%;
  ${mq.TABLET`
    width: 50%;
  `}
`;

type Props = {
  content: AboutType[],
  poi: Poi
};

const OFFICIAL_WEBSITE_ID = 'officialwebsiteid'

const About = ({ content, poi }: Props) => {
  if (poi.official_url && !content.some(item => item.title === OFFICIAL_WEBSITE_ID)) {
    // add a property to the about object
    content.push({
      title: OFFICIAL_WEBSITE_ID,
      abstract: OFFICIAL_WEBSITE_ID
    });
  }

  const leftAndRight = getColumnContent(content);

  return (
    <Container {...SECTION_LAYOUT_NO_PADDING_MOBILE} id="about">
      <TitleBox $mx={[15, null, null, null, 0]} $pt={37} $pb={34}>
        <TitleLine>
          <Text t="general_info" />
        </TitleLine>
      </TitleBox>
      <Flex
        $flexDirection={['column', null, null, null, 'row']}
        $mt={40}
        $px={[15, null, null, null, 0]}
      >
        <Column $pr={[0, null, null, null, 7.5]}>
          {leftAndRight.left.map(text => (
            text.title === OFFICIAL_WEBSITE_ID ? (
              <AboutSectionOfficialUrl key={text.title} content={poi.official_url} />
            ) : (
              <AboutSection key={text.title} title={text.title} content={text.abstract} />
            )
          ))}
        </Column>
        <Column $pl={[0, null, null, null, 7.5]}>
          {leftAndRight.right.map(text => (
            text.title === OFFICIAL_WEBSITE_ID ? (
              <AboutSectionOfficialUrl key={text.title} content={poi.official_url} />
            ) : (
              <AboutSection key={text.title} title={text.title} content={text.abstract} />
            )
          ))}
        </Column>
      </Flex>
    </Container>
  );
};

export default About;
