// @flow strict
import React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Box, Flex } from '../../primitives/Essentials/index.js';
import IntlContext from '../../services/intl/context.js';
import ContentContext from '../../services/content/context.js';
import { container } from '../../services/styleUtils/index.js';
import Footer from '../../components/Footer/index.js';
import { SECTION_LAYOUT } from '../../consts/layout.js';
import Navbar from '../../components/Navbar/index.js';
import Subheader from '../../components/Subheader/index.js';
import Text from '../../components/Text/index.js';
import Breadcrumbs from '../../components/Breadcrumbs/index.js';
import Conditions from '../../components/Conditions/index.js';

const StyledContainerBox = styled(Box)`
  ${container()};
`;

const StyledContainer = styled.div`
  position: relative;
`;

const TermsAndConditionsPage = () => {
  const { language, translate } = React.useContext(IntlContext);
  const { terms } = React.useContext(ContentContext);

  return (
    <>
      <StyledContainer>
        <Navbar>
          <Subheader>
            <Text t="title_termsandconditions" />
          </Subheader>
        </Navbar>
      </StyledContainer>
      <StyledContainerBox {...SECTION_LAYOUT} $mt={0}>
        <Flex $flexDirection="column">
          <Breadcrumbs
            items={[
              {
                name: translate('title_termsandconditions'),
                url: `/${language}/termsandconditions`,
              },
            ]}
            activeIndex={0}
          />
          <Conditions conditions={terms} />
        </Flex>
      </StyledContainerBox>
      <Footer />
    </>
  );
};

export default TermsAndConditionsPage;
