// @flow strict
import * as React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Box, Flex } from '../../../../../../../../primitives/Essentials/index.js';
import UrlContext from '../../../../../../../../services/url/context.js';
import type { Language } from '../../../../../../../../services/intl/context.js';
import type { Poi } from '../../../../../../../../records/Poi.js';
import Line from '../../../../../../../../primitives/Line/index.js';
import mq from '../../../../../../../../services/mediaQuery/index.js';
import Link from '../../../../../../../../components/Link/index.js';
import { BITMAP_URL } from '../../../../../../../../../../etc/appConfig.js';
import Text from '../../../../../../../../components/Text/index.js';
import Image from '../../../../../../../../components/Image/index.js';
import { hover } from '../../../../../../../../services/styleUtils/index.js';

const StyledBox = styled(Box)`
  padding-top: 10px;

  ${mq.TABLET`
    position: relative;
    height: 258px;
    padding-left: 10px;
    &:nth-child(3n + 1) {
      padding-left: 0px;
    }
  `};
  ${mq.DESKTOP`
    padding-left: 10px;
    ${hover()}
    &:nth-child(3n + 1) {
      padding-left: 10px;
    }
    &:nth-child(4n + 1) {
      padding-left: 0px;
    }
  `};
`;

const StyledImgWrapper = styled.div`
  width: 112.5px;
  min-width: 112.5px;
  height: ${({ height }) => height}px;
  background-color: rgb(245, 245, 245);
  border-radius: 5px 0 0 5px;
  ${mq.TABLET`
    height: ${({ height }) => 248 - height}px;
    min-width: 231px;
    width: 100%;
    border-radius: 5px 5px 0 0;
  `};
`;

const StyledImg = styled(Image)`
  width: 112.5px;
  min-width: 112.5px;
  height: ${({ height }) => height}px;
  object-fit: cover;
  border-radius: 5px 0 0 5px;
  ${mq.TABLET`
    height: ${({ height }) => 248 - height}px;
    min-width: 231px;
    width: 100%;
    border-radius: 5px 5px 0 0;
  `};
`;

const StyledTextFlex = styled(Flex)`
  width: 100%;
  min-height: 75px;
  background-color: ${({ theme }) => theme.trip.background};
  border-radius: 0 5px 5px 0;
  ${mq.TABLET`
    position: absolute;
    bottom: 0;
    border-radius: 0 0 5px 5px;
    width: ${({ order }) => (order % 3 === 0 ? '100%' : 'calc(100% - 10px)')};
    min-width: 231px;
  `};
  ${mq.DESKTOP`
    width: ${({ order }) => (order % 4 === 0 ? '100%' : 'calc(100% - 10px)')};
  `};
`;

const StyledTitleLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  color: ${({ theme }) => theme.section.title};
  font-size: 18px;
  ${mq.TABLET`
    font-size: 22px;
  `}
`;

const StyledLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  color: ${({ theme }) => theme.trip.abstract};
`;

type Props = {
  attraction: Poi,
  order: number,
  language: Language,
};

type State = {
  height: number,
};

class Attraction extends React.PureComponent<Props, State> {
  textDivRef: React.Ref<*>;

  constructor(props: Props) {
    super(props);
    this.textDivRef = React.createRef();
    this.state = {
      height: 75,
    };
  }

  componentDidMount() {
    const { height } = this.state;

    if (
      // $FlowFixMe
      this.textDivRef.current &&
      this.textDivRef.current.clientHeight &&
      this.textDivRef.current.clientHeight !== height
    ) {
      this.setState({
        // $FlowFixMe
        height: this.textDivRef.current.clientHeight,
      });
    }
  }

  render() {
    const { attraction, order, language } = this.props;
    const { height } = this.state;

    const crawlable = attraction.index.includes(language);

    return (
      <UrlContext.Consumer>
        {({ buildPoiPageUrl, openPoiPage }) => (
          <StyledBox width={[1, null, null, null, 1 / 3, 1 / 4]}>
            <Link
              useDiv={!crawlable}
              href={crawlable ? buildPoiPageUrl(attraction) : undefined}
              onClick={!crawlable ? () => openPoiPage(attraction) : undefined}
            >
              <Flex $flexDirection={['row', null, null, null, 'column']}>
                <StyledImgWrapper height={ height } >
                  <StyledImg
                    src={`${BITMAP_URL}/poi/${attraction.objectID}/380x173?returnCrop=yes`}
                    srcSet={`${BITMAP_URL}/poi/${attraction.objectID}/760x346?returnCrop=yes 2x`}
                    alt={attraction.name}
                    height={height}
                    lazy
                  />
                </StyledImgWrapper>
                <StyledTextFlex
                  ref={this.textDivRef}
                  $px={[8.5, null, null, null, 12]}
                  $py={10.5}
                  $justifyContent="center"
                  $flexDirection="column"
                  order={order}
                  className="darkenOnHover"
                >
                  <StyledTitleLine className="title" fontWeight={900}>
                    {attraction.name}
                  </StyledTitleLine>
                  <StyledLine fontSize={16}>
                    <Text
                      html
                      t="tickets_and_guidedtours"
                      values={{ count: attraction.toursCount }}
                    />
                  </StyledLine>
                </StyledTextFlex>
              </Flex>
            </Link>
          </StyledBox>
        )}
      </UrlContext.Consumer>
    );
  }
}

export default Attraction;
