// @flow strict
import * as React from 'react';
import type { Match, RouterHistory, Location } from 'react-router-dom/src/index.js';
import PoiContext from '../../services/poi/context.js';
import type { PoiContextType } from '../../services/poi/context.js';
import PoiToursSectionContext from '../../services/poi/sections_context.js';
import type { PoiToursSectionContextType } from '../../services/poi/sections_context.js';
import IntlContext from '../../services/intl/context.js';
import ContentContext from '../../services/content/context.js';
import WithAlgolia from '../withPoi/components/WithAlgolia.js';

type Props = { match: Match, location: Location, history: RouterHistory };
type EnhancedProps = Props & PoiContextType & PoiToursSectionContextType;

const withPoiSection = (
  Component: React.ComponentType<EnhancedProps>,
): React.ComponentType<EnhancedProps> => (props: Props) => {
  const { poiId } = props;

  return (
    <ContentContext.Consumer>
      {({ imageAttributions }) => (
        <IntlContext.Consumer>
          {({ language }) => (
            <PoiToursSectionContext.Consumer>
              {(poiToursSectionProps: PoiToursSectionContextType) => {
                // Logic to handle cases when POI data is not yet available 
                if (poiId && !poiToursSectionProps.poi[language].name) {
                  return (
                    <WithAlgolia language={language} id={poiId} imageAttributions={imageAttributions}>
                      <Component {...props} {...poiToursSectionProps} />
                    </WithAlgolia>
                    );
                  }
                  // When POI data is available, pass both POI context and POI tours section context to the Component
                  return <Component {...props} {...poiToursSectionProps} />;
                }}
            </PoiToursSectionContext.Consumer>
          )}
        </IntlContext.Consumer>
      )}
    </ContentContext.Consumer>
  );
}

export default withPoiSection;
