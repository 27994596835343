// @flow strict
import * as React from 'react';
import type { SectionConfig } from '../../../records/SectionConfig.js';
import type { Language } from '../../../services/intl/context.js';
import { getConfig } from '../../../services/api/config/index.js';

type Props = {
  id: string,
  children: React.Node,
};

type State = {
  configs: { [Language]: SectionConfig },
};

class WithLiveConfig extends React.PureComponent<Props, State> {
  constructor() {
    super();
    this.state = {
      configs: {
        en: {
          Attractions: [],
          Categories: [],
          Tags: [],
        },
        de: {
          Attractions: [],
          Categories: [],
          Tags: [],
        },
        it: {
          Attractions: [],
          Categories: [],
          Tags: [],
        },
      },
    };
  }

  componentDidMount = () => {
    const { id } = this.props;

    getConfig(id).then(configs => {
      this.setState({ configs });
    });
  };

  render() {
    const { children } = this.props;
    const { configs } = this.state;

    const childrenWithProps = React.Children.map(children, child =>
      React.cloneElement(child, { configs }),
    );

    return childrenWithProps[0];
  }
}

export default WithLiveConfig;
