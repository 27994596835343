// @flow strict
import React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import mq from '../../../../../../../../../../../../services/mediaQuery/index.js';
import type { Provider } from '../../../../../../../../../../../../records/Providers';

type Props = {
  provider: Provider,
};

const StyledImg = styled.img`
  width: 20px;
  height: 20px;
  ${mq.TABLET`width: 50px; height: 50px;`};
`;

const ProviderIcon = ({ provider }: Props) => {
  const providerName = provider.name.replace('.com', '');
  const imageUrl = `https://cdn.ticketlens.com/providers/${provider.imgId}`;
  return (
    <StyledImg
      src={`${imageUrl}/50x50`}
      srcSet={`${imageUrl}/100x100 2x, ${imageUrl}/150x150 3x`}
      alt={providerName}
    />
  );
};

export default ProviderIcon;
