// @flow strict
import * as React from 'react';
import { Flex } from '../../../../../../../../primitives/Essentials/index.js';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import Line from '../../../../../../../../primitives/Line/index.js';
import UrlContext from '../../../../../../../../services/url/context.js';
import Link from '../../../../../../../Link/index.js';
import type { Poi } from '../../../../../../../../records/Poi.js';

const StyledSuggestionFlex = styled(Flex)`
  height: auto;
  background-color: ${({ selected, theme }) => (selected ? theme.trip.backgroundLight : 'initial')};
`;

const StyledPoiNameSuggestionLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  color: ${({ theme }) => theme.section.title};
`;

const StyledPoiDestinationSuggestionSpan = styled.span`
  color: ${({ theme }) => theme.trip.divider};
`;

const Icon = styled.span`
  min-width: 16px;
  margin-right: 16px;
`;

const BottomSeparator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.trip.backgroundLight};
`;

type Props = {
  name: string,
  tag: string,
  urlName: string,
  destinationId?: number,
  destinationName: string,
  selected: boolean,
  onHover: () => void,
};

const PoiSuggestion = ({
  name,
  tag,
  urlName,
  destinationId,
  destinationName,
  selected,
  onHover,
}: Props) => {
  // not nice mapping, should unify Attraction from config and Poi form API better
  const poi: Poi = {
    objectID: tag.replace('p:', ''),
    name,
    urlName,
    // $FlowFixMe destinationId is missing when search suggestion on home page. Not having it works anyway as buildPoiPageUrl defaults to url w/o destinationId
    breadcrumbDestination: destinationId,
    toursCount: 0, // no needed
    searchRankingScore: 0, // no needed
    displayPoiGroupId: 0, // no needed
    index: [],
    ratings: [],
  };

  return (
    <UrlContext.Consumer>
      {({ buildPoiPageUrl }) => (
        <Link href={buildPoiPageUrl(poi)}>
          <StyledSuggestionFlex
            $px={15}
            $flexDirection="column"
            $alignItems="flex-start"
            selected={selected}
            onMouseEnter={onHover}
          >
            <Flex justifiContent="flex-start" $alignItems="center" $py={15}>
              <Icon>
                <img src="/icons/place.svg" alt="place" />
              </Icon>
              <StyledPoiNameSuggestionLine fontSize={18}>
                {poi.name}
                <StyledPoiDestinationSuggestionSpan>
                  {`, ${destinationName}`}
                </StyledPoiDestinationSuggestionSpan>
              </StyledPoiNameSuggestionLine>
            </Flex>
            <BottomSeparator />
          </StyledSuggestionFlex>
        </Link>
      )}
    </UrlContext.Consumer>
  );
};

export default PoiSuggestion;
