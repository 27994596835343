// @flow strict
import React from 'react';
import * as R from 'ramda/src/index.js';
import { Box, Flex } from '../../../../../../primitives/Essentials/index.js';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import Text from '../../../../../../components/Text/index.js';
import Line from '../../../../../../primitives/Line/index.js';
import type { Providers } from '../../../../../../records/Providers.js';
import type { Language } from '../../../../../../services/intl/context.js';
import type { PromocodeWithProvider } from '../../../../../../services/tours/getTourPromocode.js';
import { formatDateReview } from '../../../../../../services/dateTimeUtils/index.js';

const StyledLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  color: ${({ theme }) => theme.trip.abstract};
`;

const ActiveDiscountsLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  color: ${({ theme }) => theme.trip.abstract};
  strong {
    color: ${({ theme }) => theme.primary};
  }
`;

const StyledList = styled.ul`
  list-style: none;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
`;

type Props = {
  activePromocodes: PromocodeWithProvider[],
  providers: Providers,
  language: Language,
};

const PromoCodesInfo = ({ activePromocodes, providers, language }: Props) => (
  <Flex $flexDirection="column" $px={15} $pt={38}>
    <Line fontSize={20} fontWeight={900} lineHeight={1.5}>
      <Text t="how_do_promo_codes_work" />
    </Line>
    <StyledLine fontSize={15} lineHeight={1.4}>
      <Text t="promo_code_notice" html />
    </StyledLine>
    <Box $mt={20} $mb={8}>
      <Line fontSize={15}>
        <strong>
          <Text t="active_discounts" lineHeight={1.45} />
        </strong>
      </Line>
    </Box>
    <ActiveDiscountsLine fontSize={15} lineHeight={1.4}>
      <StyledList>
        {R.uniq(activePromocodes).map(promocode => (
          <li key={promocode.code}>
            <Text
              t="active_discount"
              html
              values={{
                discount: promocode.discountPercent,
                code: promocode.code,
                provider: providers[promocode.providerId].name,
                date: formatDateReview(new Date(promocode.endUTC), language),
              }}
            />
          </li>
        ))}
      </StyledList>
    </ActiveDiscountsLine>
  </Flex>
);

export default PromoCodesInfo;
