// @flow strict
import type { Destination } from '../../records/Destination';
import type { Tag } from '../../records/SectionConfig';

export function destinationSelector(destinations: Destination[], id: number): ?Destination {
  return destinations.find(dest => dest.destination_id === id) || null;
}

export function tagSelector(tags: Tag[], id: string): ?Tag {
  return tags.find(t => t.Tag === id) || null;
}
