// @flow strict
import React from 'react';
import { Flex, Box } from '../../../../../../primitives/Essentials/index.js';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import Button from '../../../../../../components/Button/index.js';
import Attraction from '../../../../../../components/Attraction/index.js';
import type { Poi } from '../../../../../../records/Poi.js';
import Text from '../../../../../../components/Text/index.js';
import UrlContext from '../../../../../../services/url/context.js';
import type { Language } from '../../../../../../services/intl/context.js';
import { SECTION_LAYOUT } from '../../../../../../consts/layout.js';

type Props = {
  attractions: Poi[],
  language: Language,
};

const StyledH2 = styled.h2`
  margin: 0;
  color: ${({ theme }) => theme.section.title};
  font-weight: 900;
  font-size: 24px;
`;

const Attractions = ({ attractions, language }: Props) => (
  <Box {...SECTION_LAYOUT}>
    <Flex $flexDirection="column">
      <StyledH2>
        <Text t="top_attractions" />
      </StyledH2>
      <Flex $flexWrap="wrap" $justifyContent="flex-start">
        {attractions.map(attraction => (
          <Attraction key={attraction.objectID} attraction={attraction} language={language} />
        ))}
      </Flex>
      {attractions.length >= 4 && (
        <Box $mt={[10, null, null, null, 18.5]}>
          <UrlContext.Consumer>
            {({ buildDestinationCategoryPageUrl }) => (
              <Button rounded href="#see_all_attractions">
                <Text t="see_more_attractions" />
              </Button>
            )}
          </UrlContext.Consumer>
        </Box>
      )}
    </Flex>
  </Box>
);

export default Attractions;
