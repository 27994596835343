// @flow strict
import type { Values } from '../../../../../components/Text/index.js';
import type { Tour } from '../../../../../records/Tour.js';
import type { Destination } from '../../../../../records/Destination.js';
import type { Poi } from '../../../../../records/Poi.js';
import { destinationSelector } from '../../../../../services/destination/selectors.js';

export const getFooterText = (tour: ?Tour): string =>
  tour && (tour.breadcrumbPoi || tour.breadcrumbDestination) ? 'more_from' : 'more_destinations';

export const getFooterValues = (tour: ?Tour, destinations: Destination[], poi: ?Poi): Values => {
  if (!tour) {
    return {};
  }
  if (tour.breadcrumbPoi) {
    return {
      place: poi ? poi.name : '',
    };
  }
  if (tour.breadcrumbDestination) {
    const destination = destinationSelector(destinations, Number(tour.breadcrumbDestination));
    return {
      place: destination ? destination.name : '',
    };
  }
  return {};
};
