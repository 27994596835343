// @flow strict
import React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Box, Flex } from '../../../../../../primitives/Essentials/index.js';
import Line from '../../../../../../primitives/Line/index.js';
import Text from '../../../../../../components/Text/index.js';
import mq from '../../../../../../services/mediaQuery/index.js';
import type { TopTip } from '../../../../../../records/PoiContent.js';
import Image from '../../../../../../components/Image/index.js';
import PhotoAttribution from '../../../../../../components/PhotoAttribution/index.js';

const StyledBox = styled(Box)`
  padding-right: 15px;
  padding-left: 15px;

  ${mq.TABLET`
    padding-right: 0;
    padding-left: 0;
    &:nth-child(2n) {
      padding-left: 7.5px;
    }
    &:nth-child(2n + 1) {
      padding-right: 7.5px;
    }
  `}
`;

const StyledLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  margin-bottom: 40px;
  color: ${({ theme }) => theme.trip.abstract};
  a {
    color: inherit;
  }
`;

const Title = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  margin-bottom: 2px;
  color: ${({ theme }) => theme.primary};
`;

const StyledImg = styled(Image)`
  display: block;
  width: 100%;
  height: auto;
  min-height: 194px;
  margin-bottom: 6px;
  border-radius: 5px;
  ${mq.SMALL_MOBILE`
    min-height: 211px;
  `};
  ${mq.MIDDLE_MOBILE`
    min-height: 247px;
  `};
  ${mq.BIG_MOBILE`
    min-height: 372px;
  `};
  ${mq.TABLET`
    min-height: 227px;
  `};
  ${mq.DESKTOP`
    min-height: 379px;
  `};
`;

const StyledImageLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  color: ${({ theme }) => theme.trip.divider};
  text-align: center;
`;

const Number = styled.div`
  margin-right: 12px;
  color: ${({ theme }) => theme.trip.divider};
  font-weight: 900;
  font-size: 54px;
  line-height: 0.9;
  text-align: center;
`;

type Props = {
  tip: TopTip,
  order: number,
};

const Tip = ({ tip, order }: Props) => (
  <StyledBox width={[1, null, null, null, 1 / 2]}>
    {tip.photo && (
      <Flex $flexDirection="column" $mb={14.5}>
        <StyledImg
          src={tip.photo.photoUrls[0]}
          srcSet={tip.photo.photoUrls[1] ? `${tip.photo.photoUrls[1]} 2x` : undefined}
          alt={tip.photo.photoTitle}
          lazy
        />
        <StyledImageLine fontSize={14}>
          {tip.photo.photoTitle}
          <PhotoAttribution attribution={tip.photo.photoAttribution} />
        </StyledImageLine>
      </Flex>
    )}
    <Flex>
      <Number>{order + 1}</Number>
      <Flex $flexDirection="column">
        {tip.title && (
          <Title fontSize={18} fontWeight={900}>
            {tip.title}
          </Title>
        )}
        <StyledLine fontSize={16} letterSpacing={-0.02} lineHeight={1.38}>
          <Text t={tip.abstract} html />
        </StyledLine>
      </Flex>
    </Flex>
  </StyledBox>
);

export default Tip;
