// @flow strict
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '../../../../../../primitives/Essentials/index.js';
import IntlContext from '../../../../../../services/intl/context.js';
import UrlContext from '../../../../../../services/url/context.js';
import Breadcrumbs from '../../../../../Breadcrumbs/index.js';
import type { Breadcrumb } from '../../../../../Breadcrumbs/index.js';
import type { Poi } from '../../../../../../records/Poi.js';
import type { Tag } from '../../../../../../records/SectionConfig';

type Props = {
  resultsCount: number,
  resultsForString: ?string,
  breadcrumbs: Breadcrumb[],
  poi: ?Poi,
  sectionConfigTags: ?(Tag[]),
  sectionId?: string,
};

const CategoryToursHeader = ({
  resultsForString,
  resultsCount,
  breadcrumbs,
  poi,
  sectionConfigTags,
  sectionId,
}: Props) => {
  const { category } = useParams(); // Access params directly with useParams

  return (
    <Box $pl={[14.5, null, null, null, 29.5]}>
      {Boolean(resultsCount) && (
        <UrlContext.Consumer>
          {({
            buildDestinationCategoryPageUrl,
            buildDestinationSubCategoryPage,
            buildPoiCategoryUrl,
          }) => {
            let lastBreadcrumbUrl;
            if (breadcrumbs.length === 1) {
              // destination category page
              lastBreadcrumbUrl = buildDestinationCategoryPageUrl(
                category,
                sectionConfigTags || undefined,
              );
            } else if (poi) {
              // poi category page
              lastBreadcrumbUrl = buildPoiCategoryUrl(poi, sectionId);
            } else if (breadcrumbs.length === 2 && sectionId) {
              lastBreadcrumbUrl = buildDestinationSubCategoryPage(sectionId);
            }
            return (
              <IntlContext.Consumer>
                {({ translate }) => (
                  <Breadcrumbs
                    items={
                      lastBreadcrumbUrl
                        ? breadcrumbs.concat({
                            name: `${translate('results_for', {
                              count: resultsCount,
                            })} <strong>${resultsForString || ''}</strong>`,
                            url: lastBreadcrumbUrl,
                            html: true,
                          })
                        : breadcrumbs
                    }
                    activeIndex={breadcrumbs.length}
                  />
                )}
              </IntlContext.Consumer>
            );
          }}
        </UrlContext.Consumer>
      )}
    </Box>
  );
};

export default CategoryToursHeader;