// @flow strict
import React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Flex } from '../../primitives/Essentials/index.js';
import { RemoveScroll } from 'react-remove-scroll';
import Line from '../../primitives/Line/index.js';
import type { Language } from '../../services/intl/context.js';
import UrlContext from '../../services/url/context.js';
import WindowContext from '../../services/window/context.js';
import Text from '../Text/index.js';
import mq from '../../services/mediaQuery/index.js';
import { LOCALES } from '../../../../etc/appConfig.js';

const Container = styled.div`
  position: absolute;
  top: ${({ bottom }) => (!bottom ? '65px' : 'initial')};
  bottom: ${({ bottom }) => (bottom ? '185px' : 'initial')};
  z-index: 3;
  width: 375px;
  margin-left: ${({ bottom }) => (!bottom ? '-250px' : '0')};
  overflow: hidden;
  background-color: ${({ theme }) => theme.tertiary};
  border-radius: 5px;
  box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.16);
`;

const List = styled.ul`
  list-style-type: none;
  padding-inline-start: 0;
  margin-block-start: 0;
  margin-block-end: 0;
`;

const Item = styled.li`
  padding: 11.5px 15.5px 15.5px;
  font-weight: ${({ selected }) => (selected ? '900' : 'inherit')};
  font-size: 19px;
  background-color: ${({ selected }) => (selected ? '#F5F5F5' : 'inherit')};
  cursor: pointer;
  ${mq.DESKTOP`
    &:hover {
      background-color: ${({ theme }) => theme.trip.backgroundDark};
    }
  `};
`;

const StyledLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  padding: 11.5px 15.5px;
`;

const Overlay = styled(Flex)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  background-color: ${({ theme }) => theme.tertiary};
  ${mq.TABLET`
    background-color: inherit;
  `};
`;

const StyledHeader = styled(Flex)`
  width: 100%;
  height: 70px;
  background-color: ${({ theme }) => theme.secondary};
`;

const StyledTitleLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  color: ${({ theme }) => theme.tertiary};
`;

const StyledCancelButton = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  color: ${({ theme }) => theme.tertiary};
  cursor: pointer;
`;

type Props = {
  language: Language,
  onCancel: () => void,
  bottom: boolean,
};

const LanguageSelect = ({ language, onCancel, bottom }: Props) => {
  const { isMobile } = React.useContext(WindowContext);
  const { setLanguage } = React.useContext(UrlContext);

  return isMobile ? (
    <RemoveScroll>
      <Overlay $flexDirection="column">
        <StyledHeader $justifyContent="space-between" $alignItems="center" $px={15}>
          <StyledTitleLine fontSize={19} fontWeight={900}>
            <Text t="select_language" />
          </StyledTitleLine>
          <StyledCancelButton fontSize={17} fontWeight={900} onClick={onCancel}>
            <Text t="cancel_button" />
          </StyledCancelButton>
        </StyledHeader>
        <StyledLine fontSize={19} fontWeight={900}>
          <Text t="languages" />
        </StyledLine>
        <List>
          {LOCALES.map(lang => (
            <Item
              key={lang}
              onClick={() => {
                setLanguage(lang);
                onCancel();
              }}
              selected={language === lang}
            >
              <Text t={`${lang}_language`} />
            </Item>
          ))}
        </List>
      </Overlay>
    </RemoveScroll>
  ) : (
    <>
      <Overlay onClick={onCancel} />
      <Container bottom={bottom ? 1 : 0}>
        <StyledLine fontSize={19} fontWeight={900}>
          <Text t="languages" />
        </StyledLine>
        <List>
          {LOCALES.map(lang => (
            <Item
              key={lang}
              onClick={() => {
                setLanguage(lang);
                onCancel();
              }}
              selected={language === lang}
            >
              <Text t={`${lang}_language`} />
            </Item>
          ))}
        </List>
      </Container>
    </>
  );
};

export default LanguageSelect;
