// @flow strict
import * as React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
// $FlowFixMe
import { buttonColor, getHover, getHoverColor } from '../../../../services/react-dates-lite/CalendarDay.js';
// $FlowFixMe
import type { ButtonProps } from '../../../../services/react-dates-lite/CalendarDay.js';
// $FlowFixMe
import type { CalendarDayProps } from '../../../../services/react-dates-lite/CalendarDay.js';
import { Flex } from '../../../../primitives/Essentials/index.js';
import { formatDateYMD, formatDateYM } from '../../../../services/dateTimeUtils/index.js';
import Price from '../../../Price/index.js';
import { formatPrice } from '../../../Price/utils.js';
import Line from '../../../../primitives/Line/index.js';
import type { LowestPricePerDate } from '../../../../services/api/tours/services/lowestPrices/index.js';
import mq from '../../../../services/mediaQuery/index.js';
import CurrencyContext from '../../../../services/currency/context.js';
import IntlContext from '../../../../services/intl/context.js';

type Props = CalendarDayProps & {
  lowestPrices?: LowestPricePerDate,
  lowestPricePerMonth?: LowestPricePerDate,
};

// override default color
const buttonBg = (props: ButtonProps) => {
  if (props.className) {
    return 'inherit';
  }
  if (props.disabled) {
    return props.$colors.background;
  }
  if (props.$isHovered) {
    return props.$colors.selected;
  }
  if (props.$isSelected) {
    return props.$colors.selected;
  }
  return '#f2f2f2';
};

// do not show currency symbol as it's too large and ₣ is probably not used widely
const formatSwissFranksShort = (language, val) =>
  new Intl.NumberFormat(language, {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(val);

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 37px;
  height: 50px;
  color: ${props => buttonColor(props)};
  background: ${props => buttonBg(props)};
  border: none;
  border-radius: 4px;
  opacity: ${props => (props.$isHidden ? 0 : 1)};

  ${props => !props.$isHidden && 'cursor: pointer;'};
  &:focus {
    outline: none;
  }
  &:hover {
    color: ${props => getHoverColor(props)};
    background: ${props => getHover(props)};
  }
  &:disabled {
    cursor: default;
    &:hover {
      background: inherit;
    }
  }

  ${mq.TABLET`
    max-width: 42.5px;
  `};
`;

const Td = styled.div`
  display: table-cell;
  box-sizing: border-box;
  padding: 2px;
  vertical-align: top;

  ${mq.TABLET`
    padding: 2.75px;
    max-width: 42.5px;
  `};
`;

const StyledLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  color: ${({ $isLowestPrice, $isSelected, theme }) =>
    // eslint-disable-next-line no-nested-ternary
    $isSelected ? 'white' : $isLowestPrice ? theme.trip.price : theme.trip.rating};
`;

// This is a copy of CalendarDay from 'react-dates-lite'
class CalendarDay extends React.PureComponent<Props> {
  handleClick = () => {
    const { value, isHidden, selectDate } = this.props;
    if (!isHidden) {
      selectDate(value);
    }
  };

  handleHover = () => {
    const { value, isHidden, onHover } = this.props;
    if (!isHidden) {
      onHover(value);
    }
  };

  render() {
    const {
      isHidden,
      number,
      isPast,
      isFuture,
      isSelected,
      isDisabled,
      isHovered,
      colors,
      isFocused,
      customClasses,
      classes,
      lowestPrices,
      lowestPricePerMonth,
      value,
    } = this.props;

    return (
      <Td
        $isHidden={isHidden}
        $colors={colors}
        className={
          (customClasses && (!isHovered && !isSelected) && customClasses.join(' ')) || classes.day
        }
      >
        <Button
          onClick={this.handleClick}
          onMouseOver={this.handleHover}
          onFocus={this.handleHover}
          disabled={
            isPast ||
            isFuture ||
            isDisabled ||
            (lowestPrices && typeof lowestPrices[formatDateYMD(value)] !== 'number')
          }
          $isSelected={isSelected}
          $isHidden={isHidden}
          $isPast={isPast}
          $isFuture={isFuture}
          $isHovered={isHovered}
          $isDisabled={isDisabled || (lowestPrices && !lowestPrices[formatDateYMD(value)])}
          $colors={colors}
          $isFocused={isFocused}
          $number={number}
        >
          <Flex $flexDirection="column">
            {number}
            {!isDisabled && lowestPrices && typeof lowestPrices[formatDateYMD(value)] === 'number' && (
              <IntlContext.Consumer>
                {({ language }) => (
                  <CurrencyContext.Consumer>
                    {({ currency }) => {
                      const priceValue = lowestPrices[formatDateYMD(value)];
                      const roundedLowestPrice = formatPrice(priceValue, language, currency, 0, 0);
                      const roundedLowestPricePerMonth = formatPrice(
                        lowestPricePerMonth[formatDateYM(value)],
                        language,
                        currency,
                        0,
                        0,
                      );

                      return (
                        <StyledLine
                          fontSize={13}
                          $isLowestPrice={roundedLowestPrice === roundedLowestPricePerMonth}
                          $isSelected={isSelected}
                        >
                          {currency !== 'chf' ? (
                            <Price value={priceValue} noFractions />
                          ) : (
                            formatSwissFranksShort(language, priceValue)
                          )}
                        </StyledLine>
                      );
                    }}
                  </CurrencyContext.Consumer>
                )}
              </IntlContext.Consumer>
            )}
          </Flex>
        </Button>
      </Td>
    );
  }
}

export default CalendarDay;
