// @flow strict
import React, { useContext } from 'react';
import { Flex, Box } from '../../primitives/Essentials/index.js';
import { withTheme } from 'styled-components';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { BITMAP_URL } from '../../../../etc/appConfig.js';
import UrlContext from '../../services/url/context.js';
import IntlContext from '../../services/intl/context.js';
import type { Language } from '../../services/intl/context.js';
import type { Poi } from '../../records/Poi.js';
import type { Theme } from '../../records/Theme.js';
import mq from '../../services/mediaQuery/index.js';
import Link from '../Link/index.js';
import Image from '../Image/index.js';
import { hover } from '../../services/styleUtils/index.js';

type Props = {
  attraction: Poi, // from algolia
  theme: Theme,
  language: Language,
};

const StyledBox = styled(Box)`
  border-radius: 5px;

  ${mq.BIG_MOBILE`
    &:nth-child(2n) {
      padding-left: 15.5px;
    }
  `};

  ${mq.DESKTOP`
    padding-right: 15.5px;
    ${hover()}
    &:nth-child(2n) {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0px;
    }
    &:nth-child(n+5):last-child {
      padding-right: 15.5px;
    }
  `};
`;

const StyledFlex = styled(Flex)`
  position: relative;
  height: 182px;
`;

const StyledImage = styled(Image)`
  width: 100%;
  min-width: 250px;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
`;

const StyledLine = styled.h3`
  position: absolute;
  bottom: 9px;
  left: 15px;
  max-width: 200px;
  margin: 0;
  padding: 10px 15px;
  font-weight: 900;
  font-size: 20px;
  text-align: left;
  background-color: ${({ theme }) => theme.tertiary};
  border-radius: 22.5px;
`;

const Attraction = ({ attraction, theme, language }: Props) => {
  const { translate } = useContext(IntlContext);
  const { buildPoiPageUrl, openPoiPage } = useContext(UrlContext);

  const imageUrl = `${BITMAP_URL}/poi/${attraction.objectID}/568x182?returnCrop=yes`;
  const imageUrl2x = `${BITMAP_URL}/poi/${attraction.objectID}/1136x364?returnCrop=yes`;

  const crawlable = attraction.index.includes(language);

  return (
    <StyledBox $mt={[10, null, null, 15]} width={[1, null, null, 1 / 2, null, 1 / 4]}>
      {/* eslint-disable-next-line */}
      <Link
        useDiv={!crawlable}
        onClick={!crawlable ? () => openPoiPage(attraction) : undefined}
        href={crawlable ? buildPoiPageUrl(attraction) : undefined}
      >
        <StyledFlex $justifyContent="center" $alignItems="center">
          <StyledImage
            src={imageUrl}
            srcSet={`${imageUrl2x} 2x`}
            alt={translate('title_poi_page', { poi: attraction.name })}
            lazy
          />
          <StyledLine fontSize={20} fontWeight={900} color={theme.section.title}>
            {translate('attraction_mainkeyword_item', { poi: attraction.name })}  
          </StyledLine>
        </StyledFlex>
      </Link>
    </StyledBox>
  );
};

export default withTheme(Attraction);
