// @flow strict
import Cookies from 'js-cookie';

type CookiesType = {
  [cookie: string]: string,
};

// eslint-disable-next-line import/prefer-default-export
export function getGaCookies(): CookiesType {
  const allCookies = Cookies.get();
  return Object.keys(allCookies)
    .filter(cookie => cookie.startsWith('_') && cookie !== '_ga')
    .reduce((acc, key) => {
      acc[key] = allCookies[key];
      return acc;
    }, {});
}
