// @flow strict
import React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Box, Flex } from '../../../../../../primitives/Essentials/index.js';
import UrlContext from '../../../../../../services/url/context.js';
import type { Destination as DestinationType } from '../../../../../../records/Destination.js';
import mq from '../../../../../../services/mediaQuery/index.js';
import Link from '../../../../../../components/Link/index.js';
import Image from '../../../../../../components/Image/index.js';
import Line from '../../../../../../primitives/Line/index.js';
import Text from '../../../../../../components/Text/index.js';
import { hover } from '../../../../../../services/styleUtils/index.js';

const StyledBox = styled(Box)`
  padding-top: 10px;

  &:nth-child(2n) {
    padding-left: 10px;
  }

  ${mq.TABLET`
    padding-left: 10px;
    &:nth-child(3n + 1) {
      padding-left: 10px;
    }
    &:nth-child(4n + 1) {
      padding-left: 0px;
    }
  `};
  ${mq.DESKTOP`
    ${hover()}
  `}
`;

const StyledImg = styled(Image)`
  width: 100%;
  height: 165px;
  object-fit: cover;
  border-radius: 5px 5px 0 0;

  ${mq.TABLET`
    height: 200px;
  `};  
`;

const StyledTextFlex = styled(Flex)`
  background-color: ${({ theme }) => theme.trip.backgroundLight};
  border-radius: ${({ $hideCover }) => ($hideCover ? '5px' : '0 0 5px 5px')};
`;

const StyledDestinationTitle = styled.h3`
  margin: 0;
  color: ${({ theme }) => theme.section.title};
  font-weight: 900;
  font-size: 24px;
`;

const StyledSecondaryDestinationTitle = styled.h4`
  margin: 0;
  color: ${({ theme }) => theme.section.title};
  font-weight: 900;
  font-size: 19px;
`;

const StyledToursCountLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  color: ${({ theme }) => theme.trip.abstract};
`;

type Props = {
  destination: DestinationType,
  alt?: string,
  hideCover?: boolean,
  toursCount?: number,
};

const Destination = ({ destination, hideCover, alt, toursCount }: Props) => (
  <UrlContext.Consumer>
    {({ buildDestinationPageUrl }) => (
      <StyledBox width={[1 / 2, null, null, null, 1 / 4]}>
        <Link href={buildDestinationPageUrl(destination.destination_id)}>
          <Flex $flexDirection="column">
            {!hideCover && (
              <StyledImg
                src={`/images/destinations/${destination.destination_id}/destination_cover.jpg`}
                srcSet={`/images/destinations/${destination.destination_id}/destination_cover@2x.jpg 2x`}
                alt={alt || destination.name}
                lazy={true}
              />
            )}
            <StyledTextFlex
              $px={[8.5, null, null, null, 12]}
              $py={10.5}
              $alignItems="center"
              $hideCover={hideCover}
              className="darkenOnHover"
            >
              {!hideCover ? (
                <Flex $flexDirection="column">
                  <StyledDestinationTitle>{destination.name}</StyledDestinationTitle>
                  {toursCount && (
                    <StyledToursCountLine fontSize={16}>
                      <Text html t="tickets_and_tours" values={{ count: toursCount }} />
                    </StyledToursCountLine>
                  )}
                </Flex>
              ) : (
                <StyledSecondaryDestinationTitle>
                  {destination.name}
                </StyledSecondaryDestinationTitle>
              )}
            </StyledTextFlex>
          </Flex>
        </Link>
      </StyledBox>
    )}
  </UrlContext.Consumer>
);

Destination.defaultProps = {
  hideCover: false,
};

export default Destination;
