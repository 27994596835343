// @flow strict
import * as React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Flex, Box } from '../../../../../../primitives/Essentials/index.js';
import type { Tour } from '../../../../../../records/Tour.js';
import Offer from './components/Offer/index.js';
import SvgSpinner from '../../../../../../components/SvgSpinner/index.js';
import BookingFeeAbstract from './components/BookingFeeAbstract/index.js';
import type { Theme } from '../../../../../../records/Theme.js';
import type { Promocodes } from '../../../../../../records/Promocodes.js';
import getTourPromocode from '../../../../../../services/tours/getTourPromocode.js';

type Props = {
  theme: Theme,
  tours: Tour[],
  date?: ?string,
  isLoading: boolean,
  tourIdWithBookingFee: ?number,
  promocodes: Promocodes,
};

const StyledBox = styled(Box)`
  min-height: 156px;
`;

const GroupTourOffers = ({
  theme,
  tours,
  date,
  isLoading,
  tourIdWithBookingFee,
  promocodes,
}: Props) => (
  <StyledBox $mt={2}>
    {isLoading && (
      <Box $pt={80}>
        <Flex $justifyContent="center">
          <SvgSpinner />
        </Flex>
      </Box>
    )}
    {tours.map((tour, i) => {
      const hasFees = tourIdWithBookingFee && tour.id === tourIdWithBookingFee;
      const promocode = getTourPromocode(tour, promocodes);
      // only show bestseller for first tour (cheapest)
      return (
        <Box key={tour.id}>
          <Offer
            tour={tour}
            date={date}
            promocode={promocode}
            bestseller={tour.bestConverter && !tours.slice(0, i).some(t => t.bestConverter)}
          />
          {hasFees && (
            <Box $mb={[1.5, null, null, 5]}>
              <BookingFeeAbstract theme={theme} />
            </Box>
          )}
        </Box>
      );
    })}
  </StyledBox>
);

export default GroupTourOffers;
