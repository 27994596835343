// @flow strict
import type { About } from '../../../../../../../records/PoiContent';

const getItemAbstractsLength = content =>
  content.reduce((acc, item) => acc + item.abstract.length, 0);

export default function getColumnContent(content: About[]) {
  const contentItemsLength = getItemAbstractsLength(content);
  const columnLength = contentItemsLength / 2;

  const leftAndRight = content.reduce(
    (acc, item, i) => {
      if (
        i === 0 ||
        getItemAbstractsLength(acc.left) + item.abstract.length <= columnLength * 1.02
      ) {
        acc.left.push(item);
      } else {
        acc.right.push(item);
      }
      return acc;
    },
    { left: [], right: [] },
  );
  return leftAndRight;
}
