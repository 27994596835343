// @flow strict
/* eslint-disable react/no-array-index-key */
import React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Box, Flex } from '../../../../../../primitives/Essentials/index.js';
import Line from '../../../../../../primitives/Line/index.js';
import Text from '../../../../../Text/index.js';
import mq from '../../../../../../services/mediaQuery/index.js';
import type { EditorialSection as EditorialSectionType } from '../../../../../../records/EditorialContent.js';
import Image from '../../../../../Image/index.js';
import PhotoAttribution from '../../../../../PhotoAttribution/index.js';

const StyledBox = styled(Box)`
  padding-right: 0;
  padding-left: 0;

  &:not(&:last-child) {
    padding-bottom: 30px;
  }

  ${mq.TABLET`
    padding-right: 0;
    padding-left: 0;
    &:not(&:last-child) {
      padding-bottom: 0;
    }
    &:not(&:nth-last-of-type(-n+2)) {
      padding-bottom: 30px;
    }
    &:nth-child(2n) {
      padding-left: 7.5px;
    }
    &:nth-child(2n + 1) {
      padding-right: 7.5px;
    }
  `}
`;

const StyledLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  margin-bottom: 30px;
  color: ${({ theme }) => theme.trip.abstract};
  a {
    color: inherit;
  }
`;

const StyledParagraphLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  margin-bottom: 30px;
  color: ${({ theme }) => theme.trip.abstract};
  a {
    color: inherit;
  }
`;

const StyledImg = styled(Image)`
  display: block;
  width: 100%;
  height: auto;
  min-height: 194px;
  border-radius: 5px;
  ${mq.SMALL_MOBILE`
    min-height: 211px;
  `};
  ${mq.MIDDLE_MOBILE`
    min-height: 247px;
  `};
  ${mq.BIG_MOBILE`
    min-height: 372px;
  `};
  ${mq.TABLET`
    min-height: 227px;
  `};
  ${mq.DESKTOP`
    min-height: 379px;
  `};
`;

const StyledImageLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  color: ${({ theme }) => theme.trip.divider};
  text-align: center;
`;

const StyledH2 = styled.h2`
  margin: 0 0 6px;
  color: ${({ theme }) => theme.section.title};
  font-weight: 900;
  font-size: 22px;
`;

const StyledH3 = styled.h3`
  margin: 0;
  color: ${({ theme }) => theme.section.title};
  font-weight: 900;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.03px;
`;
type Props = {
  section: EditorialSectionType,
};

const EditorialSection = ({ section }: Props) => (
  <StyledBox width={[1, null, null, null, 1 / 2]}>
    {section.photo && (
      <Flex $flexDirection="column" $mb={14.5}>
        <Box $mb={8}>
          <StyledImg
            src={section.photo.photoUrls[0]}
            srcSet={section.photo.photoUrls[1] ? `${section.photo.photoUrls[1]} 2x` : undefined}
            alt={section.photo.photoTitle}
            lazy
          />
        </Box>
        <StyledImageLine fontSize={14}>
          {section.photo.photoTitle}
          <PhotoAttribution attribution={section.photo.photoAttribution} />
        </StyledImageLine>
      </Flex>
    )}
    <Box $pl={[0, null, null, null, 15]}>
      {section.title && <StyledH2>{section.title}</StyledH2>}
        <>
          {section.abstract && (
            <StyledLine fontSize={16} letterSpacing={-0.02} lineHeight={1.38}>
              <Text t={section.abstract} html />
            </StyledLine>
          )}
        </>
        <>
          {section.paragraphs &&
            section.paragraphs.map((paragraph, i) => (
              <Box key={`par-${i}`}>
                <StyledH3>{paragraph.title}</StyledH3>
                <StyledParagraphLine fontSize={16} letterSpacing={-0.02} lineHeight={1.38}>
                  <Text t={paragraph.abstract} html />
                </StyledParagraphLine>
              </Box>
            ))}
        </>
    </Box>
  </StyledBox>
);

export default EditorialSection;
