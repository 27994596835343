// @flow strict
import * as React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Box } from '../../primitives/Essentials/index.js';
import InputButton from './components/InputButton/index.js';
import { formatDate } from '../../services/dateTimeUtils/index.js';
import type { Theme } from '../../records/Theme.js';
import type { Language } from '../../services/intl/context.js';
import type { LowestPricePerDate } from '../../services/api/tours/services/lowestPrices/index.js';
import DatePickerModal from './components/DatePickerModal/index.js';
import mq from '../../services/mediaQuery/index.js';

import SvgSpinner from '../SvgSpinner/index.js';

const Loader = styled.div`
  position: absolute;
  top: 56px;
  left: -15px;
  z-index: 12001;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 311px;
  height: 318px;
  padding: 4px;
  background-color: ${({ theme }) => theme.tertiary};
  border-radius: 5px;
  box-shadow: 0 2.5px 4px 0 rgba(0, 0, 0, 0.5);

  ${mq.SMALL_MOBILE`
    left: 0px;
    padding: 10px;
`};
  ${mq.TABLET`
    width: 325px;
`};
`;

const StyledRoot = styled(Box)`
  position: relative;
  .ibWrapper {
    width: 100%;
    ${mq.TABLET`
      max-width: 345px;
      width: 345px;
    `};
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 12000;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

type Props = {
  date: ?Date,
  language: Language,
  minDate: Date,
  maxDate: Date,
  disabledDates?: ?(Date[]),
  onChangeDate: (date: Date) => void,
  lowestPrices?: ?LowestPricePerDate,
  placeholder?: string,
  theme: Theme,
  isMobile: boolean,
};

type State = {
  calendarVisible: boolean,
};

class DatePicker extends React.PureComponent<Props, State> {
  node: ?React.Ref<*>;

  calendarWrapperNode: ?React.Ref<*>; // should be typeof div

  inputButtonWrapperNode: ?React.Ref<*>; // should be typeof div

  static defaultProps = {
    placeholder: '',
    disabledDates: undefined,
    lowestPrices: null,
  };

  constructor(props: Props) {
    super(props);
    this.node = React.createRef();
    this.calendarWrapperNode = React.createRef();
    this.inputButtonWrapperNode = React.createRef();
    this.state = {
      calendarVisible: false,
    };
  }

  componentDidMount = () => {
    if (typeof window !== 'undefined') {
      document.addEventListener('mousedown', this.handleClick, false);
    }
    // $FlowFixMe
    if (this.node && this.node.current) {
      // cannot use prevent default in document attached touchstart/end listeners
      // $FlowFixMe
      this.node.current.ontouchend = this.handleClick;
    }
  };

  componentWillUnmount = () => {
    document.removeEventListener('mousedown', this.handleClick, false);
  };

  handleClick = (ev: MouseEvent | TouchEvent) => {
    const { calendarVisible } = this.state;

    if (
      calendarVisible &&
      // $FlowFixMe
      this.calendarWrapperNode &&
      // $FlowFixMe
      this.calendarWrapperNode.current &&
      // $FlowFixMe
      this.inputButtonWrapperNode &&
      // $FlowFixMe
      this.inputButtonWrapperNode.current &&
      // $FlowFixMe
      !this.calendarWrapperNode.current.contains(ev.target) &&
      // $FlowFixMe
      !this.inputButtonWrapperNode.current.contains(ev.target)
    ) {
      // click outside calendar
      this.handleToggleCalendar();
      if (ev.type === 'touchend') {
        ev.preventDefault();
      }
    }
  };

  handleToggleCalendar = () => {
    const { calendarVisible } = this.state;
    const { isMobile } = this.props;

    this.setState({ calendarVisible: !calendarVisible }, () => {
      if (
        !calendarVisible &&
        isMobile &&
        // $FlowFixMe
        this.calendarWrapperNode &&
        // $FlowFixMe
        this.calendarWrapperNode.current
      ) {
        // $FlowFixMe
        this.calendarWrapperNode.current.scrollIntoView(true);
        // correction of scrollIntoView - overall not very good on larger devices
        window.scrollBy(0, -105);
      }
    });
  };

  render() {
    const {
      date,
      language,
      minDate,
      maxDate,
      disabledDates,
      onChangeDate,
      lowestPrices,
      placeholder,
      theme,
      isMobile,
    } = this.props;

    const { calendarVisible } = this.state;

    return (
      <StyledRoot ref={this.node}>
        <div className="ibWrapper" ref={this.inputButtonWrapperNode}>
          <InputButton
            placeholder={placeholder}
            value={date ? formatDate(date, language) : ''}
            onClick={this.handleToggleCalendar}
            onClear={date ? () => onChangeDate(date) : undefined}
          />
        </div>
        {calendarVisible && (
          <>
            <Overlay onTouchEnd={this.handleClick /* needed to catch clicks outside of ref */} />
            <DatePickerModal
              date={date}
              language={language}
              minDate={minDate}
              maxDate={maxDate}
              disabledDates={disabledDates}
              onChangeDate={onChangeDate}
              lowestPrices={lowestPrices}
              theme={theme}
              isMobile={isMobile}
              handleToggleCalendar={this.handleToggleCalendar}
              handleClick={this.handleClick}
              calendarWrapperNode={this.calendarWrapperNode}
            />
          </>
        )}
      </StyledRoot>
    );
  }
}

export default DatePicker;
