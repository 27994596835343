// @flow strict
import * as React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { Box, Flex } from '../../primitives/Essentials/index.js';
import Cookies from 'js-cookie/src/js.cookie.js';
import addDays from 'date-fns/addDays/index.js';
import isSameDay from 'date-fns/isSameDay/index.js';
import { container, HEADER, FOOTER, FOOTER_MOBILE, FOOTER_BUTTON } from '../../services/styleUtils/index.js';
import { COOKIES } from '../../records/Cookies.js';
import Navbar from '../../components/Navbar/index.js';
import Footer from '../../components/Footer/index.js';
import mq from '../../services/mediaQuery/index.js';
import IntlContext from '../../services/intl/context.js';
import CurrencyContext from '../../services/currency/context.js';
import WindowContext from '../../services/window/context.js';
import ContentContext from '../../services/content/context.js';
import SearchToursContainer from '../../components/SearchToursContainer/index.js';
import SearchResultsBarWithDate from './components/SearchResultsBarWithDate/index.js';
import { formatDateYMD, parseDate } from '../../services/dateTimeUtils/index.js';

const StyledContainerBox = styled(Box)`
  ${container()};
  min-height: calc(100vh - ${HEADER} - ${FOOTER_MOBILE} - ${FOOTER_BUTTON} - 2px);
  ${mq.TABLET`
    min-height: calc(100vh - ${HEADER} - ${FOOTER} - ${FOOTER_BUTTON} - 2px);
  `};
`;

const StyledBox = styled(Box)`
  margin-top: -2px;
  ${mq.TABLET`
    margin-top: 0px;
  `};
  ${mq.DESKTOP`
    max-width: 435px;
  `};
`;

const SearchResultsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [date, setDate] = React.useState<?Date>(null);

  React.useEffect(() => {
    let parsedDate;
    const queryParams = queryString.parse(location.search);
    if (queryParams.date) {
      parsedDate = parseDate(queryParams.date);
    } else if (Cookies.get(COOKIES.DATE)) {
      parsedDate = parseDate(Cookies.get(COOKIES.DATE));
    }

    if (parsedDate && !isNaN(parsedDate.getTime())) {
      setDate(parsedDate);
    }
  }, [location]);

  const handleChangeDate = (newDate: Date) => {
    const queryParams = queryString.parse(location.search);
    if (date && isSameDay(newDate, date)) {
      delete queryParams.date;
      navigate({ search: queryString.stringify(queryParams) });
      Cookies.remove(COOKIES.DATE);
    } else {
      queryParams.date = formatDateYMD(newDate);
      navigate({ search: queryString.stringify(queryParams) });
      Cookies.set(COOKIES.DATE, queryParams.date, {
        expires: addDays(newDate, 1),
      });
    }
    setDate((prevDate) => (prevDate && !isSameDay(newDate, prevDate)) || !prevDate ? newDate : null);
  };

  const defineSearchQueryAndDestinationId = (location) => {
    const { search } = location;
    const queryParams = queryString.parse(search);
    return {
      searchQuery: String(queryParams.q),
      destinationId: Number(queryParams.d),
    };
  };

  const { searchQuery, destinationId } = defineSearchQueryAndDestinationId(location);

  return (
    <IntlContext.Consumer>
      {({ language }) => (
        <WindowContext.Consumer>
          {({ isMobile }) => (
            <>
              <Navbar
                search={!isMobile}
                destinationId={Number.isNaN(destinationId) ? null : destinationId}
                prepopulatedSearchValue={searchQuery}
              />
              <StyledContainerBox>
                <Flex $flexDirection={['column', null, null, null, null, 'row']}>
                  <StyledBox
                    $pt={[0, null, null, null, 14, 49]}
                    width={[1, null, null, null, null, 5 / 12]}
                  >
                    <SearchResultsBarWithDate
                      date={date}
                      textQuery={searchQuery}
                      destinationId={destinationId}
                      onChangeDate={handleChangeDate}
                    />
                  </StyledBox>
                  {searchQuery && (
                    <CurrencyContext.Consumer>
                      {({ currency }) => (
                        <ContentContext.Consumer>
                          {({ promocodes, currencies, providers }) => (
                            <Box width={1}>
                              <SearchToursContainer
                                language={language}
                                currency={currency}
                                date={date}
                                searchText={searchQuery}
                                promocodes={promocodes}
                                providers={providers}
                                currencies={currencies}
                              />
                            </Box>
                          )}
                        </ContentContext.Consumer>
                      )}
                    </CurrencyContext.Consumer>
                  )}
                </Flex>
              </StyledContainerBox>
              <Footer />
            </>
          )}
        </WindowContext.Consumer>
      )}
    </IntlContext.Consumer>
  );
};

export default SearchResultsPage;