// @flow strict
import React from 'react';
import type { Location } from 'react-router-dom';
import pkg from 'react-helmet-async/lib/index.js';
const { Helmet: ReactHelmet, HelmetData } = pkg;
import { DOMAIN, BITMAP_URL } from '../../../../../../../../etc/appConfig.js';

type Props = {
  title: string,
  description: string,
  location: Location,
};

const Helmet = ({ title, description, location }: Props) => {
  const url = `${DOMAIN}${location.pathname}`;
  const helmetData = new HelmetData({});

  return (
    <ReactHelmet helmetData={helmetData}>
      <title>{title}</title>
      <meta name="description" property="og:description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={`${BITMAP_URL}/image/2337070/1200x630?returnCrop=yes`} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
    </ReactHelmet>
  );
};

export default Helmet;
