// @flow strict
import React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Box, Flex } from '../../../../primitives/Essentials/index.js';
import DestinationContext from '../../../../services/destination/context.js';
import type { Translate } from '../../../../services/intl/translate.js';
import type { Language } from '../../../../services/intl/context.js';
import UrlContext from '../../../../services/url/context.js';
import ContentContext from '../../../../services/content/context.js';
import {
  container,
  HEADER,
  TAGLINE,
  COVER_MOBILE,
  COVER,
  COVER_TABLET,
  FOOTER,
  FOOTER_MOBILE,
  CONTENT_MARGIN,
} from '../../../../services/styleUtils/index.js';
import MoreLinks from '../../../../components/MoreLinks/index.js';
import Destination from './components/Destination/index.js';
import Text from '../../../../components/Text/index.js';
import mq from '../../../../services/mediaQuery/index.js';
import {
  LAYOUT_COMMON,
  SECTION_LAYOUT,
  SECTION_LAYOUT_NO_PADDING_MOBILE,
} from '../../../../consts/layout.js';
import Button from '../../../../components/Button/index.js';
import Attractions from './components/Attractions/index.js';
import USPSection from './components/USPSection/index.js';
import History from '../../../../components/History/index.js';
import ClientOnly from '../../../../components/ClientOnly/index.js';

const Container = styled(Box)`
  width: 100%;
  ${container()};
`;

const StyledContainerBox = styled(Box)`
  ${container()};
  min-height: calc(
    100vh - ${HEADER} - ${TAGLINE} - ${FOOTER_MOBILE} - ${COVER_MOBILE} - ${CONTENT_MARGIN}
  );
  ${mq.TABLET`
    min-height: calc(100vh - ${HEADER} - ${TAGLINE} - ${FOOTER} - ${COVER_TABLET} - ${CONTENT_MARGIN} - 12px);
  `};
  ${mq.DESKTOP`
    min-height: calc(100vh - ${HEADER} - ${TAGLINE} - ${FOOTER} - ${COVER} - ${CONTENT_MARGIN} - 12px);
  `};
`;

const StyledTitle = styled.h2`
  margin: 0;
  color: ${({ theme }) => theme.section.title};
  font-weight: 900;
  font-size: 24px;
`;

type Props = {
  language: Language,
  translate: Translate,
};

const Content = ({ language, translate }: Props) => (
  <ContentContext.Consumer>
    {({ topAttractions, toursCount, providers, promocodes, currencies }) => (
      <Flex $flexDirection="column">
        <ClientOnly>
          {typeof window !== 'undefined' && window.localStorage.getItem('clickHistory') && (
            <Container
              $mt={LAYOUT_COMMON.$mt}
              $py={LAYOUT_COMMON.$py}
              $px={SECTION_LAYOUT_NO_PADDING_MOBILE.$px}
            >
              <History
                language={language}
                promocodes={promocodes}
                currencies={currencies}
                providers={providers}
                history={window.localStorage.getItem('clickHistory')}
              />
            </Container>
          )}
        </ClientOnly>
        <StyledContainerBox $mt={LAYOUT_COMMON.$mt} $py={LAYOUT_COMMON.$py} $px={SECTION_LAYOUT.$px}>
          <Flex $flexDirection="column">
            <StyledTitle>
              <Text t="choose_your_destination" />
            </StyledTitle>
            <Flex $flexWrap="wrap">
              <DestinationContext.Consumer>
                {({ destinations }) =>
                  destinations[language].slice(0, 20).map((destination, i) => (
                    <Destination
                      key={destination.destination_id}
                      destination={destination}
                      toursCount={toursCount[destination.destination_id]}
                      hideCover={i > 7}
                      alt={translate('title_dest_page', {
                        destination: destination.name,
                      })}
                    />
                  ))
                }
              </DestinationContext.Consumer>
            </Flex>
            <Box $mt={[10, null, null, null, 18.5]}>
              <UrlContext.Consumer>
                {({ buildAllDestinationsPageUrl }) => (
                  <Button rounded href={buildAllDestinationsPageUrl()}>
                    <Text t="see_all_destinations" />
                  </Button>
                )}
              </UrlContext.Consumer>
            </Box>
          </Flex>
        </StyledContainerBox>
        <USPSection totalToursCount={toursCount.total} providers={providers} />
        <StyledContainerBox {...SECTION_LAYOUT}>
          <Flex $flexDirection="column">
            <Attractions attractions={topAttractions} language={language} />
            <MoreLinks attractionsOnly language={language} />
          </Flex>
        </StyledContainerBox>
      </Flex>
    )}
  </ContentContext.Consumer>
);

export default Content;
