const DEFAULT_POI_SECTIONS = {
  // : { [language: Language]: Section[] }
  en: [
    {
      id: 'tickets',
      title: 'Tickets',
      abstract: '',
      filters: '_tags:"s:tickets"',
    },
    {
      id: 'audioguides',
      title: 'With Audio Guide',
      abstract: '',
      filters: '_tags:"s:audioguide"',
    },
    {
      id: 'guidedtours',
      title: 'Guided Tours',
      abstract: '',
      filters: '_tags:"s:guidedtour"',
    },
    {
      id: 'specials',
      title: 'Specials',
      abstract: '',
      filters: '_tags:"s:specials"',
    },
  ],
  de: [
    {
      id: 'tickets',
      title: 'Tickets',
      abstract: '',
      filters: '_tags:"s:tickets"',
    },
    {
      id: 'audioguides',
      title: 'Mit Audio-Guide',
      abstract: '',
      filters: '_tags:"s:audioguide"',
    },
    {
      id: 'guidedtours',
      title: 'Führungen',
      abstract: '',
      filters: '_tags:"s:guidedtour"',
    },
    {
      id: 'specials',
      title: 'Extras',
      abstract: '',
      filters: '_tags:"s:specials"',
    },
  ],
  it: [
    {
      id: 'tickets',
      title: 'Biglietti',
      abstract: '',
      filters: '_tags:"s:tickets"',
    },
    {
      id: 'audioguides',
      title: 'Con Audioguida',
      abstract: '',
      filters: '_tags:"s:audioguide"',
    },
    {
      id: 'guidedtours',
      title: 'Visita guidata',
      abstract: '',
      filters: '_tags:"s:guidedtour"',
    },
    {
      id: 'specials',
      title: 'Speciali',
      abstract: '',
      filters: '_tags:"s:specials"',
    },
  ],
};

export default DEFAULT_POI_SECTIONS;
