// @flow strict
/* eslint-disable react/no-danger, react/require-default-props, react/default-props-match-prop-types */
import * as React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;

type Speed = 'fast' | 'slow';

type Props = {
  speed: Speed,
  color: string,
  gap: 1 | 2 | 3 | 4 | 5,
  thickness: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8,
  size: string,
  className: ?string,
};

const StyledSvg = styled.svg`
  transition-property: transform;
  animation-name: infinite-spin;
  animation-duration: ${({ speed }) => (speed === 'fast' ? '600ms' : '900ms')};
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  @keyframes infinite-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const SvgSpinner = ({ color, speed, gap, thickness, size, className }: Props) => (
  <StyledSvg
    height={size}
    width={size}
    style={{ animationDuration: `${speed === 'fast' ? 600 : 900}ms` }}
    role="img"
    aria-labelledby="title desc"
    viewBox="0 0 32 32"
    speed={speed}
    className={className}
  >
    <title>Circle loading spinner</title>
    <desc>Image of a partial circle indicating loading.</desc>
    <circle
      role="presentation"
      cx={16}
      cy={16}
      r={14 - thickness / 2}
      stroke={color}
      fill="none"
      strokeWidth={thickness}
      strokeDasharray={Math.PI * 2 * (11 - gap)}
      strokeLinecap="round"
    />
  </StyledSvg>
);

SvgSpinner.defaultProps = {
  speed: 'fast',
  color: 'rgba(0,0,0,0.4)',
  gap: 4,
  thickness: 4,
  size: '40px',
  className: null,
};

export default SvgSpinner;
