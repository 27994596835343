// @flow strict
import * as React from 'react';
import { Flex } from '../../../../../../primitives/Essentials/index.js';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import Line from '../../../../../../primitives/Line/index.js';
import Text from '../../../../../Text/index.js';
import type { Theme } from '../../../../../../records/Theme.js';
import SearchInput from '../../../../../SearchInput/index.js';
import IntlContext from '../../../../../../services/intl/context.js';

const StyledSearchBar = styled(Flex)`
  width: 100%;
  height: 70px;
  background-color: ${({ theme }) => theme.secondary};
`;

const StyledCancelButton = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  color: ${({ theme }) => theme.tertiary};
  cursor: pointer;
`;

type Props = {
  theme: Theme,
  prepopulatedValue: string,
  onTextChange: string => void,
  onSubmitPressed: string => void,
  onCancelPressed: () => void,
  handleKeyDown: KeyboardEvent => void,
};

const SearchBar = ({
  theme,
  onTextChange,
  onSubmitPressed,
  onCancelPressed,
  prepopulatedValue,
  handleKeyDown,
}: Props) => (
  <IntlContext.Consumer>
    {({ translate }) => (
      <StyledSearchBar $justifyContent="space-between" $alignItems="center" $px={15}>
        <SearchInput
          autoFocus
          theme={theme}
          placeholder={translate('search_placeholder')}
          prepopulatedValue={prepopulatedValue}
          onChange={onTextChange}
          onSubmit={onSubmitPressed}
          handleKeyDown={handleKeyDown}
        />
        <StyledCancelButton fontSize={17} fontWeight={900} onClick={onCancelPressed}>
          <Text t={translate('cancel_button')} />
        </StyledCancelButton>
      </StyledSearchBar>
    )}
  </IntlContext.Consumer>
);

export default SearchBar;
