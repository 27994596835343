// @flow strict
import React, { useContext } from 'react';
import { Flex, Box } from '../../primitives/Essentials/index.js';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { container } from '../../services/styleUtils/index.js';
import mq from '../../services/mediaQuery/index.js';
import Line from '../../primitives/Line/index.js';
import Link from '../Link/index.js';
import Text from '../Text/index.js';
import IntlContext from '../../services/intl/context.js';
import type { PoiContent } from '../../records/PoiContent.js';
import type { Poi } from '../../records/Poi.js';


const StyledTOCContainerBox = styled(Box)`
  display: none; // Default to not displaying the element at all

  ${mq.DESKTOP_TOC`
  display: block;
  position: -webkit-sticky; // For Safari support
  position: sticky;
  margin-top: 50px; 
  top: 10px;
  left: 10px;
  overflow-y: auto;
  background-color: #f9f9f9; // A light grey background
  border-radius: 5px;
  width: 250px; // Width of the ToC
  z-index: 10; 
  padding-right: 20px; 
  height: 100%;
  padding: 15px;
  font-size: 14px;
 `}
`;

const StyledTOCContainerInline = styled(Box)`
  display: block;
  font-size: 14px;

  ${mq.DESKTOP_TOC`
  display: none; 
  `}
`;

const StyledToCList = styled.ul`
  list-style: none;
  padding-top: 5px;
  padding-left: 0;
`;

const StyledToCItem = styled.li`
  padding: 8px 0;
  
  a {
    color: #808080;
    line-height: 1.79;
    // white-space: nowrap;
    word-break: break-word; // Breaks long words to prevent overflow
    overflow-wrap: break-word; // Ensures that long words do not overflow their container

    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }
  }

  // Add a thin line between items
  border-bottom: 1px solid #e0e0e0; // Use a light gray color for the line, adjust as needed

  &:last-child {
    border-bottom: none; // Remove the line for the last item
  }
`;

type Props = {
    content: PoiContent,
    poi: Poi,
    inline: boolean
};

const TableOfContents = ({ content, poi, inline }: Props) => {
    const { translate } = useContext(IntlContext);
    var tocItems = [];

    // Add sections with static URL counting up and title to tocItems
    if (content.sections && content.sections.length) {
        tocItems = tocItems.concat(content.sections.map((section, index) => [`#section-${index + 1}`, section.title]));
    }

    // Add top tips section if exists
    if (content.topTips && content.topTips.length) {
        const topTipsTitle = translate('top_tips_for_visitings', { poi: poi.name, count: content.topTips.length });
        tocItems.push([`#top-tips-for-visiting`, topTipsTitle]);
    }

    // Add editorial content sections with URL and title
    if (content.editorialContent && content.editorialContent.length) {
        tocItems = tocItems.concat(
            content.editorialContent
                .filter(item => item.title) // Filter out items with null or empty title
                .map((item, index) => [`#editorial-${index + 1}`, item.title])
        );
    }

    // Add FAQ section if exists
    if (content.faq && content.faq.length) {
        const faqTitle = translate('faq');
        tocItems.push([`#faq`, faqTitle]);
    }

    // Add about/general info section if exists
    if (content.about) {
        const aboutTitle = translate('general_info');
        tocItems.push([`#about`, aboutTitle]);
    }

    return (
        inline ? (
            <StyledTOCContainerInline as="nav" $py={0} $pl={[15, 15, 15, 15, 0, 0]} $pr={[15, 15, 15, 15, 30, 30]} $mt={[15, 15, 15, 15, 0, 0]} width={[1, 1, 1, 1, '400px', '500px']}>
                <Line fontSize={16} fontWeight={900}>
                    <Text t={translate('table_of_contents')} />
                </Line >
                <StyledToCList>
                    {tocItems.map(([url, title], index) => (
                        <StyledToCItem key={index}>
                            <Link href={url}>
                                {title}
                            </Link>
                        </StyledToCItem>
                    ))}
                </StyledToCList>
            </StyledTOCContainerInline>           
        )
        : (
            <StyledTOCContainerBox as="nav" $p={20}>
                <Line lineHeight={1.79} fontSize={16} fontWeight={900}>
                  <Text t={translate('table_of_contents')} />
                </Line >
                <StyledToCList>
                {tocItems.map(([url, title], index) => (
                    <StyledToCItem key={index}>
                        <Link href={url}>
                            {title}
                        </Link>
                    </StyledToCItem>
                ))}
                </StyledToCList>
            </StyledTOCContainerBox>
        )
    );
};

export default TableOfContents;