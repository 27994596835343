// @flow strict
import * as React from 'react';
import * as R from 'ramda/src/index.js';
import { useLocation, useNavigate } from 'react-router-dom';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Box, Flex } from '../../../../primitives/Essentials/index.js';
import queryString from 'query-string/index.js';
import Cookies from 'js-cookie/src/js.cookie.js';
// $FlowFixMe
import addDays from 'date-fns/addDays/index.js';
// $FlowFixMe
import isSameDay from 'date-fns/isSameDay/index.js';

import type { Language } from '../../../../services/intl/context.js';
import CurrencyContext from '../../../../services/currency/context.js';
import ContentContext from '../../../../services/content/context.js';
import {
  container,
  HEADER,
  FOOTER,
  FOOTER_MOBILE,
  FOOTER_BUTTON,
} from '../../../../services/styleUtils/index.js';
import ToursContainer from '../../../../components/ToursContainer/index.js';
import mq from '../../../../services/mediaQuery/index.js';
// cross import, move to common components if it works
import PoiAvailability from '../../../../components/PoiAvailability/index.js';
import { formatDateYMD, parseDate } from '../../../../services/dateTimeUtils/index.js';
import { COOKIES } from '../../../../records/Cookies.js';
import type { Poi } from '../../../../records/Poi.js';
import type { Breadcrumb } from '../../../../components/Breadcrumbs/index.js';
import type { Section } from '../../../../records/PoiContent.js';

type Props = {
  poi: Poi,
  resultsFor: string,
  section: Section,
  language: Language,
  breadcrumbs: Breadcrumb[],
};

const StyledContainerBox = styled(Box)`
  ${container()};
  min-height: calc(100vh - ${HEADER} - ${FOOTER_MOBILE} - ${FOOTER_BUTTON} - 2px);
  ${mq.TABLET`
    min-height: calc(100vh - ${HEADER} - ${FOOTER} - ${FOOTER_BUTTON} - 2px);
  `};
  ${mq.DESKTOP`
    min-height: calc(100vh - ${HEADER} - ${FOOTER} - ${FOOTER_BUTTON} - 2px);
  `};
`;

const StyledBox = styled(Box)`
  margin-top: -2px;
  ${mq.TABLET`
    margin-top: 0px;
  `};
  ${mq.DESKTOP`
    max-width: 435px;
  `};
`;

const PoiCategoryContent = ({
  poi,
  resultsFor,
  section,
  language,
  breadcrumbs,
}: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [date, setDate] = React.useState<?Date>(null);

  React.useEffect(() => {
    let parsedDate;

    const queryParams = queryString.parse(location.search);
    if (queryParams.date) {
      parsedDate = parseDate(queryParams.date);
    } else if (Cookies.get(COOKIES.DATE)) {
      parsedDate = parseDate(Cookies.get(COOKIES.DATE));
    }

    if (parsedDate && !isNaN(parsedDate.getTime())) {
      setDate(parsedDate);
    }
  }, [location.search]);

  const handleChangeDate = (newDate: Date) => {
    const queryParams = queryString.parse(location.search);
    
    if (date && isSameDay(newDate, date)) {
      delete queryParams.date;
      navigate({ search: queryString.stringify(queryParams) });
      Cookies.remove(COOKIES.DATE);
    } else {
      queryParams.date = formatDateYMD(newDate);
      navigate({ search: queryString.stringify(queryParams) });
      Cookies.set(COOKIES.DATE, queryParams.date, {
        expires: addDays(newDate, 1),
      });
    }

    setDate((prevDate) =>
      (prevDate && !isSameDay(newDate, prevDate)) || !prevDate ? newDate : null,
    );
  };

  const poiId = Number(poi.objectID);

  return (
    <StyledContainerBox>
      <Flex $flexDirection={['column', null, null, null, null, 'row']}>
        <StyledBox
          $px={[0, null, null, null, 30]}
          $pt={[0, null, null, null, 14, 49]}
          width={[1, null, null, null, null, 5 / 12]}
        >
          <PoiAvailability date={date} onChangeDate={handleChangeDate} />
        </StyledBox>
        <Box width={1}>
          <CurrencyContext.Consumer>
            {({ currency }) => (
              <ContentContext.Consumer>
                {({ promocodes, currencies, providers }) => (
                  <ToursContainer
                    id={poiId}
                    language={language}
                    currency={currency}
                    filters={section.filters}
                    sectionId={section.id}
                    poi={poi}
                    type="poi"
                    resultsForString={resultsFor}
                    date={date}
                    breadcrumbs={breadcrumbs}
                    promocodes={promocodes}
                    providers={providers}
                    currencies={currencies}
                  />
                )}
              </ContentContext.Consumer>
            )}
          </CurrencyContext.Consumer>
        </Box>
      </Flex>
    </StyledContainerBox>
  );
};

export default PoiCategoryContent;