// @flow strict
import * as React from 'react';
import { withTheme } from 'styled-components';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Flex, Box } from '../../primitives/Essentials/index.js';
import { IconSearch } from '../Icons/index.js';
import IntlContext from '../../services/intl/context.js';
import type { SearchSuggestion } from '../../records/SearchSuggestion.js';
import type { Theme } from '../../records/Theme.js';
import type { Poi } from '../../records/Poi.js';
import type { Language } from '../../services/intl/context.js';
import type { Destination } from '../../records/Destination.js';
import SearchInput from '../SearchInput/index.js';
import ClientOnly from '../ClientOnly/index.js';
import mq from '../../services/mediaQuery/index.js';
import SvgSpinner from '../SvgSpinner/index.js';
import { getSuggestions, getHomePageSuggestions } from '../../services/api/search/index.js';
import {
  filterInDestinationSuggestions,
  filterInDestinationExtraSuggestions,
  filterHomePageSuggestions,
  filterHomePageExtraSuggestions,
} from '../../services/api/search/utils/index.js';
import Overlay from './components/Overlay/index.js';

const SearchOverlay = styled(Flex)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.21);
  ${mq.TABLET`
    position: relative;
    background-color: inherit;
  `};
`;

const SearchSuggestionsContainer = styled(Flex)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 1);

  ${mq.TABLET`
    top: 7px;
    max-width: 386px;
    width: 386px;
    height: ${({ searchSuggRows }) => `${60 * searchSuggRows}`}px;
    border-radius: 5px;
    box-shadow: 0 2.5px 4px 0 rgba(0, 0, 0, 0.5);
  `};
`;

const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 56px;
`;

const StyledRoot = styled(Box)`
  position: relative;
  .iWrapper {
    width: 386px;
    max-width: 386px;
  }
`;

const StyledSearchButton = styled(Flex)`
  cursor: pointer;
`;

type State = {
  searchVisible: boolean,
  searchText: string,
  cursor: number,
  searchSuggestions: SearchSuggestion[],
};

type Props = {
  theme: Theme,
  language: Language,
  className?: ?string,
  destinationId?: number,
  isMobile: boolean,
  isHomePage?: ?boolean,
  prepopulatedValue: string,
  withinSearchResults: boolean,
  contextDestinations: Destination[],
  onCancel?: () => void,
  openDestinationPage: (?number) => void,
  openPoiPage: Poi => void,
  buildFullTextSearchUrl: (string, ?number) => string,
};

class Search extends React.PureComponent<Props, State> {
  node: ?React.Ref<*>;

  searchWrapperNode: ?React.Ref<*>;

  inputWrapperNode: ?React.Ref<*>;

  static defaultProps = {
    destinationId: null,
    onCancel: null,
    isHomePage: false,
  };

  constructor(props: Props) {
    super(props);
    this.node = React.createRef();
    this.searchWrapperNode = React.createRef();
    this.inputWrapperNode = React.createRef();
    this.state = {
      searchVisible: false,
      searchText: props.prepopulatedValue,
      cursor: -1,
      searchSuggestions: [],
    };
  }

  componentDidUpdate = () => {
    const { isMobile } = this.props;
    const { searchVisible } = this.state;

    if (!isMobile) {
      if (searchVisible) {
        // $FlowFixMe
        if (this.node && this.node.current) {
          // cannot use prevent default in document attached touchstart/end listeners
          // $FlowFixMe
          this.node.current.ontouchend = this.handleClickOutside;
        }

        if (typeof window !== 'undefined') {
          document.addEventListener('mousedown', this.handleClickOutside, false);
        }
      } else {
        document.removeEventListener('mousedown', this.handleClickOutside, false);
      }
    }
  };

  populateSuggestions = (text: string = '') => {
    const { language, destinationId, contextDestinations } = this.props;

    if (destinationId) {
      const destinationTag = `d:${destinationId}`;
      const tagFilter = [destinationTag];
      getSuggestions(language, tagFilter, text)
        .then(result => {
          const { suggestions } = result;
          const inDestinationSuggestions = suggestions.filter(sugg =>
            filterInDestinationSuggestions(sugg, destinationTag),
          );

          const extraSuggestions = suggestions.filter(sugg =>
            filterInDestinationExtraSuggestions(sugg, language, destinationTag),
          );

          return inDestinationSuggestions.length > 0 ? inDestinationSuggestions : extraSuggestions;
        })
        .then(searchSuggestions => {
          this.setState({
            searchSuggestions,
          });
        });
    } else if (text === '') {
      // eslint-disable-next-line no-underscore-dangle
      const destinationsSuggestions = contextDestinations.slice(0, 6).map(dest => ({
        tag: `d:${dest.destination_id}`,
        name: dest.name,
        secondaryInformation: dest.country,
        urlName: dest.urlName,
        highlightResult: {},
      }));
      this.setState({
        searchSuggestions: destinationsSuggestions,
      });
    } else {
      getHomePageSuggestions(language, text)
        .then(result => {
          const { suggestions } = result;
          const destinationSuggestions = suggestions.filter(sugg =>
            filterHomePageSuggestions(sugg),
          );
          const worldwidePoiSuggestions = suggestions.filter(sugg =>
            filterHomePageExtraSuggestions(sugg),
          );

          return destinationSuggestions.length > 0
            ? destinationSuggestions
            : worldwidePoiSuggestions;
        })
        .then(searchSuggestions => {
          this.setState({
            searchSuggestions,
          });
        });
    }
  };

  handleChange = (value: string) => {
    this.setState({
      searchText: value,
      cursor: -1,
    });
  };

  // perform fulltext search with redirect
  handleSubmit = (url: string) => {
    this.setState(
      {
        searchVisible: false,
      },
      () => window.location.replace(url),
    );
  };

  handleCancel = () => {
    const { onCancel } = this.props;
    if (onCancel) {
      onCancel();
    }
    this.setState({
      searchVisible: false,
    });
  };

  handleClickOutside = (ev: MouseEvent | TouchEvent) => {
    const { searchVisible } = this.state;

    if (
      searchVisible &&
      // $FlowFixMe
      this.searchWrapperNode &&
      // $FlowFixMe
      this.searchWrapperNode.current &&
      // $FlowFixMe
      this.inputWrapperNode &&
      // $FlowFixMe
      this.inputWrapperNode.current &&
      // $FlowFixMe
      !this.searchWrapperNode.current.contains(ev.target) &&
      // $FlowFixMe
      !this.inputWrapperNode.current.contains(ev.target)
    ) {
      // click outside suggestions
      this.setState({
        searchVisible: false,
      });
      if (ev.type === 'touchend') {
        ev.preventDefault();
      }
    }
  };

  toggleSearch = () => {
    const { searchVisible } = this.state;
    this.setState({
      searchVisible: !searchVisible,
    });
  };

  displaySearch = () => {
    this.setState({
      searchVisible: true,
    });
  };

  handleKeyDown = (ev: KeyboardEvent) => {
    const { openDestinationPage, openPoiPage, destinationId, buildFullTextSearchUrl } = this.props;
    const { cursor, searchSuggestions, searchText } = this.state;

    const suggestionsLength = searchSuggestions.length - 1 + (searchText ? 1 : 0);

    if (ev.keyCode === 13 && cursor >= 0) {
      // enter
      ev.preventDefault();
      // this.handleChange(searchSuggestions[cursor].name);
      const suggestion = searchSuggestions[cursor];
      if (suggestion && suggestion.tag.startsWith('d:')) {
        openDestinationPage(Number(suggestion.tag.replace('d:', '')));
      } else if (suggestion && suggestion.tag.startsWith('p:')) {
        const poi: Poi = {
          objectID: suggestion.tag.replace('p:', ''),
          name: suggestion.name,
          urlName: suggestion.urlName,
          breadcrumbDestination: destinationId || 0,
          toursCount: 0, // not needed
          searchRankingScore: 0, // not needed
          displayPoiGroupId: 0, // not needed
          index: [],
          ratings: [],
        };
        openPoiPage(poi);
      } else if (cursor === suggestionsLength) {
        // full text result enter
        this.handleSubmit(buildFullTextSearchUrl(searchText, destinationId));
      }
      // arrow up/down button should select next/previous list element
    } else if (ev.keyCode === 38 && cursor > 0) {
      this.setState(prevState => ({
        cursor: prevState.cursor - 1,
      }));
    } else if (ev.keyCode === 40 && cursor < suggestionsLength) {
      this.setState(prevState => ({
        cursor: prevState.cursor + 1,
      }));
    }
  };

  handleCursorChange = (cur: number) => this.setState({ cursor: cur });

  render() {
    const { buildFullTextSearchUrl } = this.props;
    const { searchVisible, searchText, searchSuggestions } = this.state;
    const {
      theme,
      destinationId,
      withinSearchResults,
      isMobile,
      isHomePage,
      className,
    } = this.props;
    const { cursor } = this.state;

    const searchPlaceholderString = destinationId
      ? 'search_placeholder'
      : 'search_placeholder_no_dest';

    return (
      <IntlContext.Consumer>
        {({ translate }) =>
          isMobile ? (
            <StyledRoot className={className}>
              {isHomePage ? (
                <SearchInput
                  theme={theme}
                  placeholder={translate(searchPlaceholderString)}
                  prepopulatedValue={searchText}
                  onFocus={this.toggleSearch}
                  handleKeyDown={this.handleKeyDown}
                />
              ) : (
                <StyledSearchButton $ml={[6, 14]} onClick={this.toggleSearch}>
                  <IconSearch size={21.5} color={theme.secondary} />
                </StyledSearchButton>
              )}
              {(searchVisible || withinSearchResults) && (
                <Overlay
                  theme={theme}
                  searchText={searchText}
                  destinationId={destinationId}
                  onChange={this.handleChange}
                  onSubmit={value =>
                    this.handleSubmit(buildFullTextSearchUrl(value, destinationId))
                  }
                  onCancel={this.handleCancel}
                  isMobile={isMobile}
                  handleKeyDown={this.handleKeyDown}
                  cursor={cursor}
                  searchSuggestions={searchSuggestions}
                  populateSuggestions={this.populateSuggestions}
                  handleCursorChange={this.handleCursorChange}
                />
              )}
            </StyledRoot>
          ) : (
            <ClientOnly>
              <StyledRoot ref={this.node} className={className}>
                <>
                  <div className="iWrapper" ref={this.inputWrapperNode}>
                    <SearchInput
                      theme={theme}
                      placeholder={translate(searchPlaceholderString)}
                      prepopulatedValue={searchText}
                      onFocus={this.displaySearch}
                      handleKeyDown={this.handleKeyDown}
                    />
                  </div>
                  {searchVisible && (
                    <Overlay
                      theme={theme}
                      searchText={searchText}
                      onChange={this.handleChange}
                      onSubmit={value =>
                        this.handleSubmit(buildFullTextSearchUrl(value, destinationId))
                      }
                      placeholder={translate(searchPlaceholderString)}
                      destinationId={destinationId}
                      searchWrapperNode={this.searchWrapperNode}
                      onClickOutside={this.handleClickOutside}
                      isMobile={isMobile}
                      handleKeyDown={this.handleKeyDown}
                      cursor={cursor}
                      searchSuggestions={searchSuggestions}
                      populateSuggestions={this.populateSuggestions}
                      handleCursorChange={this.handleCursorChange}
                    />
                  )}
                </>
              </StyledRoot>
            </ClientOnly>
          )
        }
      </IntlContext.Consumer>
    );
  }
}

export default withTheme(Search);
