// @flow strict
import * as React from 'react';
import { Box } from '../../../../../../primitives/Essentials/index.js';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import type { SearchSuggestion } from '../../../../../../records/SearchSuggestion.js';
import PoiSuggestion from './components/PoiSuggestion/index.js';
import DestinationSuggestion from './components/DestinationSuggestion/index.js';

const StyledHitsContainer = styled(Box)`
  height: auto;
`;

type Props = {
  suggestions: SearchSuggestion[],
  destinationId?: number, // current destination id used as fallback
  cursor: number, // current destination id used as fallback
  handleCursorChange: number => void,
};

const SearchSuggestions = ({ suggestions, destinationId, cursor, handleCursorChange }: Props) => (
  <StyledHitsContainer>
    <>
      {suggestions.map((hit, i) => {
        if (hit.tag.startsWith('d:')) {
          return (
            <DestinationSuggestion
              key={hit.tag}
              name={hit.name}
              tag={hit.tag}
              country={hit.secondaryInformation}
              selected={cursor === i}
              onHover={() => handleCursorChange(i)}
            />
          );
        }
        if (hit.tag.startsWith('p:')) {
          const poiDestinationTag = hit.tags && hit.tags.find(tag => tag.startsWith('d:'));
          return (
            <PoiSuggestion
              key={hit.tag}
              name={hit.name}
              tag={hit.tag}
              urlName={hit.urlName}
              destinationId={poiDestinationTag ? Number(poiDestinationTag.slice(2)) : destinationId}
              destinationName={hit.secondaryInformation}
              selected={cursor === i}
              onHover={() => handleCursorChange(i)}
            />
          );
        }
        return null;
      })}
    </>
  </StyledHitsContainer>
);

export default SearchSuggestions;
