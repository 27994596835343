// @flow strict
import React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Flex, Box } from '../../../../primitives/Essentials/index.js';
import mq from '../../../../services/mediaQuery/index.js';
import SIZES from '../../../../consts/sizes.js';
import Text from '../../../Text/index.js';
import { container } from '../../../../services/styleUtils/index.js';
import Search from '../../../Search/index.js';
import IntlContext from '../../../../services/intl/context.js';
import WindowContext from '../../../../services/window/context.js';
import DestinationContext from '../../../../services/destination/context.js';
import UrlContext from '../../../../services/url/context.js';

const StyledContainerFlex = styled(Flex)`
  position: relative;
  max-width: 330px;
  ${mq.TABLET`
    min-width: 400px;
  `}
`;

const StyledTitle = styled.h1`
  z-index: 1;
  margin: 0 0 15.5px;
  color: rgba(255, 255, 255, 0.8);
  font-weight: 400;
  font-size: 20px;
  line-height: 1.25;
  letter-spacing: 0.43px;

  ${mq.MIDDLE_MOBILE`
    font-size: 22px;
  `};

  ${mq.TABLET`
    font-size: 25px;
  `}

  strong {
    color: ${({ theme }) => theme.tertiary};
  }
`;

const StyledRoot = styled(Box)`
  position: relative;
  height: 200px;
  background-color: ${({ theme }) => theme.secondary};

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: inset 0 7px 9px -7px rgba(0, 0, 0, 0.32);
    content: '';
  }
  ${mq.TABLET`
    height: 250px;
  `}
`;

const StyledImg = styled.img`
  display: none;
  ${mq.TABLET`
    display: initial;
  `};
  ${mq.BIG_TABLET`
    width: 499px;
    height: 202px;
  `};
  ${mq.DESKTOP`
    width: 659px;
    height: 228px;
  `};
`;

const StyledFlex = styled(Flex)`
  ${container()};
  height: 100%;
`;

const ContainerImageFlex = styled(Flex)`
  ${mq.TABLET`
    width: 100%;
  `}
`;

const StyledSearch = styled(Search)`
  z-index: 1;
`;

const StyledLoaderDiv = styled.div`
  height: 40px;
`;

type Props = {
  text: string,
  destinationId?: ?number,
  prepopulatedSearchValue?: ?string,
};

const Cover = ({ text, destinationId, prepopulatedSearchValue }: Props) => {
  const { openDestinationPage, openPoiPage, buildFullTextSearchUrl } = React.useContext(UrlContext);
  const { language } = React.useContext(IntlContext);
  const { destinations } = React.useContext(DestinationContext);
  const { isMobile } = React.useContext(WindowContext);
  return (
    <StyledRoot $pt={30} $pb={15.5}>
      <StyledFlex $px={[15.5, null, null, null, 30]} $justifyContent="space-between">
        <StyledContainerFlex $flexDirection="column" $justifyContent="flex-end">
          <StyledTitle>
            <Text t={text} html />
          </StyledTitle>
          <StyledLoaderDiv>
            <StyledSearch
              isMobile={isMobile}
              isHomePage
              language={language}
              destinationId={destinationId}
              prepopulatedValue={prepopulatedSearchValue}
              contextDestinations={destinations[language]}
              openDestinationPage={openDestinationPage}
              openPoiPage={openPoiPage}
              buildFullTextSearchUrl={buildFullTextSearchUrl}
            />
          </StyledLoaderDiv>
        </StyledContainerFlex>
        <ContainerImageFlex
          $pl={30}
          $flexDirection="column"
          $justifyContent="flex-end"
          $alignItems="center"
        >
          <picture>
            <source
              type="image/jpeg"
              media={`(min-width: ${SIZES.DESKTOP}px)`}
              srcSet="/images/mrtickets/ticketlens-mrtickets-bigger.jpg 1x, /images/mrtickets/ticketlens-mrtickets-bigger@2x.jpg 2x"
            />
            <source
              type="image/jpeg"
              media={`(min-width: ${SIZES.BIG_TABLET}px)`}
              srcSet="/images/mrtickets/ticketlens-mrtickets-medium.jpg 1x, /images/mrtickets/ticketlens-mrtickets-medium@2x.jpg 2x"
            />
            <StyledImg
              src="/images/mrtickets/ticketlens-mrtickets.jpg"
              srcSet="/images/mrtickets/ticketlens-mrtickets@2x.jpg 2x"
              alt="Ticketlens Mr Tickets"
            />
          </picture>
        </ContainerImageFlex>
      </StyledFlex>
    </StyledRoot>
  );
};

export default Cover;
