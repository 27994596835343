// @flow strict
import * as React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;

const StyledDiv = styled.div`
  width: 100%;
  height: 1px;
  margin: 10px 0;
  background-color: ${({ theme }) => theme.tertiary};
  opacity: 0.6;
`;

const DividerLine = () => <StyledDiv $my={10} />;

export default DividerLine;
