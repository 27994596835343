// @flow strict
import * as React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Flex } from '../../primitives/Essentials/index.js';
import { SECTION_LAYOUT } from '../../consts/layout.js';
import type { TopList as TopListType } from '../../records/DestinationContent.js';
import Line from '../../primitives/Line/index.js';
import ListElements from '../ListElements/index.js';

type Props = {
  topList: TopListType,
  simpleLinkStyle: boolean,
};

const List = styled(Flex)`
  text-align: center;
`;

const Title = styled.h2`
  margin-block-start: 0;
  margin-block-end: 0;
  font-weight: 900;
  font-size: 24px;
  line-height: 1.35;
`;

const AbstractLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  max-width: 700px;
  margin-top: 8px;
  color: ${({ theme }) => theme.trip.abstract};
`;

const TopList = ({ topList, simpleLinkStyle }: Props) => (
  <Flex $px={SECTION_LAYOUT.$px} $mt={38} $alignItems="center" $flexDirection="column" id={`${topList.title.replace(/\s+/g, '-').toLowerCase()}`}>
    <List key={topList.title} $flexDirection="column" $alignItems="center">
      {topList.title && <Title>{topList.title}</Title>}
      {topList.abstract && (
        <AbstractLine fontSize={20} lineHeight={1.35}>
          {topList.abstract}
        </AbstractLine>
      )}
      {topList.listElements && (
        <ListElements listElements={topList.listElements} simpleLinkStyle={simpleLinkStyle} />
      )}
    </List>
  </Flex>
);

TopList.defaultProps = {
  simpleLinkStyle: false,
};

export default TopList;
