// @flow strict
import React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Box } from '../../../../../../primitives/Essentials/index.js';
import Line from '../../../../../../primitives/Line/index.js';
import Text from '../../../../../../components/Text/index.js';
import Link from '../../../../../../components/Link/index.js';

const SubsectionTitle = styled.h3`
  margin: 0;
  font-weight: 900;
  font-size: 19px;
  line-height: 1.45;
  letter-spacing: -0.04px;
`;

const StyledLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  margin-bottom: 20px;
  color: ${({ theme }) => theme.trip.abstract};
  a {
    color: inherit;
    text-decoration: underline;
    cursor: auto;
  }
`;

type Props = {
  title: string,
  content: string,
};

const AboutSection = ({ title, content }: Props) => (
  <div>
    <Box $mb={8}>
      <SubsectionTitle>{title}</SubsectionTitle>
    </Box>
    <StyledLine lineHeight={1.38} fontSize={16} letterSpacing={-0.04}>
      <Text t={content} html />
    </StyledLine>
  </div>
);

const AboutSectionOfficialUrl = ({ content }: Props) => (
  <div>
    <Box $mb={8}>
      <SubsectionTitle><Text t={"website_label"}/></SubsectionTitle>
    </Box>
    <StyledLine lineHeight={1.38} fontSize={16} letterSpacing={-0.04}>
      <Text t={"official_website"} /><Text t={": "} /><Link href={content} target="_blank" rel="noopener noreferrer">{content}</Link>
    </StyledLine>
  </div>
);

export { AboutSection, AboutSectionOfficialUrl };
