// @flow strict
import * as React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Box, Flex } from '../../../../../../../../primitives/Essentials/index.js';
import type { Tour } from '../../../../../../../../records/Tour.js';
import type { Promocode } from '../../../../../../../../records/Promocodes.js';
import OfferPrices from './components/OfferPrices/index.js';
import OfferContent from './components/OfferContent/index.js';
import Link from '../../../../../../../../components/Link/index.js';
import UrlContext from '../../../../../../../../services/url/context.js';
import IntlContext from '../../../../../../../../services/intl/context.js';
import CurrencyContext from '../../../../../../../../services/currency/context.js';
import ContentContext from '../../../../../../../../services/content/context.js';
import WindowContext from '../../../../../../../../services/window/context.js';
import mq from '../../../../../../../../services/mediaQuery/index.js';
import Line from '../../../../../../../../primitives/Line/index.js';
import Text from '../../../../../../../../components/Text/index.js';

type Props = {
  tour: Tour,
  promocode: ?Promocode,
  date?: ?string,
  bestseller: boolean,
};

const StyledContainerBox = styled(Box)`
  position: relative;
  background-color: ${({ theme, $bestseller }) =>
    $bestseller ? 'rgba(255, 179, 0, 0.1)' : theme.trip.backgroundLight};
  ${({ $bestseller, theme }) => ($bestseller ? `border: 2px solid ${theme.trip.rating};` : '')}
  ${mq.DESKTOP`
    &:hover {
      background-color: ${({ theme, $bestseller }) =>
        $bestseller ? 'rgba(255, 179, 0, 0.2)' : theme.trip.backgroundDark};
      .provider {
        text-decoration: underline;
      }
    }
  `};
`;

const StyledLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
`;

const UsePromoCodeBox = styled(Box)`
  margin-right: -8px;
  margin-left: -8px;
  background-color: ${({ theme }) => theme.tertiary};
  border-radius: 5px;
  ${mq.TABLET`
    display: none;
  `};
`;

const StyledLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  max-width: 70%;
  color: ${({ theme }) => theme.trip.price};
`;

const StyledButtonBox = styled(Box)`
  position: relative;
  height: 24px;
  color: ${({ theme }) => theme.tertiary};
  font-weight: 900;
  font-size: 12px;
  white-space: nowrap;
  background-color: ${({ theme }) => theme.trip.price};
  border-radius: 20px;
`;

const StyledFlex = styled(Flex)`
  height: 100%;
`;

const BestsellerWrapper = styled(Box)`
  position: absolute;
  right: 50%;
  bottom: -210px;
  left: 50%;
  z-index: 2;
  width: 300px;
  height: 150px;
  padding: 9px;
  transform: translate(-50%, -50%);
  ${mq.TABLET`
  right: calc(50% + 115px);
  left: calc(50% - 115px);
  `}
`;

const BestsellerInfo = styled.div`
  position: absolute;
  right: 50%;
  bottom: 109px;
  left: 50%;
  z-index: 2;
  width: fit-content;
  padding: 5px;
  color: #fff;
  font-weight: 900;
  font-size: 14px;
  letter-spacing: 0.69px;
  background-color: ${({ theme }) => theme.trip.rating};
  border-radius: 5px;
  transform: translate(-50%, -50%);
  cursor: default;
`;

const CloseButton = styled.img`
  position: absolute;
  top: 13px;
  right: 11px;
  width: 10px;
  height: 10px;
  cursor: pointer;
  ${mq.DESKTOP`
    display: none;
  `};
`;

const BestsellerInfoExpanded = styled(Flex)`
  position: absolute;
  right: 50%;
  bottom: -45px;
  left: 50%;
  z-index: 2;
  width: 300px;
  padding: 9px;
  color: #fff;
  font-size: 14px;
  letter-spacing: 0.69px;
  background-color: ${({ theme }) => theme.trip.rating};
  border-radius: 2.5px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.34);
  transform: translate(-50%, -50%);
  opacity: 0.95;
  ul {
    margin-block-start: 4px;
    margin-block-end: 0;
    padding-inline-start: 25px;
  }
  &::before {
    position: absolute;
    top: -3px;
    right: 50%;
    left: 50%;
    width: 0;
    height: 0;
    border-right: 10px solid transparent;
    border-bottom: 10px solid ${({ theme }) => theme.trip.rating};
    border-left: 10px solid transparent;
    transform: translate(-50%, -50%);
    content: '';
  }
`;

const Offer = ({ tour, date, promocode, bestseller }: Props) => {
  const { buildTourPageUrl } = React.useContext(UrlContext);
  const { language } = React.useContext(IntlContext);
  const { currency } = React.useContext(CurrencyContext);
  const { providers } = React.useContext(ContentContext);
  const { isMobile, isTablet } = React.useContext(WindowContext);
  const [showBestseller, setShowBestseller] = React.useState(false);
  const isMobileOrTablet = isMobile || isTablet;
  const isNotMobileOrTablet = !isMobile && !isTablet;

  return (
    <StyledContainerBox $mb={1.5} $pt={13.5} $px={15} $pb={12} $bestseller={bestseller}>
      <StyledLink
        href={buildTourPageUrl(tour.id)}
        target="_blank"
        rel="noopener noreferrer nofollow"
        onClick={
          promocode
            ? () => {
                const tempInput = document.createElement('input');
                // $FlowFixMe
                tempInput.style = 'position: absolute; left: -1000px; top: -1000px';
                tempInput.value = promocode.code;
                tempInput.setAttribute('contenteditable', 'true');
                tempInput.setAttribute('readonly', '');
                // $FlowFixMe
                document.body.appendChild(tempInput);
                if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
                  // save current contentEditable/readOnly status
                  const editable = tempInput.contentEditable;
                  const { readOnly } = tempInput;
                  // create a selectable range
                  const range = document.createRange();
                  range.selectNodeContents(tempInput);
                  // select the range
                  const sel = window.getSelection();
                  sel.removeAllRanges();
                  sel.addRange(range);
                  tempInput.setSelectionRange(0, 999999);
                  // restore contentEditable/readOnly to original state
                  tempInput.contentEditable = editable;
                  tempInput.readOnly = readOnly;
                } else {
                  tempInput.select();
                }
                document.execCommand('copy');
                // $FlowFixMe
                document.body.removeChild(tempInput);
                window.open(buildTourPageUrl(tour.id), '_blank');
              }
            : undefined
        }
      />
      <Flex $justifyContent="space-between" $alignItems="center" $alignContent="center">
        <OfferContent tour={tour} language={language} date={date} providers={providers} />
        <OfferPrices tour={tour} currency={currency} promocode={promocode} providers={providers} />
        {bestseller && (
          <BestsellerWrapper onMouseLeave={() => setShowBestseller(false)}>
            <BestsellerInfo
              onMouseEnter={isNotMobileOrTablet ? () => setShowBestseller(true) : undefined}
              onClick={isMobileOrTablet ? () => setShowBestseller(!showBestseller) : undefined}
            >
              ★&nbsp;BESTSELLER
            </BestsellerInfo>
            {showBestseller && (
              <BestsellerInfoExpanded
                $alignItems="center"
                onMouseLeave={() => setShowBestseller(false)}
              >
                <CloseButton
                  src="/icons/close.svg"
                  alt="close"
                  onClick={() => setShowBestseller(false)}
                />
                <Text t="bestseller_info" html />
              </BestsellerInfoExpanded>
            )}
          </BestsellerWrapper>
        )}
      </Flex>
      {promocode && (
        <UsePromoCodeBox p={8} $mt={15}>
          <Flex $justifyContent="space-between" $alignItems="center">
            <StyledLine size={14} letterSpacing={0.3}>
              <Text
                t="use_promo_code"
                html
                values={{
                  discount: promocode.discountPercent,
                  code: promocode.code,
                  provider: providers[tour.providerId].name,
                }}
              />
            </StyledLine>
            <Flex $alignItems="center" $flexDirection={['column', null, null, null, 'row']}>
              <StyledButtonBox $px={[6, null, null, null, 60]} $py={2}>
                <StyledFlex
                  $flexDirection="column"
                  $justifyContent="center"
                  $alignItems="center"
                  $px={6}
                >
                  <Text t="view_on_site" />
                </StyledFlex>
              </StyledButtonBox>
            </Flex>
          </Flex>
        </UsePromoCodeBox>
      )}
    </StyledContainerBox>
  );
};

export default Offer;
