// @flow strict
import Cookies from 'js-cookie';
import { COOKIES } from '../../records/Cookies.js';
import type { Currencies } from '../../records/Currencies.js';
import type { Currency } from './context.js';
import type { Language } from '../intl/context.js';

export default function getInitialCurrency(
  lang: Language,
  currencies: Currencies,
  countryCode?: ?string,
): Currency {
  const currencyFromCookie: ?string = Cookies.get(COOKIES.CURRENCY);
  if (currencyFromCookie && Object.keys(currencies).some(x => x === currencyFromCookie)) {
    // $FlowFixMe
    return currencyFromCookie;
  }
  if (countryCode) {
    const currenciesWithCodes = Object.keys(currencies).map(x => ({ ...currencies[x], code: x }));
    // according to cloudfront docs value should be https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
    const currency = currenciesWithCodes.find(cur => cur.defaultCountries.includes(countryCode));
    if (currency) {
      return currency.code;
    }
  }
  return lang === 'de' ? 'eur' : 'usd';
}
