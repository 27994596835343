// @flow strict
import React from 'react';
import { Flex, Box } from '../../../../../../../../../../primitives/Essentials/index.js';
import Button from '../../../../../../../../../../components/Button/index.js';
import Text from '../../../../../../../../../../components/Text/index.js';
import type { Section as SectionType } from '../../../../../../../../../../records/PoiContent.js';
import type { GroupTour } from '../../../../../../../../../../records/Tour.js';
import SectionHeader from '../../../../../../../../../../components/SectionHeader/index.js';
import type { Currency } from '../../../../../../../../../../services/currency/context.js';
import UrlContext from '../../../../../../../../../../services/url/context.js';
import Tours from '../../../../../../../../../../components/Tours/index.js';
import { SECTION_LAYOUT_NO_PADDING_MOBILE } from '../../../../../../../../../../consts/layout.js';

type Props = {
  section: SectionType,
  tours: GroupTour[],
  currency: Currency,
  showAvailabilities: boolean,
  date: ?Date,
};

const Section = ({ section, tours, currency, showAvailabilities, date }: Props) => (
  <Box {...SECTION_LAYOUT_NO_PADDING_MOBILE}>
    <Flex $flexDirection="column">
      <SectionHeader title={section.title} subtitle={section.abstract || ''} />
      <Tours
        currency={currency}
        tours={tours}
        showAvailabilities={showAvailabilities}
        date={date}
      />
      {tours.length > 4 && (
        <UrlContext.Consumer>
          {({ openDestinationSubCategoryPage }) => (
            <Button useDiv onClick={() => openDestinationSubCategoryPage(section.id)}>
              <Text t="see_all" values={{ item: section.title }} />
            </Button>
          )}
        </UrlContext.Consumer>
      )}
    </Flex>
  </Box>
);

export default Section;
