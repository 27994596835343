// @flow strict
import React from 'react';
import { Flex } from '../../../../primitives/Essentials/index.js';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import type { Language } from '../../../../services/intl/context.js';

import SelectButton from '../../../SelectButton/index.js';
import ClientOnly from '../../../ClientOnly/index.js';
import mq from '../../../../services/mediaQuery/index.js';
import CurrencySelect from '../../../CurrencySelect/index.js';
import CurrencyContext from '../../../../services/currency/context.js';
import LanguageSelect from '../../../LanguageSelect/index.js';

const StyledLanguageSelect = styled(SelectButton)`
  width: 45px;
  margin-left: 6px;
  ${mq.SMALL_MOBILE`
    margin-left: 14px;
  `};
`;

const StyledCurrencySelect = styled(SelectButton)`
  width: 58px;
`;

type Props = {
  language: Language,
};

const Options = ({ language }: Props) => {
  const [currencyOpen, setCurrencyOpen] = React.useState(false);
  const [languageOpen, setLanguageOpen] = React.useState(false);

  return (
    <Flex>
      <ClientOnly>
        <>
          {currencyOpen && (
            <CurrencySelect
              language={language}
              onCancel={() => setCurrencyOpen(false)}
              bottom={false}
            />
          )}
          <CurrencyContext.Consumer>
            {({ currency }) => (
              <StyledCurrencySelect
                id="currencySelect"
                value={currency.toUpperCase()}
                onChange={() => setCurrencyOpen(true)}
              />
            )}
          </CurrencyContext.Consumer>
          {languageOpen && (
            <LanguageSelect
              language={language}
              onCancel={() => setLanguageOpen(false)}
              bottom={false}
            />
          )}
          <StyledLanguageSelect
            id="languageSelect"
            value={language.toUpperCase()}
            onChange={() => setLanguageOpen(true)}
          />
        </>
      </ClientOnly>
    </Flex>
  );
};

export default Options;
