// @flow strict
import React from 'react';
import { Box } from '../../../../../../../../primitives/Essentials/index.js';
import type { Currency } from '../../../../../../../../services/currency/context.js';
import type { GroupToursBySection } from '../../../../../../../../records/Tour.js';
import type { Section as SectionType } from '../../../../../../../../records/PoiContent.js';
import Section from './components/Section/index.js';

type Props = {
  shownTours: GroupToursBySection,
  sections: SectionType[],
  currency: Currency,
  date: ?Date,
};

const CategorySections = ({ sections, shownTours, currency, date }: Props) => (
  <Box>
    {sections
      .filter(section => shownTours[String(section.id)] && shownTours[String(section.id)].length)
      .map(section => (
        <Section
          key={section.id}
          section={section}
          tours={shownTours[String(section.id)]}
          currency={currency}
          showAvailabilities
          date={date}
        />
      ))}
  </Box>
);

export default CategorySections;
