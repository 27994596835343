// @flow strict
import * as React from 'react';
import { withTheme } from 'styled-components';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Box, Flex } from '../../primitives/Essentials/index.js';
// $FlowFixMe
import addMonths from 'date-fns/addMonths/index.js';
import { getDisabledDates } from '../../services/dateTimeUtils/index.js';
import Text from '../Text/index.js';
import Line from '../../primitives/Line/index.js';
import { SECTION_LAYOUT_NO_PADDING_MOBILE } from '../../consts/layout.js';
import DatePicker from '../DatePicker/index.js';
import mq from '../../services/mediaQuery/index.js';
import IntlContext from '../../services/intl/context.js';
import WindowContext from '../../services/window/context.js';
import type { Theme } from '../../records/Theme.js';

type Props = {
  theme: Theme,
  date: ?Date,
  onChangeDate: (date: Date) => void,
};

const StyledLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  color: ${({ theme }) => theme.tertiary};
`;

const StyledFlex = styled(Flex)`
  background-color: ${({ theme }) => theme.secondary};
  ${mq.TABLET`
    border-radius: 5px;
  `};
`;

// eslint-disable-next-line
const PoiAvailability = ({ theme, date, onChangeDate }: Props) => {
  const today = new Date();
  const minDate = today;
  const maxDate = addMonths(minDate, 2);

  const disabledDates = getDisabledDates(minDate, maxDate);

  return (
    <Box {...SECTION_LAYOUT_NO_PADDING_MOBILE} $px={0} $mt={0} $py={1.5}>
      <StyledFlex $flexDirection="column" $px={15} $py={15}>
        <StyledLine letterSpacing={0.4} fontSize={19} fontWeight={900}>
          <Text t="check_availability_long" />
        </StyledLine>
        <Box>
          <WindowContext.Consumer>
            {({ isMobile }) => (
              <IntlContext.Consumer>
                {({ language, translate }) => (
                  <DatePicker
                    date={date}
                    onChangeDate={onChangeDate}
                    language={language}
                    minDate={minDate}
                    maxDate={maxDate}
                    disabledDates={disabledDates}
                    placeholder={translate('select_date')}
                    theme={theme}
                    isMobile={isMobile}
                  />
                )}
              </IntlContext.Consumer>
            )}
          </WindowContext.Consumer>
        </Box>
      </StyledFlex>
    </Box>
  );
};

export default withTheme(PoiAvailability);
