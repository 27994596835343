// @flow strict
import * as React from 'react';
import DestinationContext from '../../services/destination/context.js';
import type { DestinationContextType } from '../../services/destination/context.js';
import WithLiveConfig from './components/WithLiveConfig.js';

type Props = { match: Match, location?: Location };
type EnhancedProps = Props & DestinationContextType;

// Reusable useEffect makes more sense now
const withDestination = (
  Component: React.ComponentType<EnhancedProps>,
): React.ComponentType<EnhancedProps> => (props: Props) => (
  <DestinationContext.Consumer>
    {(destinationProps: DestinationContextType) => {
      const { destinationId, match } = props;

      if (destinationId)
      {
        const firstLocaleCode = Object.keys(destinationProps.configs)[0];
        if (
          (!destinationProps.configs[firstLocaleCode].Tags.length || !destinationProps.configs[firstLocaleCode].Categories.length)
        ) {
          return (
            <WithLiveConfig id={destinationId}>
              <Component {...props} {...destinationProps} />
            </WithLiveConfig>
          );
        }
      }
      return <Component {...props} {...destinationProps} />;
    }}
  </DestinationContext.Consumer>
);

export default withDestination;
