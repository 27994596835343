// @flow strict
import React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Flex, Box } from '../../../../../../../../primitives/Essentials/index.js';
import Price from '../../../../../../../Price/index.js';
import Line from '../../../../../../../../primitives/Line/index.js';
import Text from '../../../../../../../Text/index.js';
import type { Theme } from '../../../../../../../../records/Theme.js';
import type { GroupTour } from '../../../../../../../../records/Tour.js';
import type { Currency } from '../../../../../../../../services/currency/context.js';
import mq from '../../../../../../../../services/mediaQuery/index.js';
import type { Translate } from '../../../../../../../../services/intl/translate.js';
import ClientOnly from '../../../../../../../ClientOnly/index.js';
import Link from '../../../../../../../Link/index.js';
import UrlContext from '../../../../../../../../services/url/context.js';

const StyledContainerBox = styled(Box)`
  display: none;
  ${mq.TABLET`
    border-left: solid 1px ${({ theme }) => theme.trip.divider};
    display: block;
  `};
`;

const StyledPriceFlex = styled(Flex)`
  height: calc(100% - 24px);
`;

const StyledButtonBox = styled(Box)`
  z-index: ${({ $aggressiveRedirectToPartner }) => ($aggressiveRedirectToPartner ? 2 : 0)};
  min-height: 30px;
  padding: 6px 12px 8px 12px;
  color: ${({ theme }) => theme.tertiary};
  font-size: 14px;
  white-space: nowrap;
  text-align: center;
  background-color: ${({ theme }) => theme.trip.price};
  border-radius: 5px;
  cursor: pointer;
`;

const PriceWrapper = styled.div`
  height: 38px;
`;

// wrong height
const StyledFlex = styled(Flex)`
  height: 175px;
`;

const Discount = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px 4px;
`;

const DiscountLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  color: ${({ theme }) => theme.tertiary};
  transform: rotate(45deg);
`;

const Triangle = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  width: 67px;
  height: 67px;
`;

const OfferLink = styled(Link)`
  &:hover {
    text-decoration: underline;
  }
`;

type Props = {
  groupTour: GroupTour,
  theme: Theme,
  currency: Currency,
  translate: Translate,
  aggressiveRedirectToPartner: boolean,
};

const getDiscount = (groupTour, currency, hasBestseller) =>
  Number(
    (
      100 -
      (hasBestseller
        ? groupTour[`bestsellerPrice_${currency}`]
        : groupTour[`lowestPrice_${currency}`]) /
        (groupTour[`highestPrice_${currency}`] / 100)
    ).toFixed(2),
  );

const TourContentPricesTablet = ({
  groupTour,
  theme,
  currency,
  translate,
  aggressiveRedirectToPartner,
}: Props) => {
  const { openTourPage } = React.useContext(UrlContext);
  const hasBestseller = aggressiveRedirectToPartner && Boolean(groupTour.bestsellerTourId);
  const showHighestPrice =
    groupTour.offersCount > 1 &&
    (hasBestseller
      ? groupTour[`highestPrice_${currency}`] > groupTour[`bestsellerPrice_${currency}`]
      : groupTour[`highestPrice_${currency}`] > groupTour[`lowestPrice_${currency}`]);
  const discount = getDiscount(groupTour, currency, hasBestseller);
  return (
    <StyledContainerBox
      $pt={[9, null, null, null, 12]}
      $pb={[9, null, null, null, 12]}
      $pl={6}
      $pr={6}
      width={[0, null, null, null, 4 / 12]}
    >
      <StyledFlex $justifyContent="center">
        <ClientOnly>
          {discount > 5 && (
            <Discount>
              <Triangle src="/images/triangle.svg" alt="triangle" />
              <DiscountLine fontSize={17} fontWeight={900}>
                -{discount.toFixed(0)}%
              </DiscountLine>
            </Discount>
          )}
        </ClientOnly>
        <StyledPriceFlex $flexDirection="column" $alignItems="center" $justifyContent="space-between">
          <PriceWrapper>
            <ClientOnly>
              {showHighestPrice && (
                <Flex $flexDirection="column" $alignItems="center">
                  <Line $lineThrough color={theme.secondary} fontSize={19} fontWeight={900}>
                    <Price value={groupTour[`highestPrice_${currency}`]} />
                  </Line>
                  <Line
                    letterSpacing={0.4}
                    color={theme.trip.offers}
                    fontSize={12}
                    fontWeight={400}
                  >
                    <Text
                      t="available_on"
                      values={{
                        place:
                          groupTour[`highestPriceProvider_${currency}`] === 'on_official_website'
                            ? translate(groupTour[`highestPriceProvider_${currency}`])
                            : groupTour[`highestPriceProvider_${currency}`],
                      }}
                    />
                  </Line>
                </Flex>
              )}
            </ClientOnly>
          </PriceWrapper>
          <ClientOnly>
            <Flex $flexDirection="column" $alignItems="center">
              <Line letterSpacing={0.5} color={theme.primary} fontSize={14} fontWeight={900}>
                {/* eslint-disable-next-line no-nested-ternary */}
                {aggressiveRedirectToPartner ? (
                  <Text t="best_offer" />
                ) : groupTour.offersCount === 1 ? (
                  <Text t="price_from" />
                ) : (
                  <Text t="best_price" />
                )}
              </Line>
              <Line color={theme.trip.price} fontSize={22} fontWeight={900}>
                <Price
                  value={
                    hasBestseller
                      ? groupTour[`bestsellerPrice_${currency}`]
                      : groupTour[`lowestPrice_${currency}`]
                  }
                />
              </Line>
              <Line letterSpacing={0.4} color={theme.trip.offers} fontSize={12} fontWeight={400}>
                <Text
                  t="available_on"
                  values={{
                    place: hasBestseller
                      ? groupTour[`bestsellerProvider_${currency}`]
                      : groupTour[`lowestPriceProvider_${currency}`],
                  }}
                />
              </Line>
            </Flex>
          </ClientOnly>
          <StyledButtonBox
            $mt={8}
            className={aggressiveRedirectToPartner ? 'title' : undefined}
            $aggressiveRedirectToPartner={aggressiveRedirectToPartner}
          >
            {/* eslint-disable-next-line no-nested-ternary */}
            {aggressiveRedirectToPartner ? (
              <OfferLink
                useDiv
                onClick={() =>
                  openTourPage(
                    hasBestseller
                      ? groupTour.bestsellerTourId
                      : groupTour[`lowestPriceTourId_${currency}`],
                  )
                }
              >
                <Text t="go_to_offer" />
              </OfferLink>
            ) : groupTour.offersCount === 1 ? (
              <Text t="view_on_site" />
            ) : (
              <Text t="view_offers" />
            )}
          </StyledButtonBox>
        </StyledPriceFlex>
      </StyledFlex>
    </StyledContainerBox>
  );
};

export default TourContentPricesTablet;
