// @flow strict
import React, { useContext } from 'react';
import { withTheme } from 'styled-components';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Box, Flex } from '../../../../../../primitives/Essentials/index.js';
import mq from '../../../../../../services/mediaQuery/index.js';
import WindowContext from '../../../../../../services/window/context.js';
import ContentContext from '../../../../../../services/content/context.js';
import CurrencyContext from '../../../../../../services/currency/context.js';
import UrlContext from '../../../../../../services/url/context.js';
import IntlContext from '../../../../../../services/intl/context.js';
import type { GroupTour } from '../../../../../../records/Tour.js';
import type { Theme } from '../../../../../../records/Theme.js';
import TourContentHeader from './components/TourContentHeader/index.js';
import TourContentPricesMobile from './components/TourContentPricesMobile/index.js';
import TourContentPricesTablet from './components/TourContentPricesTablet/index.js';
import Link from '../../../../../Link/index.js';
import Text from '../../../../../Text/index.js';
import Line from '../../../../../../primitives/Line/index.js';
import ClientOnly from '../../../../../ClientOnly/index.js';

const StyledFlex = styled(Flex)`
  position: relative;
  justify-content: space-between;
  height: 102px;

  ${({ $secondary }) =>
    !$secondary &&
    mq.TABLET`
    height: 151px;
  `};
`;

const StyledOffersAndPrices = styled(Flex)`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
`;

const AllOffersLink = styled(Link)`
  position: relative;
  z-index: 2;
  &:hover {
    text-decoration: underline;
  }
`;

type Props = {
  tour: GroupTour, // tour we show data for
  theme: Theme,
  showAvailabilities: boolean,
  secondary: boolean,
  aggressiveRedirectToPartner: boolean,
  showAvailableOnDateText: boolean,
  date: ?Date,
};

const TourContent = ({
  tour,
  theme,
  showAvailabilities,
  secondary,
  aggressiveRedirectToPartner,
  showAvailableOnDateText,
  date,
}: Props) => {
  const { openGroupPageUrl } = React.useContext(UrlContext);
  const { language, translate } = useContext(IntlContext);
  const { isMobile } = useContext(WindowContext);
  const { providers } = useContext(ContentContext);
  const { currency } = useContext(CurrencyContext);

  const paddings = [9, null, null, null, !secondary ? 12 : null];

  const providerName = providers[tour.providerId] ? providers[tour.providerId].name : '';
  const officialWebSite = providers[tour.providerId].officialWebsite ? providers[tour.providerId] : undefined;

  return (
    <Flex width={1} $pr={[9, null, null, null, 0]} $pl={paddings} className="darkenOnHover">
      <Box
        $pt={paddings}
        $pb={paddings}
        $pr={paddings}
        width={[1, null, null, null, !secondary ? 8 / 12 : null]}
      >
        <StyledFlex $flexDirection="column" $secondary={secondary}>
          <TourContentHeader
            language={language}
            showAvailabilities={showAvailabilities}
            theme={theme}
            groupTour={tour}
            secondary={secondary}
            currency={currency}
            aggressiveRedirectToPartner={aggressiveRedirectToPartner}
            showAvailableOnDateText={showAvailableOnDateText}
            officialWebSite={officialWebSite} 
            date={date}
          />
          <StyledOffersAndPrices $justifyContent="space-between" $alignItems="flex-end">
            <Line fontSize={14} color={theme.trip.offers}>
              {tour[`distinctProvidersCount_${currency}`] > 1 ? (
                <ClientOnly>
                  {aggressiveRedirectToPartner && !isMobile ? (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <AllOffersLink useDiv onClick={() => openGroupPageUrl(tour.groupId)}>
                      <Text
                        html
                        t="on_count_websites"
                        values={{ count: tour[`distinctProvidersCount_${currency}`] }}
                      />
                      &nbsp;&nbsp;
                      <Text t="view_all_offers" />
                    </AllOffersLink>
                  ) : (
                    <Text
                      html
                      t="on_count_websites"
                      values={{ count: tour[`distinctProvidersCount_${currency}`] }}
                    />
                  )}
                </ClientOnly>
              ) : (
                <strong>{providerName}</strong>
              )}
            </Line>
            {(isMobile || secondary) && (
              <ClientOnly>
                <TourContentPricesMobile
                  theme={theme}
                  lowestPrice={
                    aggressiveRedirectToPartner && Boolean(tour.bestsellerTourId)
                      ? tour[`bestsellerPrice_${currency}`]
                      : tour[`lowestPrice_${currency}`]
                  }
                  highestPrice={tour[`highestPrice_${currency}`]}
                  secondary={secondary}
                />
              </ClientOnly>
            )}
          </StyledOffersAndPrices>
        </StyledFlex>
      </Box>
      {!isMobile && !secondary && (
        <TourContentPricesTablet
          theme={theme}
          groupTour={tour}
          currency={currency}
          translate={translate}
          aggressiveRedirectToPartner={aggressiveRedirectToPartner}
        />
      )}
    </Flex>
  );
};

export default withTheme(TourContent);
