// @flow strict
import { algoliasearch } from 'algoliasearch';
import { APP_ID, API_KEY, INDICES, LOCALES } from '../../../../../etc/appConfig.js';
import type { Language } from '../../intl/context.js';
import type { Poi } from '../../../records/Poi.js';
import mapperPois from '../../mapper/pois/index.js';

const client = algoliasearch(APP_ID, API_KEY);

type PoiByLanguage = {
  [Language]: Poi,
};

export const getPoi = async (id: string): Promise<PoiByLanguage> => {
  const attributesToRetrieve = LOCALES.reduce(
    (acc, lang) => {
      acc.push(`name_${lang}`);
      acc.push(`urlName_${lang}`);
      return acc;
    },
    [],
  ).concat('_tags');

  try {
    const poi = await client.getObject({
      indexName: INDICES.POIS,
      objectID: id,
      attributesToRetrieve,
    });

    const pois = LOCALES.reduce((acc, lang) => {
      acc[lang] = mapperPois([poi], lang)[0];
      return acc;
    }, {});

    return pois;
  } catch (err) {
    // Handle the error as needed
    throw err;
  }
};