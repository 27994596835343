// @flow strict
/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Flex, Box } from '../../../../primitives/Essentials/index.js';
import type { EditorialSection as EditorialSectionType } from '../../../../records/EditorialContent/index.js';
import { SECTION_LAYOUT_NO_PADDING_MOBILE } from '../../../../consts/layout.js';
import EditorialSection from './components/EditorialSection/index.js';

type Props = {
  sections: EditorialSectionType[],
};

const EditorialSections = ({ sections }: Props) => (
  <Box {...SECTION_LAYOUT_NO_PADDING_MOBILE}>
    <Flex $flexDirection="row" $flexWrap="wrap">
      {sections.map((section, i) => (
        <EditorialSection key={`es-${i}`} section={section} />
      ))}
    </Flex>
  </Box>
);

export default EditorialSections;
