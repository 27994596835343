// @flow strict
import * as React from 'react';
import { useSpring, animated } from '@react-spring/web';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Box, Flex } from '../../../primitives/Essentials/index.js';
import Tagline from './Tagline/index.js';
import Text from '../../Text/index.js';
import { container } from '../../../services/styleUtils/index.js';
import type { Language } from '../../../services/intl/context.js';
import TaglinePrefix from './TaglinePrefix/index.js';

const StyledContainerBox = styled(Box)`
  min-height: 60px;
  overflow-x: hidden;
  background-color: ${({ theme }) => theme.navbar.searchBg};
`;

const StyledBox = styled(Box)`
  ${container()};
`;

const Icon = styled.div`
  width: 30px;
  height: 30px;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
`;

const taglines = [
  <Tagline
    key="1"
    title={<Text t="find_tickets_and_tours" />}
    subtitle={<Text t="with_tickets_in_destinations" html />}
    icon={<Icon src="/icons/search-filled.svg" alt="search filled" />}
  />,
  <Tagline
    key="2"
    title={<Text t="compare_prices" />}
    subtitle={<Text t="from_websites" html />}
    icon={<Icon src="/icons/compare.svg" alt="compare tickets" />}
  />,
  <Tagline
    key="3"
    title={<Text t="book_best_price" />}
    subtitle={<Text t="save_time" html />}
    icon={<Icon src="/icons/piggy-bank.svg" alt="piggy bank" />}
  />,
];

type Props = {
  isMobile: boolean,
  language: Language,
};

const TaglineCarouselContent = ({ isMobile, language }: Props) => {
  const [step, setStep] = React.useState(0);
  const [firstTimeAnimationRender, setFirstTimeAnimationRender] = React.useState(true);
  const intervalRef = React.useRef<?IntervalID>(null);

  React.useEffect(() => {
    if (isMobile) {
      intervalRef.current = setInterval(() => {
        setStep(prevStep => (prevStep < taglines.length - 1 ? prevStep + 1 : 0));
        setFirstTimeAnimationRender(false);
      }, 2000); // Adjust timing for better effect
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [isMobile]);

    // Define fade-in/fade-out styles
    const fadeStyle = useSpring({
      opacity: 1,
      from: { opacity: 0 },
      reset: true,
    });

    return (
      <StyledContainerBox>
        <StyledBox $px={[15.5, null, null, null, 30]} $py={[11.5, null, null, null, 12]}>
          <Flex
            $alignItems={['flex-start', null, null, null, 'center']}
            $justifyContent={['flex-start', null, null, 'space-between']}
          >
            {isMobile ? (
              <animated.div style={fadeStyle}>{taglines[step]}</animated.div>
            ) : (
              <>
                <TaglinePrefix language={language} />
                { taglines.map(tagline => tagline) }
              </>
            )}
          </Flex>
        </StyledBox>
      </StyledContainerBox>
    );
} 

export default TaglineCarouselContent;