// @flow strict
import React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import defaultProps from '../records/IconProps.js';
import type { IconProps } from '../records/IconProps.js';
import { ThemeDefault } from '../../../records/Theme.js';

const StyledBtn = styled.rect`
  cursor: pointer;
  fill: #fff;
  stroke: #fff;
  fill-opacity: 0;
  stroke-opacity: 0;
`;

type Props = IconProps & {
  disabled?: boolean,
  disabledColor?: string,
  onClick?: () => void,
};

const SvgArrowPrev = ({ size, color, disabledColor, className, disabled, onClick }: Props) => (
  <svg
    className={className}
    width={`${size}px`}
    height={`${size}px`}
    viewBox="0 0 12 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={!disabled ? color : disabledColor}
      fillRule="evenodd"
      d="M1.298 8.617L.156 9.723a.488.488 0 0 0-.011.704l9.572 9.433a.525.525 0 0 0 .726-.011l1.401-1.358a.487.487 0 0 0 .011-.703l-7.839-7.725 7.82-7.874a.481.481 0 0 0-.01-.696L10.438.15A.52.52 0 0 0 9.72.14L1.298 8.616z"
    />
    <StyledBtn
      className="btn"
      x="0"
      y="0"
      width={`${size}px`}
      height={`${size}px`}
      onClick={onClick}
    />
  </svg>
);

SvgArrowPrev.defaultProps = {
  ...defaultProps,
  disabled: false,
  disabledColor: ThemeDefault.loader,
  color: ThemeDefault.secondary,
};

export default SvgArrowPrev;
