// @flow strict
import React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Flex } from '../../../../../../primitives/Essentials/index.js';
import Text from '../../../../../../components/Text/index.js';
import Line from '../../../../../../primitives/Line/index.js';
import mq from '../../../../../../services/mediaQuery/index.js';

const StyledNote = styled(Flex)`
  padding: 34.5px 15px 37px;
  text-align: center;
  background-color: ${({ theme }) => theme.trip.backgroundLight};
  ${mq.TABLET`
    border-radius: 5px;
    padding: 23px 40px 25px;
  `}
`;

const StyledLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  width: 285px;
  color: ${({ theme }) => theme.trip.abstract};
  strong {
    color: ${({ theme }) => theme.primary};
  }
  ${mq.TABLET`
    width: 350px;
  `}
`;

const NoToursNote = () => (
  <StyledNote $justifyContent="center">
    <StyledLine fontSize={16} letterSpacing={-0.02}>
      <Text t="no_offers" html />
    </StyledLine>
  </StyledNote>
);

export default NoToursNote;
