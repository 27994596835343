// SwipeableViews.js
// @flow strict
import React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { useEffect, useRef } from "react"

const StyledSwipeableContainer = styled.div`
  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  width: 100%;
  height: 100%;

  & > * {
    flex: 0 0 100%;
    scroll-snap-align: center;
  }

  /* Hide scrollbar */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;

export function SwipeableViews({
  className = '',
  index,
  onChangeIndex,
  children,
  ...rootProps
}) {
  const containerRef = useRef(null);
  const scrollTimeout = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      const scrollLeft = index * containerRef.current.offsetWidth;
      containerRef.current.scrollTo({ left: scrollLeft, behavior: 'smooth' });
    }
  }, [index]);

  const handleScroll = () => {
    if (scrollTimeout.current) clearTimeout(scrollTimeout.current);
    scrollTimeout.current = window.setTimeout(() => {
      if (containerRef.current) {
        const pageWidth = containerRef.current.offsetWidth;
        const scrollLeft = containerRef.current.scrollLeft;
        const newIndex = Math.round(scrollLeft / pageWidth);
        onChangeIndex(newIndex);
      }
    }, 50); // Adjust timeout as needed
  };

  return (
    <StyledSwipeableContainer
      { ...rootProps }
      ref = { containerRef }
  className = { className }
  onScroll = { handleScroll }
    >
    { children }
    </StyledSwipeableContainer>
  );
}

export default SwipeableViews;