// @flow strict
/* eslint-disable react/no-danger */
import * as React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import DestinationContext from '../../services/destination/context.js';
import CurrencyContext from '../../services/currency/context.js';
import UrlContext from '../../services/url/context.js';
import IntlContext from '../../services/intl/context.js';
import ContentContext from '../../services/content/context.js';
import Navbar from '../../components/Navbar/index.js';
import Footer from '../../components/Footer/index.js';
import MoreLinks from '../../components/MoreLinks/index.js';
import Text from '../../components/Text/index.js';
import { destinationSelector } from '../../services/destination/selectors.js';
import TaglineCarousel from '../../components/TaglineCarousel/index.js';
import DestinationContent from './components/DestinationContent/index.js';

type Props = {
  destinationId: number,
  match: Match,
};

const DestinationPage = ({ destinationId, match }: Props) => {
  const { language, translate } = React.useContext(IntlContext);
  const { buildDestinationPageUrl } = React.useContext(UrlContext);
  const { currency } = React.useContext(CurrencyContext);
  const {
    configs,
    destinations,
    tours,
    attractions,
    countryDestinations,
    content,
  } = React.useContext(DestinationContext);
  const { toursCount, imageAttributions } = React.useContext(ContentContext);

  const selectedDestination = destinationSelector(destinations[language], destinationId);

  return (
    <>
      {selectedDestination && (
        <Navbar
          destinationId={destinationId}
          imageUrl={`/images/destinations/${destinationId}`}
          title={selectedDestination.name}
          responsive
          search
          imageAlt={translate('title_dest_page', {
            destination: selectedDestination ? selectedDestination.name : '',
          })}
        />
      )}
      <TaglineCarousel />
      <DestinationContent
        language={language}
        currency={currency}
        configs={configs}
        tours={tours}
        attractions={attractions}
        countryDestinations={countryDestinations}
        content={content}
        toursCount={toursCount}
        buildDestinationPageUrl={buildDestinationPageUrl}
        selectedDestination={selectedDestination}
        destinationId={destinationId}
        imageAttributions={imageAttributions}
      />
      {selectedDestination && <MoreLinks hideTopics />}
      <Footer goBackHref={buildDestinationPageUrl()} goBackText={<Text t="more_destinations" />} />
    </>
  );
};

export default DestinationPage;
