// @flow strict
import * as React from 'react';
import { Flex, Box } from '../../primitives/Essentials/index.js';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import CoverTitle from './components/CoverTitle/index.js';
import UrlContext from '../../services/url/context.js';
import IntlContext from '../../services/intl/context.js';
import WindowContext from '../../services/window/context.js';
import DestinationContext from '../../services/destination/context.js';
import Link from '../Link/index.js';
import Options from './components/Options/index.js';
import Cover from './components/Cover/index.js';
import Search from '../Search/index.js';
import { container } from '../../services/styleUtils/index.js';
import mq from '../../services/mediaQuery/index.js';


const Container = styled(Box)`
  background-color: ${({ theme }) => theme.tertiary};
  box-shadow: 0 1px 5.5px 0 rgba(0, 0, 0, 0.32);
`;

const Header = styled(Box)`
  ${container()};
`;

const Logo = styled.img`
  width: 138px;
  height: 38px;
  ${mq.SMALL_MOBILE`
    width: 162px;
    height: 45px;
  `};
`;

type Props = {
  destinationId?: ?number,
  title?: ?string,
  imageUrl?: ?string,
  coverText?: ?string,
  responsive?: boolean,
  imageService?: ?boolean,
  showTitle?: boolean,
  children?: ?React.Node,
  search?: ?boolean,
  isHomePage?: ?boolean,
  prepopulatedSearchValue?: string,
  imageAlt?: string,
};

const Navbar = ({
  destinationId,
  title,
  imageUrl,
  coverText,
  imageService,
  responsive,
  children,
  showTitle,
  search,
  isHomePage,
  prepopulatedSearchValue,
  imageAlt,
}: Props) => (
  <UrlContext.Consumer>
    {({ buildDestinationPageUrl, openDestinationPage, openPoiPage, buildFullTextSearchUrl }) => (
      <IntlContext.Consumer>
        {({ language, translate }) => (
          <DestinationContext.Consumer>
            {({ destinations }) => (
              <WindowContext.Consumer>
                {({ isMobile }) => (
                  <>
                    <Container $py={17.75}>
                      <Header $px={[15, null, null, null, 30]}>
                        <Flex $justifyContent="space-between" $alignItems="center">
                          <Link href={buildDestinationPageUrl()}>
                            <Flex $alignItems="center">
                              <Logo
                                src="/images/ticketlens-logo.png"
                                srcSet="/images/ticketlens-logo@2x.png 2x, /images/ticketlens-logo@3x.png 3x"
                                alt={translate('alt_text_logo')}
                              />
                            </Flex>
                          </Link>
                          {search && !isMobile && !isHomePage && (
                            <Search
                              isMobile={isMobile}
                              language={language}
                              destinationId={destinationId}
                              prepopulatedValue={prepopulatedSearchValue}
                              contextDestinations={destinations[language]}
                              openDestinationPage={openDestinationPage}
                              openPoiPage={openPoiPage}
                              buildFullTextSearchUrl={buildFullTextSearchUrl}
                            />
                          )}
                          <Flex $justifyContent="flex-end" $alignItems="center">
                            <Options language={language} />
                            {search && isMobile && !isHomePage && (
                              <Search
                                isMobile={isMobile}
                                language={language}
                                destinationId={destinationId}
                                contextDestinations={destinations[language]}
                                openDestinationPage={openDestinationPage}
                                openPoiPage={openPoiPage}
                                buildFullTextSearchUrl={buildFullTextSearchUrl}
                              />
                            )}
                          </Flex>
                        </Flex>
                      </Header>
                    </Container>
                    {coverText && <Cover text={coverText} />}
                    {imageUrl && (
                      <CoverTitle
                      responsive={responsive || false}
                      imageUrl={imageUrl}
                      imageService={imageService || false}
                      title={title || ''}
                      showTitle={Boolean(showTitle)}
                      alt={imageAlt}
                      />
                    )}
                    {children}
                  </>
                )}
              </WindowContext.Consumer>
            )}
          </DestinationContext.Consumer>
        )}
      </IntlContext.Consumer>
    )}
  </UrlContext.Consumer>
);

Navbar.defaultProps = {
  destinationId: null,
  title: null,
  imageUrl: null,
  coverText: null,
  imageService: false,
  responsive: false,
  showTitle: true,
  children: null,
  search: true,
  isHomePage: false,
};

export default Navbar;
