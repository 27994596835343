// @flow strict
import type { Rating } from '../../../../../../../records/Poi.js';

export const getVoteCount = (ratings: ?(Rating[])) =>
  ratings && ratings.length ? ratings.reduce((acc, rat) => acc + rat.count, 0) : 0;

export const getAvgRating = (ratings: ?(Rating[]), voteCount: number) =>
  ratings && ratings.length
    ? ratings.reduce((acc, rat) => acc + rat.count * rat.rating, 0) / voteCount
    : 0;
