// @flow strict
import SIZES from '../consts/sizes.js';

export type Theme = {|
  space: {
    [key: '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8']: number,
  },
  breakpoints: string[],
  primary: string,
  secondary: string,
  tertiary: string,
  loader: string,
  dismiss: string,
  navbar: {
    searchBg: string,
    searchText: string,
  },
  section: {
    title: string,
    description: string,
    abstract: string,
  },
  trip: {
    background: string,
    backgroundLight: string,
    backgroundDark: string,
    rating: string,
    abstract: string,
    offers: string,
    offersHeader: string,
    price: string,
    divider: string,
    fee: string,
    search: string,
  },
|};

// eslint-disable-next-line import/prefer-default-export
export const ThemeDefault: Theme = {
  space: {
    // reset default scale [ 0, 4, 8, 16, 32, 64, 128, 256, 512 ]
    '0': 0,
    '1': 1,
    '2': 2,
    '3': 3,
    '4': 4,
    '5': 5,
    '6': 6,
    '7': 7,
    '8': 8,
  },
  breakpoints: [
    `${SIZES.SMALL_MOBILE}px`,
    `${SIZES.MIDDLE_MOBILE}px`,
    `${SIZES.BIG_MOBILE}px`,
    `${SIZES.TABLET}px`,
    `${SIZES.DESKTOP}px`,
  ],
  primary: '#000',
  secondary: '#c51162',
  tertiary: '#ffffff',
  loader: '#d8d8d8',
  dismiss: '#c42b70',
  navbar: {
    searchBg: '#f9f9f9',
    searchText: '#acacac',
  },
  section: {
    title: '#332532',
    description: '#828282',
    abstract: '#545454',
  },
  trip: {
    backgroundLight: '#f5f5f5',
    backgroundDark: '#ededed',
    background: '#f2f2f2',
    rating: '#ffb300',
    abstract: '#808080',
    offers: '#9b9b9b',
    offersHeader: '#a0a0a0',
    price: '#6abc10',
    divider: '#c9c7c8',
    fee: '#a8a8a8',
    search: '#979797',
    officialOffer: '#0aafe0',
  },
};
