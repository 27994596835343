// @flow strict
import * as React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Box, Flex } from '../../../../../../primitives/Essentials/index.js';
// $FlowFixMe
import addMonths from 'date-fns/addMonths/index.js';
// $FlowFixMe
import addDays from 'date-fns/addDays/index.js';
// $FlowFixMe
import isLastDayOfMonth from 'date-fns/isLastDayOfMonth/index.js';
// $FlowFixMe
import lastDayOfMonth from 'date-fns/lastDayOfMonth/index.js';
import Line from '../../../../../../primitives/Line/index.js';
import type { Theme } from '../../../../../../records/Theme.js';
import Text from '../../../../../../components/Text/index.js';
import mq from '../../../../../../services/mediaQuery/index.js';
import IntlContext from '../../../../../../services/intl/context.js';
import WindowContext from '../../../../../../services/window/context.js';
import type { LowestPricePerDate } from '../../../../../../services/api/tours/services/lowestPrices/index.js';
import DatePicker from '../../../../../../components/DatePicker/index.js';
import { formatDateYMD, getDisabledDates } from '../../../../../../services/dateTimeUtils/index.js';

type Props = {
  date: ?Date, // YYYY-MM-DD
  onChangeDate: (date: Date) => void,
  theme: Theme,
  lowestPrices: LowestPricePerDate,
};

const StyledFlex = styled(Flex)`
  margin-top: 20px;
  padding: 15px;
  background-color: ${({ theme }) => theme.secondary};
  ${mq.TABLET`
    border-radius: 5px 5px 0 0;
  `};
`;

const GroupTourAvailability = ({ theme, date, onChangeDate, lowestPrices }: Props) => {
  const today = new Date();
  const tomorrow = addDays(today, 1);
  // handle edge case if today is last day of month
  const minDate = isLastDayOfMonth(today) && !lowestPrices[formatDateYMD(today)] ? tomorrow : today;
  const maxDate =
    isLastDayOfMonth(today) && !lowestPrices[formatDateYMD(today)] // is end of month and there are no tours
      ? addMonths(lastDayOfMonth(minDate), 1) // max date is 1st of next month + 1 month (up to last day of that month)
      : addMonths(minDate, 2);
  const disabledDates = getDisabledDates(minDate, maxDate);

  return (
    <StyledFlex $flexDirection="column">
      <Box>
        <Line letterSpacing={0.4} color={theme.tertiary} fontSize={19} fontWeight={900}>
          <Text t="check_availability" />
        </Line>
      </Box>
      <Box $pb={15}>
        <WindowContext.Consumer>
          {({ isMobile }) => (
            <IntlContext.Consumer>
              {({ language, translate }) => (
                <DatePicker
                  date={date}
                  onChangeDate={onChangeDate}
                  language={language}
                  lowestPrices={lowestPrices}
                  minDate={minDate}
                  maxDate={maxDate}
                  disabledDates={disabledDates}
                  placeholder={translate('select_date')}
                  theme={theme}
                  isMobile={isMobile}
                />
              )}
            </IntlContext.Consumer>
          )}
        </WindowContext.Consumer>
      </Box>
      <Box>
        <Line letterSpacing={0.4} color={theme.tertiary} fontSize={19} fontWeight={900}>
          <Text t="select_offer" />
        </Line>
      </Box>
    </StyledFlex>
  );
};

export default GroupTourAvailability;
