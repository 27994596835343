// @flow strict
import { ThemeDefault } from '../../../records/Theme.js';

export type IconProps = {
  color: string,
  size: number,
  className?: ?string,
};

const defaultProps = {
  color: ThemeDefault.secondary,
  size: 40,
  className: null,
};

export default defaultProps;
