// @flow strict
import React from 'react';
import { Flex } from '../../../../../../../../../../primitives/Essentials/index.js';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;

const StyledContainerFlex = styled(Flex)`
  height: 6px;
  background-color: ${({ theme }) => theme.secondary};
`;

type Props = {
  className?: string,
};

const Cursor = ({ className }: Props) => {
  return <StyledContainerFlex className={className} width={1 / 3} />;
};

export default Cursor;
