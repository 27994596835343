export const API_KEY = 'eafe6301a53d858f5646eff9e689117e';
export const APP_ID = '87UP2KU3PD';
export const RES_URL = 'https://cdn.ticketlens.com';
export const TICKETS_URL = process.env.IS_STAGE
  ? `${RES_URL}/tickets_staging.json`
  : `${RES_URL}/tickets.json`;

export const REVIEWS_SERVICE_URL = 'https://reviews.ticketlens.com';
export const CHATBOT_ENDPOINT =
  process.env.IS_STAGE || process.env.IS_STAGE_2 || process.env.NODE_ENV !== 'production'
    ? 'https://chat-test.ticketlens.com/chat'
    : 'https://chat.ticketlens.com/chat';

export const PARTNER_URLS_SERVICE =
  process.env.IS_STAGE || process.env.NODE_ENV !== 'production'
    ? 'https://partnerurlstest.ticketlens.com/tours'
    : 'https://partnerurls.ticketlens.com/tours';

export const LOCALES = ['en', 'de', 'it'];
export const DEFAULT_LOCALE = 'en';
export const DOMAIN = process.env.IS_STAGE || process.env.NODE_ENV !== 'production'
  ? 'https://test.ticketlens.com'
  : process.env.IS_STAGE_2
  ? 'https://stage2.ticketlens.com'
  : 'https://www.ticketlens.com';

export const BITMAP_URL = 'https://bitmap.ticketlens.com';
export const REVIEWS_URL = 'https://reviews.ticketlens.com/tours';

export const MYSQL_HOST = process.env.IS_STAGE
  ? 'hubtestdb.ticketlens.com'
  : 'tldb-cluster.cluster-ro-cvutyrutmggo.eu-west-1.rds.amazonaws.com';
export const MYSQL_PORT = 3306;

export const INDICES = {
  TOURS: 'tours',
  DESTINATIONS: 'destinations',
  DESTINATIONS_EN: 'destinations_popularity_en',
  DESTINATIONS_DE: 'destinations_popularity_de',
  DESTINATIONS_IT: 'destinations_popularity_it',
  POIS: 'pois',
  POIS_SCORE: 'pois_score',
};