// @flow strict
import React from 'react';
import { Flex, Box } from '../../../../../../primitives/Essentials/index.js';
import type { Currency } from '../../../../../../services/currency/context.js';
import UrlContext from '../../../../../../services/url/context.js';
import Tours from '../../../../../../components/Tours/index.js';
import Button from '../../../../../../components/Button/index.js';
import Text from '../../../../../../components/Text/index.js';
import type { Tag } from '../../../../../../records/SectionConfig.js';
import type { GroupTour } from '../../../../../../records/Tour.js';
import SectionHeader from '../../../../../../components/SectionHeader/index.js';
import { SECTION_LAYOUT_NO_PADDING_MOBILE } from '../../../../../../consts/layout.js';

type Props = {
  tag: Tag,
  currency: Currency,
  tours: GroupTour[],
};

const Section = ({ tag, currency, tours }: Props) => (
  <Box {...SECTION_LAYOUT_NO_PADDING_MOBILE}>
    <Flex $flexDirection="column">
      <SectionHeader title={tag.Name} />
      <Tours tag={tag} currency={currency} tours={tours} date={null} />
      {(new Set(tours.map(tour => tour.groupId)).size > 4 || tag.hasContent) && (
        <Box $mt={[0, null, null, null, 9]}>
          <UrlContext.Consumer>
            {({ openDestinationCategoryPage }) => (
              <Button
                useDiv={!tag.hasContent}
                onClick={!tag.hasContent ? () => openDestinationCategoryPage(tag.Tag) : undefined}
                href={tag.hasContent ? tag.urlName : undefined}
              >
                <Text t="see_more" values={{ item: tag.Name }} />
              </Button>
            )}
          </UrlContext.Consumer>
        </Box>
      )}
    </Flex>
  </Box>
);

export default Section;
