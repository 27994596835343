import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { space, layout, color, border, position, flexbox } from 'styled-system';
import { system } from 'styled-system';

const px = (value) => (typeof value === 'number' ? `${value}px` : value);

export const customProps = system({
  // Existing custom props
  $flexDirection: {
    property: 'flexDirection',
  },
  $justifyContent: {
    property: 'justifyContent',
  },
  $alignItems: {
    property: 'alignItems',
  },
  $flexWrap: {
    property: 'flexWrap',
  },
  // Spacing props
  $p: {
    property: 'padding',
    transform: px,
  },
  $pl: {
    property: 'paddingLeft',
    transform: px,
  },
  $pr: {
    property: 'paddingRight',
    transform: px,
  },
  $pt: {
    property: 'paddingTop',
    transform: px,
  },
  $pb: {
    property: 'paddingBottom',
    transform: px,
  },
  $px: {
    properties: ['paddingLeft', 'paddingRight'],
    transform: px,
  },
  $py: {
    properties: ['paddingTop', 'paddingBottom'],
    transform: px,
  },
  $m: {
    property: 'margin',
    transform: px,
  },
  $ml: {
    property: 'marginLeft',
    transform: px,
  },
  $mr: {
    property: 'marginRight',
    transform: px,
  },
  $mt: {
    property: 'marginTop',
    transform: px,
  },
  $mb: {
    property: 'marginBottom',
    transform: px,
  },
  $mx: {
    properties: ['marginLeft', 'marginRight'],
    transform: px,
  },
  $my: {
    properties: ['marginTop', 'marginBottom'],
    transform: px,
  },
  $height: {
    property: 'height',
    transform: px,
  },
  $minHeight: {
    property: 'minHeight',
    transform: px,
  },
  // Add other custom props as needed
});

export const Box = styled('div')`
  ${space}
  ${layout}
  ${color}
  ${border}
  ${position}
  ${flexbox}
  ${customProps}
  box-sizing: border-box;
`;

export const Flex = styled(Box)`
  display: flex;
`;

