// @flow strict
import * as React from 'react';
import { Flex, Box } from '../../../../primitives/Essentials/index.js';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import mq from '../../../../services/mediaQuery/index.js';
import TourCoverImage from './components/TourCoverImage/index.js';
import TourContent from './components/TourContent/index.js';
import UrlContext from '../../../../services/url/context.js';
import CurrencyContext from '../../../../services/currency/context.js';
import WindowContext from '../../../../services/window/context.js';
import type { GroupTour } from '../../../../records/Tour.js';
import Link from '../../../Link/index.js';
import { hover, hoverMoveRight } from '../../../../services/styleUtils/index.js';
import Text from '../../../Text/index.js';
import { BITMAP_URL } from '../../../../../../etc/appConfig.js';

type Props = {
  groupTour: GroupTour,
  showAvailabilities: boolean,
  singleColumn?: boolean,
  animateRight?: boolean,
  secondary?: boolean,
  halfSize?: boolean,
  date: ?Date,
  abTestOpenTourGroup: boolean,
};

// do not pass signleColumn (ownProps) to Box
const StyledTour = styled(({ singleColumn, ...props }) => <Flex {...props} />)`
  position: relative;
  width: 100%;
  height: 120px;
  margin-bottom: 1px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.trip.backgroundLight};

  ${({ $secondary }) =>
    !$secondary &&
    mq.TABLET`
      height: 175px;
      margin-bottom: 9.5px;
      border-radius: 5px;
  `};

  ${({ $secondary }) =>
    $secondary &&
    mq.TABLET`
      margin-bottom: 9.5px;
      border-radius: 5px;
      width: calc(50% - 8px);
      &:not(&:first-child) {
        margin-left: 8px;
      }
  `};

  ${({ $secondary, $noUnderline, $halfSize, $animateRight }) =>
    $secondary &&
    mq.DESKTOP`
      ${!$halfSize ? `width: calc(33% - 3px);` : ''}
      ${$animateRight ? hoverMoveRight($noUnderline) : hover($noUnderline)}
  `};

  ${({ $secondary, $noUnderline, $singleColumn, $animateRight }) =>
    !$secondary &&
    mq.DESKTOP`
      width: ${$singleColumn ? '100%' : '50%'};
      ${$animateRight ? hoverMoveRight($noUnderline) : hover($noUnderline)}

      &:nth-child(2n) {
        width: ${$singleColumn ? '100%' : 'calc(50% - 16px)'};
        margin-left: ${$singleColumn ? '0' : '16px'};
      }
  `};
`;

const StyledLink = styled(Link)`
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const CutOutLeft = styled(Box)`
  position: absolute;
  top: calc(50% - 10px);
  left: 0;
  ${({ $secondary }) =>
    $secondary &&
    `
    left: -1px;
    top: calc(50% - 5px);
  `};
  ${({ $secondary }) =>
    !$secondary &&
    mq.TABLET`
    left: -1px;
    top: calc(50% - 20px);
  `};
`;

const CutOutRight = styled(Box)`
  position: absolute;
  top: calc(50% - 5px);
  right: 0;
  ${({ $secondary }) =>
    $secondary &&
    `
    right: -1px;
  `};
  ${({ $secondary }) =>
    !$secondary &&
    mq.TABLET`
    right: -1px;
    top: calc(50% - 20px);
  `};
`;

const TourAction = styled(Flex)`
  width: 100%;
  color: ${({ theme }) => theme.trip.offers};
  text-decoration: underline;
  background-color: ${({ theme }) => theme.trip.backgroundLight};
  ${mq.TABLET`
    display: none;
  `};
`;

const Icon = styled.img`
  width: 7.5px;
  height: 15px;
  ${({ $secondary }) =>
    !$secondary &&
    mq.BIG_MOBILE`
      width: 10px;
      height: 20px;
    `};
  ${({ $secondary }) =>
    !$secondary &&
    mq.TABLET`
    width: 17px;
    height: 33.5px;
    `};
`;

const Tour = ({
  groupTour,
  showAvailabilities,
  singleColumn,
  animateRight,
  secondary,
  halfSize,
  date,
  abTestOpenTourGroup,
}: Props) => {
  const { openGroupPageUrl, openTourPage } = React.useContext(UrlContext);
  const { currency } = React.useContext(CurrencyContext);
  const { isMobile } = React.useContext(WindowContext);
  const imageUrl = groupTour.imageId ? `${BITMAP_URL}/image/${groupTour.imageId}/` : null;

  return (
    <>
      <StyledTour
        $secondary={secondary}
        $halfSize={halfSize}
        $singleColumn={singleColumn ? 1 : 0}
        $animateRight={animateRight ? 1 : 0}
        $noUnderline={!secondary}
      >
        <StyledLink
          useDiv
          onClick={() =>
            (groupTour.offersCount === 1 || !secondary) && !abTestOpenTourGroup
              ? openTourPage(
                  groupTour.bestsellerTourId
                    ? groupTour.bestsellerTourId
                    : groupTour[`lowestPriceTourId_${currency}`],
                )
              : openGroupPageUrl(groupTour.groupId)
          }
        />
        <CutOutLeft $secondary={secondary}>
          <Icon src="/icons/cutout-left.svg" alt="ticket cut left" $secondary={secondary} />
        </CutOutLeft>
        <CutOutRight $secondary={secondary}>
          <Icon src="/icons/cutout-right.svg" alt="ticket cut right" $secondary={secondary} />
        </CutOutRight>
        <TourCoverImage tourImageUrl={imageUrl} alt={groupTour.title} />
        <TourContent
          tour={groupTour}
          showAvailabilities={showAvailabilities}
          secondary={secondary}
          aggressiveRedirectToPartner={!secondary}
          showAvailableOnDateText={!secondary}
          date={date}
        />
      </StyledTour>
      {isMobile && !secondary && groupTour[`distinctProvidersCount_${currency}`] > 1 && (
        <TourAction $mb={5} $py={8} $justifyContent="center">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link useDiv onClick={() => openGroupPageUrl(groupTour.groupId)}>
            <Text t="view_all_offers" />
          </Link>
        </TourAction>
      )}
    </>
  );
};

Tour.defaultProps = {
  singleColumn: false,
  secondary: false,
  halfSize: false,
  abTestOpenTourGroup: false,
};

export default Tour;
