// @flow strict
import * as R from 'ramda/src/index.js';
import type { Language } from '../../intl/context.js';
import type {
  TopListInput,
  TopList,
  SubListInput,
  SubList,
  ListElementInput,
  ListElement,
  DestinationContentInput,
  DestinationContent,
} from '../../../records/DestinationContent.js';
import type { ImageAttributions } from '../../../records/Photo.js';
import mapperPhoto from '../photo/index.js';

const sortByOrderIndex = (a, b) => {
  if (a.orderIndex < b.orderIndex) return -1;
  if (a.orderIndex > b.orderIndex) return 1;
  return 0;
};

function mapperListElements(
  input: ListElementInput[],
  locale: Language,
  imageAttributions: ImageAttributions,
): ListElement[] {
  return input.sort(sortByOrderIndex).map(listElement => ({
    // id
    title: listElement[`title_${locale}`] || null,
    abstract: listElement[`abstract_${locale}`] || null,
    photo: mapperPhoto(listElement, locale, imageAttributions),
    linkTo: listElement.linkTo || null,
  }));
}

function mapperTopList(
  input: TopListInput,
  locale: Language,
  imageAttributions: ImageAttributions,
): TopList {
  return {
    title: input[`title_${locale}`] || null,
    abstract: input[`abstract_${locale}`] || null,
    listElements: input.listElements
      ? mapperListElements(input.listElements, locale, imageAttributions)
      : null,
  };
}

function mapperSubList(
  input: SubListInput[],
  locale: Language,
  imageAttributions: ImageAttributions,
): SubList[] {
  return input.sort(sortByOrderIndex).map(subList => ({
    title: subList[`title_${locale}`] || null,
    abstract: subList[`abstract_${locale}`] || null,
    text: subList[`text_${locale}`] || null,
    photo: mapperPhoto(subList, locale, imageAttributions),
    linkTo: subList.linkTo || null,
    listElements: subList.listElements
      ? mapperListElements(subList.listElements, locale, imageAttributions)
      : null,
    morePOIs: subList.morePOIs
      ? { title: subList.morePOIs[`title_${locale}`], ids: subList.morePOIs.ids || null }
      : null,
  }));
}

export default function mapperDestinationContent(
  input: DestinationContentInput,
  locale: Language,
  imageAttributions: ImageAttributions,
): ?DestinationContent {
  const htmlTitle = input[`htmlTitle_${locale}`] || null;
  const htmlDescription = input[`htmlDescription_${locale}`] || null;
  const abstract = input[`abstract_${locale}`] || null;

  if (!htmlTitle) {
    return null;
  }

  return {
    ...R.omit(
      [
        'destinationId',
        'htmlTitle_en',
        'htmlTitle_de',
        'htmlTitle_it',
        'htmlDescription_en',
        'htmlDescription_de',
        'htmlDescription_it',
        'abstract_en',
        'abstract_de',
        'abstract_it',
        'translator_en',
        'translator_de',
        'translator_it',
      ],
      input,
    ),
    htmlTitle,
    htmlDescription,
    abstract,
    author: input.author || null,
    translator: input[`translator_${locale}`] || null,
    topList: input.topList ? mapperTopList(input.topList, locale, imageAttributions) : null,
    subLists: input.subLists ? mapperSubList(input.subLists, locale, imageAttributions) : null,
    topListAttractions: input.topListAttractions ? mapperTopList(input.topListAttractions, locale, imageAttributions) : null,
    subListsAttractions: input.subListsAttractions ? mapperSubList(input.subListsAttractions, locale, imageAttributions) : null,
    topListTitle: null,
  };
}
