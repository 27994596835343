// @flow strict
import React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Flex } from '../../../../../../../../primitives/Essentials/index.js';
import mq from '../../../../../../../../services/mediaQuery/index.js';
import Line from '../../../../../../../../primitives/Line/index.js';
import Price from '../../../../../../../Price/index.js';
import type { Theme } from '../../../../../../../../records/Theme.js';

const Root = styled.div`
  ${({ $secondary }) =>
    !$secondary &&
    mq.TABLET`
    display: none;
  `};
`;

type Props = {
  lowestPrice: number,
  highestPrice: number,
  theme: Theme,
  secondary: boolean,
};

const TourContentPricesMobile = ({ highestPrice, lowestPrice, theme, secondary }: Props) => (
  <Root $secondary={secondary}>
    <Flex $flexDirection="column" $alignItems="flex-end">
      {highestPrice > lowestPrice && (
        <Line
          color={theme.secondary}
          letterSpacing={0.3}
          fontWeight={900}
          fontSize={14}
          $lineThrough
        >
          <Price value={highestPrice} />
        </Line>
      )}
      <Line color={theme.trip.price} letterSpacing={0.3} fontWeight={900} fontSize={17}>
        <Price value={lowestPrice} />
      </Line>
    </Flex>
  </Root>
);

export default TourContentPricesMobile;
