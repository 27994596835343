// @flow strict
import React from 'react';
import { withTheme } from 'styled-components';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Flex, Box } from '../../primitives/Essentials/index.js';
import type { Theme } from '../../records/Theme.js';
import Navbar from '../../components/Navbar/index.js';
import Footer from '../../components/Footer/index.js';
import Text from '../../components/Text/index.js';
import IntlContext from '../../services/intl/context.js';
import {
  container,
  HEADER,
  COVER_MOBILE,
  COVER,
  COVER_TABLET,
  FOOTER,
  FOOTER_MOBILE,
  CONTENT_MARGIN,
} from '../../services/styleUtils/index.js';
import mq from '../../services/mediaQuery/index.js';
import { SECTION_LAYOUT } from '../../consts/layout.js';
import Line from '../../primitives/Line/index.js';
import Breadcrumbs from '../../components/Breadcrumbs/index.js';

type Props = {
  theme: Theme,
};

const StyledContainerBox = styled(Box)`
  ${container()};
  min-height: calc(100vh - ${HEADER} - ${FOOTER_MOBILE} - ${COVER_MOBILE} - ${CONTENT_MARGIN});
  ${mq.TABLET`
    min-height: calc(100vh - ${HEADER} - ${FOOTER} - ${COVER_TABLET} - ${CONTENT_MARGIN} - 12px);
  `};
  ${mq.DESKTOP`
    min-height: calc(100vh - ${HEADER} - ${FOOTER} - ${COVER} - ${CONTENT_MARGIN} - 12px);
  `};
`;

const StyledLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  color: ${({ theme }) => theme.section.description};
  font-size: 16px;
  ${mq.TABLET`
    font-size: 24px;
  `};
`;

const ImprintPage = ({ theme }: Props) => {
  const { language, translate } = React.useContext(IntlContext);
  return (
    <>
      <Navbar
        imageUrl="/images/imprint"
        title={translate('title_imprint')}
        responsive
        search={false}
      />
      <StyledContainerBox {...SECTION_LAYOUT} $mt={0}>
        <Breadcrumbs
          items={[
            {
              name: translate('title_imprint'),
              url: `/${language}/imprint`,
            },
          ]}
          activeIndex={0}
        />
        <Flex $alignItems="left" $flexDirection="column" $mt={[20, null, null, null, 30]}>
          <Box $mt={6}>
            <Line color={theme.section.title} fontSize={28} fontWeight={900} lineHeight={1.36}>
              <Text t="company_name" />
            </Line>
          </Box>
          <Box $mt={16}>
            <StyledLine>
              <Text t="imprint_line1" />
            </StyledLine>
          </Box>
          <Box>
            <StyledLine>
              <Text t="imprint_line2" />
            </StyledLine>
          </Box>
          <Box>
            <StyledLine>
              <Text t="imprint_line3" />
            </StyledLine>
          </Box>
          <Box $mt={26}>
            <StyledLine>
              <Text t="imprint_line4" />
            </StyledLine>
          </Box>
          <Box>
            <StyledLine>
              <Text t="imprint_line5" />
            </StyledLine>
          </Box>
          <Box $mt={26}>
            <StyledLine>
              <Text t="imprint_line6" />
            </StyledLine>
          </Box>
        </Flex>
      </StyledContainerBox>
      <Footer />
    </>
  );
};

export default withTheme(ImprintPage);
