// @flow strict
import * as React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Flex } from '../../primitives/Essentials/index.js';
import Line from '../../primitives/Line/index.js';
import Text from '../Text/index.js';
import Link from '../Link/index.js';
import { SECTION_LAYOUT } from '../../consts/layout.js';
import ContentContext from '../../services/content/context.js';
import IntlContext from '../../services/intl/context.js';
import mq from '../../services/mediaQuery/index.js';

// TODO: move margin and padding to px and mx when there is time
const ContentWrapper = styled(Flex)`
  position: relative;
  background-color: ${({ theme }) => theme.trip.backgroundLight};

  ${mq.DESKTOP`
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 20px;
    padding-right: 20px;
 `}
`;

const BioLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  max-width: 800px;
  margin-top: 12px;
  color: ${({ theme }) => theme.trip.abstract};
  text-align: center;
  a {
    color: inherit;
  }
`;

const WrittenByLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  margin-top: 6px;
  color: ${({ theme }) => theme.trip.abstract};
`;

const TranslatedByLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  margin-top: 16px;
  color: ${({ theme }) => theme.trip.divider};
`;

const AuthorImage = styled.img`
  position: absolute;
  top: -50px;
  width: 100px;
  height: 100px;
  border-radius: 50px;
`;

type Props = {
  author: string,
  translator: ?string,
};

const AuthorBio = ({ author, translator }: Props) => {
  const { language } = React.useContext(IntlContext);
  const { authors } = React.useContext(ContentContext);

  if (!authors[author]) {
    return null;
  }

  const authorPageHref = `/${language}/author/${author}`;

  return (
    <ContentWrapper
      $px={SECTION_LAYOUT.$px}
      $mt={[105, null, null, null, 115]}
      $alignItems="center"
      $flexDirection="column"
    >
      <AuthorImage
        src={authors[author].photoUrlBig[0]}
        srcSet={`${authors[author].photoUrlBig[1]} 2x`}
        alt={authors[author].name}
      />
      <Flex $flexDirection="column" $alignItems="center" $pt={55} $pb={[45, null, null, null, 30]}>
        <WrittenByLine fontSize={16} lineHeight={1.38}>
          <Text t="written_by_txt" />
        </WrittenByLine>
        <Line fontSize={24} lineHeight={1.5} fontWeight={900}>
          <Link href={authorPageHref}>{authors[author].name}</Link>
        </Line>
        <BioLine fontSize={16} lineHeight={1.38}>
          {authors[author].bio}
        </BioLine>
        {translator && translator !== author && (
          <TranslatedByLine fontSize={16}>
            <Text t="translated_by" values={{ name: authors[translator].name }} />
          </TranslatedByLine>
        )}
      </Flex>
    </ContentWrapper>
  );
};

export default AuthorBio;
