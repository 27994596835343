// @flow strict
import * as React from 'react';
import { withTheme } from 'styled-components';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Box, Flex } from '../../../../primitives/Essentials/index.js';
// $FlowFixMe
import addMonths from 'date-fns/addMonths/index.js';
import { getDisabledDates } from '../../../../services/dateTimeUtils/index.js';
import mq from '../../../../services/mediaQuery/index.js';
import IntlContext from '../../../../services/intl/context.js';
import WindowContext from '../../../../services/window/context.js';
import UrlContext from '../../../../services/url/context.js';
import DestinationContext from '../../../../services/destination/context.js';
import { SECTION_LAYOUT_NO_PADDING_MOBILE } from '../../../../consts/layout.js';
import DatePicker from '../../../../components/DatePicker/index.js';
import SearchInput from '../../../../components/SearchInput/index.js';
import Search from '../../../../components/Search/index.js';
import Text from '../../../../components/Text/index.js';
import Line from '../../../../primitives/Line/index.js';
import type { Theme } from '../../../../records/Theme.js';

const StyledLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  color: ${({ theme }) => theme.tertiary};
`;

const StyledFlex = styled(Flex)`
  background-color: ${({ theme }) => theme.secondary};
  ${mq.TABLET`
    border-radius: 5px;
  `};
`;

type State = {
  searchVisible: boolean,
};

type Props = {
  theme: Theme,
  date: Date,
  textQuery: string,
  destinationId?: number,
  onChangeDate: (date: Date) => void,
};

class SearchResultsBarWithDate extends React.PureComponent<Props, State> {
  static defaultProps = {
    destinationId: null,
  };

  constructor() {
    super();
    this.state = {
      searchVisible: false,
    };
  }

  toggleSearch = () => {
    const { searchVisible } = this.state;

    this.setState({
      searchVisible: !searchVisible,
    });
  };

  render() {
    const { theme, date, textQuery, destinationId, onChangeDate } = this.props;
    const { searchVisible } = this.state;

    const today = new Date();
    const minDate = today;
    const maxDate = addMonths(minDate, 1);
    const disabledDates = getDisabledDates(minDate, maxDate);

    return (
      <Box {...SECTION_LAYOUT_NO_PADDING_MOBILE} $mt={null} $py={1.5}>
        <UrlContext.Consumer>
          {({ openDestinationPage, openPoiPage, buildFullTextSearchUrl }) => (
            <WindowContext.Consumer>
              {({ isMobile }) => (
                <DestinationContext.Consumer>
                  {({ destinations }) => (
                    <IntlContext.Consumer>
                      {({ translate, language }) => (
                        <StyledFlex $flexDirection="column" $px={15} $py={22}>
                          {isMobile ? (
                            <>
                              {!searchVisible ? (
                                <SearchInput
                                  theme={theme}
                                  placeholder={translate('search_placeholder')}
                                  prepopulatedValue={textQuery}
                                  onFocus={this.toggleSearch}
                                />
                              ) : (
                                <Search
                                  theme={theme}
                                  language={language}
                                  prepopulatedValue={textQuery}
                                  destinationId={destinationId}
                                  onCancel={this.toggleSearch}
                                  withinSearchResults
                                  contextDestinations={destinations[language]}
                                  isMobile
                                  openDestinationPage={openDestinationPage}
                                  openPoiPage={openPoiPage}
                                  buildFullTextSearchUrl={buildFullTextSearchUrl}
                                />
                              )}
                            </>
                          ) : (
                            <StyledLine letterSpacing={0.4} fontSize={19} fontWeight={900}>
                              <Text t="check_availability_long" />
                            </StyledLine>
                          )}
                          <DatePicker
                            date={date}
                            onChangeDate={onChangeDate}
                            language={language}
                            minDate={minDate}
                            maxDate={maxDate}
                            disabledDates={disabledDates}
                            placeholder={translate('select_date')}
                            theme={theme}
                            isMobile={isMobile}
                          />
                        </StyledFlex>
                      )}
                    </IntlContext.Consumer>
                  )}
                </DestinationContext.Consumer>
              )}
            </WindowContext.Consumer>
          )}
        </UrlContext.Consumer>
      </Box>
    );
  }
}

export default withTheme(SearchResultsBarWithDate);
