// @flow strict
import * as React from 'react';
import type { Conditions } from '../../records/Conditions';
import type { Poi } from '../../records/Poi.js';
import type { Providers } from '../../records/Providers';
import type { Promocodes } from '../../records/Promocodes';
import type { Currencies } from '../../records/Currencies';
import type { Authors } from '../../records/Authors';
import type { ImageAttributions } from '../../records/Photo';

export type PopularTopic = { url: string, name: string };

export type ContentContextType = {
  terms: Conditions,
  privacyPolicy: Conditions,
  topAttractions: Poi[],
  toursCount: {
    [destinationId: number]: number,
    total: number, // total tour count
  },
  providers: Providers,
  promocodes: Promocodes,
  authors: Authors,
  currencies: Currencies,
  popularTopics: PopularTopic[],
  imageAttributions: ImageAttributions,
};

const ContentContext = React.createContext<ContentContextType>({
  terms: {
    title: '',
    subtitle: '',
    date: '',
    abstract: '',
    sections: [],
  },
  privacyPolicy: {
    title: '',
    subtitle: '',
    date: '',
    abstract: '',
    sections: [],
  },
  topAttractions: [],
  toursCount: {
    total: 0,
  },
  providers: {},
  promocodes: {},
  currencies: {},
  authors: {},
  popularTopics: [],
  imageAttributions: {},
});

export default ContentContext;
