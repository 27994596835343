// Routes.jsx
// @flow strict
import * as React from 'react';
import { useParams, Route, Routes } from 'react-router-dom';
import DestinationPage from './scenes/DestinationPage/index.js';
import DestinationsPage from './scenes/DestinationsPage/index.js';
import ImprintPage from './scenes/ImprintPage/index.js';
import PrivacyPolicyPage from './scenes/PrivacyPolicyPage/index.js';
import TermsAndConditionsPage from './scenes/TermsAndConditionsPage/index.js';
import AuthorPage from './scenes/AuthorPage/index.js';
import NotFoundPage from './scenes/NotFoundPage/index.js';
import type { ParsedUrl } from './services/url/parseUrl.js';
import { UrlContextStateManager } from './services/url/context.js';
import type { Destination } from './records/Destination.js';
import type { Language } from './services/intl/context.js';
import LandingPage from './scenes/LandingPage/index.js';
import PoiPage from './scenes/PoiPage/index.js';
import SearchResultsPage from './scenes/SearchResultsPage/index.js';
import CategoryPage from './scenes/CategoryPage/index.js';
import SubCategoryPage from './scenes/SubCategoryPage/index.js';
import PoiCategoryPage from './scenes/PoiCategoryPage/index.js';
import TourForwardPage from './scenes/TourForwardPage/index.js';
import GroupTourPage from './scenes/GroupTourPage/index.js';
import PoiToursSectionPage from './scenes/PoiToursSectionPage/index.js';

type Props = {
  buildUrl: ParsedUrl => string,
  isErrorPage: boolean,
  destinations: Destination[],
  language: Language,
};

function EntityHandler() {
  //console.log('debug EntityHandler useParams:', useParams());
  const { entityId, categoryOrSection } = useParams(); // entityId might be something like 'p660851' or 'd8'

  if (!entityId) {
    return <NotFoundPage />;
  }

  if (entityId.startsWith('p')) {
    // Poi Page or Poi Section Page
    const poiId = Number(entityId.replace(/^p/, ''));
    
    // check if there is a section
    if (categoryOrSection && categoryOrSection.startsWith('s')) {
      const sectionId = Number(categoryOrSection.replace(/^s/, ''));
      // if section Id is a number between 0 and 10 then it is a section
      if (sectionId > 0 && sectionId < 10) {  
        return <PoiToursSectionPage poiId={poiId} sectionId={sectionId} />;
      }
    } 

    return <PoiPage poiId={poiId} />;
  } else if (entityId.startsWith('t')) {
    // Tour Forward Page
    const tourId = Number(entityId.replace(/^t/, ''));
    return <TourForwardPage tourId={tourId} />;
  } else if (entityId.startsWith('g')) {
    // Group Tour Page
    const groupId = Number(entityId.replace(/^g/, ''));
    return <GroupTourPage groupId={groupId} />;
  } else if (entityId.startsWith('d')) {
    // Destination or Category Page
    const destinationId = Number(entityId.replace(/^d/, ''));

    if (categoryOrSection && categoryOrSection.startsWith('c')) {
      // Category Page
      const categoryId = Number(categoryOrSection.replace(/^c/, ''));

      if (categoryId > 0 && categoryId < 100000) {
        return <CategoryPage destinationId={destinationId} categoryId={categoryId} />;
      }
    }
    return <DestinationPage destinationId={destinationId}  />;
  } else {
    return <NotFoundPage />;
  }
}

function PoiCategoryPageHandler() {
  const { poiId } = useParams();
  if (!poiId.startsWith('p')) {
    return <NotFoundPage />;
  }
  const poiIdNumber = Number(poiId.replace(/^p/, ''));
  if (Number.isNaN(poiIdNumber)) {
    return <NotFoundPage />;
  }
  return <PoiCategoryPage poiId={poiIdNumber} />;
}

// CloudFront Error page can be loaded with valid url, e.g. /d666 (non existing destination),
// which would match destination route, so the page is rendered with only one route
const AppRoutes = ({ buildUrl, isErrorPage, destinations, language }: Props) => (
  <UrlContextStateManager buildUrl={buildUrl} destinations={destinations}>
    <Routes>
      {!isErrorPage ? (
        <>
          <Route path="/:lang" element={<LandingPage />} />
          <Route path="/" element={<LandingPage />} />
          <Route path="/:lang/destinations" element={<DestinationsPage />} />
          <Route path="/:lang/imprint" element={<ImprintPage />} />
          <Route path="/:lang/privacypolicy" element={<PrivacyPolicyPage />} />
          <Route path="/:lang/termsandconditions" element={<TermsAndConditionsPage />} />
          <Route path="/:lang/search" element={<SearchResultsPage />} />
          <Route path="/:lang/author/:authorId" element={<AuthorPage />} />
          <Route path="/:lang/:destinationId/:categoryId/category" element={<SubCategoryPage />} />
          <Route path="/:lang/:poiId/category" element={<PoiCategoryPageHandler />} />

          <Route path="/:lang/:entityId/:categoryOrSection/*" element={<EntityHandler />} />
          <Route path="/:lang/:entityId/*" element={<EntityHandler />} />

          <Route path="*" element={<NotFoundPage />} />
        </>
      ) : (
        <Route path="*" element={<NotFoundPage language={language} />} />
      )}
    </Routes>
  </UrlContextStateManager>
);

export default AppRoutes;