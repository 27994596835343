// @flow strict
import React from 'react';
import defaultProps from '../records/IconProps.js';
import type { IconProps } from '../records/IconProps.js';
import { ThemeDefault } from '../../../records/Theme.js';

const SvgSearch = ({ color, size, className }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width={`${size}px`}
    height={`${size}px`}
    viewBox="0 0 45 44"
  >
    <g fill={color} fillRule="evenodd" stroke={color} strokeWidth=".5">
      <path
        fillRule="nonzero"
        fill={color}
        d="M27.646 31.536l3.89-3.89L43.91 40.021l-3.89 3.889z"
      />
      <path
        fillRule="nonzero"
        fill={color}
        d="M18 29.75c6.49 0 11.75-5.26 11.75-11.75S24.49 6.25 18 6.25 6.25 11.51 6.25 18 11.51 29.75 18 29.75zm0 5.5C8.473 35.25.75 27.527.75 18 .75 8.473 8.473.75 18 .75 27.527.75 35.25 8.473 35.25 18c0 9.527-7.723 17.25-17.25 17.25z"
      />
    </g>
  </svg>
);

SvgSearch.defaultProps = {
  ...defaultProps,
  color: ThemeDefault.trip.search,
};

export default SvgSearch;
