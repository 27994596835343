// @flow strict
import * as R from 'ramda/src/index.js';
import type { Language } from '../../intl/context.js';
import type {
  DestinationCategoryContentInput,
  DestinationCategoryContent,
} from '../../../records/DestinationCategoryContent.js';
import type { ImageAttributions } from '../../../records/Photo.js';
import mapperSections from '../poiContent/mapperSections.js';
import mapperEditorialContent from '../editorialContent/index.js';

export default function mapperCategoryContent(
  input: DestinationCategoryContentInput,
  locale: Language,
  imageAttributions: ImageAttributions,
): DestinationCategoryContent {
  const htmlTitle = input[`htmlTitle_${locale}`];
  const htmlDescription = input[`htmlDescription_${locale}`]
    ? input[`htmlDescription_${locale}`]
    : null;
  const title = input[`title_${locale}`];
  const abstract = input[`abstract_${locale}`];
  const urlName = input[`urlName_${locale}`];

  return {
    ...R.omit(
      [
        'destinationId',
        'categoryId',
        'htmlTitle_en',
        'htmlTitle_de',
        'htmlTitle_it',
        'htmlDescription_en',
        'htmlDescription_de',
        'htmlDescription_it ',
        'title_en',
        'title_de',
        'title_it',
        'abstract_en',
        'abstract_de',
        'abstract_it',
        'urlName_en',
        'urlName_de',
        'urlName_it',
        'translator_en',
        'translator_de',
        'translator_it',
      ],
      input,
    ),
    htmlTitle,
    htmlDescription,
    title,
    abstract,
    urlName,
    sections: input.sections ? mapperSections(input.sections, locale) : undefined,
    editorialContent: input.editorialContent
      ? mapperEditorialContent(input.editorialContent, locale, imageAttributions)
      : undefined,
    author: input.author || null,
    translator: input[`translator_${locale}`] || null,
  };
}
