// @flow strict
import React from 'react';
import { Box } from '../../../../primitives/Essentials/index.js';
import CategoryToursHeader from './components/CategoryToursHeader/index.js';
import CategoryToursList from './components/CategoryToursList/index.js';
import UrlContext from '../../../../services/url/context.js';
import CurrencyContext from '../../../../services/currency/context.js';
import DestinationContext from '../../../../services/destination/context.js';
import ContentContext from '../../../../services/content/context.js';
import type { Breadcrumb } from '../../../Breadcrumbs/index.js';
import withInfiniteScroll from '../../../../decorators/withInfiniteScroll/index.js';
import { destinationSelector } from '../../../../services/destination/selectors.js';
import type { Poi } from '../../../../records/Poi.js';
import type { GroupTour } from '../../../../records/Tour.js';
import type { Tag } from '../../../../records/SectionConfig.js';
import type { Language } from '../../../../services/intl/context.js';

type Props = {
  resultsForString: ?string,
  groupTours: GroupTour[],
  resultsCount: number,
  breadcrumbs: Breadcrumb[],
  poi: ?Poi,
  date?: ?Date,
  sectionId?: number,
  sectionConfigTags?: Tag[],
  language: Language,
  destinationId?: number,
};

const CategoryTours = ({
  resultsForString,
  groupTours,
  resultsCount,
  date,
  poi,
  breadcrumbs,
  sectionConfigTags,
  language,
  destinationId, // override tour destination id
  sectionId,
}: Props) => (
  <ContentContext.Consumer>
    {({ providers }) => (
      <UrlContext.Consumer>
        {({ buildDestinationPageUrl }) => (
          <CurrencyContext.Consumer>
            {({ currency }) => (
              <DestinationContext.Consumer>
                {({ destinations }) => {
                  const tour = groupTours.length && groupTours[0];
                  let breadcrumbsWithDest = breadcrumbs;
                  if (tour && (destinationId || tour.breadcrumbDestination)) {
                    const id = destinationId || tour.breadcrumbDestination;
                    // $FlowFixMe null or undefined is already checked
                    const selectedDestination = destinationSelector(destinations[language], id);
                    // add dest either at the beginning or in front of POI breadcrumb
                    breadcrumbsWithDest = [
                      {
                        name: selectedDestination ? selectedDestination.name : String(id),
                        url: buildDestinationPageUrl(id),
                      },
                    ].concat(breadcrumbs);
                  }
                  return (
                    <Box>
                      {Boolean(groupTours.length) && (
                        <CategoryToursHeader
                          resultsCount={resultsCount}
                          resultsForString={resultsForString}
                          breadcrumbs={breadcrumbsWithDest}
                          poi={poi}
                          sectionConfigTags={sectionConfigTags}
                          sectionId={sectionId}
                        />
                      )}
                      <CategoryToursList
                        groupTours={groupTours}
                        currency={currency}
                        providers={providers}
                        date={date}
                      />
                    </Box>
                  );
                }}
              </DestinationContext.Consumer>
            )}
          </CurrencyContext.Consumer>
        )}
      </UrlContext.Consumer>
    )}
  </ContentContext.Consumer>
);

CategoryTours.defaultProps = {
  date: null,
};

const infiniteScrollCondition = ({ shouldPerformSearch }) => shouldPerformSearch();
export default withInfiniteScroll(infiniteScrollCondition)(CategoryTours);
