// @flow strict
import SIZES from '../../consts/sizes.js';

export const TYPES = {
  DESKTOP: 'desktop',
  TABLET: 'tablet',
  MOBILE: 'mobile',
};

type WindowType = $Values<typeof TYPES>;

export function getWidth(): ?number {
  // $FlowFixMe
  return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
}

export function getWindowType(): WindowType {
  const width = getWidth();

  if (width && width >= SIZES.DESKTOP) {
    return TYPES.DESKTOP;
  }
  if (width && width >= SIZES.TABLET) {
    return TYPES.TABLET;
  }
  return TYPES.MOBILE;
}

// $FlowFixMe
export const getNavigatorLanguage = (w: any): string => {
  let lang = '';
  if (!w) {
    return lang;
  }
  if (w.navigator.languages && w.navigator.languages.length) {
    // latest versions of Chrome and Firefox set this correctly
    // eslint-disable-next-line prefer-destructuring
    lang = w.navigator.languages[0];
  } else if (w.navigator.userLanguage) {
    // IE only
    lang = w.navigator.userLanguage;
  } else {
    // latest versions of Chrome, Firefox, and Safari set this correctly
    lang = w.navigator.language;
  }
  return lang.toLowerCase();
};
