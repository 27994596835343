// @flow strict
/* eslint-disable react/no-danger */
// Some people love to live dangerously
import React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Flex } from '../../../../../../../../primitives/Essentials/index.js';
import TourContentRating from './components/TourContentRating/index.js';
import mq from '../../../../../../../../services/mediaQuery/index.js';
import type { GroupTour } from '../../../../../../../../records/Tour.js';
import type { Theme } from '../../../../../../../../records/Theme.js';
import type { Currency } from '../../../../../../../../services/currency/context.js';
import TourContentAvailability from './components/TourContentAvailability/index.js';
import type { Language } from '../../../../../../../../services/intl/context.js';
import getFirstAvailable from '../../../../../../../../services/firstAvailable/index.js';
import ClientOnly from '../../../../../../../ClientOnly/index.js';
import DiscountBadgeMobile from './components/DiscountBadgeMobile/index.js';
import OfficialWebsiteLabel from '../OfficialWebsiteLabel/index.js'; 

const StyledTitleLine = styled.span`
  display: inline-block;
  max-width: ${({ $hasDiscount }) => ($hasDiscount ? 'calc(100% - 50px)' : '100%')};
  max-height: 38px;
  margin-right: 10px;
  overflow-y: hidden;
  font-weight: 900;
  font-size: 16px;
  line-height: normal;

  ${({ $secondary }) =>
    !$secondary &&
    mq.TABLET`
    font-size: 19px;
    max-height: 69px;
  `};
`;

const StyledTourContentAvailability = styled(TourContentAvailability)`
  ${({ $secondary }) =>
    $secondary &&
    mq.TABLET`
    margin-top: 5px;`};
`;

type Props = {
  language: Language,
  currency: Currency,
  groupTour: GroupTour,
  showAvailabilities: boolean,
  theme: Theme,
  secondary: boolean,
  aggressiveRedirectToPartner: boolean,
  showAvailableOnDateText: boolean,
  date: ?Date,
};

const getDiscount = (groupTour, currency, hasBestseller) =>
  Number(
    (
      100 -
      (hasBestseller
        ? groupTour[`bestsellerPrice_${currency}`]
        : groupTour[`lowestPrice_${currency}`]) /
        (groupTour[`highestPrice_${currency}`] / 100)
    ).toFixed(2),
  );

const TourContentHeader = ({
  language,
  currency,
  groupTour,
  theme,
  showAvailabilities,
  secondary,
  aggressiveRedirectToPartner,
  showAvailableOnDateText,
  officialWebSite,
  date,
}: Props) => {
  const firstAvailable = getFirstAvailable(groupTour);
  const hasBestseller = aggressiveRedirectToPartner && Boolean(groupTour.bestsellerTourId);
  const discount = getDiscount(groupTour, currency, hasBestseller);

  return (
    <Flex $flexDirection="column" $justifyContent="space-between">
      <Flex $justifyContent="space-between">
        <StyledTitleLine $secondary={secondary} $hasDiscount={discount > 5}>
          {groupTour.title}
        </StyledTitleLine>
        <ClientOnly>
          {discount > 5 && <DiscountBadgeMobile discount={discount} secondary={secondary} />}
        </ClientOnly>
      </Flex>
      {groupTour.rating > 0 && (
        <TourContentRating
          rating={groupTour.rating}
          ratingsCount={groupTour.ratingsCount}
          theme={theme}
        />
      )}
      <ClientOnly loader="&nbsp;">
        {(showAvailabilities || (showAvailableOnDateText && Boolean(date))) && (
          <StyledTourContentAvailability
            firstAvailable={firstAvailable}
            secondAvailable={groupTour.secondAvailable}
            theme={theme}
            language={language}
            $secondary={secondary}
            showAvailableOnDateText={showAvailableOnDateText}
            date={date}
          />
        )}
      </ClientOnly>
      {officialWebSite ? (
        <OfficialWebsiteLabel officialWebsite={officialWebSite}/>
      ) : null}
    </Flex>
  );
};

export default TourContentHeader;
