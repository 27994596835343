// @flow strict
import React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import IntlContext from '../../services/intl/context.js';
import Footer from '../../components/Footer/index.js';
import Navbar from '../../components/Navbar/index.js';
import Content from './components/Content/index.js';
import TaglineCarousel from '../../components/TaglineCarousel/index.js';

const StyledContainer = styled.div`
  position: relative;
`;

const LandingPage = () => (
  <IntlContext.Consumer>
    {({ translate, language }) => (
      <>
        <StyledContainer>
          <Navbar
            isHomePage
            coverText="cover"
            title={translate('title_homepage')}
            showTitle={false}
          />
          <TaglineCarousel />
        </StyledContainer>
        <Content language={language} translate={translate} />
        <Footer />
      </>
    )}
  </IntlContext.Consumer>
);

export default LandingPage;
