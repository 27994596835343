// @flow strict
import * as React from 'react';
import { Box, Flex } from '../../primitives/Essentials/index.js';
import type { GroupTour } from '../../records/Tour.js';
import Tour from './components/Tour/index.js';

type Props = {
  tours: GroupTour[],
  showAvailabilities?: boolean,
  date: ?Date,
  maxTours?: number,
  abTestOpenTourGroup?: boolean,
};

const Tours = ({ tours, showAvailabilities, date, maxTours, abTestOpenTourGroup }: Props) => (
  <Box>
    <Flex $flexWrap="wrap">
      {tours.slice(0, maxTours).map(groupTour => {
        return (
          <Tour
            key={groupTour.groupId}
            groupTour={groupTour}
            showAvailabilities={Boolean(showAvailabilities)}
            date={date}
            abTestOpenTourGroup={abTestOpenTourGroup}
          />
        );
      })}
    </Flex>
  </Box>
);

Tours.defaultProps = {
  showAvailabilities: true,
  maxTours: 4,
  abTestOpenTourGroup: false,
};

export default Tours;
