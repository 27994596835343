// @flow strict
import * as React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Box, Flex } from '../../primitives/Essentials/index.js';
import { SECTION_LAYOUT } from '../../consts/layout.js';
import Text from '../Text/index.js';
import type { SubList } from '../../records/DestinationContent.js';
import Line from '../../primitives/Line/index.js';
import { BITMAP_URL, RES_URL } from '../../../../etc/appConfig.js';
import DestinationContext from '../../services/destination/context.js';
import IntlContext from '../../services/intl/context.js';
import UrlContext from '../../services/url/context.js';
import Link from '../Link/index.js';
import { container } from '../../services/styleUtils/index.js';
import mq from '../../services/mediaQuery/index.js';

type Props = {
  subLists: ?(SubList[]),
  destinationName: string,
  destinationId?: number,
  topListTitle?: ?string,
  type: 'destination' | 'attractions',
};

const NavigationItem = styled(Box)`
  ${mq.TABLET`
    &:nth-child(even) {
      padding-left: 7.5px;
    }
    &:nth-child(odd) {
      padding-right: 7.5px;
    }
  `}
`;

const Root = styled(Flex)`
  background-color: ${({ theme }) => theme.trip.backgroundLight};
`;

const Container = styled(Flex)`
  ${container()};
`;

const LinkFlex = styled(Flex)`
  position: relative;
  height: 100px;
  ${({ $imageUrl, theme }) =>
    $imageUrl
      ? `
        background-image: url('${$imageUrl}');
        background-position: center center;
        background-size: cover;
      `
      : `background-color: ${theme.secondary};`}
  border-radius: 5px;
`;

const StyledLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  z-index: 1;
  color: ${({ theme }) => theme.tertiary};
  text-align: center;
`;

const StyledOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
`;

const Navigation = ({ subLists, destinationName, destinationId, topListTitle, type }: Props) => {
  const { language } = React.useContext(IntlContext);
  const { configs } = React.useContext(DestinationContext);
  const { buildDestinationCategoryPageUrl, buildDestinationPageUrl } = React.useContext(UrlContext);

  return (
    <Root $mt={80}>
      <Container $px={SECTION_LAYOUT.$px} $flexDirection="column" width={1}>
        {subLists && (
          <Flex $py={14} $flexDirection="column">
            <Line fontSize={24} fontWeight={900}>
              <Text
                t={type === 'attractions' ? 'more_places_to_see' : 'more_ideas_for_destination'}
                values={{ destination: destinationName }}
              />
            </Line>
            <Flex $flexWrap="wrap">
              {subLists.map((subList, i) => {
                const firstLink =
                  (subList.linkTo && subList.linkTo[0]) ||
                  (subList.listElements &&
                    subList.listElements[0].linkTo &&
                    subList.listElements[0].linkTo[0]);

                let imageUrl = subList.photo ? subList.photo.photoUrls[0] : null;

                if (
                  subList.listElements &&
                  subList.listElements[0].photo &&
                  subList.listElements[0].photo.photoUrls
                ) {
                  // eslint-disable-next-line prefer-destructuring
                  imageUrl = subList.listElements[0].photo.photoUrls[0];
                }

                if (firstLink && !subList.photo) {
                  if (firstLink.type === 'poi') {
                    imageUrl = `${BITMAP_URL}/poi/${firstLink.id}/500x100?returnCrop=yes`;
                  } else if (firstLink.type === 'category') {
                    const categories =
                      configs[language] &&
                      configs[language].Tags.concat(configs[language].Categories);
                    const category = categories
                      ? categories.find(c => Number(c.Tag) === firstLink.id)
                      : null;
                    imageUrl = category ? `${RES_URL}/tag-images/${category.Tag}.jpg` : imageUrl;
                  }
                }

                return (
                  <NavigationItem
                    key={subList.title || i}
                    $py={7.5}
                    width={[1, null, null, null, 1 / 2]}
                  >
                    <Link
                      href={`#${
                        subList.title ? subList.title.replace(/\s+/g, '-').toLowerCase() : i
                      }`}
                    >
                      <LinkFlex
                        $justifyContent="center"
                        $alignItems="center"
                        $flexDirection="column"
                        $imageUrl={imageUrl}
                        $px={12}
                      >
                        <StyledLine fontSize={24} fontWeight={900}>
                          {subList.title}
                        </StyledLine>
                        <StyledOverlay />
                      </LinkFlex>
                    </Link>
                  </NavigationItem>
                );
              })}
            </Flex>
          </Flex>
        )}
        <Flex $py={14} $flexDirection="column" width={1}>
          <Line fontSize={24} fontWeight={900}>
            <Text
              t={
                type === 'attractions'
                  ? 'more_ideas_for_destination'
                  : 'attractions_in_destination_2'
              }
              values={{ destination: destinationName }}
            />
          </Line>
          <Flex $flexWrap="wrap" width={1}>
            {topListTitle && destinationId && (
              <NavigationItem $py={7.5} width={[1, null, null, null, 1 / 2]}>
                <Link
                  href={`#${topListTitle.replace(/\s+/g, '-').toLowerCase()}`}
                >
                  <LinkFlex
                    $justifyContent="center"
                    $alignItems="center"
                    $flexDirection="column"
                    $imageUrl={`/images/destinations/${destinationId}/375x200.jpg`}
                    $px={12}
                  >
                    <StyledLine fontSize={24} fontWeight={900}>
                      {topListTitle}
                    </StyledLine>
                    <StyledOverlay />
                  </LinkFlex>
                </Link>
              </NavigationItem>
            )}
          </Flex>
        </Flex>
      </Container>
    </Root>
  );
};

export default Navigation;
