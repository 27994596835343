// @flow strict
import * as React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Box, Flex } from '../../../../../../../../primitives/Essentials/index.js';
import Line from '../../../../../../../../primitives/Line/index.js';
import Text from '../../../../../../../../components/Text/index.js';
import type { Theme } from '../../../../../../../../records/Theme.js';
import GroupTourRating from '../../../GroupTourRating/index.js';

type Props = {
  ratingsCount: number,
  rating: number,
  providerCount: number,
  theme: Theme,
};

const StyledHeaderLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  text-align: center;
`;

const ReviewsHeader = ({ ratingsCount, rating, providerCount, theme }: Props) => (
  <Box $px={[15, null, null, null, 0]} $pt={40}>
    <Line fontSize={21} fontWeight={900} letterSpacing={0.3} color={theme.section.title}>
      <Text t="reviews_txt" />
    </Line>
    <Box $px={[15, null, null, 0]} $pt={10} $pb={7}>
      <Flex $alignItems={['center', null, null, 'flex-start']} $flexDirection="column" mx={15}>
        <GroupTourRating theme={theme} ratingsCount={ratingsCount} rating={rating} inHeader />
        <StyledHeaderLine fontSize={16} lineHeight={1.31} color={theme.section.abstract}>
          <Text
            html
            t="reviews_header_txt"
            values={{
              avgRating: rating.toFixed(1),
              reviewsNumber: ratingsCount,
              numProviders: providerCount,
            }}
          />
        </StyledHeaderLine>
      </Flex>
    </Box>
  </Box>
);

export default ReviewsHeader;
