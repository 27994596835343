// PoiToursSectionContent.js
// @flow strict
import * as React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Flex } from '../../../../primitives/Essentials/index.js';
import queryString from 'query-string';
import Cookies from 'js-cookie';
// $FlowFixMe
import addDays from 'date-fns/addDays/index.js';
// $FlowFixMe
import isSameDay from 'date-fns/isSameDay/index.js';
import {
  container,
  HEADER,
  TAGLINE,
  COVER_MOBILE,
  COVER,
  COVER_TABLET,
  FOOTER,
  FOOTER_MOBILE,
  CONTENT_MARGIN
} from '../../../../services/styleUtils/index.js';
import mq from '../../../../services/mediaQuery/index.js';
import PoiAvailability from '../../../../components/PoiAvailability/index.js';
import type { Language } from '../../../../services/intl/context.js';
import type { GroupToursBySection } from '../../../../records/Tour.js';
import { parseDate, formatDateYMD } from '../../../../services/dateTimeUtils/index.js';
import { getPoiTours } from '../../../../services/api/tours/index.js';
import SvgSpinner from '../../../../components/SvgSpinner/index.js';
import { COOKIES } from '../../../../records/Cookies.js';
import type { Promocodes } from '../../../../records/Promocodes.js';
import type { Providers } from '../../../../records/Providers.js';
import type { Currencies } from '../../../../records/Currencies.js';
import type { ImageAttributions } from '../../../../records/Photo.js';
import type { Currency } from '../../../../services/currency/context.js';
import type { Poi } from '../../../../records/Poi.js';
import type { PoiToursSectionContent as PoiToursSectionContentType } from '../../../../records/PoiToursSectionContent.js';
import Breadcrumbs from '../../../../components/Breadcrumbs/index.js';
import {
  BREADCRUMBS_LAYOUT,
  LAYOUT_COMMON,
  SECTION_LAYOUT_NO_PADDING_MOBILE,
} from '../../../../consts/layout.js';
import Abstract from '../../../../components/Abstract/index.js';
import PoiSections from '../../../PoiPage/components/PoiContent/components/PoiSections/index.js';
import DEFAULT_POI_SECTIONS from '../../../../../../etc/sections.js';
import NoToursNote from '../../../PoiPage/components/PoiContent/components/NoToursNote/index.js';
import History from '../../../../components/History/index.js';
import ClientOnly from '../../../../components/ClientOnly/index.js';
import EditorialContent from '../../../../components/EditorialContent/index.js';
import AuthorBio from '../../../../components/AuthorBio/index.js';
import mapperPoiContent from '../../../../services/mapper/poiContent/index.js';

const StyledContainerBox = styled(Box)`
  ${container()};
  min-height: calc(
    100vh - ${HEADER} - ${TAGLINE} - ${FOOTER_MOBILE} - ${COVER_MOBILE} - ${CONTENT_MARGIN} - 57px
  );
  ${mq.TABLET`
    min-height: calc(100vh - ${HEADER} - ${TAGLINE} - ${FOOTER} - ${COVER_TABLET} - ${CONTENT_MARGIN} - 67px);
  `};
  ${mq.DESKTOP`
    min-height: calc(100vh - ${HEADER} - ${TAGLINE} - ${FOOTER} - ${COVER} - ${CONTENT_MARGIN} - 67px);
    left-margin: 0px;
  `};
`;

const Container = styled(Box)`
  ${container()};
`;

type Props = {
  tours: GroupToursBySection,
  language: Language,
  poi: Poi,
  content: ?PoiToursSectionContentType,
  goBackBackHref: string,
  goBackBackText: string,
  goBackHref: string,
  goBackText: string,
  pageUrl: string,
  promocodes: Promocodes,
  providers: Providers,
  currencies: Currencies,
  isMobile: boolean,
  currency: Currency,
  imageAttributions: ImageAttributions,
};

type State = {
  date: ?Date,
  dateTours: ?GroupToursBySection,
  isLoading: boolean,
  updatedContent: ?PoiToursSectionContentType,
};

// $FlowFixMe
const hasTours = (tours: GroupToursBySection) => Object.values(tours).some(x => x.length);

// defined by content team or default
const getSections = (content: ?PoiToursSectionContentType, language: Language) =>
  content && content.sections && !content.sections.some(section => section.disabled)
    ? content.sections
    : DEFAULT_POI_SECTIONS[language];

const PoiToursSectionContent = ({
  tours,
  language,
  poi,
  content,
  goBackBackHref,
  goBackBackText,
  goBackHref,
  goBackText,
  pageUrl,
  promocodes,
  providers,
  currencies,
  isMobile,
  currency,
  imageAttributions,
}: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [date, setDate] = React.useState<?Date>(null);
  const [dateTours, setDateTours] = React.useState<?GroupToursBySection>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [updatedContent, setUpdatedContent] = React.useState<?PoiToursSectionContentType>(null);
  const contentWrapperNode = React.useRef<?HTMLElement>(null);
  const sectionWrapperNode = React.useRef<?HTMLElement>(null);

  React.useEffect(() => {
    let parsedDate;
    if (hasTours(tours)) {
      const queryParams = queryString.parse(location.search);
      if (queryParams.date) {
        parsedDate = parseDate(queryParams.date);
      } else if (Cookies.get(COOKIES.DATE)) {
        parsedDate = parseDate(Cookies.get(COOKIES.DATE));
      }

      if (parsedDate && !isNaN(parsedDate.getTime())) {
        setDate(parsedDate);
        setIsLoading(true);
        updateTours(parsedDate);
      }
    }
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('message', handleMessage);
    };
  }, [tours]);

  const handleMessage = event => {
    if (
      typeof window !== 'undefined' && // Ensure window is defined
      event.data.uniqueId &&
      ((event.origin.includes('localhost') && window.location.origin.includes('localhost')) ||
        (event.origin.includes('admin') && window.location.origin.includes('ticketlens')))
    ) {
      setUpdatedContent(mapperPoiContent(event.data, language, imageAttributions));
      updateTours();
    }
  };

  const handleScroll = () => {
    if (typeof window === 'undefined') return; // Ensure window is defined
    const currentScrollPosition = window.pageYOffset;

    if (contentWrapperNode.current) {
      const contentOutOfView =
        currentScrollPosition >=
        contentWrapperNode.current.offsetTop +
        contentWrapperNode.current.getBoundingClientRect().height;
      // Handle content out of view if needed
    }
  };

  const handleChangeDate = (newDate: Date) => {
    const queryParams = queryString.parse(location.search);
    if (date && isSameDay(newDate, date)) {
      delete queryParams.date;
      navigate({ search: queryString.stringify(queryParams) });
      Cookies.remove(COOKIES.DATE);
    } else {
      queryParams.date = formatDateYMD(newDate);
      navigate({ search: queryString.stringify(queryParams) });
      Cookies.set(COOKIES.DATE, queryParams.date, {
        expires: addDays(newDate, 1),
      });
    }
    const newDateState = (date && !isSameDay(newDate, date)) || !date ? newDate : null;
    setDate(newDateState);
    setIsLoading(Boolean(newDateState));
    updateTours(newDate);
  };

  const updateTours = (selectedDate: ?Date) => {
    if (selectedDate) {
      setIsLoading(true);
      getPoiTours(
        language,
        poi.objectID,
        getSections(updatedContent || content, language),
        promocodes,
        currencies,
        providers,
        formatDateYMD(selectedDate),
      ).then(dateTours => {
        const allToursLength = Object.keys(dateTours).reduce(
          (acc, section) => acc + dateTours[section].length,
          0,
        );
        if (allToursLength === 0) {
          setDateTours(null);
          setDate(null);
        } else {
          setDateTours(dateTours);
        }
        setIsLoading(false);
        })
        .catch(error => {
          console.error('Error fetching POI tours:', error);
          setIsLoading(false);
        });
    }
  };

  const shownTours = (date || updatedContent) && dateTours ? dateTours : tours;
  const _content = updatedContent || content;
  const sections = getSections(_content, language);

  return (
    <>
      <StyledContainerBox>
        <Box $px={BREADCRUMBS_LAYOUT.$px}>
          <Breadcrumbs
            activeIndex={2}
            items={[
              { name: goBackBackText, url: goBackBackHref },
              { name: goBackText, url: goBackHref },
              { name: _content.title, url: pageUrl },
            ]}
          />
        </Box>
        <Flex $flexDirection={['column', null, null, null, null, 'row']}>
          {_content && _content.abstract && (
            <Box width={[1, null, null, null, null, 1 / 2]}>
              <Abstract author={_content.author}>
                <span dangerouslySetInnerHTML={{ __html: _content.abstract }} />
              </Abstract>
            </Box>
          )}
          <Box
            width={[1, null, null, null, null, 1 / 2]}
            $pl={_content ? [0, null, null, null, 30, 15] : [0, null, null, null, 30]}
            $pr={_content ? [0, null, null, null, 30] : [0, null, null, null, 30, 0]}
          >
            {hasTours(tours) ? (
              <PoiAvailability date={date} onChangeDate={handleChangeDate} />
            ) : (
              <NoToursNote />
            )}
          </Box>
        </Flex>
        {isLoading && (
          <Box $mt={40}>
            <Flex $justifyContent="center" $alignItems="center">
              <SvgSpinner />
            </Flex>
          </Box>
        )}
        {!isLoading && shownTours && (
          <div ref={sectionWrapperNode}>
            <PoiSections shownTours={shownTours} poi={poi} date={date} sections={sections} isPoiToursSectionPage={true} />
          </div>
        )}
        <div ref={_content && _content.editorialContent ? contentWrapperNode : null}>
          {updatedContent ? (
            <>{updatedContent.editorialContent && <EditorialContent editorialContent={updatedContent.editorialContent} smallerMarginTop />}</>
          ) : (
            <>{content && content.editorialContent && <EditorialContent editorialContent={content.editorialContent} smallerMarginTop />}</>
          )}
        </div>
      </StyledContainerBox>
      {_content && _content.author && <AuthorBio author={_content.author} translator={_content.translator} />}
      <ClientOnly>
        {typeof window !== 'undefined' && window.localStorage.getItem('clickHistory') && (
          <Container $mt={LAYOUT_COMMON.$mt} $py={LAYOUT_COMMON.$py} $px={SECTION_LAYOUT_NO_PADDING_MOBILE.$px}>
            <History
              language={language}
              promocodes={promocodes}
              currencies={currencies}
              providers={providers}
              history={window.localStorage.getItem('clickHistory')}
            />
          </Container>
        )}
      </ClientOnly>
    </>
  );
};

export default PoiToursSectionContent;