// @flow strict
import * as React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Box, Flex } from '../../../../../../../../primitives/Essentials/index.js';
import Text from '../../../../../../../../components/Text/index.js';
import Line from '../../../../../../../../primitives/Line/index.js';
import type { Theme } from '../../../../../../../../records/Theme.js';

const StyledInfoBox = styled(Box)`
  min-width: 20px;
  min-height: 20px;
  object-fit: contain;
`;

const StyledAbstractTextBox = styled(Box)`
  max-width: 720px;
`;

type Props = {
  theme: Theme,
};

const BookingFeeAbstract = ({ theme }: Props) => (
  <Flex $px={15} $pt={13.5} $pb={12} bg={theme.trip.backgroundLight}>
    <StyledInfoBox>
      <img src="/icons/info.svg" alt="info" />
    </StyledInfoBox>
    <StyledAbstractTextBox $ml={10}>
      <Line color={theme.trip.abstract} fontSize={14} letterSpacing={0.5}>
        <Text t="booking_fee_abstract" />
      </Line>
    </StyledAbstractTextBox>
  </Flex>
);

export default BookingFeeAbstract;
