// @flow strict
import * as React from 'react';
import { Box, Flex } from '../../../../primitives/Essentials/index.js';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import Text from '../../../Text/index.js';
import IntlContext from '../../../../services/intl/context.js';
import CurrencyContext from '../../../../services/currency/context.js';
import mq from '../../../../services/mediaQuery/index.js';
import Line from '../../../../primitives/Line/index.js';
import ClientOnly from '../../../ClientOnly/index.js';
import CurrencySelect from '../../../CurrencySelect/index.js';
import SelectButton from '../../../SelectButton/index.js';
import LanguageSelect from '../../../LanguageSelect/index.js';

const StyledSelect = styled(SelectButton)`
  > span {
    width: initial;
    padding: 0 11px 0 12px;
    color: ${({ theme }) => theme.primary};
    font-weight: normal;
    font-size: 16px;
    letter-spacing: -0.1px;
    border-radius: 5px;
  }

  ${mq.TABLET`
    max-width: 188px;
  `}
`;

const StyledBox = styled(Box)`
  position: relative;
`;

const StyledLanguageLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  padding-bottom: 5px;
  color: ${({ theme }) => theme.tertiary};
`;

const StyledCurrencyLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  padding-top: 13px;
  padding-bottom: 5px;
  color: ${({ theme }) => theme.tertiary};
`;

const FooterOptions = () => {
  const [currencyOpen, setCurrencyOpen] = React.useState(false);
  const [languageOpen, setLanguageOpen] = React.useState(false);

  return (
    <IntlContext.Consumer>
      {({ language, translate }) => (
        <StyledBox width={[1, null, null, null, 1 / 3, 1 / 4]} $pb={20}>
          <Flex $flexDirection="column">
            <StyledLanguageLine fontSize={16} fontWeight={900} letterSpacing={-0.1}>
              <Text t="language" />
            </StyledLanguageLine>
            {languageOpen && (
              <LanguageSelect language={language} onCancel={() => setLanguageOpen(false)} bottom />
            )}
            <StyledSelect
              value={translate(`${language}_language`)}
              onChange={() => setLanguageOpen(!languageOpen)}
            />
            <StyledCurrencyLine fontSize={16} fontWeight={900} letterSpacing={-0.1}>
              <Text t="currency" />
            </StyledCurrencyLine>
            <CurrencyContext.Consumer>
              {({ currency }) => (
                <ClientOnly>
                  {currencyOpen && (
                    <CurrencySelect
                      language={language}
                      onCancel={() => setCurrencyOpen(false)}
                      bottom
                    />
                  )}
                  <StyledSelect
                    value={currency.toUpperCase()}
                    onChange={() => setCurrencyOpen(!currencyOpen)}
                  />
                </ClientOnly>
              )}
            </CurrencyContext.Consumer>
          </Flex>
        </StyledBox>
      )}
    </IntlContext.Consumer>
  );
};

export default FooterOptions;
