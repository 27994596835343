// @flow strict
import * as React from 'react';
import { Box } from '../../../../../../primitives/Essentials/index.js';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import Line from '../../../../../../primitives/Line/index.js';
import Text from '../../../../../Text/index.js';
import IntlContext from '../../../../../../services/intl/context.js';

const StyledHeaderLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  color: ${({ theme }) => theme.primary};
`;

const SuggestionsHeader = () => (
  <IntlContext.Consumer>
    {({ translate }) => (
      <Box $px={15} $pt={14} $pb={6}>
        <StyledHeaderLine fontSize={22} fontWeight={900}>
          <Text t={translate('suggestions_header')} />
        </StyledHeaderLine>
      </Box>
    )}
  </IntlContext.Consumer>
);

export default SuggestionsHeader;
