// @flow strict
import * as React from 'react';
import type { Match } from 'react-router-dom';

import IntlContext from '../../services/intl/context.js';
import CurrencyContext from '../../services/currency/context.js';
import ContentContext from '../../services/content/context.js';
import TourForwardContent from './components/TourForwardContent/index.js';

type Props = {
  match: Match,
  tourId: number,
};

const TourForwardPage = ({ match, tourId }: Props) => {
  const { language, translate } = React.useContext(IntlContext);
  const { currency } = React.useContext(CurrencyContext);
  const { providers, promocodes, currencies } = React.useContext(ContentContext);

  if (!tourId) {
    return null;
  }

  return (
    <TourForwardContent
      translate={translate}
      language={language}
      currency={currency}
      tourId={tourId}
      providers={providers}
      promocodes={promocodes}
      currencies={currencies}
    />
  );
};

export default TourForwardPage;
