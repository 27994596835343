// @flow strict
import * as React from 'react';
import { Flex } from '../../../../../../../../primitives/Essentials/index.js';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import Line from '../../../../../../../../primitives/Line/index.js';
import UrlContext from '../../../../../../../../services/url/context.js';
import Link from '../../../../../../../Link/index.js';
import { RES_URL } from '../../../../../../../../../../etc/appConfig.js';

const StyledSuggestionFlex = styled(Flex)`
  height: auto;
  background-color: ${({ selected, theme }) => (selected ? theme.trip.backgroundLight : 'initial')};
`;

const StyledSuggestionContainerFlex = styled(Flex)`
  width: 100%;
`;

const StyledDestinationNameSuggestionLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  color: ${({ theme }) => theme.section.title};
`;

const StyledDestinationCountrySuggestionLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  color: ${({ theme }) => theme.trip.divider};
`;

const Icon = styled.span`
  min-width: 16px;
  margin-right: 16px;
`;

const StyledImg = styled.img`
  width: 40px;
  height: 40px;
  object-fit: cover;
`;

const BottomSeparator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.trip.backgroundLight};
`;

type Props = {
  name: string,
  tag: string,
  country: string,
  selected: boolean,
  onHover: () => void,
};

const DestinationSuggestion = ({ name, tag, country, selected, onHover }: Props) => {
  const destinationId = Number(tag.replace('d:', ''));

  return (
    <UrlContext.Consumer>
      {({ buildDestinationPageUrl }) => (
        <Link href={buildDestinationPageUrl(destinationId)}>
          <StyledSuggestionFlex
            $px={15}
            $flexDirection="column"
            $alignItems="flex-start"
            selected={selected}
            onMouseEnter={onHover}
          >
            <StyledSuggestionContainerFlex
              $justifyContent="space-between"
              $alignItems="center"
              $py={5}
            >
              <Flex justifiContent="flex-start" $alignItems="center">
                <Icon>
                  <img src="/icons/destination.svg" alt="destination" />
                </Icon>
                <StyledDestinationNameSuggestionLine fontSize={18}>
                  {name}
                  <StyledDestinationCountrySuggestionLine fontSize={18}>
                    {`, ${country}`}
                  </StyledDestinationCountrySuggestionLine>
                </StyledDestinationNameSuggestionLine>
              </Flex>
              <StyledImg src={`${RES_URL}/destination-images/${destinationId}.jpg`} alt={name} />
            </StyledSuggestionContainerFlex>
            <BottomSeparator />
          </StyledSuggestionFlex>
        </Link>
      )}
    </UrlContext.Consumer>
  );
};

export default DestinationSuggestion;
