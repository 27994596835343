// @flow strict
import * as React from 'react';
import { css } from 'styled-components';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { CHATBOT_ENDPOINT } from '../../../../etc/appConfig.js';
import { logEvent, isGoogleBot } from '../../services/ga4/index.js';

// CHATBOT_ENDPOINT = 'http://127.0.0.1:9090/chat'; // Local testing endpoint

const ChatWrapper = styled.div`
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: 500px;
  border-radius: 10px;  /* Add rounded borders */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  /* Add a light shadow */
  background-color: white;
  display: flex;
  flex-direction: column;
  z-index: 11000;
  box-sizing: border-box;

  /* Media query for phones or smaller screen devices */
  @media (max-width: 768px) {
    box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1);  /* Add shadow at the top */
    width: 100%; /* Take the full width of the screen */
    /*max-height: 80vh;*/ /* 80% of the screen height */
    left: 0px;
    bottom: 0px;
    border-radius: 0;
    overflow-y: auto;
  }
`;

const ChatContainer = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
  overflow-y: scroll;
  flex-grow: 1;
  min-height: 100px;
  max-height: 500px;
  display: flex;
  flex-direction: column; 
  box-sizing: border-box;

  /* Media query for phones or smaller screen devices */
  @media (max-width: 768px) {
    /*height: calc(80vh - 50px)*/ /* Adjust the height accordingly */
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px; 
  }
`;

const SpeakingBubble = styled.div`
  position: fixed;
  bottom: 90px;
  left: 20px;
  width: 250px;
  padding: 10px 15px;
  background-color: #f9f9f9;
  color: black;
  border-radius: 20px;
  border: 2px solid #c51162;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  z-index: 10999;
  display: flex;
  align-items: left;
  justify-content: left;
  animation: fadeIn 0.5s ease-in-out;
  border-bottom-left-radius: 0;

  &:hover {
    background-color: #f5f5f5;
  }

  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(10px); }
    to { opacity: 1; transform: translateY(0); }
  }
`;

const Message = styled.div`
  margin-bottom: 10px;
  padding: 8px 12px;
  border-radius: 16px;
  max-width: 60%;
  box-sizing: border-box;
  
  @media (max-width: 768px) {
    max-width: 80%;
  }
`;

const UserMessage = styled(Message)`
  align-self: flex-end;
  background-color: #f5f5f5; 
  border-bottom-right-radius: 0;
  box-sizing: border-box;
`;

const BotMessage = styled(Message)`
  align-self: flex-start;
  background-color: #e0e7ff;
  border-bottom-left-radius: 0;
  box-sizing: border-box;
`;

const InputContainer = styled.div`
  display: flex;
  padding: 10px;
  border-top: 1px solid #ccc;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap; 
  box-sizing: border-box;
  min-height: 50px; /* This line ensures that the container's height won't go below 50px */

  @media (max-width: 768px) {
    width: 100%; /* Take the full width of the screen */
    height: auto;
    overflow-y: auto;
  }
`;

const ChatInput = styled.input`
  flex: 1; 
  padding: 5px;
  margin-right: 10px; 
  border-radius: 5px;
  border: 1px solid #ccc; 
  box-sizing: border-box;
`;

const SendButton = styled.button`
  width: 40px;  
  height: 30px; 
  border: none;
  border-radius: 7px;  
  background-color: #c51162;
  color: white;
  cursor: pointer;
  font-size: 22px; 
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  box-sizing: border-box;

  &:hover {
    background-color: #a00e4e;
  }
`;

const DisabledSendButton = styled(SendButton)`
  background-color: white;
  cursor: not-allowed;
  color: #e0e0e0;
  border: 1px solid #e0e0e0;
  &:hover {
    background-color: white;
  }
`;

const CloseButton = styled.button`
  color: white;
  position: absolute;
  border: none;
  top: 8px;
  right: 8px;
  width: 26px;
  height: 26px;
  font-size: 18px; 
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 100;
  background-color: #c9c7c8;
  border-radius: 50%;
  padding-left: 0px;
  padding-right: 0px;

  &:hover {
    background-color: #808080;
  }
`;

const OpenButton = styled.div`
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: 50px;
  height: 50px;
  background-image: url(/images/ticket.png);
  background-size: cover;
  background-repeat: no-repeat; 
  background-position: center;
  background-size: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #f5f5f5;
  border-radius: 50%;
  z-index: 11000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #f2f2f2;
  }

  ${({ $shouldPulse }) =>
    $shouldPulse &&
    css`
      animation: pulseAnimation 2s 1;
    `}

  @keyframes pulseAnimation {
    0% {
      background-color: rgba(245, 245, 245, 1);
    }
    35% {
      background-color: rgba(250, 199, 222, 1);
    }
    70% {
      background-color: rgba(245, 245, 245, 1);
    }
    100% {
      background-color: rgba(245, 245, 245, 1);
    }
  }
`;

const BlinkingDots = styled.div`
  display: inline-block;
  box-sizing: border-box;
  width: 35px;
  height: 10px;
  position: relative;
  & span {
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #000;
    opacity: 0.4;
    top: 50%;
    transform: translate(-50%, -50%);
    animation: blink 1.4s infinite both;
  }
  & span:nth-child(1) {
    left: 5px;
  }
  & span:nth-child(2) {
    left: 15px;
    animation-delay: 0.2s;
  }
  & span:nth-child(3) {
    left: 25px;
    animation-delay: 0.4s;
  }
  @keyframes blink {
    0% {
      opacity: 0.2;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }
`;


class ChatUserInterface extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      messages: [
        {
          type: 'bot',
          text: this.getGreetingMessage(props.language, props.poiName)
        }
      ],
      userMessage: '',
      lastBotResponseIndex: 0,
      loading: false,
      isOpen: false,
      isVisible: true,
      showSpeakingBubble: false,
      animatedText: '',
      location: props.location,
      language: props.language,
      poiId: props.poiId,
      poiName: props.poiName,
      bubbleTimeout: null, // To manage the auto-close timer
      hasScrolled: false, // Track if the user has started scrolling
      shouldPulse: false,  // Controls the pulsing effect on the chat button
      animationKey: 0,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSend = this.handleSend.bind(this);
    this.postMessagesToChatbot = this.postMessagesToChatbot.bind(this);
    this.chatEndRef = React.createRef();
    this.toggleChat = this.toggleChat.bind(this);
    this.chatWrapperRef = React.createRef();
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.inputRef = React.createRef();
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.setChatWrapperHeight = this.setChatWrapperHeight.bind(this);
    this.handleSpeakingBubbleClick = this.handleSpeakingBubbleClick.bind(this);
    this.autoCloseBubble = this.autoCloseBubble.bind(this);
    this.handleScrollStart = this.handleScrollStart.bind(this);  // Handling initial scroll
  }

  getGreetingMessage(language, poiName) {
    const messages = {
      en: `Hello!\nI am the TicketLens AI assistant. I am here to assist you and answer all your questions!`,
      de: `Hallo!\nIch bin die TicketLens KI Assistent. Ich stehe dir zur Verfügung und beantworte alle deine Fragen!`,
      // Add more languages as needed
    };

    return messages[language] || messages.en; // Default to English if the language is not supported
  }

  getErrorMessage(language) {
    const errorMessages = {
      en: "Oops! I couldn't reach the TicketLens AI. Let's try again?",
      de: "Ups! Ich habe die TicketLens KI nicht erreicht. Versuchen wir es noch einmal?",
      // Add more languages as needed
    };

    return errorMessages[language] || errorMessages.en; // Default to English if the language is not supported
  }

  focusOnInputField() {
    if (this.inputRef && this.inputRef.current) {
      this.inputRef.current.focus();
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleOutsideClick, false);
    document.addEventListener('mouseup', this.handleOutsideClick, false);
    window.addEventListener('resize', this.setChatWrapperHeight);

    // don't set this timer for Googlebot
    if (!isGoogleBot()) {
      this.speakingBubbleTimeout = setTimeout(() => {
        this.setState({
          showSpeakingBubble: true,
          shouldPulse: true
        });
        this.animateTypingText(this.getGreetingMessage(this.state.language, this.state.poiName));
        document.addEventListener('scroll', this.handleScrollStart, { once: true, passive: true });
      }, 3000);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick, false);
    document.removeEventListener('mouseup', this.handleOutsideClick, false);

    window.removeEventListener('resize', this.setChatWrapperHeight);
    if ('visualViewport' in window) {
      window.visualViewport.removeEventListener('resize', this.setChatWrapperHeight);
    }

    if (this.speakingBubbleTimeout) {
      clearTimeout(this.speakingBubbleTimeout);
    }
    if (this.typingInterval) {
      clearInterval(this.typingInterval);
    }
    // Clear the bubble timeout if it's set
    if (this.state.bubbleTimeout) {
      clearTimeout(this.state.bubbleTimeout);
    }
    document.removeEventListener('scroll', this.handleScrollStart, { passive: true });  // Remove scroll listener
  }

  autoCloseBubble() {
    this.setState((prevState) => ({
      showSpeakingBubble: false,
      bubbleTimeout: null,
      shouldPulse: true,
      animationKey: prevState.animationKey + 1, // Increment the animationKey
    }));
  }

  handleScrollStart() {
    // Start the auto-close timer the first time the user scrolls
    if (!this.state.hasScrolled) {
      this.setState({
        hasScrolled: true,
        bubbleTimeout: setTimeout(this.autoCloseBubble, 15000)
      });
    }
  }

  // Method to animate the text in the speaking bubble
  animateTypingText(fullText) {
    let index = 0;
    const typingSpeed = 15; // Speed for regular characters
    const newLineDelay = 500; // Delay for newlines (500ms)

    this.typingInterval = setInterval(() => {
      if (index < fullText.length) {
        const currentChar = fullText[index];
        if (currentChar === '\n') {
          // Add a newline and apply the longer delay
          this.setState(prevState => ({
            animatedText: prevState.animatedText + '<br/>'
          }));
          index++;
          clearInterval(this.typingInterval); // Clear the interval temporarily
          setTimeout(() => {
            this.animateTypingText(fullText.slice(index)); // Continue after the delay
          }, newLineDelay); // Wait longer after newline
        } else {
          // Regular character typing
          this.setState(prevState => ({
            animatedText: prevState.animatedText + currentChar
          }));
          index++;
        }
      } else {
        clearInterval(this.typingInterval);
      }
    }, typingSpeed); // Adjust typing speed (50ms per character)
  }

  handleSpeakingBubbleClick() {
    if (this.state.bubbleTimeout) {
      clearTimeout(this.state.bubbleTimeout);
    }
    this.setState({
      isOpen: true,
      showSpeakingBubble: false,
      bubbleTimeout: null,
      shouldPulse: false  // Stop pulsing when the chat window opens
    }, () => {
      logEvent('speaking_bubble_clicked', {
        language: this.state.language,
        poi_id: this.state.poiId,
        poi_name: this.state.poiName
      });
      this.focusOnInputField();
      this.setChatWrapperHeight();
    });
  }

  setChatWrapperHeight() {
    if (window.innerWidth <= 768 && this.chatWrapperRef && this.chatWrapperRef.current) {
      // First, get the current style height as a number.
      const computedStyle = window.getComputedStyle(this.chatWrapperRef.current);
      // Extract the height value from the computed style, remove 'px', and convert to an integer
      const currentHeight = parseInt(computedStyle.height.replace('px', ''), 10);
      // console.log(`Current height: ${currentHeight}px`);

      const viewportHeight = 'visualViewport' in window ? window.visualViewport.height : window.innerHeight;
      const maxHeight = viewportHeight - 100;

      // console.log(`Calculated height: ${maxHeight}px`);

      if (currentHeight > maxHeight || currentHeight < 150) {
        this.chatWrapperRef.current.style.height = `${maxHeight}px`;
        // console.log(`ChatWrapperRef's style height set to: ${this.chatWrapperRef.current.style.height}`);
      }  
    }
  }

  handleOutsideClick(e) {
    // Check if click is outside the chat wrapper and if chat covers more than 60% of screen
    if (
      window.innerWidth <= 768 && // Only do this for mobile devices
      this.state.isOpen &&
      this.chatWrapperRef.current && // Access the current property of the ref
      !this.chatWrapperRef.current.contains(e.target) && // Use contains on the current property
      this.chatWrapperRef.current.getBoundingClientRect().height > (0.4 * window.innerHeight) // Use getBoundingClientRect on the current property
    ) {
      this.toggleChat();
    }
  }

  handleKeyPress(event) {
    if (event.key === 'Enter' && this.state.userMessage && !this.state.loading) {
      this.handleSend();
      event.preventDefault();  // This prevents the default behavior of the Enter key
    }
  }

  toggleChat() {
    this.setState(prevState => {
      const newIsOpen = !prevState.isOpen;
      if (newIsOpen) {
        // If opening the chat, hide the speaking bubble
        if (this.speakingBubbleTimeout) {
          clearTimeout(this.speakingBubbleTimeout);
          this.speakingBubbleTimeout = null;
        }
        return { isOpen: newIsOpen, showSpeakingBubble: false };
      }
      return { isOpen: newIsOpen };
    });
  }

  handleInputChange(event) {
    this.setState({ userMessage: event.target.value });
  }

  handleSend() {
    this.setState((prevState) => {
      return {
        messages: prevState.messages.concat([
          { type: 'user', text: prevState.userMessage }
        ]),
        userMessage: ''
      };
    }, () => {
      // Log 'chat_message_sent' event
      logEvent('chat_message_sent', {
        language: this.state.language,
        poi_id: this.state.poiId,
        poi_name: this.state.poiName
      });

      this.postMessagesToChatbot(this.state.messages);
      this.focusOnInputField();
      this.setChatWrapperHeight();
    });
  }

  formatBotMessage(text) {
    return text.split('\n').map((line, index) => {
      // Split the line by **_ for bold and italic, ** for bold, and * for italic
      const parts = line.split(/(\*\*_.*?_\*\*|\*\*.*?\*\*|\*.*?\*)/g);
      return (
        <React.Fragment key={index}>
          {parts.map((part, i) => {
            if (part.startsWith('**_') && part.endsWith('_**')) {
              return <strong key={i}><em>{this.formatLinks(part.slice(3, -3))}</em></strong>;
            } else if (part.startsWith('**') && part.endsWith('**')) {
              return <strong key={i}>{this.formatLinks(part.slice(2, -2))}</strong>;
            } else if (part.startsWith('*') && part.endsWith('*')) {
              return <em key={i}>{this.formatLinks(part.slice(1, -1))}</em>;
            } else {
              return this.formatLinks(part);
            }
          })}
          <br />
        </React.Fragment>
      );
    });
  }

  formatLinks(text) {
    const linkRegex = /\[([^\]]+)\]\((https?:\/\/[^\)]+)\)/g;
    const parts = text.split(linkRegex);

    return parts.map((part, index) => {
      if (index % 3 === 1) {
        const url = parts[index + 1];
        return <a key={index} href={url} target="_blank" rel="noopener noreferrer">{part}</a>;
      } else if (index % 3 === 2) {
        return null; // Skip the URL part
      } else {
        return part;
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isVisible !== this.props.isVisible) {
      this.setState({ isVisible: this.props.isVisible });
    }
    if (this.state.messages.length !== prevState.messages.length || this.state.loading !== prevState.loading) {
      if (this.chatEndRef.current) {
        this.chatEndRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
    this.focusOnInputField();
    this.setChatWrapperHeight();
  }

  async postMessagesToChatbot(messages) {
    this.setState({ loading: true });  // Start the loading indicator

    const formattedMessages = messages.map(message => ({
      label: message.type,
      chatmessage: message.text
    }));

    // Add the hardcoded parameters
    const params = {
      context_title: this.state.poiName,
      context: `p${this.state.poiId}`,
      language: this.state.language
    };

    const queryString = new URLSearchParams(params).toString();
    const fullUrl = `${CHATBOT_ENDPOINT}?${queryString}`;

    try {
      const response = await fetch(fullUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formattedMessages)
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();

      this.setState({ loading: false });  // Stop the loading indicator

      // Filter out bot responses using the lastBotResponseIndex
      const newBotResponses = responseData.filter((item, index) => item.label === 'bot' && index > this.state.lastBotResponseIndex);

      if (newBotResponses.length > 0) {
        const newMessages = newBotResponses.map(response => ({
          type: response.label,
          text: response.chatmessage
        }));

        this.setState((prevState) => ({
          messages: prevState.messages.concat(newMessages),
          lastBotResponseIndex: responseData.lastIndexOf(newBotResponses[newBotResponses.length - 1])  // Update the lastBotResponseIndex
        }), () => {
          this.focusOnInputField();
        });
      }

    } catch (error) {
      console.error("Error posting messages to chatbot:", error);
      // Log error event to Google Analytics
      logEvent('chat_message_error', {
        language: this.state.language,
        poi_id: this.state.poiId
      });

      this.setState((prevState) => {
        // Calculate the new index by getting the current length of messages.
        // This assumes that all responses from the server have been processed and added to the state.
        const newMessages = prevState.messages.concat([
          { type: 'bot', text: this.getErrorMessage(this.state.language) }
        ]);

        return {
          loading: false,
          messages: newMessages,
          // Subtracting 1 because we want the index of the last bot message,
          // which is the one just added with the error message.
          lastBotResponseIndex: newMessages.length - 1
        };
      }, () => {
        if (this.chatEndRef.current) {
          this.chatEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
        this.focusOnInputField();
      });
    }
  }

  render() {
    return (
      <div hidden={!this.state.isVisible}>
        {/* Speaking Bubble for Test Group */}
        {this.state.showSpeakingBubble && !this.state.isOpen && (
          <SpeakingBubble onClick={this.handleSpeakingBubbleClick}>
            <div dangerouslySetInnerHTML={{ __html: this.state.animatedText }} />
          </SpeakingBubble>
        )}

        {
          this.state.isOpen ? (
            <ChatWrapper ref={this.chatWrapperRef}>
              <CloseButton onClick={this.toggleChat}>&times;</CloseButton>
              <ChatContainer>
                {this.state.messages.map((message, index) => (
                  message.type === 'user' ? (
                    <UserMessage key={index}>{message.text}</UserMessage>
                  ) : (
                    <BotMessage key={index}>{this.formatBotMessage(message.text)}</BotMessage>
                  )
                ))}
                {this.state.loading && (
                  <BotMessage>
                    <BlinkingDots>
                      <span></span>
                      <span></span>
                      <span></span>
                    </BlinkingDots>
                  </BotMessage>
                )}
                <div ref={this.chatEndRef} />
              </ChatContainer>
              <InputContainer>
                <ChatInput
                  ref={this.inputRef}
                  type="text"
                  value={this.state.userMessage}
                  onChange={this.handleInputChange}
                  onKeyPress={this.handleKeyPress}
                />
                {this.state.userMessage && !this.state.loading ? (
                  <SendButton onClick={this.handleSend}>&gt;</SendButton>
                ) : (
                  <DisabledSendButton disabled>&gt;</DisabledSendButton>
                )}
              </InputContainer>
            </ChatWrapper>
          ) : (
            <OpenButton
              key={this.state.animationKey}
              onClick={this.toggleChat}
              $shouldPulse={this.state.shouldPulse}
            />
          )}
      </div>
    );
  }
}

export default ChatUserInterface;