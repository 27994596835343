// @flow strict
import * as React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Box, Flex } from '../../primitives/Essentials/index.js';

type Props = {
  children: React.Node,
};

const StyledContainerBox = styled(Box)`
  background-color: ${({ theme }) => theme.secondary};
`;

const Title = styled.h1`
  margin: 0;
  color: ${({ theme }) => theme.tertiary};
  font-weight: 900;
  font-size: 24px;
  text-align: center;
`;

const Subheader = ({ children }: Props) => (
  <StyledContainerBox $pt={33.5} $px={15} $pb={37.5}>
    <Flex $justifyContent="center" $alignItems="center">
      <Title>{children}</Title>
    </Flex>
  </StyledContainerBox>
);

export default Subheader;
