// @flow strict
import React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Flex, Box } from '../../../../../../primitives/Essentials/index.js';
import SectionHeader from '../../../../../../components/SectionHeader/index.js';
import IntlContext from '../../../../../../services/intl/context.js';
import type { ObservationDeck } from '../../../../../../records/ObservationDeck.js';
import { SECTION_LAYOUT_NO_PADDING_MOBILE } from '../../../../../../consts/layout.js';
import { BITMAP_URL } from '../../../../../../../../etc/appConfig.js';
import Line from '../../../../../../primitives/Line/index.js';
import Text from '../../../../../../components/Text/index.js';
import Image from '../../../../../../components/Image/index.js';

type Props = {
  content: ObservationDeck[],
  poiId: number,
};

const RowLabel = styled(Flex)`
  min-width: 80px;
  max-width: 80px;
  height: ${({ $pxHeight }) => ($pxHeight ? `${$pxHeight}px` : 'initial')};
  min-height: ${({ $pxHeight }) => ($pxHeight ? `${$pxHeight}px` : 'initial')};
  font-weight: 900;
  font-size: 12px;
  text-align: center;
  &:not(&:last-child) {
    border-bottom: 1px solid white;
  }
`;

const Container = styled(Flex)`
  overflow: hidden;
  background: ${({ theme }) => theme.trip.backgroundLight};
  border-radius: 5px;
`;

const Item = styled(Flex)`
  height: ${({ $pxHeight }) => ($pxHeight ? `${$pxHeight}px` : 'initial')};
  min-height: ${({ $pxHeight }) => ($pxHeight ? `${$pxHeight}px` : 'initial')};
  &:not(&:last-child) {
    border-bottom: 1px solid white;
  }
  &:not(&:first-child) {
    border-left: 1px solid white;
  }
`;

const Gold = styled.span`
  color: ${({ theme }) => theme.trip.rating};
  font-weight: 900;
`;

const Table = styled(Flex)`
  overflow-x: scroll;
  background: ${({ theme }) => theme.trip.backgroundLight};
`;

const Button = styled.a`
  padding: 10px 15px;
  color: ${({ theme }) => theme.tertiary};
  font-weight: 900;
  font-size: 16px;
  text-decoration: none;
  text-align: center;
  background: ${({ theme }) => theme.secondary};
  border-radius: 22.8px;
  &:hover {
    color: ${({ theme }) => theme.tertiary};
    text-decoration: underline;
  }
`;

const VerticalText = styled(Flex)`
  width: 25px;
  background-color: ${({ theme }) => theme.secondary};
`;

const VerticalTextInner = styled(Box)`
  color: ${({ theme }) => theme.tertiary};
  font-weight: 900;
  font-size: 12px;
  white-space: nowrap;
  text-transform: uppercase;
  transform: rotate(270deg);
`;

const TableHeader = styled(Flex)`
  z-index: 1;
  box-shadow: 9px 0 15px -9px rgba(0, 0, 0, 0.25);
`;

const TowerImage = styled(Image)`
  width: 190px;
  height: 169px;
  min-height: 169px;
`;

const DeckLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  margin-top: 16px;
  color: ${({ theme }) => theme.trip.abstract};
`;

const ObservationDecks = ({ content, poiId }: Props) => (
  <IntlContext.Consumer>
    {({ translate }) => (
      <Box
        $mt={SECTION_LAYOUT_NO_PADDING_MOBILE.$mt}
        $py={SECTION_LAYOUT_NO_PADDING_MOBILE.$py}
        $px={[0, null, null, null, null, 30]}
        $pl={[0, null, null, null, 30]}
        $ml={[15, null, null, null, 0]}
      >
        <SectionHeader
          title={translate('highest_observation_decks', {
            place: content.length && content[0].destination,
          })}
          center
        />
        <Container $mt={10}>
          <TableHeader $flexDirection="column">
            <RowLabel $alignItems="center" $justifyContent="center" $pxHeight={170} $py={18}>
              <Text t="photo" />
            </RowLabel>
            <RowLabel $alignItems="center" $justifyContent="center" $py={18}>
              <Text t="building" />
            </RowLabel>
            <RowLabel $alignItems="center" $justifyContent="center" $pxHeight={70} $py={21}>
              <Text t="total_height" />
            </RowLabel>
            <RowLabel $alignItems="center" $justifyContent="center" $pxHeight={80} $py={21}>
              <Text t="height_observation_deck" />
            </RowLabel>
            <RowLabel $alignItems="center" $justifyContent="center" $pxHeight={50} $py={18}>
              <Text t="open_since" />
            </RowLabel>
            <RowLabel $alignItems="center" $justifyContent="center" $pxHeight={50} />
          </TableHeader>
          <Table>
            {content.map((deck, i) => (
              <Flex key={deck.title}>
                {i === 0 && (
                  <VerticalText $justifyContent="center" $alignItems="center">
                    <VerticalTextInner>{deck.destination}</VerticalTextInner>
                  </VerticalText>
                )}
                {i >= 1 &&
                  content[i].group !== content[i - 1].group &&
                  content[i].group !== 'sameDestination' && (
                    <VerticalText $justifyContent="center" $alignItems="center">
                      <VerticalTextInner>
                        {content[i].group === 'sameCountry' ? deck.country : <Text t="worldwide" />}
                      </VerticalTextInner>
                    </VerticalText>
                  )}
                <Flex $flexDirection="column">
                  <Item>
                    {deck.poiID ? (
                      <TowerImage
                        src={`${BITMAP_URL}/poi/${deck.poiID}/190x169?returnCrop=yes&bg=F5F5F5`}
                        srcSet={`${BITMAP_URL}/poi/${deck.poiID}/380x338?returnCrop=yes&bg=F5F5F5 2x`}
                        alt={deck.title}
                        lazy
                      />
                    ) : (
                      <TowerImage src="/images/placeholder-large.png" alt={deck.title} lazy />
                    )}
                  </Item>
                  <Item
                    $flexDirection="column"
                    $alignItems="center"
                    $justifyContent="center"
                    $pt={8}
                    $pb={6}
                    $pxHeight={50}
                  >
                    <Line fontSize={deck.title.length >= 24 ? 12 : 16} fontWeight={900}>
                      {deck.title}
                    </Line>
                    <Line fontSize={12}>
                      {deck.destination} | {deck.country}
                    </Line>
                  </Item>
                  <Item
                    $flexDirection="column"
                    $alignItems="center"
                    $justifyContent="center"
                    $pt={3}
                    $pb={2}
                    $pxHeight={70}
                  >
                    <Line fontSize={16} fontWeight={900}>
                      {deck.totalHeight}m
                    </Line>
                    {(i === 0 || deck.group === 'sameDestination') && (
                      <Line fontSize={12}>
                        <Gold>#{deck.totalHeightRankDestination}</Gold> in {deck.destination}
                      </Line>
                    )}
                    <Line fontSize={12}>
                      <Gold>#{deck.totalHeightRankCountry}</Gold> in {deck.country}
                    </Line>
                    <Line fontSize={12}>
                      <Gold>#{deck.totalHeightRankWorld}</Gold> <Text t="worldwide" />
                    </Line>
                  </Item>
                  <Item
                    $flexDirection="column"
                    $alignItems="center"
                    $justifyContent="center"
                    $pt={3}
                    $pb={2}
                    $pxHeight={80}
                  >
                    <Line fontSize={16} fontWeight={900}>
                      {deck.highestDeckHeight}m
                    </Line>
                    {i === 0 && (
                      <Line fontSize={12}>
                        <Gold>#{deck.highestDeckRankDestination}</Gold> in {deck.destination}
                      </Line>
                    )}
                    <Line fontSize={12}>
                      <Gold>#{deck.highestDeckRankCountry}</Gold> in {deck.country}
                    </Line>
                    <Line fontSize={12}>
                      <Gold>#{deck.highestDeckRankWorld}</Gold> <Text t="worldwide" />
                    </Line>
                  </Item>
                  <Item $flexDirection="column" $alignItems="center" $justifyContent="center" $py={15}>
                    <Line fontSize={16}>{deck.openSince}</Line>
                  </Item>
                  <Item
                    $flexDirection="column"
                    $alignItems="center"
                    $justifyContent="center"
                    $p="5px 7px"
                    $pxHeight={70}
                  >
                    {deck.url && deck.poiID !== poiId && (
                      <Button href={deck.url}>
                        <Text t={translate('attraction_mainkeyword_item', { poi: deck.title })} />
                      </Button>
                    )}
                  </Item>
                </Flex>
              </Flex>
            ))}
          </Table>
        </Container>
        <Box $pr={[15, null, null, null, 0]}>
          <DeckLine fontSize={16}>
            <Text
              t="observation_deck"
              values={{
                rankWorldwide: content[0].highestDeckRankWorld,
                rankDestination: content[0].highestDeckRankDestination,
                destination: content[0].destination,
                poi: content[0].title,
              }}
              html
            />
          </DeckLine>
        </Box>
      </Box>
    )}
  </IntlContext.Consumer>
);

export default ObservationDecks;
