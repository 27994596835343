// @flow strict
import { Flex } from '../../../../../../primitives/Essentials/index.js';
import * as React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import Line from '../../../../../../primitives/Line/index.js';
import UrlContext from '../../../../../../services/url/context.js';
import { IconSearch } from '../../../../../Icons/index.js';
import Link from '../../../../../Link/index.js';

const StyledSuggestionFlex = styled(Flex)`
  max-height: 70px;
  background-color: ${({ selected, theme }) => (selected ? theme.trip.backgroundLight : 'initial')};
`;

const StyledPoiSuggestionLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  color: ${({ theme }) => theme.section.title};
`;

const Icon = styled.span`
  margin-right: 16px;
`;

const BottomSeparator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.trip.backgroundLight};
`;

type Props = {
  name: string,
  destinationId?: number,
  selected: boolean,
  onHover: () => void,
};

const FullTextSuggestion = ({ name, destinationId, selected, onHover }: Props) => (
  <UrlContext.Consumer>
    {({ buildFullTextSearchUrl }) => (
      <Link href={buildFullTextSearchUrl(name, destinationId)}>
        <StyledSuggestionFlex
          $px={15}
          $flexDirection="column"
          $alignItems="flex-start"
          selected={selected}
          onMouseEnter={onHover}
        >
          <Flex justifiContent="flex-start" $alignItems="center" $py={15}>
            <Icon>
              <IconSearch size={16} />
            </Icon>
            <StyledPoiSuggestionLine fontSize={18}>{name}</StyledPoiSuggestionLine>
          </Flex>
          <BottomSeparator />
        </StyledSuggestionFlex>
      </Link>
    )}
  </UrlContext.Consumer>
);

FullTextSuggestion.defaultProps = {
  destinationId: null,
};

export default FullTextSuggestion;
