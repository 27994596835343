// @flow strict
import React, { useState } from 'react';
import { withTheme } from 'styled-components';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Box, Flex } from '../../primitives/Essentials/index.js';
import Line from '../../primitives/Line/index.js';
import type { Theme } from '../../records/Theme.js';
import Text from '../Text/index.js';
import { container } from '../../services/styleUtils/index.js';
import FooterAppStoreLink from './components/FooterAppStoreLink/index.js';
import FooterButton from './components/FooterButton/index.js';
import FooterOptions from './components/FooterOptions/index.js';
import WindowContext from '../../services/window/context.js';
import FooterLinks from './components/FooterLinks/index.js';
import DividerLine from './components/DividerLine/index.js';
import ClientOnly from '../ClientOnly/index.js';

type Props = {
  theme: Theme,
  onGoBack?: ?(ev: SyntheticEvent<*>) => void,
  goBackHref?: string,
  // $FlowFixMe
  goBackText?: React.Node,
};

const StyledBox = styled(Box)`
  background-color: ${({ theme }) => theme.secondary};
`;

const StyledFlex = styled(Flex)`
  width: 100%;
`;

const StyledFooterBox = styled(Box)`
  ${container()};
`;

const CutOutBottom = styled.div`
  position: relative;
  right: calc(50% - 22px);
  bottom: 0;
  left: calc(50% - 22px);
  width: 45px;
  height: 23px;
  overflow-y: hidden;
`;

const Footer = ({ theme, goBackHref, onGoBack, goBackText }: Props) => {
  const [visibleIndex, setVisibleIndex] = useState(0);

  return (
    <StyledBox $mt={[35, null, null, null, 75]}>
      {goBackHref && goBackText && (
        <FooterButton href={goBackHref} onClick={onGoBack}>
          {goBackText}
        </FooterButton>
      )}
      <WindowContext.Consumer>
        {({ isMobile }) => (
          <StyledFooterBox>
            <StyledBox $mt={1.5} $pt={25} $px={[15, null, null, null, 30]} $pb ={28}>
              <StyledFlex $flexDirection={['column', null, null, null, 'row']}>
                <FooterOptions />
                <ClientOnly>
                  {isMobile && <DividerLine />}
                  <FooterLinks
                    theme={theme}
                    expanded={visibleIndex === 1}
                    onTitleClick={
                      isMobile ? () => setVisibleIndex(visibleIndex !== 1 ? 1 : 0) : null
                    }
                    isMobile={isMobile}
                  />
                  {isMobile && <DividerLine />}
                  <FooterAppStoreLink
                    theme={theme}
                    expanded={visibleIndex === 2}
                    onTitleClick={
                      isMobile ? () => setVisibleIndex(visibleIndex !== 2 ? 2 : 0) : null
                    }
                    isMobile={isMobile}
                  />
                  {isMobile && <DividerLine />}
                </ClientOnly>
              </StyledFlex>
              <Box $mt={[10, null, null, null, 55]}>
                <Line color={theme.tertiary}>
                  <Text html t="copy" values={{ endYear: new Date().getFullYear() }} />
                </Line>
              </Box>
            </StyledBox>
            <ClientOnly>
              {isMobile && (
                <CutOutBottom>
                  <img src="/icons/cutout-bottom.svg" alt="ticket cut out" />
                </CutOutBottom>
              )}
            </ClientOnly>
          </StyledFooterBox>
        )}
      </WindowContext.Consumer>
    </StyledBox>
  );
};

Footer.defaultProps = {
  onGoBack: null,
  goBackHref: '',
  goBackText: '',
};

export default withTheme(Footer);
