// @flow strict
import * as React from 'react';
import type { Match } from 'react-router-dom';
import type { DestinationContextType } from '../../services/destination/context.js';
import IntlContext from '../../services/intl/context.js';
import UrlContext from '../../services/url/context.js';
import DestinationContext from '../../services/destination/context.js';
import Navbar from '../../components/Navbar/index.js';
import Footer from '../../components/Footer/index.js';
import Text from '../../components/Text/index.js';
import withDestination from '../../decorators/withDestination/index.js';
import { destinationSelector } from '../../services/destination/selectors.js';
import { RES_URL } from '../../../../etc/appConfig.js';
import CategoryContent from './components/CategoryContent/index.js';
import TaglineCarousel from '../../components/TaglineCarousel/index.js';

type Props = {
  match: Match,
  categoryId: number,
  destinationId: number,
} & DestinationContextType;

const CategoryPage = ({ match, categoryId, destinationId, configs, destinations }: Props) => {
  const { language, translate } = React.useContext(IntlContext);
  const { buildDestinationCategoryPageUrl } = React.useContext(UrlContext);
  const { categoryContent, categoryTours } = React.useContext(DestinationContext);

  if (!categoryId || !destinationId) {
    return null;
  }

  const isPrerendered = Boolean(categoryContent);

  const selectedDestination = destinationSelector(destinations[language], destinationId);
  // TODO: FALLBACK QUERY to obtain tag if data is not available
  const sectionConfigTags = configs[language].Tags.concat(configs[language].Categories);
  const tagFromConfig = sectionConfigTags.filter(tag => tag.Tag === categoryId)[0];
  const resultsForString = tagFromConfig ? tagFromConfig.Name : '';

  let title; // should be undefined on client side rendered page
  if (categoryContent) {
    title = categoryContent && categoryContent.title ? categoryContent.title : resultsForString;
  }

  return (
    <>
      <Navbar
        title={title}
        imageUrl={categoryContent ? `${RES_URL}/tag-images/${categoryId}` : null}
        responsive
        imageAlt={
          categoryContent && selectedDestination // TODO: actually use the proper meta tags
            ? translate(resultsForString ? 'title_category_page' : 'title_dest_page', {
                category: resultsForString,
                destination: selectedDestination.name,
              })
            : undefined
        }
        destinationId={destinationId}
      />
      {isPrerendered && <TaglineCarousel />}
      <CategoryContent
        isPrerendered={isPrerendered}
        selectedDestination={selectedDestination}
        sectionConfigTags={sectionConfigTags}
        categoryContent={categoryContent}
        shownTours={categoryTours}
        categoryId={categoryId}
        resultsForString={resultsForString}
        destinationId={destinationId}
        destinationName={selectedDestination ? selectedDestination.name : ''}
      />
      <Footer
        goBackHref={buildDestinationCategoryPageUrl()}
        goBackText={
          <Text
            t="more_from"
            values={{
              place: selectedDestination ? selectedDestination.name : '',
            }}
          />
        }
      />
    </>
  );
};

export default withDestination(CategoryPage);
