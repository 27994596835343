// @flow strict
export const LAYOUT_COMMON = {
  $mt: [20, null, null, null, 30],
  $py: 12.5,
};

export const SECTION_LAYOUT = {
  ...LAYOUT_COMMON,
  $px: [15.5, null, null, null, 30],
};

export const SECTION_LAYOUT_NO_PADDING_MOBILE = {
  ...LAYOUT_COMMON,
  $px: [0, null, null, null, 30],
};

export const BREADCRUMBS_LAYOUT = {
  $px: [15.5, null, null, null, 30],
};
