// @flow strict
import * as React from 'react';
import * as R from 'ramda/src/index.js';
import { Flex, Box } from '../../primitives/Essentials/index.js';
// $FlowFixMe
import isSameDay from 'date-fns/isSameDay/index.js';
import type { Currency } from '../../services/currency/context.js';
import type { Language } from '../../services/intl/context.js';
import { getTours } from '../../services/api/tours/index.js';
import CategoryTours from './components/CategoryTours/index.js';
import SvgSpinner from '../SvgSpinner/index.js';
import Text from '../Text/index.js';
import { formatDateYMD } from '../../services/dateTimeUtils/index.js';
import type { Breadcrumb } from '../Breadcrumbs/index.js';
import type { Poi } from '../../records/Poi.js';
import type { Promocodes } from '../../records/Promocodes.js';
import type { Currencies } from '../../records/Currencies.js';
import type { Providers } from '../../records/Providers.js';
import type { Tag } from '../../records/SectionConfig.js';
import type { GroupTour } from '../../records/Tour.js';

type Props = {
  language: Language,
  filters: string, // e.g. 't:6000' or 's:audioguide'
  currency: Currency,
  resultsForString: ?string,
  id: number,
  type: 'destination' | 'poi',
  sectionId?: string,
  breadcrumbs: Breadcrumb[],
  promocodes: Promocodes,
  providers: Providers,
  currencies: Currencies,
  date?: ?Date,
  poi?: ?Poi,
  destinationId?: number,
  sectionConfigTags?: Tag[],
};

type State = {
  page: number,
  groupTours: GroupTour[],
  isLoading: boolean,
  nbPages: number,
  nbHits: number,
};

const INFINITE_SCROLL_PAGE_SIZE = 10;
const INFINITE_SCROLL_Y_THRESHOLD = 500;

class ToursContainer extends React.PureComponent<Props, State> {
  static defaultProps = {
    poi: null,
    date: null,
  };

  constructor() {
    super();
    this.state = {
      page: 0,
      nbPages: 1,
      nbHits: 0,
      groupTours: [],
      isLoading: true,
    };
  }

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    const { currency, date, id } = this.props;
    const { groupTours } = this.state;

    const newDateIsDifferent =
      (date && prevProps.date && !isSameDay(date, prevProps.date)) ||
      (!date && prevProps.date) ||
      (date && !prevProps.date);

    const newIdIsDifferent = prevProps.id !== id;

    if (
      Boolean(id) &&
      (newDateIsDifferent ||
        currency !== prevProps.currency ||
        (newIdIsDifferent && R.equals(groupTours, prevState.groupTours)))
    ) {
      // TODO: fix issue
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(
        {
          page: 0,
          nbPages: 1,
          isLoading: true,
          groupTours: [],
        },
        () => this.updateData(0),
      );
    }
  };

  updateData = (page: number) => {
    const {
      language,
      filters,
      id,
      type,
      currency,
      date,
      promocodes,
      currencies,
      providers,
    } = this.props;

    if (!id) {
      // waiting for ajax to finish (withPoi)
      return;
    }

    this.setState({
      isLoading: true,
    });

    const tags = [
      `${type === 'destination' ? 'd' : 'p'}:${id}`,
      `lang:${language}`,
      `a:${date ? formatDateYMD(date) : 'default'}`,
    ];

    getTours(
      currency,
      language,
      tags,
      filters,
      INFINITE_SCROLL_PAGE_SIZE,
      promocodes,
      currencies,
      providers,
      page,
    ).then(result => {
      this.setState(state => ({
        groupTours: page === 0 ? result.groupTours : state.groupTours.concat(result.groupTours),
        page: result.page,
        nbPages: result.nbPages,
        nbHits: result.nbHits,
        isLoading: false,
      }));
    });
  };

  render() {
    const { groupTours, isLoading, page, nbPages, nbHits } = this.state;
    const {
      language,
      resultsForString,
      sectionId,
      breadcrumbs,
      date,
      poi,
      destinationId,
      sectionConfigTags,
    } = this.props;

    return (
      <>
        <Flex $justifyContent="center">
          <CategoryTours
            onPaginatedSearch={() => this.updateData(nbPages === 1 ? 0 : page + 1)}
            shouldPerformSearch={() =>
              Boolean(
                document.body &&
                  window.innerHeight + window.scrollY >=
                    document.body.offsetHeight - INFINITE_SCROLL_Y_THRESHOLD &&
                  !isLoading &&
                  page < nbPages - 1,
              )
            }
            groupTours={groupTours}
            resultsCount={nbHits}
            language={language}
            resultsForString={resultsForString}
            sectionId={sectionId}
            breadcrumbs={breadcrumbs}
            date={date}
            poi={poi}
            destinationId={destinationId}
            sectionConfigTags={sectionConfigTags}
          />
        </Flex>
        {!groupTours.length && !isLoading && (
          <Box $pt={80}>
            <Flex $justifyContent="center">
              <Text t="page_not_found" html />
            </Flex>
          </Box>
        )}
        {isLoading && (
          <Box $mt={40}>
            <Flex $justifyContent="center" $alignItems="center">
              <SvgSpinner />
            </Flex>
          </Box>
        )}
      </>
    );
  }
}

export default ToursContainer;
