// @flow strict
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl#Locale_identification_and_negotiation
// eslint-disable-next-line import/prefer-default-export
export const formatPrice = (
  value: number,
  language?: string = 'en',
  currency?: string = 'eur',
  minimumFractionDigits?: number,
  maximumFractionDigits?: number,
) =>
  new Intl.NumberFormat(language, {
    style: 'currency',
    currency,
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(value);
