// @flow strict
/* eslint-disable prefer-destructuring */
import type { Language } from '../intl/context.js';

export type PageType =
  | 'landing'
  | 'destinations'
  | 'destination'
  | 'category'
  | 'subcategory'
  | 'pois'
  | 'poiToursSection'
  | 'poi'
  | 'poiCategory'
  | 'groupTour'
  | 'forwardtopartner'
  | 'author'
  | 'imprint'
  | 'privacypolicy'
  | 'termsandconditions'
  | 'search'
  | 'notfound';

export type ParsedUrl = {
  language: Language,
  page: string,
  pageType: PageType,
  originalUrl: string,
  destination?: number,
  group?: number,
  poi?: number,
  poiToursSection?: number,
  category?: ?string, // p for pois or number string
  tour?: number,
  author?: string,
};

// !!!
// NOTE: if changed an update in lambda function may be required
// !!!
// possible refactoring using https://github.com/pillarjs/path-to-regexp
export default function parseUrl(path: string): ParsedUrl {
  const defaultLanguage = 'en'; // e.g. for main page without language specified
  let pageType = 'landing';
  const pathSplit = path.split('?');
  if (!path || !path.length) {
    return {
      language: defaultLanguage,
      page: `/${defaultLanguage}`,
      pageType,
      originalUrl: path,
    };
  }

  const [, language, maybeRes1, maybeRes2, maybeRes3] = pathSplit[0].split('/');

  if (pathSplit[0] !== '/' && language !== 'en' && language !== 'de' && language !== 'it') {
    // 404
    return {
      language: defaultLanguage,
      page: `/${defaultLanguage}/notfound`,
      pageType: 'notfound',
      originalUrl: path,
    };
  }

  // Set pageLanguage based on the language in the URL
  let pageLanguage;
  if (language === 'de') {
    pageLanguage = 'de';
  } else if (language === 'it') {
    pageLanguage = 'it';
  } else {
    pageLanguage = defaultLanguage;
  }

  let destination;
  let poi;
  let category;
  let group;
  let tour;
  let poiToursSection;

  const NotFoundPage = {
    language: pageLanguage,
    page: `/${pageLanguage}/notfound`,
    pageType: 'notfound',
    originalUrl: path,
  };

  // check if the id starts with 0 -> 404 case
  if (maybeRes1 && maybeRes1.length > 1 && maybeRes1.substring(1, 2) === '0') {
    return NotFoundPage;
  }

  if (maybeRes1 && maybeRes1.startsWith('d')) {
    const maybeDestination = maybeRes1.slice(1, maybeRes1.length);
    if (!Number.isNaN(parseFloat(maybeDestination))) {
      destination = Number(maybeDestination);
      pageType = 'destination';
    } else if (maybeRes1 && maybeRes1 === 'destinations') {
      return {
        language: pageLanguage,
        page: `/${pageLanguage}/destinations`,
        pageType: 'destinations',
        originalUrl: path,
      };
    } else {
      return NotFoundPage;
    }
  } else if (maybeRes1 && maybeRes1.startsWith('p') && maybeRes1 !== 'privacypolicy') {
    const maybePoi = maybeRes1.slice(1, maybeRes1.length);
    if (!Number.isNaN(parseFloat(maybePoi))) {
      poi = Number(maybePoi);
      pageType = 'poi';

      // there is a special case of poi page if the url also contains a section then it is a poiToursSection page 
      if (maybeRes2 && maybeRes2.startsWith('s')) {
        const maybeSection = maybeRes2.slice(1, maybeRes2.length);
        if (!Number.isNaN(parseFloat(maybeSection)) && maybeSection.length === 1) {
          poiToursSection = Number(maybeSection);
          pageType = 'poiToursSection';
        } 
      } 
    } else {
      return NotFoundPage;
    }
  } else if (maybeRes1 && maybeRes1.startsWith('g')) {
    const maybeGroup = maybeRes1.slice(1, maybeRes1.length);
    if (!Number.isNaN(parseFloat(maybeGroup))) {
      group = Number(maybeGroup);
      pageType = 'groupTour';
    } else {
      return NotFoundPage;
    }
  } else if (maybeRes1 && maybeRes1.startsWith('t') && maybeRes1 !== 'termsandconditions') {
    const maybeTour = maybeRes1.slice(1, maybeRes1.length);
    if (!Number.isNaN(parseFloat(maybeTour))) {
      tour = Number(maybeTour);
      pageType = 'forwardtopartner';
    } else {
      return NotFoundPage;
    }
  } else if (maybeRes1 && ['imprint', 'termsandconditions', 'privacypolicy'].includes(maybeRes1)) {
    return {
      language: pageLanguage,
      page: `/${pageLanguage}/${maybeRes1}`,
      // $FlowFixMe imprint | temrsandconditions | privacypolicy
      pageType: maybeRes1,
      originalUrl: path,
    };
  } else if (maybeRes1 && maybeRes1 === 'author') {
    return {
      language: pageLanguage,
      page: `/${pageLanguage}/author/${maybeRes1}`,
      pageType: 'author',
      originalUrl: path,
      author: maybeRes2,
    };
  } else if (maybeRes1 && maybeRes1 === 'search') {
    return {
      language: pageLanguage,
      page: `/${pageLanguage}/search`,
      pageType: 'search',
      originalUrl: path,
    };
  } else if (maybeRes1) {
    return NotFoundPage;
  }

  if (
    pageType !== 'groupTour' &&
    pageType !== 'forwardtopartner' &&
    pageType !== 'destinations' &&
    maybeRes2
  ) {
    if (maybeRes2.startsWith('c')) {
      if (poi && maybeRes2.includes('category')) {
        const match = path.match(/s=(\d+)/);
        category = match && match[1] && match[1][0]; // note: on lambda this might be set to null, but is not used
        pageType = 'poiCategory';
      } else {
        const maybeCategory = maybeRes2.slice(1, maybeRes2.length);
        if (!Number.isNaN(parseFloat(maybeCategory))) {
          category = maybeCategory;
          pageType = 'category';
          if (maybeRes3 && maybeRes3.includes('category')) {
            pageType = 'subcategory';
          }
        }
      }
    } else if (maybeRes2 === 'p') {
      category = 'p';
      pageType = 'pois';
    }
  }

  let pageUri = `/${pageLanguage}`;
  if (destination) {
    if (category) {
      if (pageType === 'subcategory') {
        pageUri = `${pageUri}/subcategory`;
      } else {
        const categoryUri = category === 'p' ? `d${destination}/p` : 'category';
        pageUri = `${pageUri}/${categoryUri}`;
      }
    } else {
      pageUri = `${pageUri}/${maybeRes1}`;
    }
  } else if (poi && !category && pageType !== 'poiCategory') {
    pageUri = `${pageUri}/${maybeRes1}`;
  } else if (tour) {
    pageUri = `${pageUri}/forwardtopartner`;
  } else if (poi && pageType === 'poiCategory') {
    pageUri = `${pageUri}/poiCategory`;
  } else if (group) {
    pageUri = `${pageUri}/groupTour`;
  }

  return {
    language: pageLanguage,
    page: pageUri,
    pageType,
    destination,
    category,
    group,
    poi,
    poiToursSection,
    tour,
    originalUrl: path,
  };
}
