// @flow strict
import React from 'react';
import { Box } from '../../../../../../primitives/Essentials/index.js';
import type { GroupToursBySection } from '../../../../../../records/Tour.js';
import type { Poi } from '../../../../../../records/Poi.js';
import type { Section as SectionType } from '../../../../../../records/PoiContent.js';
import Section from '../Section/index.js';

type Props = {
  shownTours: GroupToursBySection,
  sections: SectionType[],
  poi: Poi,
  date: ?Date,
  isPoiToursSectionPage: ?boolean,
  sections: SectionType[],
};

const PoiSections = ({ sections, shownTours, poi, date, isPoiToursSectionPage }: Props) => {
  return (
  <Box>
    {sections
      .filter(section => shownTours[String(section.id)] && shownTours[String(section.id)].length)
      .map((section, index) => {
        // Initialize poiToursSectionPageUrl as null
        let poiToursSectionPageUrl = null;
        if (!isPoiToursSectionPage && section.sectionsPageUrl) {
          poiToursSectionPageUrl = section.sectionsPageUrl;
        }

        // Now return the Section component
        return (
          <Section
            id={`section-${index + 1}`}
            key={section.id}
            poi={poi}
            section={section}
            tours={shownTours[String(section.id)]}
            showAvailabilities={!date}
            poiId={poi.objectID}
            date={date}
            // Pass poiToursSectionPageUrl only if it's not null
            {...(poiToursSectionPageUrl ? { poiToursSectionPageUrl } : {})}
            {...(isPoiToursSectionPage ? { maxTours: 6 } : {})}
          />
        );
      })}
  </Box>
  );
};

export default PoiSections;
