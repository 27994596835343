// @flow strict
import * as React from 'react';
import { withTheme } from 'styled-components';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Flex, Box } from '../../../../primitives/Essentials/index.js';
import Line from '../../../../primitives/Line/index.js';
import type { Theme } from '../../../../records/Theme.js';
import mq from '../../../../services/mediaQuery/index.js';

type Props = {
  theme: Theme,
  title: React.Node,
  subtitle: React.Node,
  icon: React.Node,
};

const StyledBox = styled(Box)`
  width: 100%;
  min-width: 100%;
  height: inherit;
  &:nth-child(2) {
    margin-right: 14px;
  }
  ${mq.TABLET`
    min-width: 33%;
    width: 33%;
    &:nth-child(2) {
      margin-right: 0;
    }
  `};
  ${mq.DESKTOP`
    min-width: initial;
    width: initial;
  `};
`;

const Tagline = ({ theme, title, subtitle, icon }: Props) => (
  <StyledBox>
    <Flex
      $alignItems="center"
      $justifyContent={['flex-start', null, null, null, 'center', 'flex-start']}
    >
      {icon}
      <Flex $ml={4} $flexDirection="column">
        <Line fontSize={16} letterSpacing={0.4} fontWeight={900} color={theme.secondary}>
          {title}
        </Line>
        <Line fontSize={12} letterSpacing={0.4} color={theme.navbar.searchText}>
          {subtitle}
        </Line>
      </Flex>
    </Flex>
  </StyledBox>
);

export default withTheme(Tagline);
