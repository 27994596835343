// @flow strict
import React from 'react';
import { withTheme } from 'styled-components';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Box, Flex } from '../../primitives/Essentials/index.js';
import UrlContext from '../../services/url/context.js';
import DestinationContext from '../../services/destination/context.js';
import IntlContext from '../../services/intl/context.js';
import type { Theme } from '../../records/Theme.js';
import { container } from '../../services/styleUtils/index.js';
import Line from '../../primitives/Line/index.js';
import Footer from '../../components/Footer/index.js';
import { SECTION_LAYOUT } from '../../consts/layout.js';
import Navbar from '../../components/Navbar/index.js';
import { getDestinationCountries, getDestinationsByCountry } from './services/utils.js';
import Breadcrumbs from '../../components/Breadcrumbs/index.js';
import Text from '../../components/Text/index.js';
import Subheader from '../../components/Subheader/index.js';
import Destination from '../LandingPage/components/Content/components/Destination/index.js';

const StyledContainerBox = styled(Box)`
  ${container()};
`;

const StyledContainer = styled.div`
  position: relative;
`;

type Props = {
  theme: Theme,
};

function createURLSafeID(str) {
  return str
    .toLowerCase()
    .replace(/ä/g, 'ae')
    .replace(/ö/g, 'oe')
    .replace(/ü/g, 'ue')
    .replace(/\s+/g, '-')
    .replace(/[^a-z0-9-]/g, ''); // This will remove any other character that's not a lowercase letter, number, or dash. Adjust if necessary.
}

const DestinationsPage = ({ theme }: Props) => {
  const { language, translate } = React.useContext(IntlContext);
  const { buildAllDestinationsPageUrl } = React.useContext(UrlContext);
  const { destinations } = React.useContext(DestinationContext);

  const countries = getDestinationCountries(destinations[language]);
  const destByCountry = getDestinationsByCountry(countries, destinations[language]);

  return (
    <>
      <StyledContainer>
        <Navbar>
          <Subheader>
            <Text t="all_destinations" />
          </Subheader>
        </Navbar>
      </StyledContainer>
      <StyledContainerBox {...SECTION_LAYOUT} $mt={0}>
        <Flex $flexDirection="column">
          <Breadcrumbs
            items={[
              {
                name: translate('all_destinations'),
                url: buildAllDestinationsPageUrl(),
              },
            ]}
            activeIndex={0}
          />
          {countries.map(country => (
            <Box $mt={27} key={country}>
              <Line id={createURLSafeID(country)} fontSize={24} fontWeight={900} color={theme.section.title}>
                {country}
              </Line>
              <Flex $flexWrap="wrap">
                {destByCountry[country].sort().map(destination => (
                  <Destination
                    key={destination.name}
                    hideCover
                    theme={theme}
                    destination={destination}
                  />
                ))}
              </Flex>
            </Box>
          ))}
        </Flex>
      </StyledContainerBox>
      <Footer />
    </>
  );
};

export default withTheme(DestinationsPage);
