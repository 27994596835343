// @flow strict
import React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import type { Attribution } from '../../records/Photo.js';

type Props = {
  attribution: ?Attribution,
};

const OnClickLink = styled.span`
  cursor: pointer;
`;

const PhotoAttribution = ({ attribution }: Props) => {
  if (!attribution) {
    return null;
  }
  return (
    <span>
      {attribution &&
        (attribution.source || attribution.authorName || attribution.license) &&
        ' | '}
      {attribution.source && `${attribution.source}: `}
      {attribution.authorName &&
        (attribution.originalUrl ? (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
          <OnClickLink
            onClick={() => window.open(attribution.originalUrl, '_blank')}
            role="button"
            tabIndex={0}
          >
            {attribution.authorName}{' '}
          </OnClickLink>
        ) : (
          `${attribution.authorName} `
        ))}
      {attribution.license &&
        (attribution.licenseUrl ? (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
          <OnClickLink
            onClick={() => window.open(attribution.licenseUrl, '_blank')}
            role="button"
            tabIndex={0}
          >
            {attribution.license}
          </OnClickLink>
        ) : (
          `${attribution.license}`
        ))}
    </span>
  );
};

export default PhotoAttribution;
