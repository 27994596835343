// @flow strict
/* eslint-disable react/no-array-index-key */
import React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Box, Flex } from '../../primitives/Essentials/index.js';
import Tour from '../Tours/components/Tour/index.js';
import SvgSpinner from '../SvgSpinner/index.js';
import type { GroupTour } from '../../records/Tour.js';
import type { Promocodes } from '../../records/Promocodes.js';
import type { Currencies } from '../../records/Currencies.js';
import type { Providers } from '../../records/Providers.js';
import type { Language } from '../../services/intl/context.js';
import { getGroupTours, getTour } from '../../services/api/tours/index.js';
import Text from '../Text/index.js';
import mq from '../../services/mediaQuery/index.js';
import mapperGroupTours from '../../services/mapper/groupTours/index.js';

type Props = {
  language: Language,
  promocodes: Promocodes,
  currencies: Currencies,
  providers: Providers,
  history: string,
  groupId?: string,
  maxTourCount?: number,
};

type State = {
  tours: ?(GroupTour[]),
  loading: boolean,
};

const StyledTitle = styled.h2`
  margin: 0;
  padding: 0 15.5px;
  color: ${({ theme }) => theme.section.title};
  font-weight: 900;
  font-size: 24px;
  ${mq.TABLET`
    padding: 0;
  `}
`;

const Container = styled(Flex)`
  > div:nth-child(3) {
    display: none;
  }
  ${mq.DESKTOP`
    > div:nth-child(3) {
      display: inherit;
    }
  `};
`;

class History extends React.Component<Props, State> {
  static defaultProps = {
    maxTourCount: 3,
  };

  constructor() {
    super();
    this.state = {
      loading: true,
      tours: null,
    };
  }

  componentDidMount() {
    const {
      language,
      maxTourCount,
      currencies,
      promocodes,
      history,
      groupId,
      providers,
    } = this.props;
    try {
      const parsedIds = JSON.parse(history);
      Promise.all(
        parsedIds
          .filter(x => (groupId ? `g${groupId}` !== x : true))
          .slice(0, maxTourCount)
          .map(
            // TODO: filter out current tour
            id =>
              id.startsWith('t')
                ? getTour(language, id.slice(1, id.length), promocodes, currencies).then(tour => [
                    tour,
                  ])
                : getGroupTours(language, id.slice(1, id.length), promocodes, currencies), // TODO: date
          ),
      )
        .then(tours => {
          const groupTours = tours.reduce((acc, group) => {
            if (group[0] !== null) {
              return acc.concat(
                mapperGroupTours(group, language, promocodes, currencies, providers),
              );
            }
            return acc;
          }, []);
          this.setState({
            tours: groupTours,
            loading: false,
          });
        })
        .catch(() => {
          // parsing or mapping error if history would be invalid
          this.setState({
            loading: false,
          });
        });
    } catch (err) {
      // parsing or mapping error if history would be invalid
      this.setState({
        loading: false,
      });
    }
  }

  render() {
    const { maxTourCount } = this.props;
    const { tours, loading } = this.state;
    if (loading || (tours && tours.length)) {
      return (
        <Flex $flexDirection="column">
          <StyledTitle>
            <Text t="still_interested" />
          </StyledTitle>
          {loading && (
            <Box $mt={40}>
              <Flex $justifyContent="center" $alignItems="center">
                <SvgSpinner />
              </Flex>
            </Box>
          )}
          {tours && (
            <Container $flexWrap="wrap" $mt={10}>
              {tours.map((groupTour, i) => {
                return (
                  <Tour
                    key={`${groupTour.groupId}-${i}`}
                    groupTour={groupTour}
                    showAvailabilities
                    secondary
                    halfSize={maxTourCount === 2}
                    date={null}
                  />
                );
              })}
            </Container>
          )}
        </Flex>
      );
    }
    return null;
  }
}

export default History;
