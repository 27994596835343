// @flow strict
import * as React from 'react';
import { Box, Flex } from '../../../../../../primitives/Essentials/index.js';
import Tour from '../../../../../Tours/components/Tour/index.js';
import type { GroupTour } from '../../../../../../records/Tour.js';

type Props = {
  groupTours: GroupTour[],
  date: ?Date,
};

const CategoryToursList = ({ groupTours, date }: Props) => (
  <Box $pl={[0, null, null, null, 30]} $pr={[0, null, null, null, 30]}>
    <Flex $flexWrap="wrap">
      {groupTours.map(groupTour => {
        return (
          <Tour
            key={groupTour.groupId}
            groupTour={groupTour}
            singleColumn
            showAvailabilities
            animateRight
            date={date}
          />
        );
      })}
    </Flex>
  </Box>
);

export default CategoryToursList;
