// @flow strict
/* eslint-disable react/no-danger */
import * as React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Box } from '../../../../../../primitives/Essentials/index.js';
import Line from '../../../../../../primitives/Line/index.js';
import type { Theme } from '../../../../../../records/Theme.js';
import type { Tour } from '../../../../../../records/Tour.js';
import getTourGroupRating from '../../../../../../services/rating/index.js';
import GroupTourRating from '../GroupTourRating/index.js';
import mq from '../../../../../../services/mediaQuery/index.js';

type Props = {
  theme: Theme,
  tour: Tour,
  tourGroup: Tour[],
  onRatingsClick?: ?() => void,
};

const StyledAbstractBox = styled(Box)`
  display: none;
  ${mq.TABLET`
    display: inherit;
  `};
`;

const StyledAbstract = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  font-size: 16px;
`;

const StyledH1 = styled.h1`
  margin: 0;
  color: ${({ theme }) => theme.section.title};
  font-size: 29px;
  line-height: 1.36;
`;

const GroupTourHeader = ({ theme, tour, tourGroup, onRatingsClick }: Props) => {
  const { ratingsCount, rating } = getTourGroupRating(tourGroup);

  return (
    <Box $px={[15, null, null, null, 0]}>
      <StyledH1>{tour.title}</StyledH1>
      {rating > 0 && (
        <GroupTourRating
          theme={theme}
          ratingsCount={ratingsCount}
          rating={rating}
          inHeader={false}
          onRatingsClick={onRatingsClick}
        />
      )}
      <StyledAbstractBox $mt={10}>
        <StyledAbstract color={theme.section.title}>
          <span dangerouslySetInnerHTML={{ __html: tour.abstract }} />
        </StyledAbstract>
      </StyledAbstractBox>
    </Box>
  );
};

GroupTourHeader.defaultProps = {
  onRatingsClick: null,
};

export default GroupTourHeader;
