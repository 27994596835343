// @flow strict
import React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Box, Flex } from '../../../../../../../../../../primitives/Essentials/index.js';
import type { Tour } from '../../../../../../../../../../records/Tour.js';
import type { Promocode } from '../../../../../../../../../../records/Promocodes.js';
import type { Providers } from '../../../../../../../../../../records/Providers.js';
import Text from '../../../../../../../../../../components/Text/index.js';
import Price from '../../../../../../../../../../components/Price/index.js';
import mq from '../../../../../../../../../../services/mediaQuery/index.js';
import type { Currency } from '../../../../../../../../../../services/currency/context.js';
import BookingFee from './components/BookingFee/index.js';
import Line from '../../../../../../../../../../primitives/Line/index.js';

const StyledButtonBox = styled(Box)`
  position: relative;
  display: ${({ $tabletOnly }) => ($tabletOnly ? 'none' : 'initial')};
  height: 24px;
  color: ${({ theme }) => theme.tertiary};
  font-weight: 900;
  font-size: 12px;
  white-space: nowrap;
  text-align: center;
  background-color: ${({ theme, $officialOffer }) =>
    $officialOffer ? theme.trip.officialOffer : theme.trip.price};
  border-radius: 20px;

  ${mq.TABLET`
    display: flex;
    border-radius: ${({ tabletOnly }) => (tabletOnly ? '0 0 5px 5px' : '5px')};
    height: 50px;
    font-size: 19px;
  `};
`;

const StyledFromText = styled(Box)`
  color: ${({ theme, disabled }) => (disabled ? theme.trip.divider : theme.trip.price)};
  font-size: 14px;
  letter-spacing: 0.6px;
  ${mq.TABLET`
    margin-bottom: 2px;
  `};
`;

const StyledPriceText = styled(Box)`
  color: ${({ theme, disabled }) => (disabled ? theme.trip.divider : theme.trip.price)};
  font-weight: 900;
  font-size: 19px;
  letter-spacing: 0.5px;

  ${mq.TABLET`
    font-size: 29px;
    letter-spacing: 0.8px;
  `};
`;

const StyledFlex = styled(Flex)`
  width: 100%;
  height: 100%;
  text-align: center;
`;

const DiscountBox = styled(Box)`
  position: relative;
  display: none;
  background-color: ${({ theme }) => theme.tertiary};
  border-radius: 5px 5px 0 0;

  ${mq.TABLET`
    display: flex;
  `};
`;

const DiscountLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  color: ${({ theme }) => theme.trip.price};
  text-align: initial;
`;

const StyledOldPriceText = styled(Box)`
  color: ${({ theme, disabled }) => (disabled ? theme.trip.abstract : theme.secondary)};
  font-size: 16px;
  letter-spacing: 0.6px;
  text-decoration: line-through;
  strong {
    margin-left: 4px;
  }
  ${mq.TABLET`
    margin-bottom: 2px;
  `};
`;

const StFlex = styled(Flex)`
  ${mq.TABLET`
    width: 240px;
  `}
`;

const Checkmark = styled.img`
  width: 18px;
  height: 17.5px;
  margin-right: 3px;
  margin-left: -6px;

  ${mq.TABLET`
    width: 27px;
    height: 27px;
    margin-right: 6px;
    margin-left: 0;
  `}
`;

type Props = {
  tour: Tour,
  currency: Currency,
  priceOnly?: boolean,
  providers: Providers,
  promocode: ?Promocode,
};

const OfferPrices = ({ tour, currency, priceOnly, promocode, providers }: Props) => {
  const fee = tour[`fee_${currency}`];
  const price = tour[`price_${currency}`] - fee;

  return (
    <Box>
      <Flex
        $alignItems={promocode ? 'center' : 'flex-end'}
        $flexDirection={['column', null, null, null, 'row']}
      >
        <Box>
          <Flex $justifyContent="flex-end" $alignItems="flex-end">
            <Flex $flexDirection="column" $alignItems="flex-end">
              {promocode && (
                <StyledOldPriceText>
                  <Flex $justifyContent="flex-end">
                    <Text t="from" />{' '}
                    <strong>
                      <Price value={tour[`originalPrice_${currency}`]} />
                    </strong>
                  </Flex>
                </StyledOldPriceText>
              )}
              <Flex $justifyContent="flex-end" $alignItems="flex-end">
                <StyledFromText disabled={providers[tour.providerId].officialWebsite}>
                  {promocode ? <Text t="with_code" /> : <Text t="from" />}
                </StyledFromText>
                &nbsp;
                <StyledPriceText disabled={providers[tour.providerId].officialWebsite}>
                  <Price value={price} />
                </StyledPriceText>
              </Flex>
            </Flex>
          </Flex>
          {fee > 0 && <BookingFee fee={fee} />}
        </Box>
        {!priceOnly && (
          <StFlex $flexDirection="column">
            {promocode && (
              <DiscountBox p={8} $ml={22.5}>
                <StyledFlex $flexDirection="column" $justifyContent="center" $alignItems="center">
                  <DiscountLine fontSize={14} letterSpacing={0.25}>
                    <Text
                      t="use_promo_code"
                      html
                      values={{
                        discount: promocode.discountPercent,
                        code: promocode.code,
                        provider: providers[tour.providerId].name,
                      }}
                    />
                  </DiscountLine>
                </StyledFlex>
              </DiscountBox>
            )}
            <StyledButtonBox
              $px={6}
              $py={2}
              $mt={[4, null, null, null, 0]}
              $ml={[0, null, null, null, 22.5]}
              $tabletOnly={promocode}
              $officialOffer={
                providers[tour.providerId] && providers[tour.providerId].officialWebsite
              }
            >
              <StyledFlex $flexDirection="column" $justifyContent="center" $alignItems="center" $px={6}>
                {providers[tour.providerId].officialWebsite ? (
                  <Flex $alignItems="center">
                    <Checkmark src="/icons/checkmark.svg" alt="checkmark" />
                    <Text t="official_website" />
                  </Flex>
                ) : (
                  <Text t="view_on_site" />
                )}
              </StyledFlex>
            </StyledButtonBox>
          </StFlex>
        )}
      </Flex>
    </Box>
  );
};

OfferPrices.defaultProps = {
  priceOnly: false,
};

export default OfferPrices;
