import React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Box } from '../../../../../../primitives/Essentials/index.js';
import Text from '../../../../../../components/Text/index.js';
import { format } from 'date-fns';
import type { Theme } from '../../../../../../records/Theme.js';
import { ThemeDefault } from '../../../../../../records/Theme.js';
import SectionHeader from '../../../../../../components/SectionHeader/index.js';
import IntlContext from '../../../../../../services/intl/context.js';
import mq from '../../../../../../services/mediaQuery/index.js';

type Exhibition = {
  title: string;
  subtitle: string;
  description: string;
  start_date: string;
  end_date: string;
  location: string;
  language: string;
};

type ExhibitionGroup = {
  exhibition: Exhibition[];
};

type ExhibitionsProps = {
  language: string;
  data: ExhibitionGroup[];
  theme?: Theme;
};

const SliderContainer = styled.div`
  display: flex;
  overflow-x: auto;
  padding: 10px 0px 10px 15px;

  ${mq.TABLET`
      padding: 10px 0px 10px 10px;
      margin: 0 -10px;
  `};
`;

const ExhibitionCard = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 300px; /* fixed width */
  margin-right: 15px;
  background-color: #f5f5f5;
  border-radius: 5px;
  padding: 15px;
  max-width: 300px;
  flex-shrink: 0;
  min-height: 220px;
`;

const ExhibitionTitle = styled.h3`
  margin: 0 0 8px 0;
  font-size: 18px;
  color: ${({ theme }) => theme.section.title};
`;

const ExhibitionSubtitle = styled.h4`
  margin: 0 0 10px 0;
  font-size: 15px;
  font-weight: normal;
  color: ${({ theme }) => theme.section.abstract || '#555'};
`;

/*
 * Using line-clamp to avoid cutting off partial lines.
 * Adjust -webkit-line-clamp for how many lines you'd like.
 */
const ExhibitionDescription = styled.p`
  margin: 0 0 10px 0;
  font-size: 14px;
  line-height: 1.4;
  max-height: 200px;
  display: -webkit-box;         /* needed for line-clamp to work */
  -webkit-line-clamp: 8;       /* number of lines before cutting off */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;     /* optional: show "..." */
  color: ${({ theme }) => theme.section.description};
`;

const ExhibitionLocationAndDates = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.section.description || '#777'};
  margin-top: auto; /* push the date to the bottom of the card */
`;

// Utility to determine if an exhibition is active (0), upcoming (1), or past (2)
const getExhibitionStatus = (exh: Exhibition): number => {
  const now = new Date();
  const start = new Date(exh.start_date);
  const end = new Date(exh.end_date);
  if (start <= now && now <= end) return 0; // active
  if (now < start) return 1; // upcoming
  return 2; // past
};

const Exhibitions: React.FC<ExhibitionsProps> = ({ language, data, theme }) => {
  // Fallback to ThemeDefault if theme is missing .section
  const safeTheme = theme && theme.section ? theme : ThemeDefault;

  // Decide which date format to use
  const dateFormat = (language === 'de' || language === 'it') ? 'dd.MM.yyyy' : 'MMM d, yyyy';

  // Map each group to the exhibition in the current language
  const mappedList: Exhibition[] = data.map(group => {
    const match = group.exhibition.find(e => e.language === language);
    return match || group.exhibition[0];
  });

  // Sort: active first, then upcoming
  const sortedExhibitions = data
    .map(group => group.exhibition.find(e => e.language === language) || group.exhibition[0])
    .filter(exh => getExhibitionStatus(exh) !== 2)
    .sort((a, b) => {
      const aStatus = getExhibitionStatus(a);
      const bStatus = getExhibitionStatus(b);
      if (aStatus !== bStatus) return aStatus - bStatus;

      if (aStatus === 0) {
        return new Date(a.end_date).getTime() - new Date(b.end_date).getTime();
      }
      return new Date(a.start_date).getTime() - new Date(b.start_date).getTime();
    });

  return (
    <>
      <IntlContext.Consumer>
        {({ translate }) => (
        <SectionHeader title={translate('exhibitions_section_title')} center />
        )}
      </IntlContext.Consumer>
      <SliderContainer>
      {
      sortedExhibitions.map((exh, index) => (
        <ExhibitionCard key= { index } theme = { safeTheme } >
          <ExhibitionTitle theme={ safeTheme }>{ exh.title }</ExhibitionTitle>
          {
            exh.subtitle && (
              <ExhibitionSubtitle theme={ safeTheme } > { exh.subtitle } </ExhibitionSubtitle>
            )
          }
          <ExhibitionDescription theme={ safeTheme }> { exh.description } </ExhibitionDescription>
          <ExhibitionLocationAndDates theme={ safeTheme } > 
            { format(new Date(exh.start_date), dateFormat)} –{ ' '}
            { format(new Date(exh.end_date), dateFormat) }
            { exh.location && `, ${exh.location}` }
          </ExhibitionLocationAndDates>
        </ExhibitionCard>
        ))
      }
      </SliderContainer>
    </>
  );
};

export default Exhibitions;