// TourContentAvailability.js
// @flow strict
/* eslint-disable react/no-danger */
import React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import Line from '../../../../../../../../../../primitives/Line/index.js';
import Text from '../../../../../../../../../Text/index.js';
import {
  isToday,
  isTomorrow,
  formatDate,
  parseDate,
} from '../../../../../../../../../../services/dateTimeUtils/index.js';
import type { Theme } from '../../../../../../../../../../records/Theme.js';
import type { Language } from '../../../../../../../../../../services/intl/context.js';
import mq from '../../../../../../../../../../services/mediaQuery/index.js';
import { useTheme } from 'styled-components'; // Import useTheme hook

type Props = {
  firstAvailable: string,
  secondAvailable: string,
  language: Language,
  className?: ?string,
  showAvailableOnDateText: boolean,
  date: ?Date,
};

const AvailabilityLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  max-width: 155px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${mq.SMALL_MOBILE`
    max-width: none;
    overflow: visible;
    white-space: normal;
    text-overflow: initial;
  `}
`;

const TourContentAvailability = ({
  firstAvailable,
  secondAvailable,
  className,
  language,
  showAvailableOnDateText,
  date,
}: Props) => {
  const theme = useTheme(); // Access theme via useTheme hook

  let availability;
  if (!firstAvailable) {
    return <span className={className} />;
  }
  const parsedDate = parseDate(firstAvailable);
  const isTodayOrTomorrow = isToday(parsedDate) || isTomorrow(parsedDate);
  const isTodayAndTomorrow =
    secondAvailable && isToday(parsedDate) && isTomorrow(parseDate(secondAvailable));
  if (date && showAvailableOnDateText) {
    availability = <Text t="available_on_date" values={{ date: formatDate(date, language) }} />;
  } else if (isTodayAndTomorrow) {
    availability = <Text t="available_today_and_tomorrow" />;
  } else if (isTodayOrTomorrow) {
    availability = isToday(parsedDate) ? (
      <Text t="available_today" />
    ) : (
      <Text t="available_tomorrow" />
    );
  } else {
    availability = <Text t="available_from" values={{ date: formatDate(parsedDate, language) }} />;
  }

  return (
    <AvailabilityLine
      className={className}
      color={
        isTodayOrTomorrow || (date && showAvailableOnDateText)
          ? theme.trip.price
          : theme.trip.offers
      }
      fontWeight={900}
      fontSize={14}
    >
      {availability}
    </AvailabilityLine>
  );
};

TourContentAvailability.defaultProps = {
  className: null,
};

export default TourContentAvailability;
