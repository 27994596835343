// @flow strict
import * as R from 'ramda/src/index.js';
import type { Currency } from '../currency/context.js';
import type { Tour } from '../../records/Tour.js';

// TODO: replace GroupedTours with Tour[]
export type GroupedTours = Tour[][];

// $FlowFixMe
export const omitFn = R.map(R.omit(['_distinctSeqID', '_highlightResult']));
// $FlowIssue: probably not flow-typed correctly
export const byGroupId = R.groupWith((a, b) => a.groupId === b.groupId);

const arrayToLastElementProviderId = R.compose(
  R.propOr(0, 'providerId'),
  R.last,
);

export const getMaxPrice = (tours: Tour[], currency: Currency): number =>
  Math.max(...tours.map(x => x[`price_${currency}`]));

export const getMinPrice = (tours: Tour[], currency: Currency): number =>
  Math.min(...tours.map(x => x[`price_${currency}`]));

// $FlowFixMe
export const getTourProviderIdWithHighestPrice = (tours: Tour[], currency: Currency): number =>
  R.compose(
    arrayToLastElementProviderId,
    R.sortBy(R.prop(`price_${currency}`)),
  )(tours);
