// @flow strict
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Box, Flex } from '../../primitives/Essentials/index.js';
import IntlContext from '../../services/intl/context.js';
import ContentContext from '../../services/content/context.js';
import { container } from '../../services/styleUtils/index.js';
import Footer from '../../components/Footer/index.js';
import { SECTION_LAYOUT, BREADCRUMBS_LAYOUT, SECTION_LAYOUT_NO_PADDING_MOBILE } from '../../consts/layout.js';
import Navbar from '../../components/Navbar/index.js';
import Subheader from '../../components/Subheader/index.js';
import Text from '../../components/Text/index.js';
import Breadcrumbs from '../../components/Breadcrumbs/index.js';
import type { Author } from '../../records/Authors.js';
import EditorialSections from '../../components/EditorialContent/components/EditorialSections/index.js';

const StyledContainerBox = styled(Box)`
  ${container()};
`;

const AuthorTitle = styled.h1`
  font-size: 32px;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const StyledContainer = styled.div`
  position: relative;
`;

const AuthorImage = styled.img`
  width: 300px;
  height: 300px;
  border-radius: 150px;
  vertical-align: middle;
`;

type Props = {};

const AuthorPage = () => {
  const { language, translate } = React.useContext(IntlContext);
  const { authors } = React.useContext(ContentContext);
  const location = useLocation();
  const { authorId } = useParams(); // get author ID from route parameters
  
  const found_author = authors[authorId];
  const authorName = found_author ? found_author.name : '';
  const authorBio = found_author ? found_author.bio : '';

  return (
    <>
      <StyledContainer>
          <Navbar/>
      </StyledContainer>
      <StyledContainerBox {...SECTION_LAYOUT} $mt={0}>
        <Flex $flexDirection="column">
          <Box $px={BREADCRUMBS_LAYOUT.$px}>
            <Breadcrumbs
              items={[
                {
                  name: authorName,
                  url: `/${language}/author/${authorId}`,
                },
              ]}
              activeIndex={0}
            />
          </Box>
          <Flex {...SECTION_LAYOUT_NO_PADDING_MOBILE}  $alignItems="center" $flexDirection="column">
            <AuthorImage
              src={found_author.photoUrlBig[0]}
              srcSet={`${found_author.photoUrlBig[1]} 2x`}
              alt={found_author.name} 
            />
            <AuthorTitle>{authorName}</AuthorTitle>
            <Text t={authorBio} />
          </Flex>
          {found_author.authorDescription && Boolean(found_author.authorDescription) && (
            <Flex
              key={`ec-1`}
              id={`editorial-1`}
              $flexDirection="column"
              $alignItems="center"
              $px={[0, null, null, null, 0]}
              $mt={[0, null, null, null, 0]}
            >
              <EditorialSections sections={found_author.authorDescription} />
            </Flex>
          )}
        </Flex>
      </StyledContainerBox>
      <Footer />
    </>
  );
};

export default AuthorPage;