// @flow strict

export const TOUR_ATTRIBUTES = [
  'id',
  'groupId',
  'providerId',
  'title_en',
  'title_de',
  'title_it',
  'abstract_en',
  'abstract_de',
  'abstract_it',
  'url_en',
  'url_de',
  'url_it',
  'rating',
  'ratingsCount',
  'popularity',
  'firstAvailable',
  'secondAvailable',
  'imageIds',
  '_tags',
  '_breadcrumbs',
  'bestConverter',
  // price_${currency} and fee_${currency} added dynamically
];

export default TOUR_ATTRIBUTES;