// @flow strict
import * as R from 'ramda/src/index.js';
import type { Tour } from '../../records/Tour.js';
import type { Currency } from '../currency/context.js';

const sortTours = (tours: Tour[], currency: Currency) =>
  R.sortWith([
    R.comparator((a, b) => a[`price_${currency}`] < b[`price_${currency}`]),
    R.comparator((a, b) => a.popularity > b.popularity),
  ])(tours);

export default sortTours;
