// @flow strict
import React from 'react';
import { Flex, Box } from '../../../../../../primitives/Essentials/index.js';
import UrlContext from '../../../../../../services/url/context.js';
import Button from '../../../../../../components/Button/index.js';
import Text from '../../../../../../components/Text/index.js';
import type { Section as SectionType } from '../../../../../../records/PoiContent.js';
import type { Poi } from '../../../../../../records/Poi.js';
import type { GroupTour } from '../../../../../../records/Tour.js';
import SectionHeader from '../../../../../../components/SectionHeader/index.js';
import Tours from '../../../../../../components/Tours/index.js';
import { SECTION_LAYOUT_NO_PADDING_MOBILE } from '../../../../../../consts/layout.js';

type Props = {
  id: string,
  section: SectionType,
  poi: Poi,
  tours: GroupTour[],
  showAvailabilities: boolean,
  date: ?Date,
  poiToursSectionPageUrl: string,
  maxTours?: number,
};

const Section = ({ id, section, tours, showAvailabilities, poi, date, poiToursSectionPageUrl, maxTours }: Props) => { 
  const poiId = Number(poi.objectID);
  const abTestOpenTourGroup = ((poiId % 2) === 0)

  return (
  <Box id={id} {...SECTION_LAYOUT_NO_PADDING_MOBILE}>
    <Flex $flexDirection="column">
      <SectionHeader title={section.title} subtitle={section.abstract || ''} />
      <Tours tours={tours} showAvailabilities={showAvailabilities} date={date} maxTours={maxTours} abTestOpenTourGroup={abTestOpenTourGroup} />
      {(tours.length > 4 || poiToursSectionPageUrl) && (
        <UrlContext.Consumer>
          {({ openPoiCategoryUrl }) => (
            poiToursSectionPageUrl ? (
              <Button href={poiToursSectionPageUrl}>
                <Text t="more_about" values={{ item: section.title }} />
              </Button>
            ) : (
              // Default rendering
              <Button useDiv onClick={() => openPoiCategoryUrl(poi, section.id)}>
                <Text t="see_more" values={{ item: section.title }} />
              </Button>
            )
          )}
        </UrlContext.Consumer>
      )}
    </Flex>
  </Box>
  )
};

Section.defaultProps = {
  maxTours: 4,
};

export default Section;
