// @flow strict
import * as React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Flex } from '../../../../primitives/Essentials/index.js';
import { RemoveScroll } from 'react-remove-scroll';
import mq from '../../../../services/mediaQuery/index.js';
import type { Theme } from '../../../../records/Theme.js';
import type { SearchSuggestion } from '../../../../records/SearchSuggestion.js';
import SuggestionsHeader from './components/SuggestionsHeader/index.js';
import SearchBar from './components/SearchBar/index.js';
import SearchSuggestions from './components/SearchSuggestions/index.js';
import SearchInput from '../../../SearchInput/index.js';
import FullTextSuggestion from './components/FullTextSuggestion/index.js';

const SearchOverlay = styled(Flex)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.21);
`;

const SearchSuggestionsContainer = styled(Flex)`
  position: absolute;
  top: 70px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 1);

  ${mq.TABLET`
    top: 47px;
    max-width: 386px;
    width: 386px;
    height: ${({ searchSuggRows }) => `${60 * searchSuggRows}`}px;
    border-radius: 5px;
    box-shadow: 0 2.5px 4px 0 rgba(0, 0, 0, 0.5);
  `};
`;

const SearchInputContainer = styled(Flex)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  width: 386px;
  max-width: 386px;
  height: 100%;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
`;

type Props = {
  theme: Theme,
  destinationId?: number,
  searchText: string,
  placeholder: string,
  onChange: string => void,
  onSubmit: string => void,
  onClickOutside: (MouseEvent | TouchEvent) => void,
  searchWrapperNode: ?React.Ref<*>,
  isMobile: boolean,
  onCancel?: () => void,
  handleKeyDown: KeyboardEvent => void,
  cursor: number,
  searchSuggestions: SearchSuggestion[],
  populateSuggestions: string => void,
  handleCursorChange: number => void,
};

class Overlay extends React.PureComponent<Props> {
  componentDidMount() {
    const { searchText, isMobile, populateSuggestions } = this.props;
    populateSuggestions(searchText);
    if (isMobile) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 500);
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { searchText, populateSuggestions } = this.props;

    if (searchText !== prevProps.searchText) {
      populateSuggestions(searchText);
    }
  }

  render() {
    const {
      theme,
      searchText,
      placeholder,
      onChange,
      onSubmit,
      destinationId,
      searchWrapperNode,
      onClickOutside,
      onCancel,
      isMobile,
      handleKeyDown,
      cursor,
      searchSuggestions,
      handleCursorChange,
    } = this.props;

    return isMobile ? (
      <RemoveScroll>
        <SearchOverlay $flexDirection="column">
          <Flex $flexDirection="column">
            {onCancel && (
              <SearchBar
                theme={theme}
                prepopulatedValue={searchText}
                onTextChange={onChange}
                onSubmitPressed={onSubmit}
                onCancelPressed={onCancel}
                handleKeyDown={handleKeyDown}
              />
            )}
          </Flex>
          <SearchSuggestionsContainer $flexDirection="column">
            <SuggestionsHeader />
            <SearchSuggestions
              suggestions={searchSuggestions}
              destinationId={destinationId}
              cursor={cursor}
              handleCursorChange={handleCursorChange}
            />
            {searchText && searchText.length > 0 && (
              <FullTextSuggestion
                name={searchText}
                destinationId={destinationId}
                selected={cursor === searchSuggestions.length}
                onHover={() => handleCursorChange(searchSuggestions.length)}
              />
            )}
          </SearchSuggestionsContainer>
        </SearchOverlay>
      </RemoveScroll>
    ) : (
      <>
        <SearchOverlay onTouchEnd={onClickOutside} />
        <div ref={searchWrapperNode}>
          <SearchInputContainer $flexDirection="column">
            <SearchInput
              autoFocus
              theme={theme}
              placeholder={placeholder}
              prepopulatedValue={searchText}
              onChange={onChange}
              onSubmit={onSubmit}
              handleKeyDown={handleKeyDown}
            />
          </SearchInputContainer>
          <SearchSuggestionsContainer
            $flexDirection="column"
            searchSuggRows={searchSuggestions.length + 1}
          >
            <SearchSuggestions
              cursor={cursor}
              suggestions={searchSuggestions}
              destinationId={destinationId}
              handleCursorChange={handleCursorChange}
            />
            {searchText && searchText.length > 0 && (
              <FullTextSuggestion
                name={searchText}
                destinationId={destinationId}
                selected={cursor === searchSuggestions.length}
                onHover={() => handleCursorChange(searchSuggestions.length)}
              />
            )}
          </SearchSuggestionsContainer>
        </div>
      </>
    );
  }
}

export default Overlay;
