// @flow strict
import * as React from 'react';
import { Box, Flex } from '../../primitives/Essentials/index.js';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { BITMAP_URL, RES_URL } from '../../../../etc/appConfig.js';
import Text from '../Text/index.js';
import Image from '../Image/index.js';
import Line from '../../primitives/Line/index.js';
import mq from '../../services/mediaQuery/index.js';
import LinkTo from '../LinkTo/index.js';
import DestinationContext from '../../services/destination/context.js';
import IntlContext from '../../services/intl/context.js';
import type { ListElement as ListElementType } from '../../records/DestinationContent.js';
import PhotoAttribution from '../PhotoAttribution/index.js';

const NumberDiv = styled.div`
  min-width: 32px;
  margin-right: 12px;
  color: ${({ theme }) => theme.trip.divider};
  font-weight: 900;
  font-size: 54px;
  line-height: 0.9;
  text-align: center;
  word-break: break-all;
`;

const Title = styled.h3`
  margin-block-start: 0;
  margin-block-end: 0;
  font-weight: 900;
  font-size: 22px;
  line-height: 1.45;
`;

const StyledImg = styled(Image)`
  display: block;
  width: 100%;
  height: auto;
  min-height: 194px;
  margin-bottom: 6px;
  border-radius: 5px;
  aspect-ratio: 3/2;
  ${mq.BIG_MOBILE`
    min-height: 372px;
  `};
  ${mq.TABLET`
    min-height: 227px;
  `};
  ${mq.DESKTOP`
    min-height: 384px;
    max-height: 384px;
  `};
`;
const ListItem = styled(Box)`
  text-align: left;
  ${mq.TABLET`
    &:nth-child(even) {
      padding-left: 7.5px;
    }
    &:nth-child(odd) {
      padding-right: 7.5px;
    }
  `}
`;

const AbstractLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  color: ${({ theme }) => theme.trip.abstract};
  a {
    color: ${({ theme }) => theme.secondary};
    font-weight: 900;
    text-decoration: none;
  }
`;

const StyledImageLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  color: ${({ theme }) => theme.trip.divider};
  text-align: center;
`;

type Props = {
  listElements: ListElementType[],
  simpleLinkStyle: boolean,
};

const ListElements = ({ listElements, simpleLinkStyle }: Props) => {
  const { language } = React.useContext(IntlContext);
  const { attractions, configs } = React.useContext(DestinationContext);

  return (
    <Flex $flexWrap="wrap" $mt={40}>
      {listElements.map((listElement, i) => {
        const firstLink = listElement.linkTo && listElement.linkTo[0];

        let imageUrl = listElement.photo ? listElement.photo.photoUrls[0] : null;
        let imageUrl2x =
          listElement.photo && listElement.photo.photoUrls[1]
            ? listElement.photo.photoUrls[1]
            : null;
        let imageCaption = listElement.photo && listElement.photo.photoTitle;

        if (firstLink && !listElement.photo) {
          if (firstLink.type === 'poi') {
            const poi = attractions.find(p => Number(p.objectID) === firstLink.id);
            imageCaption = poi && !imageCaption ? poi.name : imageCaption; // change imageCaption only if it wasn't set in json
            imageUrl = `${BITMAP_URL}/poi/${firstLink.id}/560x376?returnCrop=yes`;
            imageUrl2x = `${BITMAP_URL}/poi/${firstLink.id}/1120x752?returnCrop=yes`;
          } else if (firstLink.type === 'category') {
            const categories =
              configs[language] && configs[language].Tags.concat(configs[language].Categories);
            const category = categories
              ? categories.find(c => Number(c.Tag) === firstLink.id)
              : null;
            imageCaption = category && !imageCaption ? category.Name : imageCaption; // change imageCaption only if it wasn't set in json
            imageUrl = category ? `${RES_URL}/tag-images/${category.Tag}.jpg` : imageUrl;
            imageUrl2x = category ? `${RES_URL}/tag-images/${category.Tag}.jpg` : imageUrl2x;
          }
        }

        return (
          <ListItem key={listElement.title || i} width={[1, null, null, null, 1 / 2]} $mb={50}>
            <Flex $flexDirection="column" $mb={14.5}>
              <StyledImg
                src={imageUrl}
                srcSet={imageUrl2x ? `${imageUrl2x} 2x` : undefined}
                alt={imageCaption}
                lazy
              />
              <StyledImageLine fontSize={14}>
                {imageCaption}
                {listElement.photo && listElement.photo.photoAttribution && (
                  <PhotoAttribution attribution={listElement.photo.photoAttribution} />
                )}
              </StyledImageLine>
            </Flex>
            <Flex $flexDirection="column">
              <Flex>
                <NumberDiv>{i + 1}</NumberDiv>
                <Flex $flexDirection="column">
                  {listElement.title && <Title>{listElement.title}</Title>}
                  {listElement.abstract && (
                    <AbstractLine fontSize={16} lineHeight={1.38}>
                      <Text t={listElement.abstract} html />
                    </AbstractLine>
                  )}
                </Flex>
              </Flex>
              {listElement.linkTo && (
                <Flex $flexDirection="column" $mt={10}>
                  {listElement.linkTo.map(link => (
                    <Box $mt={8} key={link.id} $ml={[null, null, null, null, null, 44]}>
                      <LinkTo
                        link={link}
                        singleLink={Boolean(listElement.linkTo && listElement.linkTo.length === 1)}
                        simpleLinkStyle={simpleLinkStyle}
                      />
                    </Box>
                  ))}
                </Flex>
              )}
            </Flex>
          </ListItem>
        );
      })}
    </Flex>
  );
};

export default ListElements;
