// @flow strict
/* eslint-disable no-nested-ternary */
import * as React from 'react';
import { withTheme } from 'styled-components';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Flex } from '../../primitives/Essentials/index.js';
import Line from '../../primitives/Line/index.js';
import type { Theme } from '../../records/Theme.js';
import mq from '../../services/mediaQuery/index.js';
import { container } from '../../services/styleUtils/index.js';

const StyledLink = styled.a`
  width: 100%;
  color: ${({ kind, theme }) => (kind === 'primary' ? theme.tertiary : theme.secondary)};
  text-decoration: none;
  background: ${({ kind, theme }) =>
    kind === 'primary' ? theme.secondary : theme.trip.background};
  border-radius: ${({ rounded }) => (rounded ? '5px' : '0')};
  cursor: pointer;
  ${({ $responsive }) =>
    $responsive
      ? mq.TABLET`
      width: fit-content;
      padding: 0px 40px;
      border-radius: 22.8px;
      min-width: 300px;
      `
      : ''};
  ${mq.DESKTOP`
        &:hover {
          text-decoration: underline;
        }
        `};
`;

const StyledDivLink = styled.div`
  width: 100%;
  color: ${({ kind, theme }) => (kind === 'primary' ? theme.tertiary : theme.secondary)};
  text-decoration: none;
  background: ${({ kind, theme, disabled }) =>
    disabled ? theme.trip.offers : kind === 'primary' ? theme.secondary : theme.trip.background};
  border-radius: ${({ rounded }) => (rounded ? '5px' : '0')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  ${({ $responsive }) =>
    $responsive
      ? mq.TABLET`
      width: fit-content;
      padding: 0px 40px;
      border-radius: 22.8px;
      min-width: 300px;
      `
      : ''};
  ${mq.DESKTOP`
        &:hover {
          text-decoration: underline;
        }
        `}
`;

const StyledFlex = styled(Flex)`
  position: relative;
  ${container()};
`;

const StyledIcon = styled(Flex)`
  position: absolute;
  top: calc(50% - 11px);
  left: 0;
  color: ${({ kind, theme }) => (kind === 'primary' ? theme.tertiary : theme.secondary)};
  font-weight: 900;
  font-size: 20px;
`;

const StyledButtonTextLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  margin-right: 40px;
  margin-left: 40px;
  text-align: center;
`;

type Props = {
  children: React.Node,
  theme: Theme,
  responsive?: boolean,
  rounded?: boolean,
  onClick?: () => void, // TODO: pass event
  kind?: 'primary' | 'secondary',
  icon?: ?React.Node,
  className?: ?string,
  rel?: string,
  target?: string,
  href?: string,
  useDiv?: boolean,
  disabled?: boolean,
};

const Button = ({
  theme,
  onClick,
  rounded,
  responsive,
  children,
  icon,
  className,
  kind,
  href,
  rel,
  target,
  useDiv,
  disabled,
}: Props) => {
  const Node = (
    <StyledFlex $pt={12} $pb={15} $alignItems="center" $justifyContent="center">
      <StyledIcon
        fontSize={20}
        fontWeight={900}
        color={kind === 'primary' ? theme.tertiary : theme.secondary}
        $pl={[17.5, null, null, null, 30]}
        $alignItems="center"
      >
        {icon}
      </StyledIcon>
      <StyledButtonTextLine
        fontSize={20}
        fontWeight={900}
        color={kind === 'primary' ? theme.tertiary : theme.secondary}
      >
        {children}
      </StyledButtonTextLine>
    </StyledFlex>
  );
  return (
    <Flex className={className} $justifyContent="center">
      {disabled ? (
        <StyledDivLink
          kind={kind}
          disabled={disabled}
          $rounded={rounded ? 1 : 0}
          $responsive={responsive ? 1 : 0}
          className={className}
        >
          {Node}
        </StyledDivLink>
      ) : useDiv && onClick ? (
        <StyledDivLink
          kind={kind}
          $rounded={rounded ? 1 : 0}
          $responsive={responsive ? 1 : 0}
          onClick={onClick}
          onKeyPress={ev => {
            if (ev.key === 'Enter') {
              onClick();
            }
          }}
          role="button"
          className={className}
          tabIndex={0}
        >
          {Node}
        </StyledDivLink>
      ) : (
        <StyledLink
          kind={kind}
          $rounded={rounded ? 1 : 0}
          $responsive={responsive ? 1 : 0}
          onClick={onClick}
          href={href}
          rel={rel}
          target={target}
        >
          {Node}
        </StyledLink>
      )}
    </Flex>
  );
};

Button.defaultProps = {
  className: null,
  icon: null,
  kind: 'primary',
  responsive: true,
  rounded: false,
  onClick: null,
  rel: null,
  target: null,
  href: null,
  useDiv: false,
  disabled: false,
};

export default withTheme(Button);
