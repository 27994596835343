// @flow strict
import * as React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Box, Flex } from '../../primitives/Essentials/index.js';
import type { LinkTo as LinkType } from '../../records/DestinationContent';
import Image from '../Image/index.js';
import Link from '../Link/index.js';
import Text from '../Text/index.js';
import { BITMAP_URL, RES_URL } from '../../../../etc/appConfig.js';
import DestinationContext from '../../services/destination/context.js';
import IntlContext from '../../services/intl/context.js';
import UrlContext from '../../services/url/context.js';
import WindowContext from '../../services/window/context.js';
import Line from '../../primitives/Line/index.js';
import { hover, darkOverlay } from '../../services/styleUtils/index.js';
import mq from '../../services/mediaQuery/index.js';

type Props = {
  link: LinkType,
  singleLink: boolean,
  simpleLinkStyle?: boolean,
};

const getHref = (type, poi, category, buildPoiPageUrl) => {
  if (type === 'poi' && poi) {
    return buildPoiPageUrl(poi);
  }
  if (type === 'category' && category && category.hasContent) {
    return category.urlName;
  }
  return undefined;
};

const getName = (type, poi, category) => {
  if (type === 'poi' && poi) {
    return poi.name;
  }
  if (type === 'category' && category) {
    return category.Name;
  }
  return '';
};

const getImageUrl = (link, poi, category, isMobile, doubleSize = false) => {
  const normal = !isMobile ? '120x120' : '345x149';
  const double = !isMobile ? '240x240' : '690x298';

  if (link.type === 'poi' && poi) {
    return `${BITMAP_URL}/poi/${link.id}/${doubleSize ? double : normal}?returnCrop=yes`;
  }
  if (link.type === 'category' && category) {
    // TODO: image service or mutliple resolutions
    return `${RES_URL}/tag-images/${category.Tag}.jpg`;
  }
  return '';
};

const isCrawlable = (type, poi, category, language) => {
  if (type === 'poi' && poi) {
    return poi.index.includes(language);
  }
  if (type === 'category' && category) {
    return Boolean(category.hasContent);
  }
  return false;
};

const StyledFlex = styled(Flex)`
  position: relative;
  height: 149px;
  background-image: ${({ $imageUrl }) => `url('${$imageUrl}')`};
  background-position: center center;
  background-size: cover;
  border-radius: 5px;
  ${mq.TABLET`
    display: none;
  `};
`;

const StyledLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  z-index: 1;
  color: ${({ theme }) => theme.tertiary};
  text-align: center;
`;

const Button = styled(Box)`
  z-index: 1;
  ${({ $mWidth }) => ($mWidth ? `width: 100%;` : '')}
  padding: 12px 15px;
  color: ${({ theme }) => theme.tertiary};
  font-weight: 900;
  font-size: 20px;
  text-align: center;
  background-color: ${({ theme }) => theme.secondary};
  border-radius: 5px;
  ${mq.TABLET`
    font-size: 16px;
  `}
  ${mq.DESKTOP`
    ${({ $mWidth }) =>
      $mWidth
        ? `
        width: ${$mWidth}px;
        min-width: ${$mWidth}px;
        max-width: ${$mWidth}px;
        `
        : ''}
    font-size: 20px;
  `}
`;

const WrapperTablet = styled(Flex)`
  ${hover()};
  min-height: 120px;
  background-color: ${({ theme }) => theme.trip.background};
  border-radius: 5px;
`;

const StyledOverlay = styled.div`
  ${darkOverlay()};
`;

const DesktopImage = styled(Image)`
  width: 120px;
  height: 120px;
  border-radius: 5px 0 0 5px;
  object-fit: cover;
  ${mq.TABLET`
    width: 90px;
  `}
  ${mq.DESKTOP`
    width: 120px;
  `}
`;

const NameLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  font-size: 20px;
  text-align: center;
  ${mq.TABLET`
    font-size: 18px;
  `};
  ${mq.DESKTOP`
    font-size: 20px;
  `};
`;

const FullHeightFlex = styled(Flex)`
  height: 100%;
`;

const LinkTo = ({ link, singleLink, simpleLinkStyle }: Props) => {
  const { attractions, configs } = React.useContext(DestinationContext);
  const { buildPoiPageUrl, openPoiPage, openDestinationCategoryPage } = React.useContext(
    UrlContext,
  );
  const { language } = React.useContext(IntlContext);
  const { isMobile } = React.useContext(WindowContext);

  const poi = attractions.find(p => Number(p.objectID) === link.id);
  const categories =
    configs[language] && configs[language].Tags.concat(configs[language].Categories);
  const category = categories ? categories.find(c => Number(c.Tag) === link.id) : null;

  const imageUrl = getImageUrl(link, poi, category, false);
  const imageUrl2x = getImageUrl(link, poi, category, false, true);
  const mobileImageUrl = getImageUrl(link, poi, category, true);

  const name = getName(link.type, poi, category);
  const href = getHref(link.type, poi, category, buildPoiPageUrl);
  const crawlable = isCrawlable(link.type, poi, category, language);
  const openPage =
    link.type === 'poi' && poi
      ? () => openPoiPage(poi)
      : () => openDestinationCategoryPage(category ? category.Tag : null);

  if (!href && !category && !poi) {
    return null;
  }

  return (
    <Link
      useDiv={!crawlable}
      onClick={!crawlable ? openPage : undefined}
      href={crawlable ? href : undefined}
    >
      {isMobile || simpleLinkStyle ? (
        <>
          {((singleLink && isMobile) || simpleLinkStyle) && (
            <Flex $ml={44} $justifyContent="flex-end">
              <Button fontSize={24} fontWeight={900} $mWidth={320}>
                <Text t="more_information_and_tickets" html />
              </Button>
            </Flex>
          )}
          {!singleLink && isMobile && !simpleLinkStyle && (
            <StyledFlex
              $justifyContent="space-between"
              $alignItems="center"
              $flexDirection="column"
              $imageUrl={mobileImageUrl}
              $pb={15}
            >
              <FullHeightFlex $justifyContent="center" $alignItems="center">
                <StyledLine fontSize={24} fontWeight={900}>
                  {name}
                </StyledLine>
              </FullHeightFlex>
              <Button fontSize={24} fontWeight={900}>
                <Text t="more_information_and_tickets" html />
              </Button>
              <StyledOverlay />
            </StyledFlex>
          )}
        </>
      ) : (
        <WrapperTablet $mt={10}>
          <DesktopImage src={imageUrl} srcSet={`${imageUrl2x} 2x`} alt={name} lazy />
          <Flex
            className="darkenOnHover" 
            $flexDirection="column"
            $px={[17, null, null, null, 17]}
            $justifyContent="space-between"
            width={1}
          >
            <FullHeightFlex $alignItems="center" $justifyContent="center">
              <NameLine fontWeight={900}>{name}</NameLine>
            </FullHeightFlex>
            <Box $mb={15}>
              <Button className="title" fontSize={24} fontWeight={900}>
                <Text t="more_information_and_tickets" html />
              </Button>
            </Box>
          </Flex>
        </WrapperTablet>
      )}
    </Link>
  );
};

LinkTo.defaultProps = {
  simpleLinkStyle: false,
};

export default LinkTo;
