// withPoi/index.js
// @flow strict
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PoiContext from '../../services/poi/context.js';
import type { PoiContextType } from '../../services/poi/context.js';
import IntlContext from '../../services/intl/context.js';
import ContentContext from '../../services/content/context.js';
import WithAlgolia from './components/WithAlgolia.js';

type Props = {}; // Adjust as necessary
type EnhancedProps = Props & PoiContextType;

const withPoi = (
  Component: React.ComponentType<EnhancedProps>,
): React.FC<Props> => (props: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { poiId } = props;

  return (
  <ContentContext.Consumer>
    {({ imageAttributions }) => (
      <IntlContext.Consumer>
        {({ language }) => (
          <PoiContext.Consumer>
            {(poiProps: PoiContextType) => {
              if (poiId && !poiProps.poi[language].name) {
                return (
                  <WithAlgolia language={language} id={poiId} imageAttributions={imageAttributions}>
                    <Component {...props} {...poiProps} />
                  </WithAlgolia>
                );
              }
              return <Component {...props} {...poiProps} />;
            }}
          </PoiContext.Consumer>
        )}
      </IntlContext.Consumer>
    )}
  </ContentContext.Consumer>
  );
};

export default withPoi;
