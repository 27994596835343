// @flow strict

export default function stripHtml(input: string): string {
  return input
    .replace(/<(?:.|\n)*?>/gm, '')
    .replace(/&lt;/gi, '') // remove &lt;
    .replace(/&gt;/gi, '') // remove &gt;
    .replace(/&nbsp;/gi, ' ') // remove &nbsp;
    .replace(/&quot;/gi, '"') // quotation mark;
    .replace(/&apos;/gi, "'"); // apos mark;
}
