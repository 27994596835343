// @flow strict
/* eslint-disable react/no-array-index-key */
import * as React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Box, Flex } from '../../primitives/Essentials/index.js';
import type { EditorialContent as EditorialContentType } from '../../records/EditorialContent.js';
import EditorialSections from './components/EditorialSections/index.js';
import Text from '../Text/index.js';
import mq from '../../services/mediaQuery/index.js';
import Line from '../../primitives/Line/index.js';
import Image from '../Image/index.js';
import PhotoAttribution from '../PhotoAttribution/index.js';

const StyledH2 = styled.h2`
  margin: 10px 0 0;
  color: ${({ theme }) => theme.section.title};
  font-weight: 900;
  font-size: 24px;
  text-align: center;
`;

const StyledLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  max-width: 800px;
  margin-top: 15px;
  color: ${({ theme }) => theme.trip.abstract};
  text-align: center;
  a {
    color: ${({ theme }) => theme.secondary};
  }
`;

const StyledImg = styled(Image)`
  display: block;
  width: 100%;
  min-width: 100%;
  height: auto;
  min-height: 194px;
  border-radius: 5px;
  ${mq.SMALL_MOBILE`
  min-height: 253px;
`};
  ${mq.MIDDLE_MOBILE`
  min-height: 306px;
`};
  ${mq.BIG_MOBILE`
  min-height: 462px;
`};
  ${mq.TABLET`
  min-height: 576px;
`};
  ${mq.DESKTOP`
  min-height: 779px;
`};
`;

const StyledImageLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  color: ${({ theme }) => theme.trip.divider};
  text-align: center;
`;

type Props = {
  editorialContent: EditorialContentType[],
  smallerMarginTop?: boolean,
};

const EditorialContent = ({ editorialContent, smallerMarginTop }: Props) =>
  editorialContent.map<React.Node>((ec, i) => {
    const marginTopMobile = ec.photo ? 70 : 50;
    const marginTopTablet =
      // eslint-disable-next-line no-nested-ternary
      ec.photo ? (smallerMarginTop ? 65 : 105) : smallerMarginTop ? 45 : 85;
    return (
      <Flex
        key={`ec-${i}`}
        id={`editorial-${i+1}`}
        $flexDirection="column"
        $alignItems="center"
        $px={[15, null, null, null, 0]}
        $mt={[marginTopMobile, null, null, null, marginTopTablet]}
      >
        <Flex $flexDirection="column" $alignItems="center" $px={[0, null, null, null, 30]} width={1}>
          {ec.photo && (
            <Flex width={1} $flexDirection="column">
              <Box $mb={4}>
                <StyledImg
                  src={ec.photo.photoUrls[0]}
                  srcSet={ec.photo.photoUrls[1] ? `${ec.photo.photoUrls[1]} 2x` : undefined}
                  alt={ec.photo.photoTitle}
                  lazy
                />
              </Box>
              <StyledImageLine fontSize={14}>
                {ec.photo.photoTitle}
                <PhotoAttribution attribution={ec.photo.photoAttribution} />
              </StyledImageLine>
            </Flex>
          )}
          {ec.title && <StyledH2>{ec.title}</StyledH2>}
          {ec.abstract && (
            <StyledLine fontSize={20} lineHeight={1.38}>
              <Text t={ec.abstract} html />
            </StyledLine>
          )}
        </Flex>
        {ec.editorialSections && Boolean(ec.editorialSections.length) && (
          <EditorialSections sections={ec.editorialSections} />
        )}
      </Flex>
    );
  });

export default EditorialContent;
