// @flow strict
// $FlowFixMe
import min from 'date-fns/min/index.js';
// $FlowFixMe
import isAfter from 'date-fns/isAfter/index.js';
// $FlowFixMe
import isSameDay from 'date-fns/isSameDay/index.js';
import type { Tour, GroupTour } from '../../records/Tour.js';
import { parseDate, formatDateYMD } from '../dateTimeUtils/index.js';

export default function getFirstAvailable(tour: Tour | GroupTour): string {
  const today = new Date();
  const dates = [parseDate(tour.firstAvailable)];
  if (tour.secondAvailable && tour.secondAvailable.length) {
    dates.push(parseDate(tour.secondAvailable));
  }
  const filteredDates = dates.filter(date => isAfter(date, today) || isSameDay(today, date));
  return filteredDates.length ? formatDateYMD(min(filteredDates)) : '';
}

export function getMinFirstAvailable(tourGroup: Tour[]): string {
  return formatDateYMD(min(tourGroup.map(t => parseDate(t.firstAvailable))));
}

export function getMinSecondAvailable(tourGroup: Tour[]): string {
  const dates = tourGroup.filter(t => t.secondAvailable).map(t => parseDate(t.secondAvailable));
  return dates && dates.length ? formatDateYMD(min(dates)) : '';
}
