// @flow strict
import type { Language } from '../../intl/context.js';
import type { SectionConfigInput, SectionConfig } from '../../../records/SectionConfig.js';
import mapperTags from '../tags/index.js';

export default function mapperConfig(
  config: SectionConfigInput,
  locale: Language,
  categoryContentFiles?: string[],
): SectionConfig {
  return {
    Categories: mapperTags(config.Categories, locale, categoryContentFiles),
    Tags: mapperTags(config.Tags, locale, categoryContentFiles),
  };
}
