// @flow strict
import React from 'react';
import { Flex } from '../../../../primitives/Essentials/index.js';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import IntlContext from '../../../../services/intl/context.js';
import type { Theme } from '../../../../records/Theme.js';
import Text from '../../../Text/index.js';
import Link from '../../../Link/index.js';
import CollapsableTitle from '../CollapsableTitle/index.js';
import mq from '../../../../services/mediaQuery/index.js';

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.tertiary};
  line-height: 2.86;
  letter-spacing: -0.1px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  ${mq.TABLET`
    line-height: 2.14;
  `}
`;

type Props = {
  theme: Theme,
  expanded: boolean,
  onTitleClick?: ?() => void,
  isMobile: boolean,
};

const FooterLinks = ({ theme, expanded, onTitleClick, isMobile }: Props) => (
  <IntlContext.Consumer>
    {({ language }) => (
      <Flex width={[1, null, null, null, 1 / 3, 1 / 4]} $flexDirection="column">
        <CollapsableTitle
          title="company"
          theme={theme}
          isMobile={isMobile}
          onClick={isMobile ? onTitleClick : null}
        />
        {(expanded || !isMobile) && (
          <>
            {(language === 'en' || language === 'de' || language === 'it') && (
              <StyledLink href={`/${language}/magazine`}>
                <Text t="magazine" />
              </StyledLink>
            )}
            <StyledLink href="mailto:contact@ticketlens.com">
              <Text t="contact" />
            </StyledLink>
            <StyledLink href={`/${language}/imprint`}>
              <Text t="imprint" />
            </StyledLink>
            <StyledLink href={`/${language}/termsandconditions`}>
              <Text t="title_termsandconditions" html />
            </StyledLink>
            <StyledLink href={`/${language}/privacypolicy`}>
              <Text t="title_privacypolicy" />
            </StyledLink>
          </>
        )}
      </Flex>
    )}
  </IntlContext.Consumer>
);

export default FooterLinks;
