// @flow strict
import React from 'react';
import { Flex } from '../../../../../../primitives/Essentials/index.js';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import Attraction from '../../../../../../components/Attraction/index.js';
import type { Poi } from '../../../../../../records/Poi.js';
import type { Language } from '../../../../../../services/intl/context.js';
import Text from '../../../../../../components/Text/index.js';

type Props = {
  attractions: Poi[],
  language: Language,
};

const StyledH2 = styled.h2`
  margin: 0;
  color: ${({ theme }) => theme.section.title};
  font-weight: 900;
  font-size: 24px;
`;

const Attractions = ({ attractions, language }: Props) => (
  <Flex $flexDirection="column">
    <StyledH2>
      <Text t="top_attractions" />
    </StyledH2>
    <Flex $flexWrap="wrap" $justifyContent="flex-start">
      {attractions.map(attraction => (
        <Attraction key={attraction.objectID} attraction={attraction} language={language} />
      ))}
    </Flex>
  </Flex>
);

export default Attractions;
