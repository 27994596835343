// @flow strict
import React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Flex } from '../../../../../../../../../../../../primitives/Essentials/index.js';
import Text from '../../../../../../../../../../../../components/Text/index.js';
import Price from '../../../../../../../../../../../../components/Price/index.js';
import Line from '../../../../../../../../../../../../primitives/Line/index.js';

const StyledBookingFeeLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  color: ${({ theme }) => theme.trip.fee};
  white-space: nowrap;
`;

type Props = {
  fee: number,
};

const BookingFee = ({ fee }: Props) => (
  <Flex $justifyContent="flex-end" $alignItems="flex-end" $pt={1}>
    <StyledBookingFeeLine fontSize={12} letterSpacing={0.6}>
      +&nbsp;
      <strong>
        <Price value={fee} />
      </strong>
      &nbsp;
      <Text t="booking_fee" />
    </StyledBookingFeeLine>
  </Flex>
);

export default BookingFee;
