// @flow strict
import * as React from 'react';
import CurrencyContext from '../../services/currency/context.js';
import IntlContext from '../../services/intl/context.js';
import { formatPrice } from './utils.js';

type Props = {
  value: number,
  noFractions?: boolean,
};

// TODO: language might need to be mapped to locale, e.g. en -> en-GB
const Price = ({ value, noFractions }: Props) => (
  <IntlContext.Consumer>
    {({ language }) => (
      <CurrencyContext.Consumer>
        {({ currency }) =>
          formatPrice(
            value,
            language,
            currency,
            noFractions ? 0 : undefined,
            noFractions ? 0 : undefined,
          )
        }
      </CurrencyContext.Consumer>
    )}
  </IntlContext.Consumer>
);

Price.defaultProps = {
  noFractions: false,
};

export default Price;
