// @flow strict
import { css } from 'styled-components';
import SIZES from '../../consts/sizes.js';

const mq = Object.keys(SIZES).reduce((acc, label) => {
  // $FlowFixMe
  acc[label] = (...args: string[]) => css`
    @media (min-width: ${SIZES[label] / 16}em) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});

export default mq;
