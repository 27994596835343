// @flow strict
import * as React from 'react';
import { getPoi } from '../../../services/api/poi/index.js';
import type { Poi } from '../../../records/Poi.js';
import type { PoiContent } from '../../../records/PoiContent.js';
import type { ImageAttributions } from '../../../records/Photo.js';
import type { Language } from '../../../services/intl/context.js';
import { getPoiContent } from '../../../services/api/poiContent/index.js';

type Props = {
  id: string,
  language: Language,
  children: React.Node,
  imageAttributions: ImageAttributions,
};

type State = {
  poi: { [Language]: Poi },
  poiContent: ?PoiContent,
};

class WithAlgolia extends React.PureComponent<Props, State> {
  constructor() {
    super();
    this.state = {
      poi: {
        en: {
          name: '',
          urlName: '',
          objectID: '0',
          breadcrumbDestination: 0,
          toursCount: 0,
          searchRankingScore: 0,
          displayPoiGroupId: 0,
          index: [],
          ratings: [],
        },
        de: {
          name: '',
          urlName: '',
          objectID: '0',
          breadcrumbDestination: 0,
          toursCount: 0,
          searchRankingScore: 0,
          displayPoiGroupId: 0,
          index: [],
          ratings: [],
        },
        it: {
          name: '',
          urlName: '',
          objectID: '0',
          breadcrumbDestination: 0,
          toursCount: 0,
          searchRankingScore: 0,
          displayPoiGroupId: 0,
          index: [],
          ratings: [],
        }
      },
      poiContent: null,
    };
  }

  componentDidMount = () => {
    const { id, language, imageAttributions } = this.props;

    getPoi(id).then(poi => {
      this.setState({ poi });
    });
    getPoiContent(id, language, imageAttributions).then(poiContent => {
      if (poiContent) {
        this.setState({ poiContent });
      }
    });
  };

  render() {
    const { children } = this.props;
    const { poi, poiContent } = this.state;

    const childrenWithProps = React.Children.map(children, child =>
      React.cloneElement(child, { poi, tours: {}, poiContent }),
    );

    return childrenWithProps[0];
  }
}

export default WithAlgolia;
