// @flow strict
import * as React from 'react';
import { Box } from '../../../../../../primitives/Essentials/index.js';
import IntlContext from '../../../../../../services/intl/context.js';
import Breadcrumbs from '../../../../../Breadcrumbs/index.js';

type Props = {
  resultsCount: number,
  resultsForString: ?string,
};

const SearchToursHeader = ({ resultsForString, resultsCount }: Props) => (
  <Box $pl={[14.5, null, null, null, 29.5]}>
    {Boolean(resultsCount) && (
      <IntlContext.Consumer>
        {({ translate }) => (
          <Breadcrumbs
            items={[
              {
                name: `${translate('results_for', {
                  count: resultsCount,
                })} <strong>${resultsForString || ''}</strong>`,
                html: true,
              },
            ]}
            activeIndex={0}
          />
        )}
      </IntlContext.Consumer>
    )}
  </Box>
);

export default SearchToursHeader;
