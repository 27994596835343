// @flow strict
import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import parseUrl from './parseUrl.js';
import type { ParsedUrl } from './parseUrl.js';
import type { Language } from '../intl/context.js';
import type { Poi } from '../../records/Poi.js';
import type { Tag } from '../../records/SectionConfig.js';
import type { Destination } from '../../records/Destination.js';
import type { PoiToursSectionContent } from '../../records/PoiToursSectionContent.js';
import { destinationSelector } from '../destination/selectors.js';

type UrlContextType = {
  setLanguage: (Language) => void,
  buildAllDestinationsPageUrl: () => string,
  buildFullTextSearchUrl: (string, ?number) => string,
  openDestinationPage: (?number) => void,
  buildDestinationPageUrl: (?number) => string,
  buildDestinationCategoryPageUrl: (category: ?string, tags?: Tag[], destinationId?: number) => string,
  openDestinationCategoryPage: (category: ?string, tags?: Tag[]) => void,
  buildPoiCategoryUrl: (Poi, ?string) => string,
  openPoiCategoryUrl: (Poi, ?string) => void,
  buildPoiToursSectionUrl: (PoiToursSectionContent) => string,
  openPoiToursSectionUrl: (PoiToursSectionContent) => void,
  buildDestinationSubCategoryPage: (string) => string,
  openDestinationSubCategoryPage: (string) => void,
  buildGroupPageUrl: (?number) => string,
  openGroupPageUrl: (?number) => void,
  buildTourPageUrl: (?number) => string,
  openTourPage: (?number) => void,
  buildPoiPageUrl: (Poi) => string,
  openPoiPage: (Poi) => void,
};

const UrlContext = React.createContext<UrlContextType>();

type Props = {
  children: React.Node,
  buildUrl: (ParsedUrl, ?(Tag[]), ?number) => string,
  destinations: Destination[],
};

const putToHistory = (arr: string[], tour: string): string[] => {
  const toReturn = [...arr];
  if (toReturn.includes(tour)) {
    toReturn.splice(toReturn.indexOf(tour), 1);
  } else if (toReturn.length >= 4) {
    toReturn.pop();
  }
  toReturn.unshift(tour);
  return toReturn;
};

const UrlContextStateManager = ({ children, buildUrl, destinations }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const setLanguage = (language: Language) => {
    const parsedUrl = parseUrl(`${location.pathname}${location.search}`);
    if (language !== parsedUrl.language) {
      const newPage = parsedUrl.page
        .split('/')
        .map((part, i) => (i === 1 ? language : part))
        .join('/');

      const newUrl = parsedUrl.originalUrl
        ? parsedUrl.originalUrl.replace(parsedUrl.language, language)
        : buildUrl({ ...parsedUrl, language, page: newPage });

      navigate(newUrl);
      window.location.reload();
    }
  };

  const buildFullTextSearchUrl = (query: string, destination: ?number): string => {
    const parsedUrl = parseUrl(location.pathname);
    return destination
      ? `/${parsedUrl.language}/search?q=${query}&d=${destination}`
      : `/${parsedUrl.language}/search?q=${query}`;
  };

  const buildAllDestinationsPageUrl = (): string => {
    const parsedUrl = parseUrl(location.pathname);
    return `/${parsedUrl.language}/destinations`;
  };

  const openDestinationPage = (destination: ?number) => {
    window.open(buildDestinationPageUrl(destination), '_self');
  };

  const buildDestinationPageUrl = (destination: ?number): string => {
    const parsedUrl = parseUrl(location.pathname);
    return destination
      ? `/${parsedUrl.language}/d${destination}`
      : `/${parsedUrl.language}`;
  };

  const buildPoiPageUrl = (poi: Poi): string => {
    const parsedUrl = parseUrl(`${location.pathname}${location.search}`);
    const destination = destinations
      ? destinationSelector(destinations, poi.breadcrumbDestination)
      : null;

    return destination
      ? `/${parsedUrl.language}/p${poi.objectID}/${destination.urlName}/${poi.urlName}`
      : `/${parsedUrl.language}/p${poi.objectID}/${poi.urlName}`;
  };

  const openPoiPage = (poi: Poi) => {
    window.open(buildPoiPageUrl(poi), '_self');
  };

  const buildPoiCategoryUrl = (poi: Poi, category: ?string): string => {
    if (!category) return buildPoiPageUrl(poi);
    const parsedUrl = parseUrl(`${location.pathname}${location.search}`);
    return `/${parsedUrl.language}/p${poi.objectID}/category?s=${category}`;
  };

  const openPoiCategoryUrl = (poi: Poi, category: ?string) => {
    window.open(buildPoiCategoryUrl(poi, category), '_self');
  };

  const buildPoiToursSectionUrl = (section: PoiToursSectionContent): string => section.urlName;

  const openPoiToursSectionUrl = (section: PoiToursSectionContent) => {
    window.open(buildPoiToursSectionUrl(section), '_self');
  };

  const buildDestinationSubCategoryPage = (section: string): string => {
    const parsedUrl = parseUrl(`${location.pathname}${location.search}`);
    if (!parsedUrl.destination || !parsedUrl.category) throw new Error('malformed url');
    return `/${parsedUrl.language}/d${parsedUrl.destination}/c${parsedUrl.category}/category?s=${section}`;
  };

  const openDestinationSubCategoryPage = (section: string) => {
    window.open(buildDestinationSubCategoryPage(section), '_self');
  };

  const buildDestinationCategoryPageUrl = (
    category: ?string,
    tags?: Tag[],
    destinationId?: number,
  ): string => {
    const parsedUrl = parseUrl(location.pathname);
    const newPage = !category
      ? `/${parsedUrl.language}/d${parsedUrl.destination || destinationId}`
      : category === 'p'
      ? `/${parsedUrl.language}/d${parsedUrl.destination || destinationId}/p`
      : `/${parsedUrl.language}/d${parsedUrl.destination || destinationId}/c${category}`;

    return buildUrl(
      {
        ...parsedUrl,
        page: newPage,
        pageType: category === 'p' ? 'pois' : 'category',
        category,
      },
      tags,
      parsedUrl.destination || destinationId,
    );
  };

  const openDestinationCategoryPage = (category: ?string, tags?: Tag[]) => {
    window.open(buildDestinationCategoryPageUrl(category, tags), '_self');
  };

  const buildGroupPageUrl = (group: ?number): string => {
    const parsedUrl = parseUrl(location.pathname);
    return group ? `/${parsedUrl.language}/g${group}` : `/${parsedUrl.language}`;
  };

  const openGroupPageUrl = (group: ?number) => {
    if ('localStorage' in window && group) {
      const clickHistory = window.localStorage.getItem('clickHistory');
      window.localStorage.setItem(
        'clickHistory',
        JSON.stringify(putToHistory(clickHistory ? JSON.parse(clickHistory) : [], `g${group}`)),
      );
    }
    window.open(buildGroupPageUrl(group), '_self');
  };

  const buildTourPageUrl = (tour: ?number): string => {
    const parsedUrl = parseUrl(location.pathname);
    return tour ? `/${parsedUrl.language}/t${tour}` : `/${parsedUrl.language}`;
  };

  const openTourPage = (tour: ?number) => {
    if ('localStorage' in window && tour) {
      const clickHistory = window.localStorage.getItem('clickHistory');
      window.localStorage.setItem(
        'clickHistory',
        JSON.stringify(putToHistory(clickHistory ? JSON.parse(clickHistory) : [], `t${tour}`)),
      );
    }
    window.open(buildTourPageUrl(tour), '_blank');
  };

  return (
    <UrlContext.Provider
      value={{
        setLanguage,
        buildAllDestinationsPageUrl,
        buildFullTextSearchUrl,
        openDestinationPage,
        buildDestinationPageUrl,
        buildDestinationCategoryPageUrl,
        openDestinationCategoryPage,
        buildPoiCategoryUrl,
        openPoiCategoryUrl,
        buildPoiToursSectionUrl,
        openPoiToursSectionUrl,
        buildDestinationSubCategoryPage,
        openDestinationSubCategoryPage,
        buildGroupPageUrl,
        openGroupPageUrl,
        buildTourPageUrl,
        openTourPage,
        buildPoiPageUrl,
        openPoiPage,
      }}
    >
      {children}
    </UrlContext.Provider>
  );
};

export default UrlContext;
export { UrlContextStateManager };