// @flow strict
/* eslint-disable react/no-danger */
import React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import UrlContext from '../../services/url/context.js';
import Text from '../Text/index.js';

export type Breadcrumb = {
  url?: string,
  name: string,
  html?: boolean,
};

type Props = {
  items: Breadcrumb[],
  activeIndex?: ?number,
  className?: ?string,
  endingSlash?: ?boolean,
};

const StyledList = styled.ol`
  padding-left: 0px;
  color: ${({ theme }) => theme.secondary};
  list-style: none;

  ${({ $endingSlash }) => ($endingSlash ? 'li:after' : 'li + li:before')} {
    display: inline-block;
    padding: 0 4px;
    color: ${({ theme }) => theme.loader};
    content: '/';
  }
`;

const ListItem = styled.li`
  display: inline;
  font-size: 14px;
  line-height: 1.4;
  &:hover {
    text-decoration: underline;
  }
`;

const Link = styled.a`
  color: ${({ theme, $selected }) => (!$selected ? theme.secondary : theme.navbar.searchText)};
  font-weight: ${({ $selected }) => (!$selected ? 900 : 400)};
  text-decoration: none;
  cursor: pointer;
  strong {
    color: ${({ theme }) => theme.primary};
  }
`;

// hacky way for <br/> to work and to only break last breadcrumb part on mobile devices

// TEST: https://search.google.com/structured-data/testing-tool/u/0/
// GUIDE: https://audisto.com/insights/guides/2/
// https://schema.org/BreadcrumbList microdata
const Breadcrumbs = ({ className, items, activeIndex, endingSlash }: Props) => (
  <StyledList
    $endingSlash={endingSlash ? 1 : 0}
    className={className}
    itemScope
    itemType="https://schema.org/BreadcrumbList"
  >
    <ListItem key="home" itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
      <UrlContext.Consumer>
        {({ buildDestinationPageUrl }) => (
          <Link $selected={false} itemProp="item" href={buildDestinationPageUrl()}>
            <span itemProp="name">
              <Text t="title_long" />
            </span>
          </Link>
        )}
      </UrlContext.Consumer>
      <meta itemProp="position" content={1} />
    </ListItem>
    {items.map((item, i) => (
      <ListItem
        key={item.name}
        itemProp="itemListElement"
        itemScope
        itemType="https://schema.org/ListItem"
      >
        <Link $selected={i === activeIndex ? 1 : 0} itemProp="item" href={item.url}>
          {item.html ? (
            <span itemProp="name" dangerouslySetInnerHTML={{ __html: item.name }} />
          ) : (
            <span itemProp="name">{item.name}</span>
          )}
        </Link>
        <meta itemProp="position" content={i + 2} />
      </ListItem>
    ))}
  </StyledList>
);

Breadcrumbs.defaultProps = {
  activeIndex: null,
  className: null,
  endingSlash: false,
};

export default Breadcrumbs;
