// @flow strict
import * as React from 'react';
import { withTheme } from 'styled-components';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import type { Theme } from '../../records/Theme.js';

type Props = {
  theme: Theme,
  className?: ?string,
};

const animationSpeed = '1s';
const anmiationDelay = '0s';

const StyledRect = styled.rect`
  animation: rect1 ${animationSpeed} steps(1, end) ${anmiationDelay} infinite;
  fill: ${({ theme }) => theme.secondary};
  @keyframes rect1 {
    0% {
      opacity: 1;
    }
    33% {
      opacity: 0;
    }
    66% {
      opacity: 0;
    }
  }
`;
const StyledRect2 = styled.rect`
  animation: rect2 ${animationSpeed} steps(1, end) ${anmiationDelay} infinite;
  fill: ${({ theme }) => theme.secondary};
  @keyframes rect2 {
    0% {
      opacity: 0;
    }
    33% {
      opacity: 1;
    }
    66% {
      opacity: 0;
    }
  }
`;

const StyledRect3 = styled.rect`
  animation: rect3 ${animationSpeed} steps(1, end) ${anmiationDelay} infinite;
  fill: ${({ theme }) => theme.secondary};
  @keyframes rect3 {
    0% {
      opacity: 0;
    }
    33% {
      opacity: 0;
    }
    66% {
      opacity: 1;
    }
  }
`;

const SvgDotLoader = ({ theme, className }: Props) => (
  <svg
    className={className}
    height="25"
    viewBox="0 0 150 50"
    width="75"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd">
      <path d="m-294-850h750v1624h-750z" fill={theme.tertiary} />
      <g fill={theme.loader}>
        <rect height="20" rx="10" width="32" x="59" y="15" />
        <rect height="20" rx="10" width="32" x="11" y="15" />
        <rect height="20" rx="10" width="32" x="108" y="15" />
        <rect height="20" rx="10" width="32" x="59" y="15" />
      </g>
      <StyledRect height="20" rx="10" width="32" x="11" y="15" />
      <StyledRect2 height="20" rx="10" width="32" x="59" y="15" />
      <StyledRect3 height="20" rx="10" width="32" x="108" y="15" />
    </g>
  </svg>
);

SvgDotLoader.defaultProps = {
  className: null,
};

export default withTheme(SvgDotLoader);
