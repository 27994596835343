// @flow strict
import React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Flex, Box } from '../../../../../../primitives/Essentials/index.js';
import Line from '../../../../../../primitives/Line/index.js';
import Text from '../../../../../../components/Text/index.js';
import Price from '../../../../../../components/Price/index.js';

const DiscountNote = styled(Flex)`
  position: relative;
  background-color: ${({ theme }) => theme.trip.price};
`;

const DiscountLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  color: ${({ theme }) => theme.tertiary};
`;

const OriginalPriceLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  color: ${({ theme }) => theme.secondary};
  text-decoration: line-through;
`;

const Wrapper = styled(Box)`
  &::after {
    position: absolute;
    right: 50%;
    bottom: -7px;
    left: 50%;
    z-index: 1;
    width: 0;
    height: 0;
    border-top: 10px solid ${({ theme }) => theme.trip.price};
    border-right: 13px solid transparent;
    border-left: 13px solid transparent;
    content: '';
  }
`;

type Props = {
  discount: number,
  lowestPrice: number,
  highestPrice: number,
};

const DiscountBanner = ({ discount, lowestPrice, highestPrice }: Props) => (
  <DiscountNote $mt={1} $pt={11} $pb={14} $justifyContent="center">
    <Wrapper>
      <DiscountLine fontWeight={900} fontSize={17} letterSpacing={0.38}>
        <Text t="save" /> {discount.toFixed(0)}% - <Price value={lowestPrice} />
      </DiscountLine>
      &nbsp;
      <OriginalPriceLine fontWeight={900} fontSize={17} letterSpacing={0.38}>
        <Price value={highestPrice} />
      </OriginalPriceLine>
    </Wrapper>
  </DiscountNote>
);

export default DiscountBanner;
