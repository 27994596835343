import React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Flex, Box } from '../../../../../../../../primitives/Essentials/index.js'
import Text from '../../../../../../../Text/index.js';

const LabelContainer = styled.span`
  color: #6abc10;
  font-size: 14px;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 3px;
  padding-right: 5px;
  border-radius: 4px;
  border: 1px solid #6abc10;
  display: inline-flex;
  align-items: center;
  margin-left: 5px;
`;

const Icon = styled.img`
  width: 16px;
  height: 16px;
  margin-top: 2px;
  margin-bottom: 2px;
  padding-bottom: 1px;
  margin-right: 3px;
`;

const WebsiteName = styled(Box)`
  font-size: 14px;
  font-weight: 800;
  color: #9b9b9b;
`;

const OfficialWebsiteLabel = ({ officialWebsite }) => (
  <Flex $alignItems="center" $mt="6px">
    <WebsiteName>{officialWebsite.officialWebsiteName}</WebsiteName>
    <LabelContainer>
      <Icon src="/icons/checkmark-circle-outline.svg" alt="checkmark" />
      <Text t="official_website" />
    </LabelContainer>
  </Flex>
);

export default OfficialWebsiteLabel;