// @flow strict
import * as React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import mq from '../../../../services/mediaQuery/index.js';

type Props = {
  value: string,
  onClick: () => void,
  onClear?: () => void,
  placeholder?: string,
  className?: ?string,
};

const StyledInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  margin-top: 10px;
  padding: 11px 11px 11px 38px;
  text-decoration: none;
  background-color: ${({ theme }) => theme.tertiary};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  ${mq.TABLET`
    max-width: 345px;
    width: 345px;
  `};
  &:hover {
    background-color: ${({ theme }) => theme.trip.background};
  }
`;

const StyledRoot = styled.div`
  position: relative;
`;

const Icon = styled.img`
  position: absolute;
  top: 20px;
  left: 10px;
  cursor: pointer;
`;

const ClearIcon = styled.img`
  position: absolute;
  top: 22px;
  right: 10px;
  cursor: pointer;
`;

const InputButton = ({ value, placeholder, className, onClick, onClear }: Props) => (
  <StyledRoot onClick={onClick}>
    <Icon src="/icons/calendar.svg" alt="calendar" />
    <StyledInput
      readOnly
      type="text"
      value={value}
      placeholder={placeholder}
      className={className}
      onMouseDown={ev => ev.preventDefault()}
    />
    {value && onClear && (
      <ClearIcon
        src="/icons/clear.svg"
        alt="clear date"
        onClick={ev => {
          ev.preventDefault();
          ev.stopPropagation();
          onClear();
        }}
      />
    )}
  </StyledRoot>
);

InputButton.defaultProps = {
  className: null,
  placeholder: '',
};

export default InputButton;
