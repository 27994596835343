// @flow strict
import * as React from 'react';
import Cookies from 'js-cookie';
import { COOKIES, COOKIE_OPTIONS } from '../../records/Cookies.js';
import type { Currencies } from '../../records/Currencies.js';

export type Currency = string;

// eslint-disable-next-line import/prefer-default-export
export const currencyDefault: Currency = 'eur';

type Props = {|
  children: React.Node,
  initialCurrency: Currency,
  currencies: Currencies,
|};

type State = {|
  currency: Currency,
|};

type CurrencyContextType = {|
  currency: Currency,
  setCurrency: string => void,
|};

const CurrencyContext = React.createContext<CurrencyContextType>({
  currency: currencyDefault,
  setCurrency: () => {},
});

export class CurrencyContextManager extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    if (Cookies.get(COOKIES.CURRENCY) !== props.initialCurrency) {
      Cookies.set(COOKIES.CURRENCY, props.initialCurrency, COOKIE_OPTIONS);
    }

    this.state = {
      currency: props.initialCurrency,
    };
  }

  setCurrency = (currency: string) => {
    const { currencies } = this.props;
    if (Object.keys(currencies).includes(currency)) {
      this.setState({ currency }, () => {
        if (Cookies.get(COOKIES.CURRENCY) !== currency) {
          Cookies.set(COOKIES.CURRENCY, currency, COOKIE_OPTIONS);
        }
        window.scrollTo(0, 0);
      });
    }
  };

  render() {
    const { currency } = this.state;
    const { children } = this.props;

    return (
      <CurrencyContext.Provider
        value={{
          currency,
          setCurrency: this.setCurrency,
        }}
      >
        {children}
      </CurrencyContext.Provider>
    );
  }
}

export default CurrencyContext;
