/* eslint-disable import/prefer-default-export */
// @flow strict
// $FlowFixMe
import parse from 'date-fns/parse/index.js';
// $FlowFixMe
import isSameDay from 'date-fns/isSameDay/index.js';
// $FlowFixMe
import addDays from 'date-fns/addDays/index.js';
// $FlowFixMe
import format from 'date-fns/format/index.js';
// $FlowFixMe
import enUS from 'date-fns/locale/en-US/index.js';
// $FlowFixMe
import de from 'date-fns/locale/de/index.js';
// $FlowFixMe
import it from 'date-fns/locale/it/index.js';
// $FlowFixMe
import subDays from 'date-fns/subDays/index.js';
// $FlowFixMe
import eachDayOfInterval from 'date-fns/eachDayOfInterval/index.js';
// $FlowFixMe
import startOfMonth from 'date-fns/startOfMonth/index.js';
// $FlowFixMe
import endOfMonth from 'date-fns/endOfMonth/index.js';
// $FlowFixMe
import isBefore from 'date-fns/isBefore/index.js';
// $FlowFixMe
import compareAsc from 'date-fns/compareAsc/index.js';
// $FlowFixMe
import differenceInHours from 'date-fns/differenceInHours/index.js';
import type { Language } from '../intl/context.js';

const locales = {
  en: enUS,
  de,
  it,
};

const languages = {
  en: {
    dateFormatShort: 'E d MMM',
    dateFormatReview: 'MMM dd, yyyy',
  },
  de: {
    dateFormatShort: 'E d. MMM',
    dateFormatReview: 'dd MMM. yyyy',
  },
  it: {
    dateFormatShort: 'E d MMM',
    dateFormatReview: 'dd MMM yyyy',
  }, 
};

// $FlowFixMe
export const parseDate = (date: string): Date => parse(date, 'yyyy-MM-dd', new Date());
export const parseReviewDate = (date: string): Date => parse(date, 'dd-MM-yyyy', new Date());
export const formatDate = (date: Date, language?: Language): string =>
  format(
    date,
    language && languages[language]
      ? languages[language].dateFormatShort
      : languages.en.dateFormatShort,
    language ? { locale: locales[language] } : undefined,
  );
export const formatDateReview = (date: Date, language?: Language): string =>
  format(
    date,
    language && languages[language]
      ? languages[language].dateFormatReview
      : languages.en.dateFormatReview,
    language ? { locale: locales[language] } : undefined,
  );
export const formatDay = (language: Language, date: Date): string =>
  format(date, 'E', { locale: locales[language] });
export const formatDay3Letter = (language: Language, date: Date): string =>
  format(date, 'EEE', { locale: locales[language] });

export const formatMonth = (language: Language, date: Date): string =>
  format(date, 'MMMM yyyy', { locale: locales[language] });
export const formatMonthShort = (language: Language, date: Date): string =>
  format(date, 'MMMM', { locale: locales[language] });

export const formatDateYMD = (date: Date): string => format(date, 'yyyy-MM-dd');
export const formatDateYM = (date: Date): string => format(date, 'yyyy-MM');
export const isToday = (date: Date): boolean => isSameDay(new Date(), date);
export const isTomorrow = (date: Date): boolean => isSameDay(addDays(new Date(), 1), date);

export const getDisabledDates = (minDate: Date, maxDate: Date): Date[] => {
  const disabledDates = [...eachDayOfInterval({ start: maxDate, end: endOfMonth(maxDate) })];
  if (isBefore(maxDate, endOfMonth(maxDate))) {
    disabledDates.push(...eachDayOfInterval({ start: maxDate, end: endOfMonth(maxDate) }));
  }
  if (isBefore(startOfMonth(minDate), subDays(minDate, 1))) {
    disabledDates.push(
      ...eachDayOfInterval({ start: startOfMonth(minDate), end: subDays(minDate, 1) }),
    );
  }

  return disabledDates;
};

export const sortDatesAscending = (dates: Date[]): Date[] => dates.sort(compareAsc);
export const parseTime = (time: string): Date => parse(time, 'HH:mm', new Date());
export const formatTime = (time: Date): string => format(time, 'HH:mm');
export const hoursBetweenTimes = (time1: Date, time2: Date): number =>
  differenceInHours(time1, time2);
