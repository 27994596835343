// @flow strict
import * as React from 'react';

type Props = {|
  loader: React.Node,
  children: React.Node,
|};

type State = {|
  mounted: boolean,
|};

class ClientOnly extends React.PureComponent<Props, State> {
  static defaultProps = {
    loader: null,
  };

  constructor() {
    super();
    this.state = {
      mounted: false,
    };
  }

  componentDidMount() {
    this.setState({ mounted: true });
  }

  render() {
    const { loader, children } = this.props;
    const { mounted } = this.state;

    if (!mounted) {
      return loader;
    }

    return children;
  }
}

export default ClientOnly;
