// @flow strict
import { RES_URL } from '../../../../../etc/appConfig.js';
import mapperPoiContent from '../../mapper/poiContent/index.js';
import type { Language } from '../../intl/context.js';
import type { PoiContent } from '../../../records/PoiContent.js';
import type { ImageAttributions } from '../../../records/Photo.js';

// eslint-disable-next-line import/prefer-default-export
export const getPoiContent = (
  poiId: string,
  language: Language,
  imageAttributions: ImageAttributions,
): Promise<?PoiContent> =>
  fetch(`${RES_URL}/pois${process.env.IS_STAGE ? '_stage' : ''}/p${poiId}.json`)
    .then(res => res.json())
    .then(poiContent => {
      if (poiContent) {
        return mapperPoiContent(poiContent, language, imageAttributions);
      }
      return null;
    })
    .catch(() => {
      // no content file or invalid json
      return null;
    });
