// @flow strict
import * as React from 'react';
import pkg from 'react-helmet-async/lib/index.js';
const { Helmet, HelmetData } = pkg;
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Box, Flex } from '../../../../primitives/Essentials/index.js';
import Cookies from 'js-cookie';
import queryString from 'query-string';

import Text from '../../../../components/Text/index.js';
import { container } from '../../../../services/styleUtils/index.js';
import SvgDotLoader from '../../../../components/SvgDotLoader/index.js';
import { getTour } from '../../../../services/api/tours/index.js';
import type { Currency } from '../../../../services/currency/context.js';
import type { Language } from '../../../../services/intl/context.js';
import type { Translate } from '../../../../services/intl/translate.js';
import type { Tour } from '../../../../records/Tour.js';
import type { Providers } from '../../../../records/Providers.js';
import type { Promocodes } from '../../../../records/Promocodes.js';
import type { Currencies } from '../../../../records/Currencies.js';
import { PARTNER_URLS_SERVICE } from '../../../../../../etc/appConfig.js';
import { parseDate, formatDateYMD } from '../../../../services/dateTimeUtils/index.js';
import { getGaCookies } from '../../../../services/gtm/index.js';
import mq from '../../../../services/mediaQuery/index.js';
import { COOKIES } from '../../../../records/Cookies.js';
import Line from '../../../../primitives/Line/index.js';
import getTourPromocode from '../../../../services/tours/getTourPromocode.js';

const StyledContainerFlex = styled(Flex)`
  ${container()};
  min-height: calc(100vh);
`;

const StyledImg = styled.img`
  width: 108px;
  height: 107.5px;
`;

const StyledTextFlex = styled(Flex)`
  max-width: 318px;
  color: ${({ theme }) => theme.section.title};
  font-size: 19px;
  text-align: center;
  ${mq.TABLET`
    font-size: 29px;
    max-width: 484px;
  `}
`;

const StyledLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  font-size: 19px;
  ${mq.TABLET`
    font-size: 29px;
  `};
`;

const DiscountLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  font-size: 14px;
  ${mq.TABLET`
    font-size: 16px;
  `};
`;

const DiscountBox = styled(Box)`
  max-width: 347px;
  background-color: ${({ theme }) => theme.trip.backgroundLight};

  ${mq.TABLET`
    max-width: 400px;
  `};
`;

type Props = {
  tourId: string,
  currency: Currency,
  language: Language,
  translate: Translate,
  providers: Providers,
  promocodes: Promocodes,
  currencies: Currencies,
};

type State = {
  tour: ?Tour,
};

/*function getClientIdGA4() {
  return new Promise(function(resolve) {
    if (typeof gtag !== 'undefined') {
      gtag('get', GA4_TAG, 'client_id', function(clientId) {
        if (clientId) {
          resolve(clientId);
        } else {
          resolve(null);
        }
      });
    } else {
      resolve(null);
    }
  });
}*/

class TourForwardContent extends React.PureComponent<Props, State> {
  constructor() {
    super();
    this.state = {
      tour: null,
    };
  }

  componentDidMount = () => {
    const { tourId, currency, language, promocodes, currencies } = this.props;

    getTour(language, tourId, promocodes, currencies).then(tour => {
      if (!tour) {
        return;
      }
      this.setState({
        tour,
      });

      const queryParams = queryString.parse(window.location.search);
      //const gclidCookie: ?string = Cookies.get(`_gac_${GA4_TAG}`);
      const dateCookie: ?string = Cookies.get(COOKIES.DATE);
      //let gclidFromCookie;
      let date = null; // from query

      /*if (gclidCookie) {
        const split = gclidCookie.split('.');
        gclidFromCookie = split[split.length - 1];
      }*/

      // query is not really propagated so this might not happen
      if (queryParams.date) {
        const parsedDate = parseDate(queryParams.date);
        // eslint-disable-next-line no-restricted-globals
        if (!isNaN(parsedDate.getTime())) {
          date = formatDateYMD(parsedDate);
        }
      }

      if (!date && dateCookie) {
        // priority query > cookie
        const parsedDate = parseDate(dateCookie);
        date = formatDateYMD(parsedDate);
      }

      const promocode = getTourPromocode(tour, promocodes);

      setTimeout(
        async () => {
          // viewContent(tour, currency, tour.objectID); this is FB pixel
          // give ga time to initialize
          const cliendIdGA4 = 'undefined' // await getClientIdGA4();

          fetch(`${PARTNER_URLS_SERVICE}/${tourId}/url`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json; charSet=utf-8',
            },
            body: JSON.stringify({
              date,
              currency,
              lang: language,
              'user-agent': window.navigator.userAgent,
              gadata: {
                ...getGaCookies(),
                cid: cliendIdGA4,
                'cidGA4': cliendIdGA4,
                dl: window.location.href,
                exp: Cookies.get('_gaexp') || null,
                // Google Adwords
                gclid: 'disabled', // queryParams.gclid || gclidFromCookie,
                dclid: queryParams.dclid || Cookies.get('dclid'),
              },
            }),
          })
            .then(res => res.json())
            .then(res => {
              const url = res.data || tour.url;

              window.location.replace(url);
            })
            .catch(error => {
              // eslint-disable-next-line no-console
              console.error('Error:', error);
              window.location.replace(tour.url);
            });
        },
        promocode ? 5000 : 2000,
      );
    });
  };

  render() {
    const { tour } = this.state;
    const { translate, providers, promocodes } = this.props;

    let promocode = null;
    const helmetData = new HelmetData({});

    if (tour) {
      promocode = getTourPromocode(tour, promocodes);
    }

    return (
      <>
        {tour && (
          <Helmet helmetData={helmetData}>
            <title>
              {translate('title_forward_page', { provider: providers[tour.providerId].name })}
            </title>
          </Helmet>
        )}
        <StyledContainerFlex $justifyContent="center" $flexDirection="column" $alignItems="center">
          <Box>
            <StyledImg
              src="/images/ticket.png"
              srcSet="/images/ticket@2x.png 2x, /images/ticket@3x.png 3x"
              alt="ticket"
            />
          </Box>
          <Box $mt={9}>
            <SvgDotLoader />
          </Box>
          <StyledTextFlex $flexDirection="column" $alignItems="center">
            {tour && (
              <StyledLine>
                <Text
                  html
                  t="forward_text"
                  values={{ provider: providers[tour.providerId].name }}
                />
              </StyledLine>
            )}
            {promocode && (
              <DiscountBox $mt={[16, null, null, null, 32]} $px={12} $pt={7.5} $pb={8.5}>
                <DiscountLine letterSpacing={0.3}>
                  <Text html t="promo_code_copied" values={{ code: promocode.code }} />
                </DiscountLine>
              </DiscountBox>
            )}
          </StyledTextFlex>
        </StyledContainerFlex>
      </>
    );
  }
}

export default TourForwardContent;
