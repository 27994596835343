// @flow strict
import React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Flex } from '../../../../primitives/Essentials/index.js';
import type { Theme } from '../../../../records/Theme.js';
import Line from '../../../../primitives/Line/index.js';
import Text from '../../../Text/index.js';
import Link from '../../../Link/index.js';
import CollapsableTitle from '../CollapsableTitle/index.js';
import Image from '../../../Image/index.js';

type Props = {
  theme: Theme,
  expanded: boolean,
  onTitleClick?: ?() => void,
  isMobile: boolean,
};

const StyledAppStoreButton = styled(Image)`
  width: 137px;
  height: 39px;
  padding-top: 10px;
`;

const FooterAppStoreLink = ({ theme, expanded, onTitleClick, isMobile }: Props) => (
  <Flex width={[1, null, null, null, 1 / 3, 1 / 4]} $flexDirection="column">
    <CollapsableTitle
      title="get_the_app"
      theme={theme}
      isMobile={isMobile}
      onClick={isMobile ? onTitleClick : null}
    />
    {(expanded || !isMobile) && (
      <>
        <Line color={theme.tertiary} lineHeight={1.57}>
          <Text t="currently_ios_only" />
        </Line>
        <Link href="https://apps.apple.com/app/apple-store/id317902596?pt=121899788&ct=website&mt=8" target="_blank" rel="noopener noreferrer">
          <StyledAppStoreButton
            src="/images/app-store.png"
            srcSet="/images/app-store@2x.png 2x, /images/app-store@3x.png 3x"
            alt="itunes app store"
            lazy
            secondary
          />
        </Link>
      </>
    )}
  </Flex>
);

export default FooterAppStoreLink;
