// @flow strict
import * as React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { IconDropdown } from '../Icons/index.js';

type Props = {
  id: string,
  value: string,
  onChange: (value: SyntheticInputEvent<HTMLSelectElement>) => void,
  className?: ?string,
};

const Container = styled.div`
  position: relative;
`;

const StyledSelect = styled.span`
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 0;
  color: ${({ theme }) => theme.secondary};
  font-weight: 900;
  font-size: 18.5px;
  background-color: ${({ theme }) => theme.tertiary};
  border: none;
  cursor: pointer;
  appearance: none;
  &:focus {
    outline: none;
  }
`;

const Icon = styled.span`
  position: absolute;
  top: 7px;
  right: 5px;
  color: ${({ theme }) => theme.secondary};
  pointer-events: none;
`;

const SelectButton = ({ id, value, onChange, className }: Props) => (
  <Container className={className}>
    <StyledSelect id={id} value={value} onClick={onChange}>
      {value}
    </StyledSelect>
    <Icon>
      <IconDropdown size={10} />
    </Icon>
  </Container>
);

SelectButton.defaultProps = {
  className: null,
};

export default SelectButton;
