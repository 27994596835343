// @flow strict
import * as R from 'ramda/src/index.js';
import type { Language } from '../../intl/context.js';
import type { PoiContentInput, PoiContent } from '../../../records/PoiContent.js';
import mapperSections from './mapperSections.js';
import mapperEditorialContent from '../editorialContent/index.js';
import type { ImageAttributions } from '../../../records/Photo.js';
import mapperPhoto from '../photo/index.js';

export default function mapperPoiContent(
  input: PoiContentInput,
  locale: Language,
  imageAttributions: ImageAttributions,
): PoiContent {
  const htmlTitle = input[`htmlTitle_${locale}`];
  const htmlDescription = input[`htmlDescription_${locale}`]
    ? input[`htmlDescription_${locale}`]
    : null;
  const abstract = input[`abstract_${locale}`];
  const faqNotInTargetLocale =
    input.faq &&
    input.faq.some(faqItem => !faqItem[`question_${locale}`] || !faqItem[`answer_${locale}`]);
  const aboutNotInTargetLocale =
    input.about &&
    input.about.some(
      aboutItem => !aboutItem[`title_${locale}`] || !aboutItem[`abstract_${locale}`],
    );
  const topTipsNotInTargetLocale =
    input.topTips &&
    input.topTips.some(
      tip => !tip[`abstract_${locale}`] || (tip.photoUrls && !tip[`photoTitle_${locale}`]),
    );

  // add exemptions below -> search for "TODO add dynamic content" to find the relevant code for adding further dynamic content
  return {
    ...R.omit(
      [
        'uniqueId',
        'htmlTitle_en',
        'htmlTitle_de',
        'htmlTitle_it',
        'htmlDescription_en',
        'htmlDescription_de',
        'htmlDescription_it',
        'translator_en',
        'translator_de',
        'translator_it',
        'abstract_en',
        'abstract_de',
        'abstract_it',
        'topTips',
        'faq',
        'about',
        'sections',
        'exhibitions',
      ],
      input,
    ),
    htmlTitle,
    htmlDescription,
    abstract,
    topTips:
      input.topTips && !topTipsNotInTargetLocale
        ? input.topTips // sort by orderIndex and then discard
            .sort((tipA, tipB) => {
              if (tipA.orderIndex < tipB.orderIndex) {
                return -1;
              }
              if (tipA.orderIndex > tipB.orderIndex) {
                return 1;
              }
              return 0;
            })
            .map(tip => ({
              title: tip[`title_${locale}`] || null,
              abstract: tip[`abstract_${locale}`],
              photo: mapperPhoto(tip, locale, imageAttributions),
            }))
        : null,
    faq:
      input.faq && !faqNotInTargetLocale
        ? input.faq.map(faqItem => ({
            question: faqItem[`question_${locale}`],
            answer: faqItem[`answer_${locale}`],
          }))
        : null,
    about:
      input.about && !aboutNotInTargetLocale
        ? input.about.map(aboutItem => ({
            title: aboutItem[`title_${locale}`],
            abstract: aboutItem[`abstract_${locale}`],
          }))
        : null,
    sections: input.sections ? mapperSections(input.sections, locale) : undefined,
    editorialContent: input.editorialContent
      ? mapperEditorialContent(input.editorialContent, locale, imageAttributions)
      : null,
    author: input.author || null,
    translator: input[`translator_${locale}`] || null,
    // search for "TODO add dynamic content" to find the relevant code for adding further dynamic content
    dynamicContent: {
      exhibitions: input.exhibitions || null,
    },
  };
}
