// @flow strict
import type { Language } from '../../intl/context.js';
import type { ImageAttributions, Photo } from '../../../records/Photo.js';
import type { ListElementInput, SubListInput } from '../../../records/DestinationContent.js';
import type { TopTipInput } from '../../../records/PoiContent.js';
import type {
  EditorialContentInput,
  EditorialSectionInput,
} from '../../../records/EditorialContent.js';

const IMAGE_SOURCES = {
  '10': 'Ulmon',
  '20': 'Flickr',
  '21': 'Unsplash',
  '22': 'Pixabay',
  '23': 'Wiki Commons',
};

const LICENSE_TYPES = {
  '1': 'Ulmon',
  '2': 'Public Domain',
  '3': 'CC0',
  '4': 'Unsplash',
  '5': 'Pixabay',
  '6': 'CC BY 2.0',
  '12': 'CC BY 3.0',
  '18': 'CC BY 4.0',
  '7': 'CC BY-SA 2.0',
  '13': 'CC BY-SA 3.0',
  '19': 'CC BY-SA 4.0',
  '8': 'CC BY-ND 2.0',
  '14': 'CC BY-ND 3.0',
  '20': 'CC BY-ND 4.0',
  '9': 'CC BY-NC 2.0',
  '15': 'CC BY-NC 3.0',
  '21': 'CC BY-NC 4.0',
  '10': 'CC BY-NC-SA 2.0',
  '16': 'CC BY-NC-SA 3.0',
  '22': 'CC BY-NC-SA 4.0',
  '11': 'CC BY-NC-ND 2.0',
  '17': 'CC BY-NC-ND 3.0',
  '23': 'CC BY-NC-ND 4.0',
};

const LICENSE_URLS = {
  // [LICENSE_TYPES[0]]: 'Ulmon',
  '2': 'https://creativecommons.org/share-your-work/public-domain/pdm/',
  '3': 'https://creativecommons.org/publicdomain/zero/1.0/',
  '4': 'https://unsplash.com/license',
  '5': 'https://pixabay.com/de/service/license/',
  '6': 'https://creativecommons.org/licenses/by/2.0/',
  '12': 'https://creativecommons.org/licenses/by/3.0/',
  '18': 'https://creativecommons.org/licenses/by/4.0/',
  '7': 'https://creativecommons.org/licenses/by-sa/2.0/',
  '13': 'https://creativecommons.org/licenses/by-sa/3.0/',
  '19': 'https://creativecommons.org/licenses/by-sa/4.0/',
  '8': 'https://creativecommons.org/licenses/by-nd/2.0/',
  '14': 'https://creativecommons.org/licenses/by-nd/3.0/',
  '20': 'https://creativecommons.org/licenses/by-nd/4.0/',
  '9': 'https://creativecommons.org/licenses/by-nc/2.0/',
  '15': 'https://creativecommons.org/licenses/by-nc/3.0/',
  '21': 'https://creativecommons.org/licenses/by-nc/4.0/',
  '10': 'https://creativecommons.org/licenses/by-nc-sa/2.0/',
  '16': 'https://creativecommons.org/licenses/by-nc-sa/3.0/',
  '22': 'https://creativecommons.org/licenses/by-nc-sa/4.0/',
  '11': 'https://creativecommons.org/licenses/by-nc-nd/2.0/',
  '17': 'https://creativecommons.org/licenses/by-nc-nd/3.0/',
  '23': 'https://creativecommons.org/licenses/by-nc-nd/4.0/',
};

type Input =
  | TopTipInput
  | ListElementInput
  | SubListInput
  | EditorialContentInput
  | EditorialSectionInput;

export default function mapperPhoto(
  input: Input,
  locale: Language,
  imageAttributions: ImageAttributions,
): ?Photo {
  if (!input.photoUrls) {
    return null;
  }
  let photoUrls = [];
  let photoAttribution = null;
  const photoTitle = input[`photoTitle_${locale}`] || null;
  if (typeof input.photoUrls === 'number') {
    photoAttribution = {
      source: '',
      authorName: '',
      license: '',
      originalUrl: '',
      licenseUrl: '',
    };
    photoUrls = [
      `https://bitmap.ticketlens.com/image/${input.photoUrls}/700x470?returnCrop=yes`,
      `https://bitmap.ticketlens.com/image/${input.photoUrls}/1400x940?returnCrop=yes`,
    ];
    if (imageAttributions[input.photoUrls]) {
      photoAttribution.source =
        imageAttributions[input.photoUrls].source &&
        IMAGE_SOURCES[imageAttributions[input.photoUrls].source]
          ? `${IMAGE_SOURCES[imageAttributions[input.photoUrls].source]}`
          : '';
      photoAttribution.authorName = imageAttributions[input.photoUrls].authorName
        ? `${imageAttributions[input.photoUrls].authorName}`
        : '';
      photoAttribution.license =
        imageAttributions[input.photoUrls].licenseType &&
        LICENSE_TYPES[imageAttributions[input.photoUrls].licenseType]
          ? `${LICENSE_TYPES[imageAttributions[input.photoUrls].licenseType]}`
          : '';
      photoAttribution.originalUrl = imageAttributions[input.photoUrls].imagePageUrl || '';
      photoAttribution.licenseUrl = imageAttributions[input.photoUrls].licenseType
        ? LICENSE_URLS[imageAttributions[input.photoUrls].licenseType]
        : '';
    }
  } else {
    photoUrls = input.photoUrls;
  }
  return {
    // $FlowFixMe
    photoUrls,
    photoTitle,
    photoAttribution,
  };
}
