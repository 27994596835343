// @flow strict
import * as React from 'react';
import * as R from 'ramda/src/index.js';
import { Flex, Box } from '../../../../primitives/Essentials/index.js';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { container } from '../../../../services/styleUtils/index.js';
import { SECTION_LAYOUT } from '../../../../consts/layout.js';
import IntlContext from '../../../../services/intl/context.js';
import UrlContext from '../../../../services/url/context.js';
import type { Language } from '../../../../services/intl/context.js';
import type { Destination } from '../../../../records/Destination.js';
import type { DestinationContent } from '../../../../records/DestinationContent.js';
import type { Poi } from '../../../../records/Poi.js';
import type { ImageAttributions } from '../../../../records/Photo.js';
import mapperDestinationContent from '../../../../services/mapper/destinationContent/index.js';
import Line from '../../../../primitives/Line/index.js';
import Text from '../../../../components/Text/index.js';
import Attraction from '../../../PoiPage/components/PoiContent/components/Attractions/components/Attraction/index.js';
import TopList from '../../../../components/TopList/index.js';
import Navigation from '../../../../components/Navigation/index.js';
import SubLists from '../../../../components/SubLists/index.js';

const StyledContainerBox = styled(Box)`
  ${container()};
`;

const StyledAbstractLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  max-width: 720px;
  color: ${({ theme }) => theme.section.title};
`;

type Props = {
  language: Language,
  attractions: Poi[],
  content: ?DestinationContent,
  selectedDestination: ?Destination,
  destinationId: number,
  imageAttributions: ImageAttributions,
};

type State = {
  updatedContent: ?DestinationContent,
};

const getTopListPoiLinks = (content: ?DestinationContent): number[] => {
  const topListListElements = R.path(['topListAttractions', 'listElements'], content);
  return topListListElements
    ? topListListElements.reduce((acc, le) => {
        const links = le.linkTo
          ? le.linkTo.filter(link => link.type === 'poi').map(link => link.id)
          : [];
        return acc.concat(links);
      }, [])
    : [];
};

const getSubListPoiLinks = (content: ?DestinationContent): number[] => {
  const subListListElements =
    content && content.subListsAttractions
      ? content.subListsAttractions.reduce(
          (acc, sl) => (sl.listElements ? acc.concat(sl.listElements) : acc),
          [],
        )
      : null;
  const subListLinks =
    content && content.subListsAttractions
      ? content.subListsAttractions.reduce(
          (acc, sl) =>
            sl.linkTo
              ? acc.concat(sl.linkTo.filter(link => link.type === 'poi').map(link => link.id))
              : acc,
          [],
        )
      : [];
  const subListMorePOILinks =
    content && content.subListsAttractions
      ? content.subListsAttractions.reduce((acc, sl) => (sl.morePOIs ? acc.concat(sl.morePOIs.ids) : acc), [])
      : [];
  const listElementLinks = subListListElements
    ? subListListElements.reduce(
        (acc, le) =>
          le.linkTo
            ? acc.concat(le.linkTo.filter(link => link.type === 'poi').map(link => link.id))
            : acc,
        [],
      )
    : [];
  return Array.from(new Set(listElementLinks.concat(subListMorePOILinks).concat(subListLinks)));
};

class AttractionsContent extends React.Component<Props, State> {
  constructor() {
    super();
    this.state = {
      updatedContent: null, // set from json editor to alter page for preview
    };
  }

  componentDidMount = () => {
    window.addEventListener('message', this.handleMessage);
  };

  componentWillUnmount() {
    window.removeEventListener('message', this.handleMessage);
  }

  // for tweb admin live preview
  // $FlowFixMe
  handleMessage = event => {
    const { language, content, imageAttributions } = this.props;
    // event.origin will most likely be https://admin.ticketlens.com
    // window.location.origin will most likely be https://www.ticketlens.com
    if (
      event.data.destinationId &&
      ((event.origin.includes('localhost') && window.location.origin.includes('localhost')) ||
        (event.origin.includes('admin') && window.location.origin.includes('ticketlens')))
    ) {
      this.setState({
        updatedContent: {
          ...mapperDestinationContent(event.data, language, imageAttributions),
          topListTitleAttractions: content && content.topListAttractions ? content.topListAttractions.title : null,
        },
      });
    }
  };

  render() {
    const { attractions, content, selectedDestination, destinationId } = this.props;

    const { updatedContent } = this.state;

    // eslint-disable-next-line no-underscore-dangle
    const _content = updatedContent || content;

    const contentPoiLinks = getTopListPoiLinks(_content).concat(getSubListPoiLinks(_content));
    const filteredAttractions = attractions.filter(
      att => !contentPoiLinks.includes(Number(att.objectID)),
    );
    const destinationName = selectedDestination ? selectedDestination.name : String(destinationId);

    return (
      <IntlContext.Consumer>
        {({ language, translate }) => (
          <UrlContext.Consumer>
            {({ buildDestinationPageUrl, buildDestinationCategoryPageUrl }) => (
              <>
                {_content && _content.topListAttractions && (
                  <StyledContainerBox>
                    <TopList topList={_content.topListAttractions} simpleLinkStyle />
                  </StyledContainerBox>
                )}
                {_content && (
                  <Navigation
                    subLists={_content.subListsAttractions}
                    destinationName={destinationName}
                    destinationId={destinationId}
                    topListTitle={_content && _content.topListTitleAttractions ? _content.topListTitleAttractions : null}
                    type="attractions"
                  />
                )}
                {_content && _content.subListsAttractions && (
                  <StyledContainerBox>
                    <SubLists
                      subLists={_content.subListsAttractions}
                      destinationName={destinationName}
                      language={language}
                      attractions={attractions}
                      simpleLinkStyle
                    />
                  </StyledContainerBox>
                )}
                {_content && (
                  <StyledContainerBox>
                    <Box {...SECTION_LAYOUT} $py={0} $mt={0}>
                      <Line fontSize={24} fontWeight={900}>
                        <Text
                          t="more_sights_in_destination"
                          values={{
                            destination: destinationName,
                          }}
                        />
                      </Line>
                      <Flex $flexWrap="wrap" $mt={5}>
                        {filteredAttractions.map((attraction, order) => (
                          <Attraction
                            key={attraction.objectID}
                            order={order}
                            attraction={attraction}
                            language={language}
                          />
                        ))}
                      </Flex>
                    </Box>
                  </StyledContainerBox>
                )}
                {!_content && (
                  <StyledContainerBox>
                    <Box {...SECTION_LAYOUT} $py={0} $mt={55} id="see_all_attractions">
                      <Line fontSize={26} fontWeight={900}>
                        <Text
                          t="more_sights_in_destination"
                          values={{
                            destination: destinationName,
                          }}
                        />
                      </Line>
                      <Flex $flexWrap="wrap" $mt={5}>
                        {attractions.map((attraction, order) => (
                          <Attraction
                            key={attraction.objectID}
                            order={order}
                            attraction={attraction}
                            language={language}
                          />
                        ))}
                      </Flex>
                    </Box>
                  </StyledContainerBox>
                )}
              </>
            )}
          </UrlContext.Consumer>
        )}
      </IntlContext.Consumer>
    );
  }
}

export default AttractionsContent;
