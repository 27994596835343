// @flow strict

const SIZES = {
  DESKTOP_TOC: 1720, // Table of contents on desktop adding 500px to the desktop size
  DESKTOP: 1220,
  BIG_TABLET: 1112,
  TABLET: 768,
  BIG_MOBILE: 600,
  MIDDLE_MOBILE: 414,
  SMALL_MOBILE: 360,
};

export default SIZES;
