// @flow strict
// $FlowFixMe
import isWithinInterval from 'date-fns/isWithinInterval/index.js';
import type { Promocodes, Promocode } from '../../records/Promocodes.js';
import type { Tour, TourInput } from '../../records/Tour.js';

const getTourPromocode = (tour: Tour | TourInput, promocodes: Promocodes): ?Promocode =>
  promocodes[tour.providerId] && promocodes[tour.providerId].length
    ? promocodes[tour.providerId].find(
        x =>
          isWithinInterval(new Date(), {
            start: new Date(x.startUTC),
            end: new Date(x.endUTC),
          }) &&
          (!x.productWhitelist.length || x.productWhitelist.includes(tour.id)),
      )
    : null;

export type PromocodeWithProvider = Promocode & { providerId: number };

export const getTourGroupPromocodes = (
  tours: Tour[],
  promocodes: Promocodes,
): PromocodeWithProvider[] =>
  tours.reduce((acc, tour) => {
    const promocode = getTourPromocode(tour, promocodes);
    if (promocode) {
      return acc.concat({ ...promocode, providerId: tour.providerId });
    }
    return acc;
  }, []);

export default getTourPromocode;
