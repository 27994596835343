// @flow strict
/* eslint-disable react/no-danger */
import React from 'react';
import IntlContext from '../../services/intl/context.js';

type Value = string | number;
export type Values = { [key: string]: Value };

type Props = {
  t: string,
  values?: Values,
  html?: boolean,
};

const Text = ({ t, values, html }: Props) => (
  <IntlContext.Consumer>
    {intl =>
      html ? (
        <span dangerouslySetInnerHTML={{ __html: intl.translate(t, values) }} />
      ) : (
        intl.translate(t, values)
      )
    }
  </IntlContext.Consumer>
);

Text.defaultProps = {
  values: {},
  html: false,
};

export default Text;
