// @flow strict
import { TICKETS_URL } from '../../../../../etc/appConfig.js';
import type { SectionConfig } from '../../../records/SectionConfig.js';
import mapperConfig from '../../mapper/config/index.js';
import type { Language } from '../../intl/context.js' ;

// eslint-disable-next-line import/prefer-default-export
export const getConfig = (
  destinationId: string,
): Promise<{ [language: Language]: SectionConfig }> =>
  fetch(TICKETS_URL)
    .then(res => res.json())
    .then(tickets => ({
      en: mapperConfig(tickets[destinationId], 'en'),
      de: mapperConfig(tickets[destinationId], 'de'),
      it: mapperConfig(tickets[destinationId], 'it'),
    }));
