// @flow strict
/* eslint-disable react/no-danger */
import React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import * as R from 'ramda/src/index.js';
import { Box, Flex } from '../../../../../../primitives/Essentials/index.js';
import Line from '../../../../../../primitives/Line/index.js';
import Text from '../../../../../../components/Text/index.js';
import { LAYOUT_COMMON } from '../../../../../../consts/layout.js';
import type { Rating as RatingType } from '../../../../../../records/Poi.js';
import type { Language } from '../../../../../../services/intl/context.js';
import { REVIEWS_SERVICE_URL } from '../../../../../../../../etc/appConfig.js';
import StarSvg from './components/StarSvg/index.js';
import { getVoteCount, getAvgRating } from './services/utils.js';
import mq from '../../../../../../services/mediaQuery/index.js';

// TODO: move margin and padding to px and mx when there is time
const Container = styled(Flex)`
  text-align: center;
  background-color: ${({ theme }) => theme.trip.backgroundLight};

  ${mq.DESKTOP`
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 20px;
    padding-right: 20px;
 `}
`;

const StarWrapper = styled(Box)`
  margin-right: 8.5px;
  cursor: ${({ voted }) => (!voted ? 'pointer' : 'initial')};
`;

type Props = {
  language: Language,
  ratings: ?(RatingType[]),
  poiId: string,
  noMarginTop?: boolean,
};

type State = {
  voted: boolean,
  loading: boolean,
  rating: number,
  hoverIndex: number,
};

class Rating extends React.Component<Props, State> {
  static defaultProps = {
    noMarginTop: false,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      voted: false,
      loading: false,
      rating: 0,
      hoverIndex: -1,
    };
  }

  handleVoting = (rating: number) => {
    const { poiId, language } = this.props;
    const { loading, voted } = this.state;

    if (loading || voted) {
      return;
    }

    this.setState(
      {
        loading: true,
      },
      () => {
        fetch(`${REVIEWS_SERVICE_URL}/poi-pages/${poiId}/ratings`, {
          method: 'POST',
          body: JSON.stringify({
            rating,
            languageCode: language,
          }), // body data type must match "Content-Type" header
        }).then(() => {
          this.setState({
            voted: true,
            loading: false,
            rating,
          });
        });
      },
    );
  };

  render() {
    const { ratings, noMarginTop } = this.props;
    const { voted, rating, hoverIndex } = this.state;

    const voteCount = getVoteCount(ratings);
    const avgRating = getAvgRating(ratings, voteCount);

    return (
      <Container
        $mt={noMarginTop ? 4 : LAYOUT_COMMON.$mt}
        $pt={33}
        $pb={45}
        $justifyContent="center"
        $alignItems="center"
        $flexDirection="column"
      >
        <Line fontSize={22} letterSpacing={-0.07} fontWeight={900}>
          <Text t="how_useful" />
        </Line>
        <Flex $mt={17.5} $mb={19.5} onMouseLeave={() => this.setState({ hoverIndex: -1 })}>
          {voted ? (
            <>
              {R.map(
                i => (
                  <StarWrapper key={i} onClick={() => this.handleVoting(i + 1)} $voted={voted}>
                    <StarSvg kind="faded" alt="star" />
                  </StarWrapper>
                ),
                R.times(R.identity, Math.round(rating)),
              )}
              {R.map(
                i => (
                  <StarWrapper
                    key={Math.round(rating) + i}
                    onClick={() => this.handleVoting(Math.round(rating) + i + 1)}
                    $voted={voted}
                  >
                    <StarSvg alt="star empty" kind="empty" />
                  </StarWrapper>
                ),
                R.times(R.identity, 5 - Math.round(rating)),
              )}
            </>
          ) : (
            <>
              {R.map(
                i => (
                  <StarWrapper
                    key={i}
                    onClick={() => this.handleVoting(i + 1)}
                    onMouseEnter={() => this.setState({ hoverIndex: i })}
                    $voted={voted}
                  >
                    <StarSvg alt="star" kind={hoverIndex >= i ? 'full' : 'faded'} />
                  </StarWrapper>
                ),
                R.times(R.identity, Math.round(avgRating)),
              )}
              {R.map(
                i => (
                  <StarWrapper
                    key={Math.round(avgRating) + i}
                    onClick={() => this.handleVoting(i + Math.round(avgRating) + 1)}
                    onMouseEnter={() => this.setState({ hoverIndex: Math.round(avgRating) + i })}
                    $voted={voted}
                  >
                    <StarSvg
                      kind={hoverIndex >= i + Math.round(avgRating) ? 'full' : 'empty'}
                      alt="star empty"
                    />
                  </StarWrapper>
                ),
                R.times(R.identity, 5 - Math.round(avgRating)),
              )}
            </>
          )}
        </Flex>
        <Line fontSize={16} letterSpacing={0.04}>
          {!voted ? (
            <Text
              t="average_rating_and_vote_count"
              values={{ rating: Math.round(avgRating * 10) / 10, count: voteCount }}
            />
          ) : (
            <Text t="thanks_for_rating" />
          )}
        </Line>
      </Container>
    );
  }
}

export default Rating;
