// @flow strict
import React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Box, Flex } from '../../../../../../../../../../primitives/Essentials/index.js';
import type { Tour } from '../../../../../../../../../../records/Tour.js';
import type { Providers } from '../../../../../../../../../../records/Providers.js';
import Text from '../../../../../../../../../../components/Text/index.js';
import {
  parseDate,
  formatDate,
  isToday,
  isTomorrow,
} from '../../../../../../../../../../services/dateTimeUtils/index.js';
import ProviderIcon from './components/ProviderIcon/index.js';
import mq from '../../../../../../../../../../services/mediaQuery/index.js';
import type { Language } from '../../../../../../../../../../services/intl/context.js';
import getFirstAvailable from '../../../../../../../../../../services/firstAvailable/index.js';

type Props = {
  tour: Tour,
  date?: ?string,
  language: Language,
  providers: Providers,
};

const StyledProviderName = styled(Box)`
  color: ${({ theme }) => theme.section.title};
  font-weight: 900;
  font-size: 19px;
  letter-spacing: 0.1px;

  ${mq.TABLET`
    font-size: 22px;
  `};
`;

const StyledProviderAvailability = styled(Box)`
  color: ${({ theme, $green }) => ($green ? theme.trip.price : theme.trip.offers)};
  font-weight: 900;
  font-size: 14px;
  letter-spacing: 0.3px;
`;

const OfferContent = ({ tour, language, date, providers }: Props) => {
  const firstAvailable = getFirstAvailable(tour);
  const secondAvailable =
    tour.secondAvailable && tour.secondAvailable.length ? parseDate(tour.secondAvailable) : null;
  const parsedDate = parseDate(firstAvailable);
  const isTodayOrTomorrow = isToday(parsedDate) || isTomorrow(parsedDate);
  const isTodayAndTomorrow = secondAvailable && isToday(parsedDate) && isTomorrow(secondAvailable);
  let availability;
  if (!firstAvailable) {
    availability = <span />;
  } else if (isTodayAndTomorrow) {
    availability = <Text t="available_today_and_tomorrow" />;
  } else if (isTodayOrTomorrow) {
    availability = isToday(parsedDate) ? (
      <Text t="available_today" />
    ) : (
      <Text t="available_tomorrow" />
    );
  } else {
    availability = <Text t="available_from" values={{ date: formatDate(parsedDate, language) }} />;
  }

  return (
    <Flex $alignItems="center">
      <Flex>
        <Box $mt={[3, null, null, null, 0]}>
          <ProviderIcon provider={providers[tour.providerId]} />
        </Box>
        <Box $ml={[8.5, null, null, null, 10]}>
          <Flex $flexDirection="column">
            <StyledProviderName className="provider" $mt={[0, null, null, null, 4]}>
              {providers[tour.providerId].name}
            </StyledProviderName>
            {date && (
              <StyledProviderAvailability $green $mt={4}>
                <Text
                  t="available_on_date"
                  values={{ date: formatDate(parseDate(date), language) }}
                />
              </StyledProviderAvailability>
            )}
            {!date && (
              <StyledProviderAvailability $green={isTodayOrTomorrow ? 1 : 0} $mt={4}>
                {availability}
              </StyledProviderAvailability>
            )}
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
};

export default OfferContent;
