// @flow strict
import * as React from 'react';
import type { GroupToursBySection } from '../../records/Tour.js';
import type { Poi } from '../../records/Poi.js';
import type { PoiToursSectionContent } from '../../records/PoiToursSectionContent.js';
import type { Language } from '../intl/context.js';

export type PoiToursSectionContextType = {
  poi: { [Language]: Poi },
  tours: GroupToursBySection,
  sectionContent: ?PoiToursSectionContent,
  popularAttractions: Poi[],
};

const PoiToursSectionContext = React.createContext < PoiToursSectionContextType > ({
  poi: {
    en: {
      name: '',
      urlName: '',
      objectID: '0',
      breadcrumbDestination: 0,
      toursCount: 0,
      searchRankingScore: 0,
      displayPoiGroupId: 0,
      index: [],
      ratings: [],
    },
    de: {
      name: '',
      urlName: '',
      objectID: '0',
      breadcrumbDestination: 0,
      toursCount: 0,
      searchRankingScore: 0,
      displayPoiGroupId: 0,
      index: [],
      ratings: [],
    },
    it: {
      name: '',
      urlName: '',
      objectID: '0',
      breadcrumbDestination: 0,
      toursCount: 0,
      searchRankingScore: 0,
      displayPoiGroupId: 0,
      index: [],
      ratings: [],
    },
  },
  tours: {},
  sectionContent: null,
  popularAttractions: [],
});

export default PoiToursSectionContext;
