// LogoCarousel.js
// @flow strict
import React, { useState, useEffect, useRef } from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import mq from '../../../../../../../../services/mediaQuery/index.js';
import IntlContext from '../../../../../../../../services/intl/context.js';
import { Flex } from '../../../../../../../../primitives/Essentials/index.js';
import Line from '../../../../../../../../primitives/Line/index.js';
import Text from '../../../../../../../../components/Text/index.js';
import debounce from 'lodash.debounce'; // For debouncing resize events
import LogoProvider from './components/LogoProvider/index.js';
import type { Providers } from '../../../../../../../../records/Providers.js';

const StyledContainerFlex = styled(Flex)`
  width: 100%;
  background-color: ${({ theme }) => theme.trip.backgroundLight};
  min-height: 170px;
  ${mq.MIDDLE_MOBILE`
    min-height: 175px;
  `};
  ${mq.BIG_MOBILE`
    min-height: 175px;
  `};
  ${mq.TABLET`
    min-height: 190px;
  `};
`;

const StyledContentContainer = styled(Flex)`
  width: 100%;
  max-width: 1150px;
`;

const StyledLine = styled(Line)`
  padding: 0 15px;
  color: ${({ theme }) => theme.primary};

  ${mq.TABLET`
    font-size: 24px;
  `};
`;

const StyledInnerCarouselFlex = styled(Flex)`
  width: 100%;
  overflow: hidden; /* Ensure no scrollbar appears */
  display: flex;
  justify-content: center;
  box-sizing: border-box;
`;

const MeasureContainer = styled.div`
  width: 100%;
  display: flex;
`;

const StyledInnerContentContainer = styled(Flex)`
  overflow: hidden;
  box-sizing: border-box;
`;

const StyledInnerCarouselContainerFlex = styled(Flex)`
  display: flex;
  position: relative;
  width: fit-content; /* Adjust width based on content */
  transition: transform 0.5s ease-in-out;
  gap: ${(props) => props.$gap}px; /* Dynamic gap */
`;

const StyledLogoWrapper = styled.div`
  flex-shrink: 0;
  width: ${(props) => props.$logoWidth}px; /* Dynamic logo width */
  height: ${(props) => props.$logoWidth}px; /* Ensure square aspect ratio */
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`;

// Constants
const MIN_LOGOS = 3; // Minimum number of logos
const MAX_LOGOS = 5; // Maximum number of logos
const GAP = 20; // Default gap between logos in px
const MIN_LOGO_WIDTH = 100; // Minimum logo width in px
const MAX_LOGO_WIDTH = 140; // Maximum logo width in px

type Props = {
  providers: Providers,
};

const LogoCarousel = ({ providers }: Props) => {
  // Filter providers with 'usp' property
  const uspProviders = Object.keys(providers).reduce((acc, providerId) => {
    if (providers[providerId].usp) {
      acc[providerId] = providers[providerId];
    }
    return acc;
  }, {});

  const logoIds = Object.keys(uspProviders).map(providerId => Number(providerId));
  const [logos] = useState([...logoIds, ...logoIds]); // Duplicate for seamless looping
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleLogos, setVisibleLogos] = useState(0); // Initialize to 0
  const [logoWidth, setLogoWidth] = useState(0); // Initialize to 0
  const [currentGap, setCurrentGap] = useState(GAP); // Initialize with default GAP

  const carouselRef = useRef(null);
  const measureRef = useRef(null);

  const transitionDuration = 500; // in ms
  const intervalDuration = 3000; // in ms

  // Function to calculate visible logos and logo width
  const calculateVisibleLogos = () => {
    if (measureRef.current) {
      const containerWidth = measureRef.current.offsetWidth;
      let selectedLogos = MIN_LOGOS;
      let calculatedLogoWidth = (containerWidth - (MIN_LOGOS - 1) * GAP) / MIN_LOGOS;
      let newGap = GAP;

      // Iterate from MAX_LOGOS down to MIN_LOGOS to find the optimal number of logos
      for (let numLogos = MAX_LOGOS; numLogos >= MIN_LOGOS; numLogos--) {
        const possibleLogoWidth = (containerWidth - (numLogos - 1) * GAP) / numLogos;
        if (possibleLogoWidth >= MIN_LOGO_WIDTH && possibleLogoWidth <= MAX_LOGO_WIDTH) {
          selectedLogos = numLogos;
          calculatedLogoWidth = possibleLogoWidth;
          break;
        }
        // If possibleLogoWidth exceeds MAX_LOGO_WIDTH for MAX_LOGOS, cap it
        if (numLogos === MAX_LOGOS && possibleLogoWidth > MAX_LOGO_WIDTH) {
          selectedLogos = MAX_LOGOS;
          calculatedLogoWidth = MAX_LOGO_WIDTH;
          newGap = (containerWidth - (selectedLogos * calculatedLogoWidth)) / (selectedLogos - 1);
          newGap = Math.max(newGap, 10); // Ensure a minimum gap
          break;
        }
      }

      // Ensure logoWidth does not exceed MAX_LOGO_WIDTH
      if (calculatedLogoWidth > MAX_LOGO_WIDTH) {
        calculatedLogoWidth = MAX_LOGO_WIDTH;
      }

      // Set state only if changed
      if (
        selectedLogos !== visibleLogos ||
        Math.round(calculatedLogoWidth) !== Math.round(logoWidth) ||
        (selectedLogos === MAX_LOGOS && Math.round(newGap) !== Math.round(currentGap))
      ) {
        setVisibleLogos(selectedLogos);
        setLogoWidth(calculatedLogoWidth);
        if (selectedLogos === MAX_LOGOS && calculatedLogoWidth === MAX_LOGO_WIDTH) {
          setCurrentGap(newGap);
        } else {
          setCurrentGap(GAP);
        }
      }
    }
  };

  // Debounced version of calculateVisibleLogos to improve performance (optional)
  const debouncedCalculate = useRef(
    debounce(() => {
      calculateVisibleLogos();
    }, 100) // 100ms debounce
  ).current;

  useEffect(() => {
    calculateVisibleLogos(); // Initial calculation

    const resizeObserver = new ResizeObserver(() => {
      requestAnimationFrame(() => {
        debouncedCalculate();
      });
    });

    if (measureRef.current) {
      resizeObserver.observe(measureRef.current);
    }

    return () => {
      if (measureRef.current) {
        resizeObserver.unobserve(measureRef.current);
      }
      debouncedCalculate.cancel(); // Cancel any pending debounced calls
    };
  }, []); // Empty dependency array to run once

  // Set up sliding interval
  useEffect(() => {
    if (visibleLogos === 0) return; // Do not set interval until visibleLogos is set

    const interval = setInterval(() => {
      slideNext();
    }, intervalDuration);

    return () => clearInterval(interval);
  }, [currentIndex, visibleLogos]);

  const slideNext = () => {
    if (carouselRef.current) {
      const newIndex = currentIndex + 1;
      setCurrentIndex(newIndex);
      const totalShift = (logoWidth + currentGap) * newIndex;

      carouselRef.current.style.transition = `transform ${transitionDuration}ms ease-in-out`;
      carouselRef.current.style.transform = `translateX(-${totalShift}px)`;

      setTimeout(() => {
        if (newIndex >= logoIds.length) {
          // Reset to start
          carouselRef.current.style.transition = 'none';
          carouselRef.current.style.transform = 'translateX(0)';
          setCurrentIndex(0);
        }
      }, transitionDuration);
    }
  };

  return (
    <StyledContainerFlex 
      $justifyContent="center"
      $px={ [null, null, null, 15] } $pt={20}
    >
      <StyledContentContainer $flexDirection="column" >
        <IntlContext.Consumer>
        {({ translate }) => (
          <StyledLine fontSize= { 18} fontWeight = { 900} >
            <Text t={ translate('usp-title-providers') } />
          </StyledLine>
        )}
        </IntlContext.Consumer>
        <StyledInnerCarouselFlex>
          <MeasureContainer ref={measureRef}>
          { visibleLogos > 0 && logoWidth > 0 && (
            <StyledInnerContentContainer
              style={{ width: `${visibleLogos * logoWidth + (visibleLogos - 1) * currentGap}px`, overflow: 'hidden', }} >
              <StyledInnerCarouselContainerFlex ref={ carouselRef } $gap = { currentGap } >
              {
                logos.map((logo, index) => (
                  <StyledLogoWrapper key= {`${logo}-${index}`} $logoWidth = { logoWidth } >
                    <LogoProvider provider={ uspProviders[logo] } />
                  </StyledLogoWrapper>
                ))
              }
              </StyledInnerCarouselContainerFlex>
            </StyledInnerContentContainer>
          )}
          </MeasureContainer>
        </StyledInnerCarouselFlex>
      </StyledContentContainer>
    </StyledContainerFlex>
  );
};

export default LogoCarousel;