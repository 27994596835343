// @flow strict
import * as R from 'ramda/src/index.js';
import type { Language } from '../../intl/context.js';
import type { GroupTour, Tour } from '../../../records/Tour.js';
import type { Promocodes } from '../../../records/Promocodes.js';
import type { Providers } from '../../../records/Providers.js';
import type { Currencies } from '../../../records/Currencies.js';
import {
  getMaxPrice,
  getMinPrice,
  byGroupId,
  getTourProviderIdWithHighestPrice,
} from '../../utils/index.js';
import { filterTourGroup } from '../../tours/filterTours.js';
import getTourGroupRating from '../../rating/index.js';
import sortTours from '../../tours/sortTours.js';
import { getMinFirstAvailable, getMinSecondAvailable } from '../../firstAvailable/index.js';

export default function mapperGroupTours(
  tours: Tour[],
  locale: Language,
  promocodes: Promocodes,
  currencies: Currencies,
  providers: Providers,
): GroupTour[] {
  return byGroupId(tours).reduce((acc, tourGroup) => {
    const tour = tourGroup.find(t => t.providerId === 1) || tourGroup[0]; // default to GyG tour for displayed data
    const firstAvailable = getMinFirstAvailable(tourGroup); // need the earliest of any tour
    const secondAvailable = getMinSecondAvailable(tourGroup); // need the earliest of any tour

    let bestsellerTourId = null;
    const prices = Object.keys(currencies).reduce((cacc, cur) => {
      // eslint-disable-next-line no-underscore-dangle
      const _sortedTours = filterTourGroup(sortTours(tourGroup, cur), providers, cur);
      const bestsellingTour = _sortedTours.find(t => t.bestConverter); // find first bestseller in group, which should be cheapest
      if (bestsellingTour) {
        bestsellerTourId = bestsellingTour.id;
      }
      // eslint-disable-next-line no-param-reassign
      cacc[`lowestPrice_${cur}`] = getMinPrice(_sortedTours, cur);
      // eslint-disable-next-line no-param-reassign
      cacc[`bestsellerPrice_${cur}`] = bestsellingTour
        ? bestsellingTour[`price_${cur}`]
        : cacc[`lowestPrice_${cur}`];
      // eslint-disable-next-line no-param-reassign
      cacc[`bestsellerProvider_${cur}`] = bestsellingTour
        ? providers[bestsellingTour.providerId].name
        : getMinPrice(_sortedTours, cur);
      // eslint-disable-next-line no-param-reassign
      cacc[`highestPrice_${cur}`] = getMaxPrice(_sortedTours, cur);
      // eslint-disable-next-line no-param-reassign
      const highestPriceProvider = providers[getTourProviderIdWithHighestPrice(_sortedTours, cur)];
      // eslint-disable-next-line no-param-reassign
      cacc[`highestPriceProvider_${cur}`] = highestPriceProvider.officialWebsite
        ? 'on_official_website'
        : highestPriceProvider.name;
      // eslint-disable-next-line no-param-reassign
      cacc[`lowestPriceProvider_${cur}`] = providers[_sortedTours[0].providerId] // might need sort
        ? providers[_sortedTours[0].providerId].name
        : '';
      // eslint-disable-next-line no-param-reassign
      cacc[`lowestPriceTourId_${cur}`] = _sortedTours[0].id;
      // eslint-disable-next-line no-param-reassign
      cacc[`distinctProvidersCount_${cur}`] = new Set(_sortedTours.map(t => t.providerId)).size;
      return cacc;
    }, {});

    const { rating, ratingsCount } = getTourGroupRating(tourGroup);

    return acc.concat({
      ...R.omit(
        [
          'objectID',
          'originalPrice_eur',
          'originalPrice_usd',
          'originalPrice_gbp',
          'originalPrice_chf',
          'abstract',
        ],
        tour,
      ),
      offersCount: tourGroup.length,
      rating, // override
      ratingsCount, // override
      ...prices,
      firstAvailable,
      secondAvailable,
      bestsellerTourId,
    });
  }, []);
}
