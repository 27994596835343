/* eslint-disable no-underscore-dangle */
// @flow strict
import type { Language } from '../../intl/context.js';
import type {
  SearchSuggestion,
  PoiSearchSuggestionInput,
  DestinationSearchSuggestionInput,
} from '../../../records/SearchSuggestion.js';
import { INDICES } from '../../../../../etc/appConfig.js';

export function mapperPoiSuggestions(
  pois: PoiSearchSuggestionInput[],
  locale: Language,
): SearchSuggestion[] {
  return pois.map(poi => ({
    name: poi[`name_${locale.toLowerCase()}`],
    tag: `p:${poi.objectID}`,
    urlName: poi[`urlName_${locale.toLowerCase()}`],
    secondaryInformation: poi[`destinationName_${locale.toLowerCase()}`],
    tags: poi._tags,
    highlightResult: poi._highlightResult,
  }));
}

export function mapperDestinationSuggestions(
  destinations: DestinationSearchSuggestionInput[],
  locale: Language,
): SearchSuggestion[] {
  return destinations.map(destination => ({
    name: destination[`name_${locale.toUpperCase()}`],
    tag: `d:${destination.destination_id}`,
    urlName: destination[`urlName_${locale.toLowerCase()}`],
    secondaryInformation: destination[`country_${locale.toUpperCase()}`],
    highlightResult: destination._highlightResult,
  }));
}

type AlgoliaIndexResponse = {
  index: string,
  hits: PoiSearchSuggestionInput[] & DestinationSearchSuggestionInput[],
};

export function mapperSuggestions(
  input: AlgoliaIndexResponse[],
  locale: Language,
): SearchSuggestion[] {
  return input.reduce((acc, sugg) => {
    if (sugg.index === INDICES.POIS_SCORE) {
      const pois = mapperPoiSuggestions(sugg.hits, locale);
      return acc.concat(pois);
    }

    const destIdx = (() => {
      switch (locale) {
        case 'en':
          return INDICES.DESTINATIONS_EN;
        case 'de':
          return INDICES.DESTINATIONS_DE;
        case 'it':
          return INDICES.DESTINATIONS_IT;
        default:
          return INDICES.DESTINATIONS_EN;
      }
    })();

    if (sugg.index === destIdx) {
      const dests = mapperDestinationSuggestions(sugg.hits, locale);
      return acc.concat(dests);
    }

    return acc;
  }, []);
}
