// @flow strict
import React from 'react';
import { Flex, Box } from '../../../../../../primitives/Essentials/index.js';
import SectionHeader from '../../../../../../components/SectionHeader/index.js';
import IntlContext from '../../../../../../services/intl/context.js';
import type { TopTip } from '../../../../../../records/PoiContent.js';
import { SECTION_LAYOUT_NO_PADDING_MOBILE } from '../../../../../../consts/layout.js';
import Tip from './Tip.js';

type Props = {
  tips: TopTip[],
  poiName: string,
};

const TopTips = ({ tips, poiName }: Props) => (
  <IntlContext.Consumer>
    {({ translate }) => {
      const longTitle = translate('top_tips_for_visitings', { poi: poiName, count: tips.length });
      return (
        <Box {...SECTION_LAYOUT_NO_PADDING_MOBILE} id="top-tips-for-visiting">
          <SectionHeader
            title={longTitle.length >= 65 ? translate('top_tips') : longTitle}
            center
          />
          <Flex $flexDirection="row" $flexWrap="wrap">
            {tips.map((tip, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <Tip key={i} tip={tip} order={i} />
            ))}
          </Flex>
        </Box>
      );
    }}
  </IntlContext.Consumer>
);

export default TopTips;
