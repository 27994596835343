// @flow strict
import type { Language } from '../../intl/context.js';
import type {
  EditorialSectionInput,
  EditorialSection,
  Paragraph,
  ParagraphInput,
  EditorialContent,
  EditorialContentInput,
} from '../../../records/EditorialContent.js';
import type { ImageAttributions } from '../../../records/Photo.js';
import mapperPhoto from '../photo/index.js';

const sortByOrderIndex = (a, b) => {
  if (a.orderIndex < b.orderIndex) return -1;
  if (a.orderIndex > b.orderIndex) return 1;
  return 0;
};

function mapperParagraphs(input: ParagraphInput[], locale: Language): Paragraph[] {
  return input.map(paragraph => ({
    title: paragraph[`title_${locale}`],
    abstract: paragraph[`abstract_${locale}`],
  }));
}

function mapperEditorialSections(
  input: EditorialSectionInput[],
  locale: Language,
  imageAttributions: ImageAttributions,
): EditorialSection[] {
  return input.sort(sortByOrderIndex).map(edSec => ({
    title: edSec[`title_${locale}`] || null,
    abstract: edSec[`abstract_${locale}`] || null,
    photo: mapperPhoto(edSec, locale, imageAttributions),
    paragraphs: edSec.paragraphs ? mapperParagraphs(edSec.paragraphs, locale) : null,
  }));
}

export default function mapperEditorialContent(
  input: EditorialContentInput[],
  locale: Language,
  imageAttributions: ImageAttributions,
): EditorialContent[] {
  return input.map(content => ({
    title: content[`title_${locale}`] || null,
    abstract: content[`abstract_${locale}`] || null,
    photo: mapperPhoto(content, locale, imageAttributions),
    editorialSections: content.editorialSections
      ? mapperEditorialSections(content.editorialSections, locale, imageAttributions)
      : null,
  }));
}
