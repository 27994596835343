// @flow strict
import type { Currency } from '../../../../currency/context.js';

type TourSubset = {
  price_eur?: number,
  price_usd?: number,
  price_gbp?: number,
  price_chf?: number,
  _tags: string[],
};

export type LowestPricePerDate = {
  [date: string]: number,
};

export default function(input: TourSubset[], currency: Currency): LowestPricePerDate {
  return input.reduce((acc, tour) => {
    // eslint-disable-next-line no-underscore-dangle
    tour._tags.forEach(tag => {
      const trimmedTag = tag.slice(2, tag.length);
      if (!acc[trimmedTag]) {
        acc[trimmedTag] = tour[`price_${currency}`];
      } else {
        acc[trimmedTag] = Math.min(tour[`price_${currency}`], acc[trimmedTag]);
      }
    });
    return acc;
  }, {});
}
