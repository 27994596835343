// @flow strict
import * as React from 'react';
import { Box } from '../../../../../../primitives/Essentials/index.js'; 
import type { Tour } from '../../../../../../records/Tour.js';
import type { Review } from '../../../../../../records/Review.js';
import type { Language } from '../../../../../../services/intl/context.js';
import getTourGroupRating from '../../../../../../services/rating/index.js';
import ReviewsHeader from './components/ReviewsHeader/index.js';
import ReviewContent from './components/ReviewContent/index.js';
import type { Theme } from '../../../../../../records/Theme.js';

type Props = { tourGroup: Tour[], language: Language, theme: Theme, reviews: Review[] }; // $FlowFixMe
const GroupTourReviews = React.forwardRef((props: Props, ref) => {
  const { tourGroup, language, theme, reviews } = props;
  const { ratingsCount, rating } = getTourGroupRating(tourGroup);
  const distinctProvidersCount = new Set(tourGroup.map(t => t.providerId)).size;
  return (
    <Box ref={ref}>
      <ReviewsHeader
        ratingsCount={ratingsCount}
        rating={rating}
        providerCount={distinctProvidersCount}
        theme={theme}
      />
      {reviews.map((review, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <ReviewContent key={idx} review={review} language={language} />
      ))}
    </Box>
  );
});
export default GroupTourReviews;
