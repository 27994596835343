// @flow strict
import React from 'react';
import defaultProps from '../records/IconProps.js';
import type { IconProps } from '../records/IconProps.js';

const SvgDropdown = ({ color, size, className }: IconProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size / 2}
    viewBox="0 0 20 10"
  >
    <path fill={color} fillRule="evenodd" d="M0 0l10 10L20 0z" />
  </svg>
);

SvgDropdown.defaultProps = defaultProps;

export default SvgDropdown;
