// @flow strict
import React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Box } from '../../../../../../primitives/Essentials/index.js';
import Line from '../../../../../../primitives/Line/index.js';
import Text from '../../../../../../components/Text/index.js';
import mq from '../../../../../../services/mediaQuery/index.js';

const StyledBox = styled(Box)`
  padding-right: 15px;
  padding-left: 15px;

  a {
    color: inherit;
  }

  ${mq.TABLET`
    padding-right: 0;
    padding-left: 0;
    &:nth-child(2n) {
      padding-left: 15px;
    }
  `}
`;

const Question = styled.h3`
  margin: 0;
  font-weight: 900;
  font-size: 16px;
  line-height: 1.45;
  letter-spacing: -0.02px;
`;

const StyledLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  margin-bottom: 20px;
  color: ${({ theme }) => theme.trip.abstract};
`;

const InvisibleLink = styled.a`
  display: none;
`;

type Props = {
  question: string,
  answer: string,
  pageUrl: string,
};

const QuestionAnswer = ({ question, answer, pageUrl }: Props) => (
  <StyledBox
    width={[1, null, null, null, 1 / 2]}
    itemScope
    itemProp="mainEntity"
    itemType="https://schema.org/Question"
  >
    <Question itemProp="name">
      <Text t={question} html />
    </Question>
    <StyledLine
      itemScope
      itemProp="acceptedAnswer"
      itemType="https://schema.org/Answer"
      fontSize={16}
      letterSpacing={-0.02}
      lineHeight={1.38}
    >
      <div itemProp="text">
        <Text t={answer} html />{' '}
        <InvisibleLink href={pageUrl}>
          <Text t="read_more" />
        </InvisibleLink>
      </div>
    </StyledLine>
  </StyledBox>
);

export default QuestionAnswer;
