// @flow strict
import React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Flex, Box } from '../../../../../../../../primitives/Essentials/index.js';
import mq from '../../../../../../../../services/mediaQuery/index.js';
import IntlContext from '../../../../../../../../services/intl/context.js';
import WindowContext from '../../../../../../../../services/window/context.js';
import SliderPage from './components/SliderPage/index.js';
import { IconArrowPrev, IconArrowNext } from '../../../../../../../../components/Icons/index.js';
import Cursor from './components/Cursor/index.js';
import SIZES from '../../../../../../../../consts/sizes.js';
import SwipeableViews from '../../../../../../../../components/SwipeableViews/index.js';

const WrappingContainer = styled(Box)`
  width: 100%;
  background-color: ${({ theme }) => theme.trip.backgroundLight};

  min-height: 360px;

  ${mq.BIG_TABLET`
    min-height: 450px;
  `};
  ${mq.DESKTOP`
    min-height: 500px;
  `};
  ${mq.DESKTOP_TOC`
    min-height: 500px;
  `};
`;

const StyledContainerFlex = styled(Flex)`
  /* Desktop container should be hidden on small screens */
  @media (max-width: ${({ theme }) => SIZES.TABLET - 1}px) {
    display: none;
  }
`;

const StyledMobileContainerFlex = styled(Flex)`
  position: relative;
  z-index: 0;
  overflow: hidden; /* Prevent vertical scrolling */

  /* Mobile container should be hidden on larger screens */
  @media (min-width: ${({ theme }) => SIZES.TABLET}) {
    display: none;
  }
`;

const StyledNotMobileContainerFlex = styled(Flex)`
  width: 100%;
  max-width: ${SIZES.DESKTOP}px;
`;

const StyledPrevArrow = styled(IconArrowPrev)`
  position: absolute;
  top: 50%;
  left: 15px;
  z-index: 1;
  touch-action: none;
`;

const StyledNextArrow = styled(IconArrowNext)`
  position: absolute;
  top: 50%;
  right: 15px;
  z-index: 1;
  touch-action: none;
`;

const StyledSliderPage = styled(SliderPage)`
  width: 100%;
  height: 100%;
`;

type Props = {
  totalToursCount: number,
};

type State = {
  indexVisiblePage: number,
  cursorPosition: number,
};

const StyledCursor = styled(Cursor)`
  position: absolute;
  bottom: 0;
  left: ${({ xPosition }) => `${xPosition}px`};
`;

class USPSlider extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      indexVisiblePage: 0,
      cursorPosition: 0,
    };
  }

  handlePageSwitch = (index: number) => {
    this.setState({
      indexVisiblePage: index,
      cursorPosition: index,
    });
  };

  handleNextPageClick = () => {
    const { indexVisiblePage } = this.state;
    const updatedIndex = indexVisiblePage < 2 ? indexVisiblePage + 1 : indexVisiblePage;
    this.handlePageSwitch(updatedIndex);
  };

  handlePrevPageClick = () => {
    const { indexVisiblePage } = this.state;
    const updatedIndex = indexVisiblePage > 0 ? indexVisiblePage - 1 : indexVisiblePage;
    this.handlePageSwitch(updatedIndex);
  };

  render() {
    const { indexVisiblePage, cursorPosition } = this.state;
    const { totalToursCount } = this.props;
    const newCursorPosition =
      typeof window !== 'undefined' ? (window.innerWidth / 3) * cursorPosition : 0;

    return (
      <IntlContext.Consumer>
        {({ translate, language }) => (
          <WindowContext.Consumer>
            {({ isMobile }) => (
              <WrappingContainer $mt={55}>
                {isMobile ? (
                  <StyledMobileContainerFlex>
                    <StyledPrevArrow
                      size={20}
                      disabled={indexVisiblePage === 0}
                      onClick={this.handlePrevPageClick}
                    />
                    <SwipeableViews index={indexVisiblePage} onChangeIndex={this.handlePageSwitch}>
                      <StyledSliderPage
                        image="usp-1"
                        text={translate('usp-page1-text', { toursNumber: totalToursCount })}
                        altText={translate('usp-page1-alt-text')}
                      />
                      <StyledSliderPage
                        image={`usp-2-${language.toLocaleLowerCase()}`}
                        text={translate('usp-page2-text')}
                        altText={translate('usp-page2-alt-text')}
                      />
                      <StyledSliderPage
                        image="usp-3"
                        text={translate('usp-page3-text')}
                        altText={translate('usp-page3-alt-text')}
                      />
                    </SwipeableViews>
                    <StyledNextArrow
                      size={20}
                      disabled={indexVisiblePage === 2}
                      onClick={this.handleNextPageClick}
                    />
                    <StyledCursor xPosition={newCursorPosition} />
                  </StyledMobileContainerFlex>
                ) : (
                  <StyledContainerFlex $justifyContent="space-around" $alignItems="center">
                    <StyledNotMobileContainerFlex
                      $justifyContent="space-around"
                      $mt={[null, null, null, null, 30, 50]}
                      $mb={[null, null, null, null, 20]}
                      $px={15}
                    >
                      <SliderPage
                        image="usp-1"
                        text={translate('usp-page1-text', { toursNumber: totalToursCount })}
                        altText={translate('usp-page1-alt-text')}
                      />
                      <SliderPage
                        image={`usp-2-${language.toLocaleLowerCase()}`}
                        text={translate('usp-page2-text')}
                        altText={translate('usp-page2-alt-text')}
                      />
                      <SliderPage
                        image="usp-3"
                        text={translate('usp-page3-text')}
                        altText={translate('usp-page3-alt-text')}
                      />
                    </StyledNotMobileContainerFlex>
                  </StyledContainerFlex>
                )}
              </WrappingContainer>
            )}
          </WindowContext.Consumer>
        )}
      </IntlContext.Consumer>
    );
  }
}

export default USPSlider;
