// @flow strict
import React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Box } from '../../../../primitives/Essentials/index.js';
import mq from '../../../../services/mediaQuery/index.js';

const StyledImageBox = styled(Box)`
  position: relative;
  height: 200px;

  ${mq.TABLET`
    height: 250px;
  `} ${mq.DESKTOP`
    height: 400px;
  `};
`;

const StyledImage = styled.img`
  display: block;
  float: left;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const StyledTitle = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.tertiary};
  font-weight: bold;
  font-size: 40px;
  text-align: center;
  text-shadow: 0 1px 36px rgba(0, 0, 0, 0.87);

  ${mq.TABLET`
    font-size: 60px;
  `} ${mq.DESKTOP`
    font-size: 90px;
  `};
`;

const StyledRoot = styled.div`
  position: relative;
  &:after {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    box-shadow: inset 0 7px 9px -7px rgba(0, 0, 0, 0.32);
    content: '';
  }
`;

const StyledH1 = styled.h1`
  z-index: 1;
  margin: 0 6px;
  font-size: inherit;
`;

type Props = {
  imageUrl: string,
  title: string,
  responsive: boolean,
  imageService: boolean,
  showTitle: boolean,
  alt?: string,
};

const CoverTitle = ({ responsive, imageUrl, imageService, title, showTitle, alt }: Props) => {
  const isPoiPage = showTitle && imageService;
  const bg = isPoiPage ? 'c51162' : 'f5f5f5';

  return (
    <StyledRoot>
      <StyledImageBox>
        {responsive ? (
          <StyledImage
            src={`${imageUrl}${
              !imageService ? '/375x200.jpg' : `/375x200?returnCrop=yes&bg=${bg}`
            }`}
            srcSet={`
              ${imageUrl}${
              !imageService ? '/375x200.jpg' : `/375x200?returnCrop=yes&bg=${bg}`
            } 375w,
              ${imageUrl}${
              !imageService ? '/835x400.jpg' : `/835x400?returnCrop=yes&bg=${bg}`
            } 835w,
              ${imageUrl}${
              !imageService ? '/1400x400.jpg' : `/1400x400?returnCrop=yes&bg=${bg}`
            } 1400w,
              ${imageUrl}${
              !imageService ? '/835x400@2x.jpg' : `/1670x400?returnCrop=yes&bg=${bg}`
            } 1670w,
              ${imageUrl}${
              !imageService ? '/1920x400.jpg' : `/1920x400?returnCrop=yes&bg=${bg}`
            } 1920w`}
            alt={alt || title || 'cover'}
            $secondary={isPoiPage}
          />
        ) : (
          <StyledImage src={imageUrl} alt={alt || title} />
        )}
        {showTitle && (
          <StyledTitle>
            <StyledH1>{title}</StyledH1>
          </StyledTitle>
        )}
      </StyledImageBox>
    </StyledRoot>
  );
};

export default CoverTitle;
