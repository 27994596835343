// @flow strict
import * as React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Flex } from '../../primitives/Essentials/index.js';
import mq from '../../services/mediaQuery/index.js';
import { IconSearch } from '../Icons/index.js';
import type { Theme } from '../../records/Theme.js';

const StyledFlexContainer = styled(Flex)`
  width: 100%;
  height: 40px;
  background-color: ${({ theme }) => theme.tertiary};
  border-radius: 5px;

  ${mq.TABLET`
    max-width: 386px;
    width: 386px;
    background-color: ${({ theme }) => theme.trip.backgroundLight};
  `};
  &:hover {
    background-color: ${({ theme }) => theme.trip.backgroundDark};
  }
`;

const StyledForm = styled.form`
  width: 100%;
  padding-left: 5px;
`;

const StyledTextInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  font-size: 17px;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
  outline-width: 0;
  caret-color: ${({ theme }) => theme.secondary};
`;

type Props = {
  theme: Theme,
  onChange?: ?(string) => void,
  onSubmit?: ?(string) => void,
  onFocus?: ?() => void,
  placeholder: string,
  prepopulatedValue: string,
  className?: string,
  autoFocus?: boolean,
  handleKeyDown?: KeyboardEvent => void,
};

type State = {
  value: string,
};

class SearchInput extends React.PureComponent<Props, State> {
  static defaultProps = {
    className: '',
    onChange: null,
    onSubmit: null,
    onFocus: null,
  };

  constructor(props: Props) {
    super(props);

    const initialPrepopulatedValue =
      typeof props.prepopulatedValue === 'undefined' ? '' : props.prepopulatedValue;

    this.state = {
      value: initialPrepopulatedValue,
    };
  }

  // FIXME https://reactjs.org/docs/react-component.html#unsafe_componentwillreceiveprops
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps = (nextProps: Props) => {
    const { value } = this.state;
    const { prepopulatedValue } = nextProps;

    if (
      typeof prepopulatedValue !== 'undefined' &&
      typeof value !== 'undefined' &&
      value !== prepopulatedValue
    ) {
      this.setState({
        value: prepopulatedValue,
      });
    }
  };

  handleTextChange = (event: SyntheticInputEvent<*>) => {
    const { onChange } = this.props;
    const { value } = event.target;

    if (onChange) {
      onChange(value);
    }

    this.setState({
      value,
    });
  };

  handleSubmit = (event: MouseEvent | TouchEvent) => {
    const { onSubmit } = this.props;
    const { value } = this.state;

    event.preventDefault();

    if (onSubmit) {
      onSubmit(value);
    }
  };

  handleOnFocus = () => {
    const { onFocus } = this.props;

    if (onFocus) {
      onFocus();
    }
  };

  render() {
    const { value } = this.state;
    const { placeholder, theme, className, autoFocus, handleKeyDown } = this.props;

    return (
      <StyledFlexContainer $justifyContent="flex-start" $alignItems="center" $mr={16} $px={8} $py={10}>
        <IconSearch size={19} color={theme.secondary} />
        <StyledForm onSubmit={this.handleSubmit}>
          <StyledTextInput
            type="search"
            autoFocus={autoFocus}
            placeholder={placeholder}
            className={className}
            value={value}
            onChange={this.handleTextChange}
            onFocus={this.handleOnFocus}
            onKeyDown={handleKeyDown}
          />
        </StyledForm>
      </StyledFlexContainer>
    );
  }
}

export default SearchInput;
