// @flow strict
import * as React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Flex } from '../../../../../../../../../../primitives/Essentials/index.js';
import Image from '../../../../../../../../../../components/Image/index.js';
import type { Provider } from '../../../../../../../../../../records/Providers.js';

const StyledContainerFlex = styled(Flex)`
  width: 100%;
`;

const StyledImage = styled(Image)`
  width: 100%;
  height: auto;
  min-height: 63px;
  object-fit: cover;
`;

type Props = {
  provider: Provider,
};

const LogoProvider = ({ provider }: Props) => {
  const imageUrl = `https://cdn.ticketlens.com/providers/${provider.imgId}/logo`;

  return (
    <StyledContainerFlex $alignItems="center" $justifyContent="center">
      <StyledImage
        lazy
        src={`${imageUrl}/160x100`}
        srcSet={`${imageUrl}/320x200 2x, ${imageUrl}/480x300 3x`}
        alt={provider.name}
      />
    </StyledContainerFlex>
  );
};

export default LogoProvider;
