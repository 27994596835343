// @flow strict
import React from 'react';
import type { Location } from 'react-router-dom';
import pkg from 'react-helmet-async/lib/index.js';
const { Helmet: ReactHelmet, HelmetData } = pkg;
import { DOMAIN } from '../../../../../../etc/appConfig.js';
import type { Translate } from '../../../../services/intl/translate';
// import type { Language } from '../../../../services/intl/context.js';

type Props = {
  translate: Translate,
  resultsForString: string,
  destinationName: string,
  location: Location,
  sectionId: string,
};

const Helmet = ({ translate, resultsForString, location, sectionId, destinationName }: Props) => {
  const title = `${translate(resultsForString ? 'title_category_page' : 'title_dest_page', {
    destination: destinationName,
    category: resultsForString,
  })}`;
  const description = translate('desc_category_page', {
    destination: destinationName,
    category: resultsForString,
  });
  const url = `${DOMAIN}${location.pathname}?s=${sectionId}`;
  const helmetData = new HelmetData({});

  return (
    <ReactHelmet helmetData={helmetData}>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta name="twitter:title" content={title} />
      <meta name="description" property="og:description" content={description} />
      <meta name="twitter:description" content={description} />
      <meta property="og:url" content={url} />
    </ReactHelmet>
  );
};

export default Helmet;
