// @flow strict
import React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Box, Flex } from '../../../../../../primitives/Essentials/index.js';
import Line from '../../../../../../primitives/Line/index.js';
import Attraction from './components/Attraction/index.js';
import { SECTION_LAYOUT } from '../../../../../../consts/layout.js';
import Text from '../../../../../../components/Text/index.js';
import type { Poi } from '../../../../../../records/Poi.js';
import type { Language } from '../../../../../../services/intl/context.js';

const StyledLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  color: ${({ theme }) => theme.section.title};
`;

type Props = {
  attractions: Poi[],
  destinationName: string,
  currentPoi: Poi,
  language: Language,
};

const Attractions = ({ attractions, destinationName, currentPoi, language }: Props) => (
  <Box {...SECTION_LAYOUT}>
    <Flex $flexDirection="column">
      <StyledLine fontSize={22} fontWeight={900}>
        <Text
          t="other_top_attractions"
          values={{
            destination: destinationName,
          }}
        />
      </StyledLine>
      <Flex $mt={5} $flexWrap="wrap">
        {attractions
          .filter(att => att.objectID !== currentPoi.objectID)
          .slice(0, 8)
          .map((att, i) => (
            <Attraction key={att.objectID} attraction={att} order={i} language={language} />
          ))}
      </Flex>
    </Flex>
  </Box>
);

export default Attractions;
