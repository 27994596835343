// @flow strict
import React from 'react';
import { Box } from '../../../../primitives/Essentials/index.js'
import SearchToursHeader from './components/SearchToursHeader/index.js';
import SearchToursList from './components/SearchToursList/index.js';
import type { GroupTour } from '../../../../records/Tour.js';
import withInfiniteScroll from '../../../../decorators/withInfiniteScroll/index.js';

type Props = {
  resultsForString: ?string,
  groupTours: GroupTour[],
  resultsCount: number,
  date?: ?Date,
};

const SearchTours = ({ resultsForString, groupTours, resultsCount, date }: Props) => (
  <Box>
    {Boolean(groupTours.length) && (
      <SearchToursHeader resultsCount={resultsCount} resultsForString={resultsForString} />
    )}
    <SearchToursList groupTours={groupTours} showAvailabilities={!date} date={date} />
  </Box>
);

SearchTours.defaultProps = {
  date: null,
};

const infiniteScrollCondition = ({ shouldPerformSearch }) => shouldPerformSearch();
export default withInfiniteScroll(infiniteScrollCondition)(SearchTours);
