// @flow strict
import React from 'react';
import { Flex } from '../../../../../../../../../../primitives/Essentials/index.js';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;

import StarRating from '../../../../../../../../../StarRating/index.js';
import Line from '../../../../../../../../../../primitives/Line/index.js';
import type { Theme } from '../../../../../../../../../../records/Theme.js';

type Props = {
  rating: number,
  ratingsCount: number,
  theme: Theme,
};

const StyledStarRating = styled(StarRating)`
  margin-right: 3px;
  margin-left: 3px;
  color: ${({ theme }) => theme.trip.rating};
`;

const TourContentRating = ({ rating, ratingsCount, theme }: Props) => (
  <Flex $mt={[2, null, null, 4]} $alignItems="center">
    <Line fontSize={14} fontWeight={900} color={theme.trip.rating}>
      {rating.toFixed(1)}
    </Line>
    <StyledStarRating rating={rating} stars={5} />
    <Line fontSize={14} color={theme.trip.offers}>
      ({ratingsCount})
    </Line>
  </Flex>
);

export default TourContentRating;
