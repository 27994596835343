// @flow strict
import * as React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Box, Flex } from '../../../../../../../../primitives/Essentials/index.js'
import Line from '../../../../../../../../primitives/Line/index.js';
import Text from '../../../../../../../../components/Text/index.js';
import type { Review } from '../../../../../../../../records/Review.js';
import type { Language } from '../../../../../../../../services/intl/context.js';
import ContentContext from '../../../../../../../../services/content/context.js';
import StarRating from '../../../../../../../../components/StarRating/index.js';
import { parseReviewDate, formatDateReview } from '../../../../../../../../services/dateTimeUtils/index.js';

type Props = {
  review: Review,
  language: Language,
};

const StyledContainerBox = styled(Box)`
  position: relative;
  background-color: ${({ theme }) => theme.trip.backgroundLight};
  border-radius: 5px;
`;

const StyledDateProviderLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  color: ${({ theme }) => theme.trip.fee};
`;

const StyledReviewContentLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  max-width: 720px;
  color: ${({ theme }) => theme.section.abstract};
  line-height: 1.39;
`;

const StyledAuthorLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  color: ${({ theme }) => theme.section.title};
`;

const ReviewContent = ({ review, language }: Props) => {
  const { providers } = React.useContext(ContentContext);
  const reviewDate = formatDateReview(parseReviewDate(review.date), language);

  return (
    <StyledContainerBox $my={10} $py={18} $mx={[15, null, null, 0]} $px={15}>
      <Flex $flexDirection="column">
        <Flex $flexDirection="row" $mb={5}>
          <StarRating rating={review.rating} stars={5} starSize={14.5} />
          &nbsp;
          <StyledDateProviderLine lineHeight={1.39}>
            <Text t="available_on" values={{ place: providers[review.providerId].name }} />
          </StyledDateProviderLine>
        </Flex>
        <StyledReviewContentLine>{review.title}</StyledReviewContentLine>
        <StyledReviewContentLine>{review.body}</StyledReviewContentLine>
        <Flex $flexDirection="row" $justifyContent="space-between" $mt={5}>
          <StyledAuthorLine lineHeight={1.39} fontWeight="bold">
            {review.author}
          </StyledAuthorLine>
          <StyledDateProviderLine lineHeight={1.39}>{reviewDate}</StyledDateProviderLine>
        </Flex>
      </Flex>
    </StyledContainerBox>
  );
};

export default ReviewContent;
