// @flow strict
import * as React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;

const StyledLink = styled.a`
  display: inline;
  color: inherit;
  text-decoration: none;
`;

const StyledDivLink = styled.div`
  cursor: pointer;
`;

type Props = {
  href?: string,
  children: React.Node,
  target?: ?string,
  rel?: ?string,
  onClick?: ?() => void,
  className?: ?string,
  useDiv: ?boolean,
};

// use to wrap stuff in invisible links
const Link = ({ children, href, target, rel, className, onClick, useDiv }: Props) =>
  useDiv && onClick ? (
    <StyledDivLink
      onClick={ev => {
        ev.preventDefault();
        onClick();
      }}
      onKeyPress={ev => {
        if (ev.key === 'Enter') {
          onClick();
        }
      }}
      role="button"
      className={className}
      tabIndex={0}
    >
      {children}
    </StyledDivLink>
  ) : (
    <StyledLink
      onClick={
        onClick
          ? ev => {
              ev.preventDefault();
              onClick();
            }
          : null
      }
      className={className}
      href={href}
      target={target}
      rel={rel}
    >
      {children}
    </StyledLink>
  );

Link.defaultProps = {
  href: '',
  className: null,
  onClick: null,
  rel: null,
  target: null,
  useDiv: false,
};

export default Link;
