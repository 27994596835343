// @flow strict
import type { Tour } from '../../records/Tour.js';
import type { Providers } from '../../records/Providers.js';
import type { Currency } from '../currency/context.js';

// eslint-disable-next-line import/prefer-default-export
export const filterTourGroup = (
  group: Tour[],
  providers: Providers,
  currency: Currency,
): Tour[] => {
  const providerIdsWithFilteringActive = Object.keys(providers).filter(
    id => providers[id].mustBeCheapestByMinPercent,
  );
  if (group.length > 1 && providerIdsWithFilteringActive.includes(String(group[0].providerId))) {
    const firstTourPrice = group[0][`price_${currency}`];
    const secondTourPrice = group[1][`price_${currency}`];
    const firstTourProvider = providers[group[0].providerId];
    if (
      firstTourProvider &&
      firstTourProvider.mustBeCheapestByMinPercent &&
      secondTourPrice - firstTourPrice <=
        (secondTourPrice / 100) * firstTourProvider.mustBeCheapestByMinPercent
    ) {
      // price difference <= defined% treshold
      return group.slice(1); // remove first tour
    }
  }
  return group;
};
