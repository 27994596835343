// @flow strict
import * as React from 'react';

import type { Translate, Translations } from './translate';

export type Language = 'en' | 'de' | 'it';

export type IntlContextType = {|
  language: Language,
  translations: Translations,
  translate: Translate,
|};

const IntlContext = React.createContext<IntlContextType>({
  language: 'en',
  translations: {},
  translate: id => id,
});

export default IntlContext;
