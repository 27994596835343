// @flow strict
import * as React from 'react';
import * as R from 'ramda/src/index.js';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
// $FlowFixMe
import Calendar from '../../../../services/react-dates-lite/Calendar.js';
import CalendarDay from '../CalendarDay/index.js';
import { IconArrowBack, IconArrowForward } from '../../../Icons/index.js';
import { formatDay, formatMonth } from '../../../../services/dateTimeUtils/index.js';
import type { Theme } from '../../../../records/Theme.js';
import type { Language } from '../../../../services/intl/context.js';
import type { LowestPricePerDate } from '../../../../services/api/tours/services/lowestPrices/index.js';
import { getNavigatorLanguage } from '../../../../services/window/utils.js';
import mq from '../../../../services/mediaQuery/index.js';

const StyledCalendar = styled(Calendar)`
  position: absolute;
  top: 56px;
  left: -15px;
  z-index: 12001;
  padding: 4px;
  background-color: ${({ theme }) => theme.tertiary};
  border-radius: 5px;
  box-shadow: 0 2.5px 4px 0 rgba(0, 0, 0, 0.5);
  .month > span {
    font-weight: 900;
  }
  .month > div {
    margin: 20px 0 10px;
  }

  ${mq.SMALL_MOBILE`
    left: 0px;
    padding: 10px;
  `};
`;

type Props = {
  date: ?Date,
  language: Language,
  minDate: Date,
  maxDate: Date,
  disabledDates?: ?(Date[]),
  onChangeDate: (date: Date) => void,
  lowestPrices?: ?LowestPricePerDate,
  theme: Theme,
  isMobile: boolean,
  calendarWrapperNode: ?React.Ref<*>,
  handleToggleCalendar: () => void,
};

const DatePickerModal = ({
  date,
  language,
  minDate,
  maxDate,
  disabledDates,
  onChangeDate,
  lowestPrices,
  theme,
  isMobile,
  calendarWrapperNode,
  handleToggleCalendar,
}: Props) => {
  const lowestPricePerMonth =
    lowestPrices && !R.isEmpty(lowestPrices)
      ? Object.keys(lowestPrices).reduce((acc, dateLowestPrice) => {
          const yyyyMM = dateLowestPrice.slice(0, -3);
          if (!acc[yyyyMM]) {
            acc[yyyyMM] = lowestPrices[dateLowestPrice];
          } else if (lowestPrices[dateLowestPrice] < acc[yyyyMM]) {
            acc[yyyyMM] = lowestPrices[dateLowestPrice];
          }
          return acc;
        }, {})
      : {};

  return (
    <div ref={calendarWrapperNode}>
      <StyledCalendar
        visibleMonths={1}
        firstMonth={minDate}
        lastMonth={maxDate}
        disabledDates={disabledDates}
        selectedDates={date ? [date] : []}
        selectDates={dates => {
          onChangeDate(dates[0]);
          handleToggleCalendar();
        }}
        showWeekDayNames
        showMonthName
        weekDayFormatter={R.partial(formatDay, [language])}
        monthNameFormatter={R.partial(formatMonth, [language])}
        colors={{
          selected: theme.secondary,
          selectedHover: theme.secondary,
          background: theme.tertiary,
          border: theme.tertiary,
        }}
        CustomTd={
          lowestPrices
            ? (props, children) => (
                <CalendarDay
                  {...props}
                  lowestPrices={lowestPrices}
                  lowestPricePerMonth={lowestPricePerMonth}
                >
                  {children}
                </CalendarDay>
              )
            : (props, children) => <CalendarDay {...props}>{children}</CalendarDay>
        }
        width={isMobile ? 311 : 325}
        buttonBack={<IconArrowBack size={18} />}
        buttonForward={<IconArrowForward size={18} />}
        classes={{
          month: 'month',
        }}
        firstWeekDay={
          language === 'en' && ['en-us', 'en-au', 'en-ca'].includes(getNavigatorLanguage(window))
            ? 0
            : 1
        } // 0 is sunday
      />
    </div>
  );
};

export default DatePickerModal;
