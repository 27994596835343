// @flow strict
import React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import defaultProps from '../records/IconProps.js';
import type { IconProps } from '../records/IconProps.js';
import { ThemeDefault } from '../../../records/Theme.js';

const StyledBtn = styled.rect`
  cursor: pointer;
  fill: #fff;
  stroke: #fff;
  fill-opacity: 0;
  stroke-opacity: 0;
`;

type Props = IconProps & {
  disabled?: boolean,
  disabledColor?: string,
  onClick?: () => void,
};

const SvgArrowNext = ({ size, color, disabledColor, className, disabled, onClick }: Props) => (
  <svg
    className={className}
    width={`${size}px`}
    height={`${size}px`}
    viewBox="0 0 12 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={!disabled ? color : disabledColor}
      fillRule="evenodd"
      d="M10.702 8.617l1.142 1.106a.488.488 0 0 1 .011.704L2.283 19.86a.525.525 0 0 1-.726-.011L.156 18.49a.487.487 0 0 1-.011-.703l7.839-7.725-7.82-7.874a.481.481 0 0 1 .01-.696L1.562.15A.52.52 0 0 1 2.28.14l8.422 8.477z"
    />
    <StyledBtn
      className="btn"
      x="0"
      y="0"
      width={`${size}px`}
      height={`${size}px`}
      onClick={onClick}
    />
  </svg>
);

SvgArrowNext.defaultProps = {
  ...defaultProps,
  disabled: false,
  disabledColor: ThemeDefault.loader,
  color: ThemeDefault.secondary,
};

export default SvgArrowNext;
