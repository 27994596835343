// @flow strict
import React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Box, Flex } from '../../../../../../../../primitives/Essentials/index.js';
import Line from '../../../../../../../../primitives/Line/index.js';
import type { Theme } from '../../../../../../../../records/Theme.js';
import UrlContext from '../../../../../../../../services/url/context.js';
import mq from '../../../../../../../../services/mediaQuery/index.js';
import { darkOverlay, hover } from '../../../../../../../../services/styleUtils/index.js';
import Link from '../../../../../../../../components/Link/index.js';
import type { Tag } from '../../../../../../../../records/SectionConfig.js';

type Props = {
  topic: Tag,
  theme: Theme,
  imageUrl: string,
};

const StyledBox = styled(Box)`
  padding-top: 10px;
  border-radius: 5px;
  ${mq.BIG_MOBILE`
   &:nth-child(odd) {
      padding-right: 5px;
    }
    &:nth-child(even) {
      padding-left: 5px;
    }
  `};
  ${mq.TABLET`
    padding-left: 5px;
    padding-right: 5px;
    &:nth-child(3n + 1) {
      padding-left: 0px;
      padding-right: 5px;
    }
    &:nth-child(3n + 3) {
      padding-left: 5px;
      padding-right: 0px;
    }
  `};
  ${mq.DESKTOP`
    ${hover()}
    &:nth-child(3n + 1) {
      padding-left: 5px;
    }
    &:nth-child(3n + 3) {
      padding-right: 5px;
    }
    &:nth-child(4n + 4) {
      padding-right: 0px;
    }
    &:nth-child(4n + 1) {
      padding-left: 0px;
    }
  `};
`;

const StyledFlex = styled(Flex)`
  position: relative;
  height: 75px;
  background-image: ${({ $imageUrl }) => `url('${$imageUrl}')`};
  background-position: center center;
  background-size: cover;
  border-radius: 5px;
  ${mq.BIG_MOBILE`
    height: 170.5px;
  `};
`;

const StyledLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  z-index: 1;
  text-align: center;
`;

const StyledOverlay = styled.div`
  ${darkOverlay()};
`;

const Topic = ({ topic, theme, imageUrl }: Props) => (
  <StyledBox width={[1, null, null, 1 / 2, 1 / 3, 1 / 4]}>
    <UrlContext.Consumer>
      {({ openDestinationCategoryPage }) => (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link
          useDiv={!topic.hasContent}
          onClick={!topic.hasContent ? () => openDestinationCategoryPage(topic.Tag) : undefined}
          href={topic.hasContent ? topic.urlName : undefined}
        >
          <StyledFlex $justifyContent="center" $alignItems="center" $imageUrl={imageUrl}>
            <StyledLine className="title" fontSize={24} fontWeight={900} color={theme.tertiary}>
              {topic.Name}
            </StyledLine>
            <StyledOverlay />
          </StyledFlex>
        </Link>
      )}
    </UrlContext.Consumer>
  </StyledBox>
);

export default Topic;
