// @flow strict
import React from 'react';

type Props = {
  kind?: 'full' | 'faded' | 'empty',
};

const getColor = kind => {
  if (kind === 'full') {
    return '#ffb300';
  }
  if (kind === 'faded') {
    return '#ffcf5c';
  }
  return '#c7c7c7';
};

const StarSvg = ({ kind }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="38px" height="38px" viewBox="0 0 78 77">
    <path
      fill={getColor(kind)}
      fillRule="nonzero"
      d="M39.061 65.03l-20.99 11.573c-1.227.677-2.748.189-3.398-1.09a2.715 2.715 0 0 1-.256-1.665L18.43 49.31 1.415 31.915a2.697 2.697 0 0 1-.034-3.704 2.48 2.48 0 0 1 1.43-.757l23.5-3.581L36.802 1.575c.612-1.3 2.118-1.836 3.365-1.2.5.256.906.678 1.15 1.2l10.494 22.298 23.499 3.581c1.374.21 2.325 1.54 2.124 2.97a2.66 2.66 0 0 1-.727 1.49L59.693 49.31l4.013 24.54c.233 1.425-.688 2.778-2.057 3.021a2.427 2.427 0 0 1-1.599-.267L39.061 65.03z"
    />
  </svg>
);

export default StarSvg;
