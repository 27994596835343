// @flow strict
import * as React from 'react';
import { Box } from '../../../../primitives/Essentials/index.js'
import { IconArrowBack } from '../../../Icons/index.js';
import Button from '../../../Button/index.js';

type Props = {
  onClick: ?(ev: SyntheticEvent<*>) => void,
  href: string,
  children: React.Node,
};

const FooterButton = ({ onClick, href, children }: Props) => (
  <Box $mb={1.5}>
    <Button
      onClick={onClick || undefined}
      responsive={false}
      kind="secondary"
      icon={<IconArrowBack size={20} />}
      href={href}
    >
      {children}
    </Button>
  </Box>
);

export default FooterButton;
