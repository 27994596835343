// @flow strict
import * as React from 'react';
import WindowContext from '../../services/window/context.js';
import IntlContext from '../../services/intl/context.js';
import TaglineCarouselContent from './components/TaglineCarouselContent.js';

const TaglineCarousel = () => (
  <WindowContext.Consumer>
    {({ isMobile }) => (
      <IntlContext.Consumer>
        {({ language }) => <TaglineCarouselContent isMobile={isMobile} language={language} />}
      </IntlContext.Consumer>
    )}
  </WindowContext.Consumer>
);

export default TaglineCarousel;
