// @flow strict
import React from 'react';
import { Flex } from '../../../../../../../../../../primitives/Essentials/index.js';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import mq from '../../../../../../../../../../services/mediaQuery/index.js';
import Text from '../../../../../../../../../../components/Text/index.js';
import Line from '../../../../../../../../../../primitives/Line/index.js';
import Image from '../../../../../../../../../../components/Image/index.js';

const StyledContainerFlex = styled(Flex)`
  max-width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.trip.backgroundLight};

  ${mq.TABLET`
    max-width: 33%;
    max-height: 400px;
  `};

  ${mq.DESKTOP`
    max-height: 500px;
  `};
`;

const StyledImage = styled(Image)`
  max-width: 265px;
  min-height: 265px;
  object-fit: cover;

  ${mq.TABLET`
    max-width: 240px;
    min-height: 240px;
  `};

  ${mq.BIG_TABLET`
    max-width: 320px;
    min-height: 320px;
  `};

  ${mq.DESKTOP`
    min-height: 320px;
  `};

  ${mq.DESKTOP_TOC`
    min-height: 320px;
  `};

`;

const StyledLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  max-width: 218px;
  padding: 0 15px;
  text-align: center;

  ${mq.TABLET`
      padding-top: 15px;
      max-width: 228px;
  `};
`;

type Props = {
  text: string,
  image: string,
  altText: string,
  className?: ?string,
};

const SliderPage = ({ text, image, altText, className }: Props) => {
  const imageUrl = `/images/usp/${image}.jpg`;
  const imageUrlx2 = `/images/usp/${image}@2x.jpg`;

  return (
    <StyledContainerFlex
      className={className}
      $flexDirection="column"
      $justifyContent="flex-start"
      $alignItems="center"
      $pt={12}
      $pb={22}
      $px={[null, null, null, null, 15, 60]}
    >
      <StyledImage lazy src={imageUrl} srcSet={`${imageUrlx2} 2x`} alt={altText} />
      <StyledLine fontSize={16}>
        <Text html t={text} />
      </StyledLine>
    </StyledContainerFlex>
  );
};

export default SliderPage;
