// @flow strict
import React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import * as R from 'ramda/src/index.js';
import { toRender } from './utils.js';

type Props = {
  rating: number,
  stars: number,
  className?: ?string,
};

const StyledSpan = styled.span`
  min-width: 75px;
`;

const Icon = styled.img`
  width: 15px;
  height: 15px;
`;

const StarRating = ({ rating, stars, className }: Props) => {
  const render = toRender(rating, stars);
  return (
    <StyledSpan className={className}>
      {R.map(
        i => (
          <Icon key={i} src="/icons/star-full.svg" alt="star" />
        ),
        R.times(R.identity, render.full),
      )}
      {render.half && <Icon key="half" src="/icons/star-half.svg" alt="star half" />}
      {R.map(
        i => (
          <Icon key={`empty-${i}`} src="/icons/star-empty.svg" alt="star empty" />
        ),
        R.times(R.identity, render.empty),
      )}
    </StyledSpan>
  );
};

StarRating.defaultProps = {
  className: null,
};

export default StarRating;
