// @flow strict
/* eslint-disable react/no-danger */
import React from 'react';
import styledImport from 'styled-components';
const styled = styledImport.default || styledImport;
import { Box, Flex } from '../../primitives/Essentials/index.js';
import Line from '../../primitives/Line/index.js';
import type { Conditions as ConditionsType } from '../../records/Conditions';

const Date = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  margin-top: 4px;
  color: ${({ theme }) => theme.trip.abstract};
`;

const Abstract = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  margin-top: 25px;
  color: ${({ theme }) => theme.trip.abstract};
`;

const Section = styled(Box)`
  margin-top: 25px;
`;

const SectionContent = styled.div`
  margin-top: 15px;
`;

const SectionContentLine = styled(Line).withConfig({
  shouldForwardProp: (prop) => prop !== 'theme', // Allow all props except 'theme'
})`
  color: ${({ theme }) => theme.trip.abstract};
  strong {
    color: ${({ theme }) => theme.primary};
  }
`;

const SubsectionTitle = styled.h2`
  margin: 0;
  font-weight: 900;
  font-size: 19px;
  line-height: 1.45;
`;

type Props = {
  conditions: ConditionsType,
};

const Conditions = ({ conditions }: Props) => (
  <Flex $mt={27} $flexDirection="column">
    <Line fontSize={24} fontWeight={900} lineHeight={1.56}>
      {conditions.title}
    </Line>
    <Line fontSize={16} fontWeight={900} lineHeight={1.56}>
      {conditions.subtitle}
    </Line>
    <Date>{conditions.date}</Date>
    {conditions.abstract && (
      <Abstract>
        <span dangerouslySetInnerHTML={{ __html: conditions.abstract }} />
      </Abstract>
    )}
    {conditions.sections.map((section, i) => (
      <Section>
        <SubsectionTitle>
          {i + 1}. {section.title}
        </SubsectionTitle>
        <SectionContent>
          <SectionContentLine dangerouslySetInnerHTML={{ __html: section.content }} />
        </SectionContent>
      </Section>
    ))}
  </Flex>
);

export default Conditions;
