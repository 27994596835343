// @flow strict
type Cookies = {|
  CURRENCY: string,
  DATE: string,
  VIEWER_COUNTRY: string,
|};

// keep name short
export const COOKIES: Cookies = {
  CURRENCY: 'cur',
  DATE: 'date',
  VIEWER_COUNTRY: 'viewer_country',
};

// expiration 1 year
export const COOKIE_OPTIONS = {
  expires: 365,
};
